import React from 'react';
import {
  Select,
  Empty,
} from 'antd';
import { I18n } from 'react-redux-i18n';

const { Option } = Select;

function hasValueProp(hasValue, value) {
  let response = null;

  if (hasValue) {
    response = {
      value,
    };
  }

  return response;
}

class AdvancedSelectAutoComplete extends React.PureComponent {
  render() {
    const {
      value,
      options,
      label,
      onChange,
      disabled,
      placeholder,
      disableSearch,
      onSearch,
      hasValue,
      onFocus,
    } = this.props;

    return (
      <div className="advanced-select-autocomplete">
        <label className="advanced-select-autocomplete__label">
          <span className="advanced-select-autocomplete__label__inner">
            {label}
          </span>
          <Select
            {...hasValueProp(hasValue, value)}
            style={{ width: '100%' }}
            defaultValue={value || undefined}
            onChange={onChange}
            optionFilterProp={disableSearch ? 'children' : undefined}
            disabled={disabled || false}
            placeholder={placeholder || I18n.t('shared.selectSomeValue')}
            showSearch={!disableSearch || false}
            allowClear
            onFocus={onFocus}
            onSearch={onSearch ? (val) => onSearch(val) : undefined}
            notFoundContent={
              (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={I18n.t('shared.notFoundSearch')}
                />
              )
            }
            filterOption={disableSearch ? (
              input,
              option,
            ) => (
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            ) : undefined}
          >
            {options && options.length > 0 && options.map((a) => (
              <Option
                key={a.id ? a.id.toString() : null}
                value={a.id}
              >
                {a.name || a.administratorName}
              </Option>
            ))}
          </Select>
        </label>
      </div>
    );
  }
}

export default AdvancedSelectAutoComplete;
