import React from 'react';
import { navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import {
  Layout, Row, Col, message,
} from 'antd';

class DeepLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: null,
    };
  }

  componentDidMount() {
    const url = window.location.pathname;
    const dividedUrl = url.split('/');
    if (dividedUrl.length === 3 && dividedUrl[2] !== '') {
      const paymentId = dividedUrl[2];
      const options = {
        url: `beallbee://payment/${paymentId}`,
        fallbackAndroid: 'https://play.google.com/store/apps/details?id=br.com.pleno',
        fallbackIos: 'https://apps.apple.com/br/app/pleno/id1500961287',
      };
      this.setState({ options });
    } else navigate(I18n.t('routes.login.url'));
  }

  deepLink() {
    const { options } = this.state;
    const ua = window.navigator.userAgent;
    const isMobile = {
      android: () => /Android/i.test(ua),
      ios: () => /iPhone|iPad|iPod/i.test(ua),
    };

    if (isMobile.ios() || isMobile.android()) {
      setTimeout(() => {
        document.location = isMobile.android() ? options.fallbackAndroid : options.fallbackIos;
      }, 1000);
    } else {
      message.error(I18n.t('routes.deepLink.webError'));
      setTimeout(() => {
        navigate('/');
        window.location.reload();
      }, 1500);
    }
  }

  render() {
    const { Content } = Layout;
    const { options } = this.state;

    return (
      <Content className="login__container">
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ height: '100%' }}
        >
          <Col style={{ textAlign: 'center' }}>
            <img
              alt="login logo"
              className="login__logo__img"
              src="/assets/img/logo_black.svg"
            />
            <p
              style={{
                fontSize: '1rem',
                margin: '30px 0',
              }}
            >
              {I18n.t('routes.deepLink.initialTitle')}
              <br />
              {I18n.t('routes.deepLink.endTitle')}
            </p>
            <a
              href={options && options.url}
              className="paymentButton"
              onClick={() => this.deepLink()}
              style={{
                backgroundColor: 'black',
                borderRadius: '5px',
                color: 'white',
                fontSize: '1.25rem',
                padding: '10px 20px',
              }}
            >
              {I18n.t('routes.deepLink.paymentButton')}
            </a>
          </Col>
        </Row>
      </Content>
    );
  }
}

export default DeepLink;
