import React from 'react';
import { connect } from 'react-redux';
import {
  Row, Col, message, Divider, Button, Tooltip, Drawer, Card,
} from 'antd';
import { SaveOutlined, CalculatorOutlined } from '@ant-design/icons';
import * as yup from 'yup';
import { I18n } from 'react-redux-i18n';
import moment from 'moment-timezone';

import {
  EstablishmentSelectors,
  AuthSelectors,
  LoadingSelectors,
  CategorySelectors,
} from '../../redux/reducers';
import { UtilsActions } from '../../redux/actions';

import { removeSpecialChars } from '../../utils/string';
import { DaysTypeArray, GetDaysTypeTitle } from '../../enum/daysType';
import {
  AgencyTypeArray, AccountTypeArray, GetAgencyTypeAgencyMask, GetAgencyTypeAccountMask,
} from '../../enum/agencyType';
import { isAdmin } from '../../utils/permissions';
import { formatCurrency } from '../../utils/currency';

import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import AdvancedMaskedInput from '../../../components/shared/AdvancedMaskedInput/AdvancedMaskedInput';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedDatePicker from '../../../components/shared/AdvancedDatePicker/AdvancedDatePicker';
import AdvancedTimePicker from '../../../components/shared/AdvancedTimePicker/AdvancedTimePicker';
import AdvancedUpload from '../../../components/shared/AdvancedUpload/AdvancedUpload';
import AmountSelection from '../../../components/shared/AmountSelection/AmountSelection';
import TaxesCalculator from '../../../components/panel/taxesCalculator';
import AdvancedCheckbox from '../../../components/shared/AdvancedCheckbox/AdvancedCheckbox';

class EstablishmentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        name: '',
        description: '',
        openingHours: '',
        closingHours: '',
        discount: 5,
        picture: '',
        uf: '',
        city: '',
        neighborhood: '',
        doc: '',
        zipcode: '',
        street: '',
        addressNumber: '',
        latitude: '',
        longitude: '',
        contractStartAt: null,
        contractEndAt: null,
        contractValue: null,
        categories: [],
        phone: '',
        cellphone: '',
        dayWeek: DaysTypeArray.map((day) => ({
          id: day.id,
          name: day.name,
          disabled: true,
          startAt: null,
          endAt: null,
        })),
        installments: 1,
        beAllBeeCommission: 0,
        bankCode: '',
        bankAgency: '',
        accountType: '',
        bankAccount: '',
        priceRange: '',
      },
      useSamePhones: false,
      showCalculator: false,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps() {
    const { details, id } = this.props;

    if (details && id !== 'add') {
      this.setState({
        form: {
          name: details.name,
          description: details.description,
          discount: details.discount,
          picture: details.picUrl,
          uf: details.uf,
          city: details.city,
          neighborhood: details.neighborhood,
          doc: details.doc,
          zipcode: details.zipcode,
          street: details.street,
          addressNumber: details.addressNumber,
          latitude: details.latitude,
          longitude: details.longitude,
          contractStartAt: moment(details.contractStartAt, 'YYYY-MM-DD'),
          contractEndAt: moment(details.contractEndAt, 'YYYY-MM-DD'),
          contractValue: parseFloat(details.contractValue),
          categories: details.categories && details.categories.map((i) => i.id),
          phone: details.phone && details.phone.substr(2, details.phone.length),
          cellphone: details.cellphone && details.cellphone.substr(2, details.cellphone.length),
          dayWeek:
            details.dayWeek
            && details.dayWeek.map((day) => ({
              id: day.id,
              name: GetDaysTypeTitle(day.id),
              disabled: day.disabled,
              startAt: day.disabled ? null : moment(day.startAt, 'HH:mm'),
              endAt: day.disabled ? null : moment(day.endAt, 'HH:mm'),
            })),
          installments: details.installments,
          beAllBeeCommission: parseInt(details.beAllBeeCommission, 10) || 0,
          bankCode: (details.subAccount && details.subAccount.bankCode) || '',
          bankAgency: (details.subAccount && details.subAccount.bankAgency) || '',
          accountType: (details.subAccount && details.subAccount.accountType) || '',
          bankAccount: (details.subAccount && details.subAccount.bankAccount) || '',
          priceRange: (details.subAccount && details.subAccount.priceRange) || '',
        },
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const { form, useSamePhones } = this.state;

    const schema = yup.object().shape({
      name: yup.string().required(I18n.t('shared.errors.missing_name')),
      description: yup.string().required(I18n.t('shared.errors.missing_description')),
      zipcode: yup.string().required(I18n.t('shared.errors.missing_zipcode')),
      neighborhood: yup.string().required(I18n.t('shared.errors.missing_neighborhood')),
      street: yup.string().required(I18n.t('shared.errors.missing_street')),
      city: yup.string().required(I18n.t('shared.errors.missing_city')),
      uf: yup.string().required(I18n.t('shared.errors.missing_uf')),
      addressNumber: yup.string().required(I18n.t('shared.errors.missing_addressNumber')),
      latitude: yup.string().required(I18n.t('shared.errors.missing_latitude')),
      longitude: yup.string().required(I18n.t('shared.errors.missing_longitude')),
      doc: yup.string().required(I18n.t('shared.errors.missing_cnpj')),
      categories: yup.array().of(yup.string()).min(1, I18n.t('shared.errors.missing_categories')),
      phone: yup.string().required(I18n.t('shared.errors.missing_cellphone')),
      bankCode: yup.string().required(I18n.t('shared.errors.missing_bankCode')),
      bankAgency: yup.string().required(I18n.t('shared.errors.missing_bankAgency')),
      accountType: yup.string().required(I18n.t('shared.errors.missing_accountType')),
      bankAccount: yup.string().required(I18n.t('shared.errors.missing_bankAccount')),
      priceRange: yup.string().required(I18n.t('shared.errors.missing_priceRange')),
    });

    schema.validate(form, { abortEarly: true })
      .then(() => {
        const { details, submitFunction } = this.props;
        let cellphone = form.phone;

        if (!form.picture) return message.error('Adicione uma foto para finalizar o cadastro!');
        if (!(form.contractStartAt && form.contractEndAt)) return message.error(I18n.t('shared.errors.missing_contractData'));
        if (!(moment(form.contractStartAt).isValid() && moment(form.contractEndAt).isValid())) return message.error(I18n.t('shared.errors.missing_contractData'));
        if (moment(form.contractEndAt).isBefore(moment(form.contractStartAt))) return message.error(I18n.t('shared.errors.missing_contractData'));
        if (!useSamePhones && !form.cellphone) return message.error(I18n.t('shared.genericFieldsError'));
        // if (!validateCnpj(form.doc)) return message.error(I18n.t('shared.errors.invalid_cnpj'));

        if (!useSamePhones) cellphone = form.cellphone;

        if (form.dayWeek.some((i) => !i.disabled && (!i.startAt || !i.endAt))) return message.error('Os dias selecionados devem receber um valor!');

        let priceRange = 'Até R$ 100,00';
        if (formatCurrency(form.priceRange) > 100 && formatCurrency(form.priceRange) < 500) priceRange = 'Entre R$ 100,00 e R$ 500,00';
        else if (formatCurrency(form.priceRange) > 500) priceRange = 'Mais que R$ 500,00';

        const data = {
          ...form,
          doc: removeSpecialChars(form.doc),
          zipcode: removeSpecialChars(form.zipcode),
          contractStartAt: moment(form.contractStartAt).format('YYYY-MM-DD'),
          contractEndAt: moment(form.contractEndAt).format('YYYY-MM-DD'),
          contractValue: form.contractValue ? formatCurrency(form.contractValue) : null,
          ...(form && form.picture && { picUrl: form.picture.url }),
          phone: `55${removeSpecialChars(form.phone)}`,
          cellphone: `55${removeSpecialChars(cellphone)}`,
          dayWeek: form.dayWeek.map((day) => ({
            id: day.id,
            startAt: day.disabled ? null : moment(day.startAt).format('HH:mm'),
            endAt: day.disabled ? null : moment(day.endAt).format('HH:mm'),
            disabled: day.disabled,
          })),
          subAccount: {
            bankCode: form.bankCode,
            bankAgency: `${removeSpecialChars(form.bankAgency)}`,
            accountType: form.accountType,
            bankAccount: `${form.bankAccount}`,
            businessDescription: form.description,
            priceRange,
            responsibleSubAccount: {
              cellphone: `${removeSpecialChars(form.phone)}`,
              name: form.name,
              doc: removeSpecialChars(form.doc),
            },
          },
        };

        submitFunction(details ? details.id : null, data);
      })
      .catch((err) => message.error(err.message || I18n.t('shared.genericError')));
  }

  async getAddressByCep(cep) {
    const { getAddressByCep } = this.props;
    const completeAddress = await getAddressByCep(cep);
    if (!completeAddress.error) {
      this.fieldChange('uf', completeAddress.uf);
      this.fieldChange('city', completeAddress.localidade);
      this.fieldChange('street', completeAddress.logradouro);
      this.fieldChange('neighborhood', completeAddress.bairro);
      this.fieldChange('zipcode', completeAddress.cep);
    }
  }

  fieldChange(name, value) {
    const { form } = this.state;
    form[name] = value || '';
    this.setState({ form });
  }

  dayChange(index, field, value) {
    const { form } = this.state;
    form.dayWeek[index][field] = value;

    this.setState({ form });
  }

  copyDaysData(index) {
    const { form } = this.state;
    const selectedDay = form.dayWeek[index];

    const newDays = form.dayWeek.map((day) => ({
      ...day,
      startAt: day.disabled ? null : selectedDay.startAt,
      endAt: day.disabled ? null : selectedDay.endAt,
    }));

    form.dayWeek = newDays;

    this.setState({ form });
  }

  render() {
    const {
      loading, categoriesPaginated, uploadImage, me, id,
    } = this.props;

    const { form, useSamePhones } = this.state;

    return (
      <Row>
        {loading === 0 && (
          <Col>
            <form
              name="form"
              onSubmit={(ev) => this.onSubmit(ev)}
            >
              <Row gutter={16}>
                <Col>
                  <h5>{I18n.t('routes.panel.establishment.items.establishment.details.establishmentSection')}</h5>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={6}>
                  <AdvancedUpload
                    label={I18n.t('routes.panel.establishment.items.establishment.details.form.picUrl.label')}
                    pictureUrl={form && ((form.picture && form.picture.base64) || form.picture)}
                    compressImage={false}
                    shape="round"
                    aspect={1}
                    onUpload={(photoData, photoName, photoType) => uploadImage(photoData, photoName, photoType)}
                    onChange={(val) => this.fieldChange('picture', val)}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedSelect
                    options={!!categoriesPaginated && categoriesPaginated.rows}
                    label={I18n.t('routes.panel.establishment.items.establishment.details.form.categories.label')}
                    placeholder={I18n.t('shared.selectSomeValue')}
                    onChange={(val) => this.fieldChange('categories', val)}
                    disabled={loading && loading > 0}
                    disableSearch
                    mode="multiple"
                    hasValue
                    value={form && form.categories}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.establishment.items.establishment.details.form.name.label')}
                    value={form && form.name}
                    onChange={(val) => this.fieldChange('name', val)}
                    disabled={id !== 'add'}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedMaskedInput
                    label={I18n.t('routes.panel.establishment.items.establishment.details.form.doc.label')}
                    value={form && form.doc}
                    onChange={(val) => this.fieldChange('doc', val)}
                    mask={I18n.t('shared.cnpjMask')}
                    disabled={id !== 'add'}
                  />
                </Col>
                <Col span={24}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.establishment.items.establishment.details.form.description.label')}
                    value={form && form.description}
                    onChange={(val) => this.fieldChange('description', val)}
                    isTextArea
                    hasCountDown
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedMaskedInput
                    label={I18n.t('routes.panel.establishment.items.establishment.details.form.zipcode.label')}
                    value={form && form.zipcode}
                    onChange={(val) => {
                      this.fieldChange('zipcode', val);
                      if (val && removeSpecialChars(val).length === 8) {
                        this.getAddressByCep(removeSpecialChars(val));
                      }
                    }}
                    mask={I18n.t('shared.zipCodeMask')}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.establishment.items.establishment.details.form.neighborhood.label')}
                    value={form && form.neighborhood}
                    onChange={(val) => this.fieldChange('neighborhood', val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.establishment.items.establishment.details.form.street.label')}
                    value={form && form.street}
                    onChange={(val) => this.fieldChange('street', val)}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.establishment.items.establishment.details.form.city.label')}
                    value={form && form.city}
                    onChange={(val) => this.fieldChange('city', val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.establishment.items.establishment.details.form.uf.label')}
                    value={form && form.uf}
                    onChange={(val) => this.fieldChange('uf', val)}
                    maxLength={2}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.establishment.items.establishment.details.form.addressNumber.label')}
                    value={form && form.addressNumber}
                    onChange={(val) => this.fieldChange('addressNumber', val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.establishment.items.establishment.details.form.latitude.label')}
                    value={form && form.latitude}
                    onChange={(val) => {
                      val.replace(/,/, '.');
                      this.fieldChange('latitude', val.replace(/,/, '.'));
                    }}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.establishment.items.establishment.details.form.longitude.label')}
                    value={form && form.longitude}
                    onChange={(val) => {
                      this.fieldChange('longitude', val.replace(/,/, '.'));
                    }}
                  />
                </Col>
              </Row>

              <Divider />

              <Row gutter={16}>
                <Col span={12}>
                  <Row gutter={16}>
                    <Col>
                      <h5>{I18n.t('routes.panel.establishment.items.establishment.details.contactSection')}</h5>
                    </Col>
                  </Row>
                  <Row
                    gutter={16}
                    className="pt-2"
                  >
                    <Col span={12}>
                      <AdvancedMaskedInput
                        label={I18n.t('routes.panel.establishment.items.establishment.details.form.phone.label')}
                        value={form && form.phone}
                        onChange={(val) => this.fieldChange('phone', val)}
                        mask={I18n.t('shared.phoneMask')}
                      />

                    </Col>
                    {!useSamePhones && (
                      <Col span={12}>
                        <AdvancedMaskedInput
                          label={I18n.t(
                            'routes.panel.establishment.items.establishment.details.form.cellphone.label',
                          )}
                          value={form && form.cellphone}
                          onChange={(val) => this.fieldChange('cellphone', val)}
                          mask={I18n.t('shared.phoneMask')}
                        />
                      </Col>
                    )}
                    <AdvancedCheckbox
                      label={I18n.t('routes.panel.establishment.items.establishment.details.form.whatsapp.label')}
                      onChange={(e) => this.setState({ useSamePhones: e })}
                      value={useSamePhones}
                    />
                  </Row>
                </Col>
                <Col span={12}>
                  <Card>
                    <Row gutter={16}>
                      <Col span={24}>
                        <h5 className="center-title">{I18n.t('routes.panel.establishment.items.establishment.details.settingsSection')}</h5>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col
                        span={24}
                        className="d-flex"
                      >
                        <AmountSelection
                          suffix={I18n.t('application.percent')}
                          value={form && form.discount}
                          description={I18n.t(
                            'routes.panel.establishment.items.establishment.details.form.discount.label',
                          )}
                          onChange={(val) => this.fieldChange('discount', val)}
                          percentage
                          min={5}
                          number={1}
                          max={50}
                        />
                        {isAdmin(me) && (
                          <AmountSelection
                            suffix={I18n.t('application.percent')}
                            value={form && form.beAllBeeCommission}
                            description={I18n.t(
                              'routes.panel.establishment.items.establishment.details.form.beAllBeeCommission.label',
                            )}
                            onChange={(val) => this.fieldChange('beAllBeeCommission', val)}
                            percentage
                            min={0}
                            number={1}
                            max={40}
                          />
                        )}
                        <AmountSelection
                          value={form && form.installments}
                          description={I18n.t(
                            'routes.panel.establishment.items.establishment.details.form.installments.label',
                          )}
                          onChange={(val) => this.fieldChange('installments', val)}
                          percentage
                          min={1}
                          max={12}
                          number={1}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Drawer
                  title={I18n.t('routes.panel.establishment.items.establishment.details.form.calculator.label')}
                  placement="right"
                  closable
                  onClose={() => this.setState({ showCalculator: false })}
                  visible={this.state.showCalculator}
                  width="35%"
                >
                  <TaxesCalculator
                    beAllBeeCommission={form && form.beAllBeeCommission}
                    beecashEstablishment={form && form.discount}
                  />
                </Drawer>
                <AdvancedButton
                  text={I18n.t('routes.panel.establishment.items.establishment.details.form.calculator.label').toUpperCase()}
                  icon={<CalculatorOutlined />}
                  className="advanced-button advanced-button--sticked"
                  onClick={() => this.setState({ showCalculator: true })}
                />
              </Row>

              <Divider />

              <Row gutter={16}>
                <Col>
                  <h5>{I18n.t('routes.panel.establishment.items.establishment.details.daysSection')}</h5>
                </Col>
              </Row>

              {form.dayWeek
                && form.dayWeek.map((day, index) => (
                  <Row
                    gutter={16}
                    key={day.id}
                    type="flex"
                    align="middle"
                  >
                    <Col
                      span={24}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <div
                        className="mr-4"
                        style={{ width: ' 180px' }}
                      >
                        <AdvancedCheckbox
                          label={day.name}
                          onChange={(e) => this.dayChange(index, 'disabled', !e)}
                          value={!day.disabled}
                          customClass="advanced-checkbox--no-padding"
                        />
                      </div>

                      <div className="mr-4">
                        <AdvancedTimePicker
                          label={I18n.t('routes.panel.establishment.items.establishment.details.form.openingHours.label')}
                          onChange={(e) => this.dayChange(index, 'startAt', e)}
                          value={day.startAt}
                          disabled={day.disabled}
                        />
                      </div>

                      <div className="mr-4">
                        <AdvancedTimePicker
                          label={I18n.t('routes.panel.establishment.items.establishment.details.form.closingHours.label')}
                          onChange={(e) => this.dayChange(index, 'endAt', e)}
                          value={day.endAt}
                          disabled={day.disabled}
                        />
                      </div>

                      {!day.disabled && day.startAt && day.endAt && (
                        <Tooltip title={I18n.t('routes.panel.establishment.items.establishment.details.form.messageCopyValueDate.label')}>
                          <Button
                            shape="square"
                            icon="copy"
                            onClick={() => this.copyDaysData(index)}
                          />
                        </Tooltip>
                      )}
                    </Col>
                  </Row>
                ))}

              {isAdmin(me) && (
                <>
                  <Divider />

                  <Row gutter={16}>
                    <Col>
                      <h5>{I18n.t('routes.panel.establishment.items.establishment.details.contractSection')}</h5>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={6}>
                      <AdvancedDatePicker
                        label={I18n.t(
                          'routes.panel.establishment.items.establishment.details.form.contractStartAt.label',
                        )}
                        onChange={(val) => this.fieldChange('contractStartAt', val)}
                        value={form && form.contractStartAt}
                        format="DD/MM/YYYY"
                      />
                    </Col>

                    <Col span={6}>
                      <AdvancedDatePicker
                        label={I18n.t(
                          'routes.panel.establishment.items.establishment.details.form.contractEndAt.label',
                        )}
                        onChange={(val) => this.fieldChange('contractEndAt', val)}
                        value={form && form.contractEndAt}
                        format="DD/MM/YYYY"
                      />
                    </Col>
                    <Col span={6}>
                      <AdvancedInput
                        label={I18n.t('routes.panel.establishment.items.establishment.details.form.contractValue.label')}
                        value={form && form.contractValue}
                        onChange={(val) => this.fieldChange('contractValue', val)}
                        isMoney
                      />
                    </Col>
                  </Row>
                </>
              )}

              <Divider />

              <Row gutter={16}>
                <Col>
                  <h5>{I18n.t('routes.panel.establishment.items.establishment.details.subAccountSection')}</h5>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={6}>
                  <AdvancedSelect
                    options={AgencyTypeArray}
                    label={I18n.t('routes.panel.establishment.items.establishment.details.form.bank.label')}
                    placeholder={I18n.t('shared.selectSomeValue')}
                    onChange={(val) => this.fieldChange('bankCode', val)}
                    disabled={(loading && loading > 0) || id !== 'add'}
                    hasValue
                    value={form && form.bankCode}
                  />
                </Col>

                <Col span={6}>
                  <AdvancedSelect
                    options={AccountTypeArray}
                    label={I18n.t('routes.panel.establishment.items.establishment.details.form.accountType.label')}
                    placeholder={I18n.t('shared.selectSomeValue')}
                    onChange={(val) => this.fieldChange('accountType', val)}
                    disabled={(loading && loading > 0) || id !== 'add'}
                    hasValue
                    value={form && form.accountType}
                  />
                </Col>

                {form && form.bankCode && (
                  <>
                    <Col span={6}>
                      {id !== 'add' ? (
                        <AdvancedInput
                          label={I18n.t('routes.panel.establishment.items.establishment.details.form.bankAgency.label')}
                          value={form && form.bankAgency}
                          disabled
                        />
                      ) : (
                        <AdvancedMaskedInput
                          label={I18n.t('routes.panel.establishment.items.establishment.details.form.bankAgency.label')}
                          value={form && form.bankAgency}
                          onChange={(val) => this.fieldChange('bankAgency', val)}
                          mask={GetAgencyTypeAgencyMask(form && form.bankCode)}
                        />
                      )}
                    </Col>

                    <Col span={6}>
                      {id === 'add' ? (
                        <AdvancedMaskedInput
                          label={I18n.t('routes.panel.establishment.items.establishment.details.form.bankAccount.label')}
                          value={form && form.bankAccount}
                          onChange={(val) => this.fieldChange('bankAccount', val)}
                          mask={GetAgencyTypeAccountMask(form && form.bankCode)}
                        />
                      ) : (
                        <AdvancedInput
                          label={I18n.t('routes.panel.establishment.items.establishment.details.form.bankAccount.label')}
                          value={form && form.bankAccount}
                          disabled
                        />
                      )}
                    </Col>
                  </>
                )}
              </Row>
              <Row gutter={16}>
                <Col span={6}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.establishment.items.establishment.details.form.priceRangeMax.label')}
                    value={form && form.priceRange}
                    onChange={(val) => this.fieldChange('priceRange', val)}
                    isMoney
                    precision="0"
                    disabled={id !== 'add'}
                  />
                </Col>
              </Row>

              {form && form.bankCode && (
                <p className="input-observation">
                  {I18n.t('routes.panel.establishment.items.establishment.details.form.bankAccount.observation')}
                </p>
              )}

              <Divider />

              <Row>
                <Col
                  span={24}
                  className="text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.backButtonText')}
                    href={I18n.t('routes.panel.establishment.items.establishment.url')}
                  />

                  <Divider
                    className="form-button-divider"
                    type="vertical"
                  />

                  <AdvancedButton
                    htmlType="submit"
                    text={I18n.t('shared.saveButtonText')}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </form>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  details: EstablishmentSelectors.getDetails(state),
  categoriesPaginated: CategorySelectors.getPaginated(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAddressByCep: (cep) => dispatch(UtilsActions.getAddressByCep(cep)),
  uploadImage: (photoData, photoName, photoType, callback) => dispatch(UtilsActions.uploadImage(photoData, photoName, photoType, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EstablishmentForm);
