import React from 'react';
import {
  Divider,
  Layout,
  Row,
  Col,
  message,
  Breadcrumb,
  Icon,
  Collapse,
  Popconfirm,
  Button,
  Modal,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { SearchOutlined, DeleteOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';

import { SchedulingRecreationActions, CondominiumActions } from '../../../../../app/redux/actions';
import { SchedulingRecreationSelectors, LoadingSelectors, AuthSelectors } from '../../../../../app/redux/reducers';

import QueryStringUtils from '../../../../../app/utils/queryString';
import { toCurrencyLocale } from '../../../../../app/utils/currency';
import { GetScheduleTypeTag, GetStatusTypeTag, ScheduleStatus } from '../../../../../app/enum/scheduleType';
import * as DateUtils from '../../../../../app/utils/date';

import DataTable from '../../../../../components/shared/DataTable/DataTable';
import AdvancedButton from '../../../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedInput from '../../../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedAutoCompleteInput from '../../../../../components/shared/AdvancedAutoCompleteInput/AdvancedAutoCompleteInput';
import AdvancedRangePicker from '../../../../../components/shared/AdvancedRangePicker/AdvancedRangePicker';
import ExportDataModal from '../../../../../components/panel/exportDataModal/ExportDataModal';
import { isAdmin, isCondominiumManager, isGoverningBody } from '../../../../../app/utils/permissions';

const qs = new QueryStringUtils();
const { Panel } = Collapse;

const defaultParams = {
  recreationName: null,
};

class RecreationAreaReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: defaultParams,
      exportModalVisible: false,
      modalRange: false,
      isAdminModalVisible: true,
      condominiumId: null,
    };
    this.dataTableRef = React.createRef();
  }

  componentDidMount() {
    const { me } = this.props;

    if (isGoverningBody(me)) this.setState({ isAdminModalVisible: false, condominiumId: me.condominiumId });
  }

  getPaginatedData(params) {
    const { getPaginated } = this.props;
    const { condominiumId } = this.state;

    const newParams = { ...params, condominiumId };

    getPaginated(newParams);
  }

  getPaginatedDataAndRefreshTable() {
    const { params } = this.state;
    if (this.dataTableRef) {
      this.dataTableRef.current.reset(false);
      this.getPaginatedData(params);
    }
  }

  fieldChange(name, value) {
    const { params } = this.state;
    params[name] = value || null;
    this.setState({ params });
  }

  applyAdvancedFilters() {
    let { params } = this.state;
    params = {
      ...params,
      page: 1,
      offset: 0,
    };

    qs.set({ params });

    this.getPaginatedDataAndRefreshTable(params);
  }

  cleanAdvancedFilters() {
    qs.set({ params: undefined });
    window.location.reload();
  }

  remove(id) {
    const { update } = this.props;

    update(id, { isCanceled: true }, () => {
      message.success(I18n.t('routes.panel.schedule.items.recreationAreaReport.messages.deleteSuccess'));
      this.getPaginatedData({}, true);
    });
  }

  render() {
    const { Content } = Layout;
    const {
      params, exportModalVisible, modalRange, isAdminModalVisible, condominiumId,
    } = this.state;
    const {
      paginatedData, loading, me, getTransactionReportExport, getCondominiumPaginatedForSearch,
    } = this.props;

    const columns = [
      {
        key: I18n.t('routes.panel.schedule.items.recreationAreaReport.dataTable.columns.name.key'),
        title: I18n.t('routes.panel.schedule.items.recreationAreaReport.dataTable.columns.name.title'),
        render: (value) => value || '--',
      },
      {
        key: I18n.t('routes.panel.schedule.items.recreationAreaReport.dataTable.columns.scheduledBy.key'),
        title: I18n.t('routes.panel.schedule.items.recreationAreaReport.dataTable.columns.scheduledBy.title'),
        render: (value) => (value && value.name) || '--',
      },
      {
        key: I18n.t('routes.panel.schedule.items.recreationAreaReport.dataTable.columns.condominiumUser.key'),
        title: I18n.t('routes.panel.schedule.items.recreationAreaReport.dataTable.columns.condominiumUser.title'),
        render: (i) => (i && `${i.block ? `${i.condominium.type === 2 ? 'Bloco' : 'Rua'} ${i.block}, ` : ''}
          ${i.number ? `${i.condominium.type === 2 ? 'Apartamento' : 'Casa'} ${i.number}` : ''}`) || '--',
      },
      {
        key: I18n.t('routes.panel.schedule.items.recreationAreaReport.dataTable.columns.price.key'),
        title: I18n.t('routes.panel.schedule.items.recreationAreaReport.dataTable.columns.price.title'),
        render: (value) => (value && toCurrencyLocale(value.replace('.', ','))) || '--',
      },
      {
        key: I18n.t('routes.panel.schedule.items.recreationAreaReport.dataTable.columns.scheduleType.key'),
        title: I18n.t('routes.panel.schedule.items.recreationAreaReport.dataTable.columns.scheduleType.title'),
        render: (value) => (value && GetScheduleTypeTag(value)) || '--',
      },
      {
        key: I18n.t('routes.panel.schedule.items.recreationAreaReport.dataTable.columns.schedulingRecurringRecreation.key'),
        title: I18n.t('routes.panel.schedule.items.recreationAreaReport.dataTable.columns.schedulingRecurringRecreation.title'),
        render: (value) => (value && DateUtils.humanizeDateTime(value.dateInitial, 'DD/MM/YYYY HH:mm')) || '--',
      },
      {
        key: I18n.t('routes.panel.schedule.items.recreationAreaReport.dataTable.columns.schedulingRecurringRecreation.key'),
        title: I18n.t('routes.panel.schedule.items.recreationAreaReport.dataTable.columns.schedulingRecurringRecreation.titleEnd'),
        render: (value) => (value && DateUtils.humanizeDateTime(value.dateEnd, 'DD/MM/YYYY HH:mm')) || '--',
      },
      {
        key: I18n.t('routes.panel.schedule.items.recreationAreaReport.dataTable.columns.status.key'),
        title: I18n.t('routes.panel.schedule.items.recreationAreaReport.dataTable.columns.status.title'),
        render: (value, body) => (value && GetStatusTypeTag(ScheduleStatus.CANCELED)) || (moment(body.schedulingRecurringRecreation.dateEnd).isBefore(moment())
          ? GetStatusTypeTag(ScheduleStatus.FINALIZED)
          : GetStatusTypeTag(ScheduleStatus.PENDENT)),
      },
    ];

    if (isCondominiumManager(me) || isAdmin(me)) {
      columns.splice(7, 0, {
        key: I18n.t('routes.panel.schedule.items.recreationAreaReport.dataTable.columns.actions.key'),
        title: '',
        render: (id, body) => (
          <div className="dataTable__item--right">
            <Popconfirm
              placement="left"
              title={I18n.t('shared.confirmTitle')}
              onConfirm={() => this.remove(id)}
              okText={I18n.t('shared.yes')}
              cancelText={I18n.t('shared.no')}
            >
              <Button
                type="link"
                disabled={body.isCanceled || moment(body.schedulingRecurringRecreation.dateEnd).isBefore(moment())}
              >
                {I18n.t('routes.panel.schedule.items.recreationAreaReport.dataTable.columns.actions.removeText')}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </div>
        ),
      });
    }

    return (
      <div>
        {isAdminModalVisible && (
          <Modal
            title={I18n.t('routes.panel.schedule.items.recreationArea.condominiumModal.title')}
            visible
            onOk={() => {
              if (!condominiumId) return message.error(I18n.t('routes.panel.schedule.items.recreationArea.condominiumModal.error'));
              this.setState({ isAdminModalVisible: false });
            }}
            okText={I18n.t('routes.panel.schedule.items.recreationArea.condominiumModal.okText')}
            onCancel={() => {
              if (!condominiumId) return message.error(I18n.t('routes.panel.schedule.items.recreationArea.condominiumModal.error'));
              this.setState({ isAdminModalVisible: false });
            }}
          >
            <AdvancedAutoCompleteInput
              searchMethod={(parameters) => getCondominiumPaginatedForSearch(parameters)}
              data={I18n.t('advancedAutoComplete.condominium')}
              onSelect={(item) => this.setState({ condominiumId: item.id })}
              onSelectSelectable={(id) => this.setState({ condominiumId: id })}
              value={condominiumId}
              disableSearch
            />
          </Modal>
        )}

        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.schedule.items.recreationAreaReport.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse
            className="advanced-filter"
          >
            <Panel
              header={<strong>{I18n.t('routes.panel.schedule.items.recreationAreaReport.advancedFilter.panelTitle')}</strong>}
              key="1"
            >
              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.schedule.items.recreationAreaReport.advancedFilter.recreationName.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.schedule.items.recreationAreaReport.advancedFilter.recreationName.key'), val)}
                    value={params && params.recreationName}
                  />
                </Col>

                <Col span={8}>
                  <AdvancedRangePicker
                    label={I18n.t('routes.panel.schedule.items.recreationAreaReport.advancedFilter.dateInitial.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.schedule.items.recreationAreaReport.advancedFilter.dateInitial.key'), val)}
                    value={params && params.dateInitial}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col
                  span={24}
                  className="advanced-filter__search-button text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.advancedFilter.cleanButtonText')}
                    onClick={() => this.cleanAdvancedFilters()}
                  />
                  <AdvancedButton
                    text={I18n.t('shared.advancedFilter.searchButtonText')}
                    icon={<SearchOutlined />}
                    onClick={() => this.applyAdvancedFilters()}
                  />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="administrators">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    <Icon type={I18n.t('routes.panel.schedule.items.recreationAreaReport.icon')} />
                  </span>
                  {I18n.t('routes.panel.schedule.items.recreationAreaReport.pageTitle')}
                </h2>
              </Col>
              {(isCondominiumManager(me) || isAdmin(me)) && (
                <Col
                  className="text-right"
                  span={12}
                >
                  <AdvancedButton
                    text={I18n.t('routes.panel.schedule.items.recreationAreaReport.addNewButtonText')}
                    icon={<CloudDownloadOutlined />}
                    onClick={() => this.setState({ exportModalVisible: true })}
                  />
                </Col>
              )}
            </Row>

            <Divider />

            <div>
              {((isAdmin(me) && !isAdminModalVisible) || isGoverningBody(me)) && (
                <DataTable
                  getMethod={(parameters) => this.getPaginatedData(parameters)}
                  data={paginatedData}
                  loading={loading > 0}
                  ref={this.dataTableRef}
                  columns={columns}
                />
              )}
            </div>
          </div>
          <ExportDataModal
            onChangeVisible={(visible) => this.setState({ exportModalVisible: visible })}
            exportFunction={() => getTransactionReportExport({ modalRange })}
            onChangeField={(val) => this.setState({ modalRange: val })}
            isVisible={exportModalVisible}
            loading={loading}
            me={me}
            data={modalRange}
          />
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  paginatedData: SchedulingRecreationSelectors.getPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters) => dispatch(SchedulingRecreationActions.getPaginated(parameters)),
  getCondominiumPaginatedForSearch: (parameters) => dispatch(CondominiumActions.getPaginatedForSearch(parameters)),
  update: (id, data, callback) => dispatch(SchedulingRecreationActions.update(id, data, callback)),
  getTransactionReportExport: (params) => dispatch(SchedulingRecreationActions.exportReport(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecreationAreaReport);
