import React from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  message,
  Divider,
  Checkbox,
} from 'antd';
import * as yup from 'yup';
import { I18n, Translate } from 'react-redux-i18n';
import { SaveOutlined } from '@ant-design/icons';

import { CondominiumActions, UtilsActions } from '../../redux/actions';
import {
  AuthSelectors, LoadingSelectors, UserSelectors,
} from '../../redux/reducers';

import { ViewType } from '../../enum/viewType';
import { ProfileType } from '../../enum/profileType';
import { validateEmail } from '../../utils/string';
import { isAdmin, isCondominiumManager } from '../../utils/permissions';

import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedAutoCompleteInput from '../../../components/shared/AdvancedAutoCompleteInput';
import AdvancedUpload from '../../../components/shared/AdvancedUpload/AdvancedUpload';

export const ProfileTypeArray = [
  { id: ProfileType.DOORMAN, name: <Translate value="enum.profileType.doorman" /> },
  { id: ProfileType.CARETAKER, name: <Translate value="enum.profileType.caretaker" /> },
];

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('Você só pode enviar arquivos no formato JPG/PNG!');
  }
  const isLt2M = file.size / 1024 / 1024 < 3;
  if (!isLt2M) {
    message.error('A imagem deve ser menor ou igual a 3MB!');
  }
  return isJpgOrPng && isLt2M;
}

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changePasswordVisible: false,
      form: {
        name: '',
        email: '',
        password: null,
        passwordConfirmation: null,
        condominiumId: null,
        profileType: ProfileType.DOORMAN,
      },
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps() {
    const {
      details,
      id,
    } = this.props;

    if (details && id !== 'add') {
      this.setState({
        changePasswordVisible: false,
        form: {
          name: details && details.name,
          email: details && details.email,
          password: null,
          passwordConfirmation: null,
          condominiumId: details && details.condominiumId,
          profilePicUrl: details && details.profilePicUrl,
          profileType: details && details.profileType,
        },
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const { form } = this.state;
    const { viewType } = this.props;

    const schema = yup.object().shape({
      name: yup.string().required(I18n.t('shared.errors.missing_name')),
      email: yup.string().required(I18n.t('shared.errors.missing_email')),
      profileType: yup.number().required(I18n.t('shared.errors.missing_profileType')),
    });

    schema.validate(form, { abortEarly: true })
      .then(() => {
        const { details, submitFunction, me } = this.props;

        if (!validateEmail(form.email)) {
          return message.error(I18n.t('shared.errors.invalid_email'));
        }

        if (viewType === ViewType.ADD || this.state.changePasswordVisible) {
          if (!(form.password && form.passwordConfirmation)) {
            return message.error(I18n.t('shared.errors.passwordsRequired'));
          }
          if (form.password !== form.passwordConfirmation) {
            return message.error(I18n.t('shared.errors.passwordsMismatch'));
          }
        }

        if (!isCondominiumManager(me) && !form.condominiumId) {
          return message.error(I18n.t('shared.errors.missing_condominiumId'));
        }

        if (viewType === ViewType.EDIT && !this.state.changePasswordVisible) {
          delete form.password;
          delete form.passwordConfirmation;
        } else if (viewType === ViewType.EDIT) delete form.passwordConfirmation;

        if (viewType === ViewType.EDIT && form && !form.profilePicUrl) {
          delete form.profilePicUrl;
        }
        const data = {
          ...form,
          ...(form && form.picture && { profilePicUrl: form.picture.url }),
        };

        if (data.picture) delete data.picture;

        if (isCondominiumManager(me)) data.condominiumId = me.condominiumId;

        submitFunction(details ? details.id : null, data);
      })
      .catch((err) => message.error(err.message || I18n.t('shared.genericError')));
  }

  fieldChange(name, value) {
    const { form } = this.state;
    form[name] = value || '';
    this.setState({ form });
  }

  render() {
    const {
      me,
      loading,
      viewType,
      getCondominiumPaginatedForSearch,
      uploadImage,
    } = this.props;
    const { form, changePasswordVisible } = this.state;

    return (
      <Row>
        {loading === 0 && (
          <Col>
            <form
              name="form"
              onSubmit={(ev) => this.onSubmit(ev)}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.users.items.doorman.details.form.email.label')}
                    value={form && form.email}
                    onChange={(val) => this.fieldChange('email', val)}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>

                <Col span={12}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.users.items.doorman.details.form.name.label')}
                    value={form && form.name}
                    onChange={(val) => this.fieldChange('name', val)}
                  />
                </Col>
              </Row>

              {viewType === ViewType.EDIT && (
                <Row
                  gutter={16}
                  className="form-password-divider"
                >
                  <Col span={24}>
                    <Checkbox
                      onChange={(e) => this.setState({ changePasswordVisible: e.target.checked })}
                    >
                      {I18n.t('routes.panel.users.items.doorman.details.changePasswordPhrase')}
                    </Checkbox>
                  </Col>
                </Row>
              )}

              {(viewType === ViewType.ADD || changePasswordVisible) && (
                <Row gutter={16}>
                  <Col span={12}>
                    <AdvancedInput
                      label={I18n.t('routes.panel.users.items.doorman.details.form.password.label')}
                      value={form && form.password}
                      onChange={(val) => this.fieldChange('password', val)}
                      isPassword
                    />
                  </Col>
                  <Col span={12}>
                    <AdvancedInput
                      label={I18n.t('routes.panel.users.items.doorman.details.form.passwordConfirmation.label')}
                      value={form && form.passwordConfirmation}
                      onChange={(val) => this.fieldChange('passwordConfirmation', val)}
                      isPassword
                    />
                  </Col>
                </Row>
              )}

              <Row gutter={16}>
                <Col span={12}>
                  <AdvancedSelect
                    options={ProfileTypeArray}
                    label={I18n.t('routes.panel.users.items.doorman.details.form.profileType.label')}
                    placeholder={I18n.t('shared.selectSomeValue')}
                    onChange={(val) => this.fieldChange('profileType', val)}
                    disabled={loading && loading > 0}
                    disableSearch
                    hasValue
                    value={form && form.profileType}
                  />
                </Col>
                {isAdmin(me) && (
                  <Col span={12}>
                    <AdvancedAutoCompleteInput
                      searchMethod={(parameters) => getCondominiumPaginatedForSearch(parameters)}
                      data={I18n.t('advancedAutoComplete.condominium')}
                      onSelect={(item) => this.fieldChange('condominiumId', item.id)}
                      onSelectSelectable={(id) => this.fieldChange('condominiumId', id)}
                      value={form && form.condominiumId}
                      disableSearch
                      placeholder={I18n.t('shared.searchSomething')}
                    />
                  </Col>
                )}
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <AdvancedUpload
                    label={I18n.t('routes.panel.users.items.doorman.details.form.profilePicUrl.label')}
                    pictureUrl={form && ((form.picture && form.picture.base64) || form.profilePicUrl)}
                    compressImage
                    shape="round"
                    aspect={1}
                    onUpload={(photoData, photoName, photoType) => uploadImage(photoData, photoName, photoType)}
                    onChange={(val) => this.fieldChange('picture', val)}
                    beforeUpload={beforeUpload}
                  />
                </Col>
              </Row>

              <Divider />

              <Row>
                <Col
                  span={24}
                  className="text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.backButtonText')}
                    href={I18n.t('routes.panel.users.items.doorman.url')}
                  />

                  <Divider
                    className="form-button-divider"
                    type="vertical"
                  />

                  <AdvancedButton
                    htmlType="submit"
                    text={I18n.t('shared.saveButtonText')}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </form>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  details: UserSelectors.getDetails(state),
});
const mapDispatchToProps = (dispatch) => ({
  getCondominiumPaginatedForSearch: (parameters) => dispatch(CondominiumActions.getPaginatedForSearch(parameters)),
  uploadImage: (photoData, photoName, photoType, callback) => dispatch(UtilsActions
    .uploadImage(photoData, photoName, photoType, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserForm);
