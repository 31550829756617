import React from 'react';
import {
  Divider,
  Layout,
  Popconfirm,
  Button,
  Row,
  Col,
  message,
  Breadcrumb,
  Icon,
  Collapse,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { DeleteOutlined, SearchOutlined, CloudUploadOutlined } from '@ant-design/icons';

import { AuthActions, UserActions, CondominiumActions } from '../../../../../app/redux/actions';
import { AuthSelectors, LoadingSelectors, UserSelectors } from '../../../../../app/redux/reducers';

// import * as DateUtils from '../../../../../app/utils/date';
import { ProfileType } from '../../../../../app/enum/profileType';
import { isAdmin, isCondominiumManager } from '../../../../../app/utils/permissions';

import DataTable from '../../../../../components/shared/DataTable/DataTable';
import DwellerImportModal from '../../../../../components/panel/dwellerImportModal/DwellerImportModal';
import NotImportedModal from '../../../../../components/panel/notImportedModal/notImportedModal';
import AdvancedButton from '../../../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedInput from '../../../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedAutoCompleteInput from '../../../../../components/shared/AdvancedAutoCompleteInput/AdvancedAutoCompleteInput';
import QueryStringUtils from '../../../../../app/utils/queryString';

const qs = new QueryStringUtils();
const { Panel } = Collapse;

const defaultParams = {
  name: null,
  email: null,
  condominiumId: null,
  importModalVisible: false,
  notImportedModalVisible: false,
};

class DwellerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: defaultParams,
    };
    this.dataTableRef = React.createRef();
  }

  componentDidMount() {
    const { params } = qs.get();

    if (params) {
      this.setState({ params });
    }
  }

  getPaginatedData(params) {
    const { getPaginated } = this.props;
    params = {
      ...params,
      profileType: ProfileType.DWELLER,
    };
    getPaginated(params, ProfileType.DWELLER);
  }

  getPaginatedDataAndRefreshTable() {
    const { params } = this.state;
    if (this.dataTableRef) {
      this.dataTableRef.current.reset(false);
      this.getPaginatedData(params);
    }
  }

  fieldChange(name, value) {
    const { params } = this.state;
    params[name] = value ? value.toString() : null;
    this.setState({ params });
  }

  remove(value, isRemoveFamiliar) {
    const { remove, removeFromFamily, me } = this.props;
    if (isAdmin(me) && !isRemoveFamiliar) {
      remove(value, () => {
        message.success(I18n.t('routes.panel.users.items.dweller.messages.deleteSuccess'));
        this.getPaginatedDataAndRefreshTable();
      });
    } else {
      removeFromFamily(value.id, () => {
        message.success(I18n.t('routes.panel.users.items.dweller.messages.deleteSuccess'));
        this.getPaginatedDataAndRefreshTable();
      });
    }
  }

  csvImportSuccess(response) {
    const { notImported } = response;
    this.setState({ importModalVisible: false });

    if (notImported && notImported.length === 0) {
      message.success(I18n.t('routes.panel.condominium.items.condominium.dwellerImportCsvModal.success'));
      this.dataTableRef.current.reset();
    } else {
      this.setState({ notImportedModalVisible: notImported });
    }
    message.success(I18n.t('routes.panel.condominium.items.condominium.dwellerImportCsvModal.success'));
  }

  applyAdvancedFilters() {
    let { params } = this.state;
    params = {
      ...params,
      page: 1,
      offset: 0,
    };

    qs.set({ params });

    this.getPaginatedDataAndRefreshTable(params);
  }

  cleanAdvancedFilters() {
    qs.set({ params: undefined });
    window.location.reload();
  }

  render() {
    const { Content } = Layout;
    const {
      params, importModalVisible, notImportedModalVisible,
    } = this.state;

    const {
      usersPaginated,
      loading,
      me,
      importDwellers,
    } = this.props;

    const columns = [
      {
        key: I18n.t('routes.panel.users.items.dweller.dataTable.columns.name.key'),
        title: I18n.t('routes.panel.users.items.dweller.dataTable.columns.name.title'),
        render: (value) => value || '--',
      },
      {
        key: I18n.t('routes.panel.users.items.dweller.dataTable.columns.email.key'),
        title: I18n.t('routes.panel.users.items.dweller.dataTable.columns.email.title'),
        render: (value) => value || '--',
      },
      {
        key: I18n.t('routes.panel.users.items.dweller.dataTable.columns.cellphone.key'),
        title: I18n.t('routes.panel.users.items.dweller.dataTable.columns.cellphone.title'),
        render: (value) => (value && `(${value.substr(2, 2)}) ${value.substr(4, 5)}-${value.substr(9, 4)}`) || '--',
      },
      {
        key: I18n.t('routes.panel.users.items.dweller.dataTable.columns.doc.key'),
        title: I18n.t('routes.panel.users.items.dweller.dataTable.columns.doc.title'),
        render: (value) => (value && `${value.substring(0, 3)}.${value.substring(3, 6)}.${value.substring(6, 9)}-${value.substring(9, 11)}`) || '--',
      },
      {
        key: I18n.t('routes.panel.users.items.dweller.dataTable.columns.condominiumUser.key'),
        title: I18n.t('routes.panel.users.items.dweller.dataTable.columns.condominiumUser.title'),
        render: (i) => (i && (
          <p key={i.id}>
            {i.condominium && i.condominium.name}
            {i.block ? `, ${i.condominium.type === 2 ? 'Bloco' : 'Rua'} ${i.block}` : ''}
            {i.number ? `, ${i.condominium.type === 2 ? 'Apartamento' : 'Casa'} ${i.number}` : ''}
          </p>
        )) || '--',
      },
      // {
      //   key: I18n.t('routes.panel.users.items.dweller.dataTable.columns.createdAt.key'),
      //   title: I18n.t('routes.panel.users.items.dweller.dataTable.columns.createdAt.title'),
      //   render: (createdAt) => DateUtils.humanizeDateTime(createdAt, 'DD/MM/YYYY HH:mm'),
      // },
    ];

    if (isAdmin(me) || isCondominiumManager(me)) {
      columns.push({
        key: I18n.t(isAdmin(me)
          ? 'routes.panel.users.items.dweller.dataTable.columns.actions.key'
          : 'routes.panel.users.items.dweller.dataTable.columns.condominiumUser.key'),
        title: '',
        render: (id) => (
          <div className="dataTable__item--right">
            <Popconfirm
              placement="left"
              title={I18n.t('shared.confirmTitle')}
              onConfirm={() => this.remove(id)}
              okText={I18n.t('shared.yes')}
              cancelText={I18n.t('shared.no')}
              disabled={me && me.id === id}
            >
              <Button
                type="link"
                disabled={me && me.id === id}
              >
                {I18n.t(`routes.panel.users.items.dweller.dataTable.columns.actions.${isAdmin(me) ? 'deleteText' : 'removeText'}`)}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </div>
        ),
      });
    }
    if (isAdmin(me)) {
      columns.push({
        key: I18n.t('routes.panel.users.items.dweller.dataTable.columns.condominiumUser.key'),
        title: '',
        render: (id) => (
          <div className="dataTable__item--right">
            <Popconfirm
              placement="left"
              title={I18n.t('shared.confirmTitle')}
              onConfirm={() => this.remove(id, true)}
              okText={I18n.t('shared.yes')}
              cancelText={I18n.t('shared.no')}
              disabled={me && me.id === id}
            >
              <Button
                type="link"
                disabled={me && me.id === id}
              >
                {I18n.t('routes.panel.users.items.dweller.dataTable.columns.actions.removeText')}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </div>
        ),
      });
    }

    return (
      <div>
        {notImportedModalVisible && (
          <NotImportedModal
            onOkFunction={() => {
              this.dataTableRef.current.reset();
              this.setState({ notImportedModalVisible: null });
            }}
            onCancelFunction={() => {
              this.dataTableRef.current.reset();
              this.setState({ notImportedModalVisible: null });
            }}
            dataSource={notImportedModalVisible}
          />
        )}
        {importModalVisible && (
          <DwellerImportModal
            isAdmin={isAdmin(me)}
            visible
            selectedCondominium={me && me.condominiumId}
            importDwellers={(condominiumId, users, callback) => importDwellers(condominiumId, users, callback)}
            handleCancel={() => this.setState({ importModalVisible: false })}
            csvImportSuccess={(response) => this.csvImportSuccess(response)}
          />
        )}
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" />
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.users.items.dweller.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse
            className="advanced-filter"
            activeKey="1"
          >
            <Panel
              header={<strong>{I18n.t('routes.panel.users.items.dweller.advancedFilter.panelTitle')}</strong>}
              key="1"
            >
              <Row gutter={16}>
                {isAdmin(me) && (
                  <Col span={8}>
                    <AdvancedAutoCompleteInput
                      searchMethod={(parameters) => this.props.getCondominiumPaginatedForSearch(parameters)}
                      data={I18n.t('advancedAutoComplete.condominium')}
                      onSelect={(item) => this.fieldChange('condominiumId', item.id)}
                      onSelectSelectable={(id) => this.fieldChange('condominiumId', id)}
                      value={params && params.condominiumId}
                      disableSearch
                    />
                  </Col>
                )}
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.users.items.dweller.advancedFilter.name.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.users.items.dweller.advancedFilter.name.key'), val)}
                    value={params && params.name}
                  />
                </Col>

                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.users.items.dweller.advancedFilter.email.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.users.items.dweller.advancedFilter.email.key'), val)}
                    value={params && params.email}
                  />
                </Col>

              </Row>

              <Row gutter={16}>
                <Col
                  span={24}
                  className="advanced-filter__search-button text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.advancedFilter.cleanButtonText')}
                    onClick={() => this.cleanAdvancedFilters()}
                  />
                  <AdvancedButton
                    text={I18n.t('shared.advancedFilter.searchButtonText')}
                    icon={<SearchOutlined />}
                    onClick={() => this.applyAdvancedFilters()}
                  />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="administrators">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    <Icon type={I18n.t('routes.panel.users.items.dweller.icon')} />
                  </span>
                  {I18n.t('routes.panel.users.items.dweller.pageTitle')}
                </h2>
              </Col>
              {isCondominiumManager(me) && (
                <Col
                  className="text-right"
                  span={12}
                >
                  <AdvancedButton
                    onClick={() => this.setState({
                      importModalVisible: true,
                    })}
                    text={I18n.t('routes.panel.users.items.dweller.addNewButtonText')}
                    icon={<CloudUploadOutlined />}
                  />
                </Col>
              )}
            </Row>

            <Divider />

            <div>
              <DataTable
                getMethod={(parameters) => this.getPaginatedData(parameters)}
                data={usersPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                columns={columns}
              />
            </div>
          </div>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  usersPaginated: UserSelectors.getPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters, me) => dispatch(UserActions.getPaginated(parameters, me)),
  remove: (id, callback) => dispatch(UserActions.remove(id, callback)),
  removeFromFamily: (id, callback) => dispatch(UserActions.removeFromFamily(id, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
  getCondominiumPaginatedForSearch: (parameters) => dispatch(CondominiumActions.getPaginatedForSearch(parameters)),
  importDwellers: (condominiumId, users, callback) => dispatch(
    CondominiumActions.importDwellers(condominiumId, users, callback),
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DwellerList);
