import {
  ACTION_SAVE_PUSH_NOTIFICATION_PAGINATED,
  ACTION_SAVE_PUSH_NOTIFICATION_DETAILS,
  ACTION_SAVE_USERS_FOR_PUSH_PAGINATED,
  ACTION_SAVE_PUSH_PAGINATED,
  ACTION_SAVE_CONDOMINIUMS_FOR_PUSH_PAGINATED,
} from '../actions/push-notification';

const initialState = {
  paginatedData: null,
  details: null,
  usersForPushNotificationPaginated: null,
  condominiumsForPushNotificationPaginated: null,
};

export default function pushNotification(state = initialState, action) {
  switch (action.type) {
    case ACTION_SAVE_PUSH_NOTIFICATION_PAGINATED:
      state = {
        ...state,
        paginatedData: action.payload,
      };
      return state;
    case ACTION_SAVE_PUSH_NOTIFICATION_DETAILS:
      state = {
        ...state,
        details: action.payload,
      };
      return state;
    case ACTION_SAVE_USERS_FOR_PUSH_PAGINATED:
      state = {
        ...state,
        usersForPushNotificationPaginated: action.payload,
      };
      return state;
    case ACTION_SAVE_CONDOMINIUMS_FOR_PUSH_PAGINATED:
      state = {
        ...state,
        condominiumsForPushNotificationPaginated: action.payload,
      };
      return state;
    case ACTION_SAVE_PUSH_PAGINATED:
      state = {
        ...state,
        pushNotificationPaginated: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getPaginated(state) {
  return state.pushNotification.paginatedData;
}

export function getDetails(state) {
  return state.pushNotification.details;
}

export function getUsersForPushNotificationPaginated(state) {
  return state.pushNotification.usersForPushNotificationPaginated;
}

export function getCondominiumsForPushNotificationPaginated(state) {
  return state.pushNotification.condominiumsForPushNotificationPaginated;
}

export function getPushNotificationPaginated(state) {
  return state.pushNotification.pushNotificationPaginated;
}
