import { Translate } from 'react-redux-i18n';
import React from 'react';
import { Tag } from 'antd';

const ScheduleType = {
  BLOCK: 1,
  RESERVE: 2,
};

export const ScheduleStatus = {
  PENDENT: 1,
  FINALIZED: 2,
  CANCELED: 3,
};

export const ScheduleTypeArray = [
  {
    id: ScheduleType.BLOCK,
    name: <Translate value="enum.scheduleType.block" />,
    tag: <Tag color="red"><Translate value="enum.scheduleType.block" /></Tag>,
    color: 'red',
  },
  {
    id: ScheduleType.RESERVE,
    name: <Translate value="enum.scheduleType.reserve" />,
    tag: <Tag color="green"><Translate value="enum.scheduleType.reserve" /></Tag>,
    color: 'green',
  },
];

export const ScheduleStatusArray = [
  {
    id: ScheduleStatus.PENDENT,
    name: <Translate value="enum.scheduleStatus.pendent" />,
    tag: <Tag color="blue"><Translate value="enum.scheduleStatus.pendent" /></Tag>,
    color: 'blue',
  },
  {
    id: ScheduleStatus.FINALIZED,
    name: <Translate value="enum.scheduleStatus.finalized" />,
    tag: <Tag color="green"><Translate value="enum.scheduleStatus.finalized" /></Tag>,
    color: 'green',
  },
  {
    id: ScheduleStatus.CANCELED,
    name: <Translate value="enum.scheduleStatus.canceled" />,
    tag: <Tag color="red"><Translate value="enum.scheduleStatus.canceled" /></Tag>,
    color: 'red',
  },
];

export const GetScheduleTypeTitle = (value) => (ScheduleTypeArray.find((o) => o.id === value).name);
export const GetScheduleTypeTag = (value) => (ScheduleTypeArray.find((o) => o.id === value).tag);
export const GetScheduleTypeColor = (value) => (ScheduleTypeArray.find((o) => o.id === value).color);

export const GetStatusTypeTag = (value) => (ScheduleStatusArray.find((o) => o.id === value).tag);

export default ScheduleType;
