import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import {
  addLoading,
  removeLoading,
} from './loading';
import CondominiumUserRequests from '../../api/condominium-user';
import { CondominiumUserStatusType } from '../../enum/condominiumUserStatusType';
import { isAdmin, isCondominiumManager } from '../../utils/permissions';

export const ACTION_SAVE_SOLICITATION_PAGINATED = 'ACTION_SAVE_SOLICITATION_PAGINATED';

export const ACTION_CLEAN_SOLICITATION = 'ACTION_CLEAN_SOLICITATION';

// cleaners
export const clean = () => ({
  type: ACTION_CLEAN_SOLICITATION,
  payload: null,
});

export const cleanPaginated = () => ({
  type: ACTION_SAVE_SOLICITATION_PAGINATED,
  payload: null,
});

/*
 * BASIC CRUD METHODS
 *
 * getPaginated: get rows for data table
 * getDetails: get details (get by id)
 * update: the PUT method
 * create: the POST method
 */

export const getPaginated = (parameters, callback) => async (dispatch, getState) => {
  dispatch(addLoading());
  dispatch(cleanPaginated());

  try {
    const { me } = getState().auth;

    parameters = {
      ...parameters,
      limit: parameters.limit || 10,
      total: parameters.total || 0,
      page: parameters.page || 1,
      orderBy: 'createdAt',
      isDESC: true,
    };

    if (!parameters.status) {
      parameters = {
        ...parameters,
        status: CondominiumUserStatusType.PENDING,
      };
    }
    if (parameters.condominiumId && !isAdmin(me)) {
      delete parameters.condominiumId;
    }

    if (isCondominiumManager(me) && me.condominiumId) {
      parameters = {
        ...parameters,
        condominiumId: me.condominiumId,
      };
    }

    if (parameters.status && parameters.status === 10) delete parameters.status;

    dispatch({
      type: ACTION_SAVE_SOLICITATION_PAGINATED,
      payload: null,
    });

    const response = await CondominiumUserRequests.getPaginated(parameters);

    dispatch({
      type: ACTION_SAVE_SOLICITATION_PAGINATED,
      payload: response,
    });

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const update = (id, data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const response = await CondominiumUserRequests.update(id, data);
    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};
