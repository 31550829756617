import React from 'react';
import {
  Breadcrumb, Col, Divider, Icon, Layout, message, Row, Spin,
} from 'antd';
import { connect } from 'react-redux';
import { Link, navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';

import { EstablishmentActions, CategoryActions, SettingsActions } from '../../../../../app/redux/actions';
import { LoadingSelectors, AuthSelectors } from '../../../../../app/redux/reducers';
import { ViewType } from '../../../../../app/enum/viewType';
import { isEstablishmentAdmin } from '../../../../../app/utils/permissions';
import EstablishmentForm from '../../../../../app/forms/establishment/EstablishmentForm';

class EstablishmentDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType: ViewType.EDIT,
    };
  }

  componentDidMount() {
    const {
      getCategories, getAll, id,
    } = this.props;
    this.setState({
      viewType: id !== 'add' ? ViewType.EDIT : ViewType.ADD,
    });
    this.getDetails();
    getCategories();
    getAll();
  }

  getDetails() {
    const {
      getDetails, id, me,
    } = this.props;

    const param = id || (me && me.establishmentId);

    getDetails(param);
  }

  save(id, data) {
    const { update, create, me } = this.props;

    try {
      if (this.state.viewType === ViewType.ADD) {
        create(data, async () => {
          if (!isEstablishmentAdmin(me)) navigate(I18n.t('routes.panel.establishment.items.establishment.url'));
          else await this.getDetails();
          message.success(I18n.t('routes.panel.establishment.items.establishment.details.messages.successCreate'));
        });
      } else {
        update(id, data, async () => {
          if (!isEstablishmentAdmin(me)) navigate(I18n.t('routes.panel.establishment.items.establishment.url'));
          else await this.getDetails();
          message.success(I18n.t('routes.panel.establishment.items.establishment.details.messages.successEdit'));
        });
      }
    } catch (e) {
      message.error(I18n.t('shared.genericError'));
    }
  }

  render() {
    const { Content } = Layout;
    const { viewType } = this.state;
    const { loading, id } = this.props;

    return (
      <div>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" />
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={I18n.t('routes.panel.establishment.items.establishment.url')}>
                <Icon type={I18n.t('routes.panel.establishment.items.establishment.icon')} />
                <span>{I18n.t('routes.panel.establishment.items.establishment.pageTitle')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>
                {I18n.t(
                  viewType === ViewType.ADD
                    ? 'routes.panel.establishment.items.establishment.pageTitleAdd'
                    : 'routes.panel.establishment.items.establishment.pageTitleEdit',
                )}
              </span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content">
          <div>
            <Spin
              spinning={loading > 0}
              tip={I18n.t('shared.loading')}
            >
              <div className="panel__layout__content__title">
                <h2 className="panel__layout__content__title__value">
                  <span className="panel__layout__content__title__value__icon">
                    <Icon type={I18n.t('routes.panel.establishment.items.establishment.icon')} />
                  </span>
                  {I18n.t(
                    viewType === ViewType.ADD
                      ? 'routes.panel.establishment.items.establishment.pageTitleAdd'
                      : 'routes.panel.establishment.items.establishment.pageTitleEdit',
                  )}
                </h2>
                <p className="panel__layout__content__title__value__description">
                  {I18n.t(
                    viewType === ViewType.ADD
                      ? 'routes.panel.establishment.items.establishment.pageDescriptionAdd'
                      : 'routes.panel.establishment.items.establishment.pageDescriptionEdit',
                  )}
                </p>
              </div>

              <Divider />

              <div>
                <Row>
                  <Col>
                    <EstablishmentForm
                      id={id}
                      submitFunction={(dataId, data) => this.save(dataId, data)}
                      viewType={viewType}
                      loading={loading}
                    />
                  </Col>
                </Row>
              </div>
            </Spin>
          </div>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getDetails: (id) => dispatch(EstablishmentActions.getDetails(id)),
  update: (id, data, callback) => dispatch(EstablishmentActions.update(id, data, callback)),
  create: (data, callback) => dispatch(EstablishmentActions.create(data, callback)),
  getCategories: () => dispatch(CategoryActions.getPaginated({ limit: 100 })),
  getAll: (callback) => dispatch(SettingsActions.getAll(callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EstablishmentDetails);
