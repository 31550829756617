import {
  ACTION_SAVE_CONDOMINIUM_PAGINATED,
  ACTION_SAVE_CONDOMINIUM_DETAILS,
} from '../actions/condominium';

const initialState = {
  paginatedData: null,
  details: null,
};

export default function condominium(state = initialState, action) {
  switch (action.type) {
    case ACTION_SAVE_CONDOMINIUM_PAGINATED:
      state = {
        ...state,
        paginatedData: action.payload,
      };
      return state;
    case ACTION_SAVE_CONDOMINIUM_DETAILS:
      state = {
        ...state,
        details: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getPaginated(state) {
  return state.condominium.paginatedData;
}

export function getDetails(state) {
  return state.condominium.details;
}
