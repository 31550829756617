import React from 'react';
import {
  Divider,
  Layout,
  Row,
  Col,
  message, Breadcrumb, Icon, Collapse,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';

import { OrderActions, CondominiumActions } from '../../../../../app/redux/actions';
import { OrderSelectors, LoadingSelectors, AuthSelectors } from '../../../../../app/redux/reducers';

import * as DateUtils from '../../../../../app/utils/date';
import QueryStringUtils from '../../../../../app/utils/queryString';
import { removeSpecialChars, maskCpf } from '../../../../../app/utils/string';
import { GetPackageTypeTitle, PackageTypeArray } from '../../../../../app/enum/packageType';
import { GetPackageStatusTypeTag, PackageStatusTypeArray } from '../../../../../app/enum/packageStatusType';
import { isAdmin } from '../../../../../app/utils/permissions';

import DataTable from '../../../../../components/shared/DataTable/DataTable';
import AdvancedButton from '../../../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedSelect from '../../../../../components/shared/AdvancedSelect/AdvancedSelect';
import AdvancedAutoCompleteInput from '../../../../../components/shared/AdvancedAutoCompleteInput/AdvancedAutoCompleteInput';

const qs = new QueryStringUtils();
const { Panel } = Collapse;

const defaultParams = {
  condominiumId: null,
};

class OrderList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: defaultParams,
    };
    this.dataTableRef = React.createRef();
  }

  getPaginatedData(params) {
    const { getPaginated } = this.props;
    const newParams = { ...params };
    if (params.orderDate) newParams.orderDate = moment(params.orderDate).format();
    if (params.rg) newParams.rg = removeSpecialChars(params.rg);

    getPaginated(newParams);
  }

  getPaginatedDataAndRefreshTable() {
    const { params } = this.state;
    if (this.dataTableRef) {
      this.dataTableRef.current.reset(false);
      this.getPaginatedData(params);
    }
  }

  fieldChange(name, value) {
    const { params } = this.state;
    params[name] = value || null;
    this.setState({ params });
  }

  remove(id) {
    this.props.remove(id, () => {
      message.success(I18n.t('routes.panel.condominium.items.order.messages.deleteSuccess'));
      this.getPaginatedDataAndRefreshTable();
    });
  }

  applyAdvancedFilters() {
    let { params } = this.state;
    params = {
      ...params,
      page: 1,
      offset: 0,
    };

    qs.set({ params });

    this.getPaginatedDataAndRefreshTable(params);
  }

  cleanAdvancedFilters() {
    qs.set({ params: undefined });
    window.location.reload();
  }

  render() {
    const { Content } = Layout;
    const { params } = this.state;
    const { paginatedData, loading, me } = this.props;

    return (
      <div>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.condominium.items.order.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse
            className="advanced-filter"
            // activeKey="1"
          >
            <Panel
              header={<strong>{I18n.t('routes.panel.condominium.items.order.advancedFilter.panelTitle')}</strong>}
              key="1"
            >
              <Row gutter={16}>
                {isAdmin(me) && (
                <Col span={8}>
                  <AdvancedAutoCompleteInput
                    searchMethod={(parameters) => this.props.getCondominiumPaginatedForSearch(parameters)}
                    data={I18n.t('advancedAutoComplete.condominium')}
                    onSelect={(item) => this.fieldChange('condominiumId', item.id)}
                    onSelectSelectable={(id) => this.fieldChange('condominiumId', id)}
                    value={params && params.condominiumId}
                    disableSearch
                  />
                </Col>
                )}

                <Col span={8}>
                  <AdvancedSelect
                    label={I18n.t('routes.panel.condominium.items.order.advancedFilter.packageType.label')}
                    onChange={(val) => this.fieldChange('packageType', val)}
                    value={params && params.packageType}
                    options={PackageTypeArray}
                  />
                </Col>

                <Col span={8}>
                  <AdvancedSelect
                    label={I18n.t('routes.panel.condominium.items.order.advancedFilter.packageStatusType.label')}
                    onChange={(val) => this.fieldChange('status', val)}
                    value={params && params.status}
                    options={PackageStatusTypeArray}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col
                  span={24}
                  className="advanced-filter__search-button text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.advancedFilter.cleanButtonText')}
                    onClick={() => this.cleanAdvancedFilters()}
                  />
                  <AdvancedButton
                    text={I18n.t('shared.advancedFilter.searchButtonText')}
                    icon={<SearchOutlined />}
                    onClick={() => this.applyAdvancedFilters()}
                  />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="administrators">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    <Icon type={I18n.t('routes.panel.condominium.items.order.icon')} />
                  </span>
                  {I18n.t('routes.panel.condominium.items.order.pageTitle')}
                </h2>
              </Col>
            </Row>

            <Divider />

            <div>
              <DataTable
                getMethod={(parameters) => this.getPaginatedData(parameters)}
                data={paginatedData}
                loading={loading > 0}
                ref={this.dataTableRef}
                columns={
                  [
                    {
                      key: I18n.t('routes.panel.condominium.items.order.dataTable.columns.receiver.key'),
                      title: I18n.t('routes.panel.condominium.items.order.dataTable.columns.receiver.title'),
                      render: (value) => (value && value.name) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.condominium.items.order.dataTable.columns.packageType.key'),
                      title: I18n.t('routes.panel.condominium.items.order.dataTable.columns.packageType.title'),
                      render: (value) => (value && GetPackageTypeTitle(value)) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.condominium.items.order.dataTable.columns.withdrawerName.key'),
                      title: I18n.t('routes.panel.condominium.items.order.dataTable.columns.withdrawerName.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.condominium.items.order.dataTable.columns.withdrawerCpf.key'),
                      title: I18n.t('routes.panel.condominium.items.order.dataTable.columns.withdrawerCpf.title'),
                      render: (value) => (value && maskCpf(value)) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.condominium.items.order.dataTable.columns.signaturePhotoUrl.key'),
                      title: I18n.t('routes.panel.condominium.items.order.dataTable.columns.signaturePhotoUrl.title'),
                      render: (value) => (value && (
                      <a
                        href={value}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Visualizar
                      </a>
                      )) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.condominium.items.order.dataTable.columns.status.key'),
                      title: I18n.t('routes.panel.condominium.items.order.dataTable.columns.status.title'),
                      render: (value) => (value && GetPackageStatusTypeTag(value)) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.condominium.items.order.dataTable.columns.withdrawalAt.key'),
                      title: I18n.t('routes.panel.condominium.items.order.dataTable.columns.withdrawalAt.title'),
                      render: (value) => DateUtils.humanizeDateTime(value, 'DD/MM/YYYY HH:mm'),
                    },
                    {
                      key: I18n.t('routes.panel.condominium.items.visits.dataTable.columns.condominiumUser.key'),
                      title: I18n.t('routes.panel.condominium.items.visits.dataTable.columns.condominiumUser.title'),
                      render: (value) => (value && `${value.condominium && value.condominium.name} ${value.block ? `, Bloco ${value.block}` : ''} ${value.tower ? `, Torre ${value.tower}` : ''} ${value.number ? `, Número ${value.number}` : ''}`) || '--',
                    },
                  ]
                }
              />
            </div>
          </div>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  paginatedData: OrderSelectors.getPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters) => dispatch(OrderActions.getPaginated(parameters)),
  getCondominiumPaginatedForSearch: (parameters) => dispatch(CondominiumActions.getPaginatedForSearch(parameters)),
  remove: (id, callback) => dispatch(OrderActions.remove(id, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderList);
