import { Translate } from 'react-redux-i18n';
import React from 'react';

const VisitPeriodType = {
  MORNING: 1,
  AFTERNOON: 2,
  NIGHT: 3,
  MORNING_AND_AFTERNOON: 4,
  MORNING_AND_NIGHT: 5,
  AFTERNOON_AND_NIGHT: 6,
  ALL: 7,
};

export const VisitPeriodTypeArray = [
  { id: VisitPeriodType.MORNING, name: <Translate value="enum.visitPeriodType.morning" /> },
  { id: VisitPeriodType.AFTERNOON, name: <Translate value="enum.visitPeriodType.afternoon" /> },
  { id: VisitPeriodType.NIGHT, name: <Translate value="enum.visitPeriodType.night" /> },
  { id: VisitPeriodType.MORNING_AND_AFTERNOON, name: <Translate value="enum.visitPeriodType.morning_and_afternoon" /> },
  { id: VisitPeriodType.MORNING_AND_NIGHT, name: <Translate value="enum.visitPeriodType.morning_and_night" /> },
  { id: VisitPeriodType.AFTERNOON_AND_NIGHT, name: <Translate value="enum.visitPeriodType.afternoon_and_night" /> },
  { id: VisitPeriodType.ALL, name: <Translate value="enum.visitPeriodType.all" /> },
];

export const GetVisitPeriodTypeTitle = (value) => (VisitPeriodTypeArray.find((o) => o.id === value).name);

export default VisitPeriodType;
