/* eslint-disable no-mixed-operators */
/* eslint-disable no-plusplus */
export function getInitials(name) {
  let initials = name.match(/\b\w/g) || [];
  initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  if (initials && initials.length > 0 && initials.length > 2) {
    initials = initials.substr(0, 2);
  }
  return initials;
}

export function validateEmail(str) {
  // eslint-disable-next-line max-len,no-useless-escape
  const exp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return exp.test(str);
}

export const validateCPF = (cpf) => {
  if (cpf === '' || !(/^\d+$/.test(cpf))) return false;
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf.length !== 11 || cpf === '00000000000' || cpf === '11111111111' || cpf === '22222222222'
    || cpf === '33333333333' || cpf === '44444444444' || cpf === '55555555555' || cpf === '66666666666'
    || cpf === '77777777777' || cpf === '88888888888' || cpf === '99999999999') { return false; }
  let add = 0;
  for (let i = 0; i < 9; i += 1) { add += parseInt(cpf.charAt(i), 10) * (10 - i); }
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) { rev = 0; }
  if (rev !== parseInt(cpf.charAt(9), 10)) { return false; }
  add = 0;
  for (let i = 0; i < 10; i += 1) { add += parseInt(cpf.charAt(i), 10) * (11 - i); }
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) { rev = 0; }
  if (rev !== parseInt(cpf.charAt(10), 10)) { return false; }
  return true;
};

export const validateCnpj = (cnpj) => {
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj === '') return false;

  if (cnpj.length !== 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (cnpj === '00000000000000'
    || cnpj === '11111111111111'
    || cnpj === '22222222222222'
    || cnpj === '33333333333333'
    || cnpj === '44444444444444'
    || cnpj === '55555555555555'
    || cnpj === '66666666666666'
    || cnpj === '77777777777777'
    || cnpj === '88888888888888'
    || cnpj === '99999999999999') {
    return false;
  }

  // Valida DVs
  let length = cnpj.length - 2;
  let number = cnpj.substring(0, length);
  const digits = cnpj.substring(length);
  let soma = 0;
  let pos = length - 7;
  for (let i = length; i >= 1; i--) {
    soma += number.charAt(length - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let results = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (results !== digits.charAt(0)) return false;

  length += 1;
  number = cnpj.substring(0, length);
  soma = 0;
  pos = length - 7;
  for (let i = length; i >= 1; i--) {
    soma += number.charAt(length - i) * pos--;
    if (pos < 2) pos = 9;
  }
  results = soma % 11 < 2 ? 0 : 11 - soma % 11;
  if (results !== digits.charAt(1)) return false;

  return true;
};

export function removeSpecialChars(str) {
  return str ? str.replace(/[^A-Za-z0-9]/g, '').replace(/\/s/g, '') : null;
}

export function maskCellphone(str) {
  if (str) {
    str = removeSpecialChars(str);
    str = str.replace('55', '').match(/(\d{2})(\d{1})(\d{4})(\d{4})/);
    str = `(${str[1]}) ${str[2]} ${str[3]}-${str[4]}`;
  }

  return str;
}

export function maskCpf(cpf) {
  cpf = cpf.replace(/\D/g, '');
  cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
  cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  return cpf;
}
