import axios from 'axios';
import getInstance from './config';

export async function getBucketInstance(imageType) {
  const header = {
    ...(imageType !== 'application/pdf' && {
      'Content-Type': `${imageType}`,
      ContentEncoding: 'base64',
    }),
  };

  const axiosInstance = axios.create({
    timeout: 35000,
    headers: header,
  });

  axiosInstance.interceptors.response.use((response) => response,
    async (err) => {
      if (err.response.status === 401) {
        // invalid_token
        // axiosHandleUnatorizedErr();
      } else if (err.response.status === 403) {
        // Snackbar(I18n.t('screen.login.errors.user_no_access'));
        // axiosHandleUnatorizedErr();
      }
      // return Promise.reject(handleAxiosError(err));
    });
  return axiosInstance;
}

export default class UtilsApi {
  static async getAddressByCep(cep) {
    return axios.get(`https://viacep.com.br/ws/${cep}/json/`);
  }

  static async getImageUrl(parameters) {
    const instance = await getInstance();
    const { data } = await instance.get(`/v1/utils/secure-upload-url?imageName=${parameters}`);
    return data;
  }

  static async uploadImage(url, parameters, imageType) {
    const instance = await getBucketInstance(imageType);
    const { data } = await instance.put(url, parameters);
    return data;
  }
}
