import getInstance from './config';

export default class SettingsApi {
  static async getAll() {
    const instance = await getInstance();
    const { data } = await instance.get('/v1/settings');
    return data;
  }

  static async update(settingsData) {
    const instance = await getInstance();
    const { data } = await instance.put('/v1/settings', settingsData);
    return data;
  }
}
