import getInstance from './config';

export default class UserApi {
  static async getPaginated(params) {
    const instance = await getInstance();
    const { data } = await instance.get('/v1/condominium', { params });
    return data;
  }

  static async getSelectives(params) {
    const instance = await getInstance();
    const { data } = await instance.get('/v1/condominium/selective', { params });
    return data;
  }

  static async getDetails(id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/v1/condominium/${id}`);
    return data;
  }

  static async update(id, userData) {
    const instance = await getInstance();
    const { data } = await instance.put(`/v1/condominium/${id}`, userData);
    return data;
  }

  static async create(userData) {
    const instance = await getInstance();
    const { data } = await instance.post('/v1/condominium', userData);
    return data;
  }

  static async remove(id) {
    const instance = await getInstance();
    const { data } = await instance.delete(`/v1/condominium/${id}`);
    return data;
  }

  static async importDwellers(importData) {
    const instance = await getInstance();
    const { data } = await instance.post('/v1/condominium/import', importData);
    return data;
  }
}
