import React from 'react';
import {
  Divider,
  Layout,
  Popconfirm,
  Button,
  Row,
  Col,
  message,
  Breadcrumb,
  Icon,
  Collapse,
  Tag,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Link } from '@reach/router';

import {
  AuthSelectors, LoadingSelectors, EstablishmentSelectors, CategorySelectors,
} from '../../../../../app/redux/reducers';
import { AuthActions, EstablishmentActions, CategoryActions } from '../../../../../app/redux/actions';

import QueryStringUtils from '../../../../../app/utils/queryString';

import DataTable from '../../../../../components/shared/DataTable/DataTable';
import AdvancedButton from '../../../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedInput from '../../../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedSelect from '../../../../../components/shared/AdvancedSelect/AdvancedSelect';

const qs = new QueryStringUtils();
const { Panel } = Collapse;

const defaultParams = {
  name: null,
  categoryIdList: null,
  city: null,
};

class EstablishmentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: defaultParams,
    };
    this.dataTableRef = React.createRef();
  }

  componentDidMount() {
    const { getCategories } = this.props;
    const { params } = qs.get();

    if (params) {
      this.setState({ params });
    }
    getCategories();
  }

  getPaginatedData(params) {
    const { me } = this.props;
    const { getPaginated } = this.props;

    getPaginated(params, me);
  }

  getPaginatedDataAndRefreshTable() {
    const { params } = this.state;
    if (this.dataTableRef) {
      this.dataTableRef.current.reset(false);
      this.getPaginatedData(params);
    }
  }

  fieldChange(name, value) {
    const { params } = this.state;
    params[name] = value || null;
    this.setState({ params });
  }

  remove(id) {
    this.props.remove(id, () => {
      message.success(I18n.t('routes.panel.establishment.items.establishment.messages.deleteSuccess'));
      this.getPaginatedDataAndRefreshTable();
    });
  }

  applyAdvancedFilters() {
    let { params } = this.state;
    params = {
      ...params,
      page: 1,
      offset: 0,
    };

    qs.set({ params });

    this.getPaginatedDataAndRefreshTable(params);
  }

  cleanAdvancedFilters() {
    qs.set({ params: undefined });
    window.location.reload();
  }

  render() {
    const { Content } = Layout;
    const { params } = this.state;

    const {
      categoriesPaginated,
      establishmentsPaginated,
      loading,
      me,
    } = this.props;

    return (
      <div>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" />
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.establishment.items.establishment.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse
            className="advanced-filter"
          >
            <Panel
              header={<strong>{I18n.t('routes.panel.establishment.items.establishment.advancedFilter.panelTitle')}</strong>}
              key="1"
            >
              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.establishment.items.establishment.advancedFilter.name.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.establishment.items.establishment.advancedFilter.name.key'), val)}
                    value={params && params.name}
                  />
                </Col>

                <Col span={8}>
                  <AdvancedSelect
                    options={!!categoriesPaginated && categoriesPaginated.rows}
                    label={I18n.t('routes.panel.establishment.items.establishment.details.form.categories.label')}
                    placeholder={I18n.t('shared.selectSomeValue')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.establishment.items.establishment.advancedFilter.categoryIdList.key'), val)}
                    disabled={loading && loading > 0}
                    disableSearch
                    mode="multiple"
                    hasValue
                    value={params && params.categoryIdList}
                  />
                </Col>

                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.establishment.items.establishment.advancedFilter.city.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.establishment.items.establishment.advancedFilter.city.key'), val)}
                    value={params && params.city}
                  />
                </Col>

              </Row>

              <Row gutter={16}>
                <Col
                  span={24}
                  className="advanced-filter__search-button text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.advancedFilter.cleanButtonText')}
                    onClick={() => this.cleanAdvancedFilters()}
                  />
                  <AdvancedButton
                    text={I18n.t('shared.advancedFilter.searchButtonText')}
                    icon={<SearchOutlined />}
                    onClick={() => this.applyAdvancedFilters()}
                  />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="administrators">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    <Icon type={I18n.t('routes.panel.establishment.items.establishment.icon')} />
                  </span>
                  {I18n.t('routes.panel.establishment.items.establishment.pageTitle')}
                </h2>
              </Col>
              <Col
                className="text-right"
                span={12}
              >
                <AdvancedButton
                  text={I18n.t('routes.panel.establishment.items.establishment.addNewButtonText')}
                  href={`${I18n.t('routes.panel.establishment.items.establishment.url')}/add`}
                  icon={<Icon type={I18n.t('routes.panel.establishment.items.establishment.icon')} />}
                />
              </Col>
            </Row>

            <Divider />

            <div>
              <DataTable
                getMethod={(parameters) => this.getPaginatedData(parameters)}
                data={establishmentsPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                columns={
                  [
                    {
                      key: I18n.t('routes.panel.establishment.items.establishment.dataTable.columns.name.key'),
                      title: I18n.t('routes.panel.establishment.items.establishment.dataTable.columns.name.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.establishment.items.establishment.dataTable.columns.categories.key'),
                      title: I18n.t('routes.panel.establishment.items.establishment.dataTable.columns.categories.title'),
                      render: (value) => (value && value.map((i) => (
                        <Tag
                          key={i.id}
                          color="#ffc72c"
                        >
                          {i.title}
                        </Tag>
                      ))) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.establishment.items.establishment.dataTable.columns.discount.key'),
                      title: I18n.t('routes.panel.establishment.items.establishment.dataTable.columns.discount.title'),
                      render: (value) => (value && `${value}%`) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.establishment.items.establishment.dataTable.columns.street.key'),
                      title: I18n.t('routes.panel.establishment.items.establishment.dataTable.columns.street.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.establishment.items.establishment.dataTable.columns.neighborhood.key'),
                      title: I18n.t('routes.panel.establishment.items.establishment.dataTable.columns.neighborhood.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.establishment.items.establishment.dataTable.columns.city.key'),
                      title: I18n.t('routes.panel.establishment.items.establishment.dataTable.columns.city.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.establishment.items.establishment.dataTable.columns.actions.key'),
                      title: '',
                      render: (id) => (
                        <div className="dataTable__item--right">
                          <Popconfirm
                            placement="left"
                            title={I18n.t('shared.confirmTitle')}
                            onConfirm={() => this.remove(id)}
                            okText={I18n.t('shared.yes')}
                            cancelText={I18n.t('shared.no')}
                            disabled={me && me.id === id}
                          >
                            <Button
                              type="link"
                              disabled={me && me.id === id}
                            >
                              {I18n.t('routes.panel.establishment.items.establishment.dataTable.columns.actions.removeText')}
                              <DeleteOutlined />
                            </Button>
                          </Popconfirm>
                          <Link to={`${I18n.t('routes.panel.establishment.items.establishment.url')}/${id}`}>
                            {I18n.t('routes.panel.establishment.items.establishment.dataTable.columns.actions.goToDetailsText')}
                            &nbsp;&nbsp;<EditOutlined />
                          </Link>
                        </div>
                      ),
                    },
                  ]
                }
              />
            </div>
          </div>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  establishmentsPaginated: EstablishmentSelectors.getPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  categoriesPaginated: CategorySelectors.getPaginated(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters, me) => dispatch(EstablishmentActions.getPaginated(parameters, me)),
  remove: (id, callback) => dispatch(EstablishmentActions.remove(id, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
  getCategories: () => dispatch(CategoryActions.getPaginated({ limit: 100 })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EstablishmentList);
