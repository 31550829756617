import { Translate } from 'react-redux-i18n';
import React from 'react';

const PackageType = {
  BOX: 1,
  ENVELOPE: 2,
  LETTER: 3,
  OUTRO: 4,
};

export const PackageTypeArray = [
  { id: PackageType.BOX, name: <Translate value="enum.packageType.box" /> },
  { id: PackageType.ENVELOPE, name: <Translate value="enum.packageType.envelope" /> },
  { id: PackageType.LETTER, name: <Translate value="enum.packageType.letter" /> },
  { id: PackageType.OUTRO, name: <Translate value="enum.packageType.other" /> },
];

export const GetPackageTypeTitle = (value) => (PackageTypeArray.find((o) => o.id === value).name);

export default PackageType;
