/* eslint-disable no-dupe-else-if */
import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Modal, Table } from 'antd';

import { GetNotImportedTitle } from '../../../app/enum/notImportedType';

class NotImportedModal extends React.PureComponent {
  render() {
    const {
      onOkFunction,
      onCancelFunction,
      dataSource,
    } = this.props;
    return (
      <Modal
        visible
        width={1000}
        title={I18n.t('component.notImportedModal.title')}
        onCancel={onCancelFunction}
        onOk={onOkFunction}
      >
        <Table
          columns={[
            {
              title: I18n.t('component.notImportedModal.dataTable.columns.name.title'),
              dataIndex: I18n.t('component.notImportedModal.dataTable.columns.name.key'),
            },
            {
              title: I18n.t('component.notImportedModal.dataTable.columns.email.title'),
              dataIndex: I18n.t('component.notImportedModal.dataTable.columns.email.key'),
            },
            {
              title: I18n.t('component.notImportedModal.dataTable.columns.cellphone.title'),
              dataIndex: I18n.t('component.notImportedModal.dataTable.columns.cellphone.key'),
            },
            {
              title: I18n.t('component.notImportedModal.dataTable.columns.reason.title'),
              dataIndex: I18n.t('component.notImportedModal.dataTable.columns.reason.key'),
              render: (value) => (value && GetNotImportedTitle(value)) || '--',
            },
          ]}
          dataSource={dataSource}
          size="middle"
          bordered={false}
          locale={{
            emptyText: I18n.t('shared.nothingToShow'),
          }}
        />
      </Modal>
    );
  }
}

export default NotImportedModal;
