import React from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  message,
  Divider,
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import * as yup from 'yup';
import { I18n } from 'react-redux-i18n';

import { CondominiumAdministratorSelectors, AuthSelectors, LoadingSelectors } from '../../redux/reducers';
import { UtilsActions } from '../../redux/actions';
import { removeSpecialChars, validateEmail } from '../../utils/string';

import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedMaskedInput from '../../../components/shared/AdvancedMaskedInput/AdvancedMaskedInput';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';

class CondominiumForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        administratorName: '',
        contactName: '',
        phone: '',
        email: '',
        doc: '',
        zipcode: '',
        neighborhood: '',
        street: '',
        city: '',
        uf: '',
        addressNumber: '',
        complement: '',
      },
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps() {
    const {
      details,
      id,
    } = this.props;

    if (details && id !== 'add') {
      this.setState({
        form: {
          administratorName: details.administratorName,
          contactName: details.contactName,
          phone: details.phone && details.phone.substr(2, details.phone.length),
          email: details.email,
          doc: details.doc,
          zipcode: details.zipcode,
          neighborhood: details.neighborhood,
          street: details.street,
          city: details.city,
          uf: details.uf,
          addressNumber: details.addressNumber,
          complement: details.complement,
        },
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const { form } = this.state;
    const schema = yup.object().shape({
      administratorName: yup.string().required(I18n.t('shared.errors.missing_administratorName')),
      contactName: yup.string().required(I18n.t('shared.errors.missing_contactName')),
      phone: yup.string()
        .required(I18n.t('shared.errors.missing_cellphone'))
        .min(10, I18n.t('shared.errors.invalid_cellphone')),
      email: yup.string().required(I18n.t('shared.errors.missing_email')),
      doc: yup.string().required(I18n.t('shared.errors.missing_cnpj')),
      zipcode: yup.string().required(I18n.t('shared.errors.missing_zipcode')),
      neighborhood: yup.string().required(I18n.t('shared.errors.missing_neighborhood')),
      street: yup.string().required(I18n.t('shared.errors.missing_street')),
      city: yup.string().required(I18n.t('shared.errors.missing_city')),
      uf: yup.string().required(I18n.t('shared.errors.missing_uf')),
      addressNumber: yup.string().required(I18n.t('shared.errors.missing_addressNumber')),
    });

    schema.validate(form, { abortEarly: true })
      .then(() => {
        const { details, submitFunction } = this.props;

        if (!validateEmail(form.email)) {
          return message.error(I18n.t('shared.errors.invalid_email'));
        }

        const data = {
          ...form,
          phone: `55${removeSpecialChars(form.phone)}`,
          doc: removeSpecialChars(form.doc),
          zipcode: removeSpecialChars(form.zipcode),
        };

        if (details) {
          submitFunction(details.id, data);
        } else {
          submitFunction(null, data);
        }
      })
      .catch((err) => message.error(err.message || I18n.t('shared.genericError')));
  }

  async getAddressByCep(cep) {
    const { getAddressByCep } = this.props;
    const completeAddress = await getAddressByCep(cep);
    if (!completeAddress.error) {
      this.fieldChange('uf', completeAddress.uf);
      this.fieldChange('city', completeAddress.localidade);
      this.fieldChange('street', completeAddress.logradouro);
      this.fieldChange('neighborhood', completeAddress.bairro);
      this.fieldChange('zipcode', completeAddress.cep);
    }
  }

  fieldChange(name, value) {
    const { form } = this.state;
    form[name] = value || '';
    this.setState({ form });
  }

  render() {
    const {
      loading,
    } = this.props;
    const { form } = this.state;
    return (
      <Row>
        {loading === 0 && (
          <Col>
            <form
              name="form"
              onSubmit={(ev) => this.onSubmit(ev)}
            >
              <Row gutter={16}>
                <Col>
                  <h5>{I18n.t('routes.panel.condominium.items.condominiumAdministrator.details.condominiumSection')}</h5>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.condominium.items.condominiumAdministrator.details.form.administratorName.label')}
                    value={form && form.administratorName}
                    onChange={(val) => this.fieldChange('administratorName', val)}
                  />
                </Col>

                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.condominium.items.condominiumAdministrator.details.form.name.label')}
                    value={form && form.contactName}
                    onChange={(val) => this.fieldChange('contactName', val)}
                  />
                </Col>

                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.condominium.items.condominiumAdministrator.details.form.email.label')}
                    onChange={(val) => this.fieldChange('email', val)}
                    value={form && form.email}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedMaskedInput
                    label={I18n.t('routes.panel.condominium.items.condominiumAdministrator.details.form.cellphone.label')}
                    onChange={(val) => this.fieldChange('phone', removeSpecialChars(val))}
                    value={form && form.phone}
                    mask={I18n.t('shared.phoneMask')}
                  />
                </Col>

                <Col span={8}>
                  <AdvancedMaskedInput
                    label={I18n.t('routes.panel.condominium.items.condominiumAdministrator.details.form.document.label')}
                    onChange={(val) => this.fieldChange('doc', val)}
                    value={form && form.doc}
                    mask={I18n.t('shared.cnpjMask')}
                  />
                </Col>
              </Row>

              <Divider />

              <Row gutter={16}>
                <Col>
                  <h5>{I18n.t('routes.panel.condominium.items.condominiumAdministrator.details.streetSection')}</h5>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedMaskedInput
                    label={I18n.t('routes.panel.condominium.items.condominiumAdministrator.details.form.zipcode.label')}
                    value={form && form.zipcode}
                    onChange={(val) => {
                      this.fieldChange('zipcode', val);
                      if (val && removeSpecialChars(val).length === 8) {
                        this.getAddressByCep(removeSpecialChars(val));
                      }
                    }}
                    mask={I18n.t('shared.zipCodeMask')}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.condominium.items.condominiumAdministrator.details.form.neighborhood.label')}
                    value={form && form.neighborhood}
                    onChange={(val) => this.fieldChange('neighborhood', val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.condominium.items.condominiumAdministrator.details.form.street.label')}
                    value={form && form.street}
                    onChange={(val) => this.fieldChange('street', val)}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.condominium.items.condominiumAdministrator.details.form.city.label')}
                    value={form && form.city}
                    onChange={(val) => this.fieldChange('city', val)}
                  />
                </Col>
                <Col span={3}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.condominium.items.condominiumAdministrator.details.form.uf.label')}
                    value={form && form.uf}
                    onChange={(val) => this.fieldChange('uf', val)}
                    maxLength={2}
                  />
                </Col>
                <Col span={5}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.condominium.items.condominiumAdministrator.details.form.addressNumber.label')}
                    value={form && form.addressNumber}
                    onChange={(val) => this.fieldChange('addressNumber', val)}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.condominium.items.condominiumAdministrator.details.form.complement.label')}
                    value={form && form.complement}
                    onChange={(val) => this.fieldChange('complement', val)}
                  />
                </Col>
              </Row>

              <Divider />

              <Row>
                <Col
                  span={24}
                  className="text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.backButtonText')}
                    href={I18n.t('routes.panel.condominium.items.condominiumAdministrator.url')}
                  />

                  <Divider
                    className="form-button-divider"
                    type="vertical"
                  />

                  <AdvancedButton
                    htmlType="submit"
                    text={I18n.t('shared.saveButtonText')}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </form>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  details: CondominiumAdministratorSelectors.getDetails(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAddressByCep: (cep) => dispatch(UtilsActions.getAddressByCep(cep)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CondominiumForm);
