import React from 'react';
import {
  Divider,
  Checkbox,
  message,
  Row,
  Col,
  Icon,
} from 'antd';
import { connect } from 'react-redux';
import { SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { I18n } from 'react-redux-i18n';
import * as yup from 'yup';
import { navigate } from '@reach/router';
import moment from 'moment-timezone';

import { LoadingSelectors, PushNotificationSelectors, AuthSelectors } from '../../redux/reducers';
import { PushNotificationActions, AuthActions } from '../../redux/actions';

import { NotificationType } from '../../enum/notificationType';

import DataTable from '../../../components/shared/DataTable/DataTable';
import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';

import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedCheckbox from '../../../components/shared/AdvancedCheckbox/AdvancedCheckbox';
import AdvancedDatePicker from '../../../components/shared/AdvancedDatePicker/AdvancedDatePicker';

import { isAdmin } from '../../utils/permissions';

class PushNotification extends React.Component {
  constructor(props) {
    super(props);
    this.dataTableRef = React.createRef();
    this.state = {
      params: {
        name: null,
      },
      form: {
        title: '',
        body: null,
        scheduledDate: null,
        isSchedule: false,
        sendForAllUsers: false,
        description: '',
        selectedUsers: null,
        userIdList: [],
        sendPush: false,
        sendAll: false,
        condominiumId: null,
        everyoneInCondominium: null,
      },
    };
  }

  onSubmit(e) {
    e.preventDefault();

    const { form } = this.state;
    const { me, createNotification } = this.props;

    const schema = yup.object().shape({
      title: yup.string().required(I18n.t('shared.errors.missing_notificationTitle')),
      description: yup.string().required(I18n.t('shared.errors.missing_description')),
    });

    schema.validate(form, { abortEarly: true })
      .then(() => {
        if (!form.sendAll && form.userIdList.length <= 0) {
          return message.error(!isAdmin(me)
            ? I18n.t('routes.panel.pushNotification.items.send.messages.errors.emptyUser')
            : I18n.t('routes.panel.pushNotification.items.send.messages.errors.emptyCondominium'));
        }
        const notification = {
          title: form.title,
          body: form.description,
          type: NotificationType.NEW_MESSAGE,
          ...(form.scheduledDate && { scheduledDate: moment(form.scheduledDate).format() }),
          sendPush: form.sendPush && true,
          everyoneInCondominium: form.sendAll,
          ...(!isAdmin(me) && (!form.sendAll
            && { userList: form.userIdList.map((dweller) => dweller.id) })),
          ...(isAdmin(me) && (!form.sendAll
            && { condominiumId: form.userIdList.map((condominium) => condominium.id) })),
        };
        try {
          createNotification(notification, () => {
            message.success(I18n.t('routes.panel.pushNotification.items.send.messages.successCreate'));
            navigate(I18n.t('routes.panel.pushNotification.items.send.urlReturn'));
          });
        } catch (err) {
          message.error(I18n.t('routes.panel.pushNotification.items.send.messages.errors.generic'));
        }
      })
      .catch((err) => message.error(err.message || I18n.t('shared.genericError')));
  }

  onInputChange(name, value) {
    const { form } = this.state;
    form[name] = value || '';
    this.setState({ form });
  }

  onParamsChange(name, value) {
    const { params } = this.state;
    params[name] = value ? value.toString() : null;
    this.setState({ params });
  }

  onSelectRows(rows) {
    const { form } = this.state;
    form.userIdList = rows;
    this.setState({
      form,
    });
  }

  getColumns(params) {
    return params.map((o) => (
      {
        title: o.title,
        dataIndex: o.key,
        key: o.key,
        render: o.render ? o.render : undefined,
      }
    ));
  }

  applyAdvancedFilters(params) {
    const { getUsersForPushNotificationPaginated, getCondominiumsForPushNotificationPaginated, me } = this.props;
    params = {
      ...params,
      page: 1,
      offset: 0,
    };
    this.setState({ params });
    this.dataTableRef.current.state.page = 1;
    if (!isAdmin(me)) getUsersForPushNotificationPaginated({ ...params, condominiumId: me.condominiumId });
    else getCondominiumsForPushNotificationPaginated(params);
  }

  cleanAdvancedFilters() {
    window.location.reload();
  }

  render() {
    const { form, params } = this.state;
    const {
      usersForPushNotificationPaginated, loading, me, condominiumsForPushNotificationPaginated, getUsersForPushNotificationPaginated,
      getCondominiumsForPushNotificationPaginated,
    } = this.props;

    return (
      <Row>
        <Col>
          <form
            name="form"
            onSubmit={(ev) => this.onSubmit(ev)}
          >
            <div className="">
              <Row>
                <Col span={24}>
                  <h2>
                    <span className="panel__layout__content__title__value__icon">
                      <Icon type={I18n.t('routes.panel.pushNotification.icon')} />
                    </span>
                    {I18n.t('routes.panel.pushNotification.sidebarTitle')}
                  </h2>
                  <p>
                    {I18n.t('routes.panel.pushNotification.items.send.pageDescriptionAdd')}
                  </p>
                  <Divider />

                </Col>
              </Row>
              <Row>
                <Col>
                  <AdvancedInput
                    label={I18n.t('routes.panel.pushNotification.items.send.form.title.label')}
                    onChange={(value) => this.onInputChange('title', value)}
                    value={form && form.title}
                    placeholder={I18n.t('shared.type')}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <AdvancedInput
                    label={I18n.t('routes.panel.pushNotification.items.send.form.description.label')}
                    onChange={(value) => this.onInputChange('description', value)}
                    value={form && form.description}
                    isTextarea
                    placeholder={I18n.t('shared.type')}
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  span={4}
                  style={{ marginBottom: '-10px' }}
                >
                  <AdvancedCheckbox
                    label={I18n.t('routes.panel.pushNotification.items.send.details.form.isSchedule.label')}
                    onChange={(val) => this.onInputChange('isSchedule', val)}
                    value={form && form.isSchedule}
                  />
                </Col>
              </Row>
              {form.isSchedule && (
                <Row>
                  <Col span={4}>
                    <AdvancedDatePicker
                      label={I18n.t('routes.panel.pushNotification.items.send.details.form.scheduledDate.label')}
                      onChange={(val) => this.onInputChange('scheduledDate', val)}
                      value={form && form.scheduledDate}
                      showTime={{ format: 'HH:mm' }}
                      format="DD/MM/YYYY HH:mm"
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col style={{ marginBottom: '10px' }}>
                  <Checkbox onChange={(ev) => this.onInputChange('sendPush', ev.target.checked)}>
                    {I18n.t('routes.panel.pushNotification.items.send.form.sendPush.label')}
                  </Checkbox>
                </Col>
              </Row>
              <Row>
                <Col style={{ marginBottom: '10px' }}>
                  <Checkbox onChange={(ev) => this.onInputChange('sendAll', ev.target.checked)}>
                    {I18n.t('routes.panel.pushNotification.items.send.form.sendAll.label')}
                  </Checkbox>
                </Col>
              </Row>
            </div>
            <Divider />
            {!form.sendAll && (
              <div className="push-notification">
                <Row>
                  <Col>
                    <h5 className="push-notification__block-title">
                      {I18n.t(!isAdmin(me) ? 'routes.panel.pushNotification.items.send.form.datatable.title' : 'routes.panel.pushNotification.items.send.form.dataTableAdmin.title')}

                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Row>
                    <Row gutter={16}>
                      <Col span={8}>
                        <AdvancedInput
                          label={I18n.t(!isAdmin(me) ? 'routes.panel.pushNotification.items.send.form.datatable.name.label' : 'routes.panel.pushNotification.items.send.form.dataTableAdmin.name.title')}
                          value={params && params.name}
                          onChange={(val) => this.onParamsChange('name', val)}
                          placeholder={I18n.t('shared.type')}
                        />
                      </Col>
                      {!isAdmin(me) && (
                        <Col span={8}>
                          <AdvancedInput
                            label={I18n.t('routes.panel.pushNotification.items.send.form.datatable.email.label')}
                            value={params && params.email}
                            onChange={(val) => this.onParamsChange('email', val)}
                            placeholder={I18n.t('shared.type')}
                          />
                        </Col>
                      )}
                    </Row>
                    <Row gutter={16}>
                      <Col
                        span={24}
                        className="advanced-filter__search-button text-right"
                      >
                        <AdvancedButton
                          type="link"
                          text={I18n.t('shared.advancedFilters.clearButtonText')}
                          onClick={() => this.cleanAdvancedFilters()}
                        />
                        <AdvancedButton
                          text={I18n.t('shared.advancedFilters.filterButtonText')}
                          icon={<SearchOutlined />}
                          onClick={() => this.applyAdvancedFilters(this.state.params)}
                        />
                      </Col>
                    </Row>
                  </Row>
                  <Divider />
                </Row>
                <Row>
                  <Col>
                    <DataTable
                      getMethod={(parameters) => (!isAdmin(me)
                        ? getUsersForPushNotificationPaginated({ ...parameters, condominiumId: me.condominiumId })
                        : getCondominiumsForPushNotificationPaginated(parameters))}
                      data={!isAdmin(me) ? usersForPushNotificationPaginated : condominiumsForPushNotificationPaginated}
                      loading={loading > 0}
                      ref={this.dataTableRef}
                      columns={this.getColumns([
                        {
                          key: I18n.t(!isAdmin(me) ? 'routes.panel.pushNotification.items.send.form.datatable.name.key' : 'routes.panel.condominium.items.condominium.dataTable.columns.name.key'),
                          title: I18n.t(!isAdmin(me) ? 'routes.panel.pushNotification.items.send.form.datatable.name.label' : 'routes.panel.condominium.items.condominium.dataTable.columns.name.title'),
                          render: (value) => value || '--',
                        },
                        {
                          key: I18n.t(!isAdmin(me) ? 'routes.panel.pushNotification.items.send.form.datatable.email.key' : 'routes.panel.condominium.items.condominium.dataTable.columns.neighborhood.key'),
                          title: I18n.t(!isAdmin(me) ? 'routes.panel.pushNotification.items.send.form.datatable.email.label' : 'routes.panel.condominium.items.condominium.dataTable.columns.neighborhood.title'),
                          render: (value) => value,
                        },
                        {
                          key: I18n.t(!isAdmin(me) ? 'routes.panel.pushNotification.items.send.form.datatable.cellphone.key' : 'routes.panel.condominium.items.condominium.dataTable.columns.city.key'),
                          title: I18n.t(!isAdmin(me) ? 'routes.panel.pushNotification.items.send.form.datatable.cellphone.label' : 'routes.panel.condominium.items.condominium.dataTable.columns.city.title'),
                          render: (value) => value || '--',
                        },
                      ])}
                      bordered={false}
                      pagination={false}
                      size="middle"
                      locale={{
                        emptyText: I18n.t('shared.nothingToShow'),
                      }}
                      selectable
                      onSelectRow={(rows) => this.onSelectRows(rows)}
                      params={params}
                    />
                  </Col>
                </Row>
                <Divider />
              </div>
            )}
            <div className="row">
              <div
                className="col-12 text-right"
              >
                <Divider
                  className="form-button-divider"
                  type="vertical"
                />
                <AdvancedButton
                  htmlType="submit"
                  text={I18n.t('shared.send')}
                  icon={<SaveOutlined />}
                />
              </div>
            </div>
          </form>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  me: AuthSelectors.getMe(state),
  usersForPushNotificationPaginated: PushNotificationSelectors.getUsersForPushNotificationPaginated(state),
  condominiumsForPushNotificationPaginated: PushNotificationSelectors.getCondominiumsForPushNotificationPaginated(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUsersForPushNotificationPaginated: (parameters, me) => dispatch(
    PushNotificationActions.getUsersForPushNotification(parameters, me),
  ),
  getCondominiumsForPushNotificationPaginated: (parameters, me) => dispatch(
    PushNotificationActions.getCondominiumsForPushNotification(parameters, me),
  ),
  createNotification: (notification, callback) => dispatch(
    PushNotificationActions.createNotification(notification, callback),
  ),
  getMe: () => dispatch(AuthActions.getMe()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PushNotification);
