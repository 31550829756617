function csvToJson(csv) {
  const file = csv.replaceAll(';', ',');
  const lines = file.split('\n');
  const result = [];
  let headers = ['nome', 'email', 'celular', 'bloco', 'numero'];

  headers = headers.map((o) => o.trim());

  for (let i = 1; i < lines.length; i += 1) {
    const obj = {};
    const currentLine = lines[i].split(',');
    for (let j = 0; j < headers.length; j += 1) {
      obj[headers[j]] = currentLine[j];
    }
    result.push(obj);
  }

  return result;
}

export default {
  csvToJson,
};
