export default {
  header: {
    basic: 'NzI0NjE0ZjBjODU4ZmQ1ZDNmNDYwZjYxZTRlNWE3YzM6ZTBjMmU4YjE4MmRiZjQ5Nzg1ZDY4MjU1Zjc4YWQzOTY2Nzg1OTM1MzFlMGI4Y2Y0YThhZTllZDg3YTc2NTM2OA==', // eslint-disable-line
    platform: 'web',
    version: '1.0.0',
  },
  timezone: 'America/Sao_Paulo',
  language: 'pt-br',
  imageCompression: {
    maxWidth: 1500,
    quality: 0.80,
  },
  highImageCompression: {
    maxWidth: 1920,
    quality: 1,
  },
  tinyApiKey: '22hum910ps8ad439ryhdq56zw6p115s518jrzdp6glb2js4r',
  tinyMCEOptions: {
    placeholder: 'Digite o conteúdo...',
    height: 450,
    menubar: 'file edit view insert format tools table help',
    plugins: [
      'print preview paste searchreplace autolink',
      'save directionality code visualblocks visualchars fullscreen',
      'image link media codesample table charmap hr pagebreak',
      'nonbreaking anchor toc insertdatetime advlist lists wordcount',
      'imagetools textpattern noneditable help charmap quickbars emoticons fullpage',
    ],
    fullpage_default_doctype: '<!DOCTYPE html>',
    toolbar:
      'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen preview save print | insertfile image media template link anchor codesample | ltr rtl',  // eslint-disable-line
    toolbar_sticky: true,
    contextmenu: 'link image imagetools table',
    toolbar_mode: 'sliding',
    quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
    image_caption: true,
    image_advtab: true,
    paste_data_images: true,
    language: 'pt_BR',
  },
};
