import { Translate } from 'react-redux-i18n';
import React from 'react';
import { Tag } from 'antd';

const PackageStatusType = {
  PENDING: 1,
  WITHDRAWN: 2,
};

export const PackageStatusTypeArray = [
  {
    id: PackageStatusType.PENDING,
    name: <Translate value="enum.packageStatusType.pending" />,
    tag: <Tag color="gold"><Translate value="enum.packageStatusType.pending" /></Tag>,
  },
  {
    id: PackageStatusType.WITHDRAWN,
    name: <Translate value="enum.packageStatusType.withdraw" />,
    tag: <Tag color="green"><Translate value="enum.packageStatusType.withdraw" /></Tag>,
  },
];

export const GetPackageStatusTypeTitle = (value) => (PackageStatusTypeArray.find((o) => o.id === value).name);
export const GetPackageStatusTypeTag = (value) => (PackageStatusTypeArray.find((o) => o.id === value).tag);

export default PackageStatusType;
