import { message } from 'antd';
import moment from 'moment-timezone';
import { I18n } from 'react-redux-i18n';
import {
  addLoading,
  removeLoading,
} from './loading';
import EstablishmentRequests from '../../api/establishment';
import { addLoadingSearch, removeLoadingSearch } from './loading-search';

export const ACTION_SAVE_ESTABLISHMENT_PAGINATED = 'ACTION_SAVE_ESTABLISHMENT_PAGINATED';
export const ACTION_SAVE_ESTABLISHMENT_DETAILS = 'ACTION_SAVE_ESTABLISHMENT_DETAILS';

export const ACTION_CLEAN_ESTABLISHMENT = 'ACTION_CLEAN_ESTABLISHMENT';

// cleaners
export const clean = () => ({
  type: ACTION_CLEAN_ESTABLISHMENT,
  payload: null,
});

export const cleanDetails = () => ({
  type: ACTION_SAVE_ESTABLISHMENT_DETAILS,
  payload: null,
});

export const cleanPaginated = () => ({
  type: ACTION_SAVE_ESTABLISHMENT_PAGINATED,
  payload: null,
});

/*
 * BASIC CRUD METHODS
 *
 * getPaginated: get rows for data table
 * getDetails: get details (get by id)
 * update: the PUT method
 * create: the POST method
 */

export const getPaginated = (parameters, callback) => async (dispatch) => {
  dispatch(addLoading());
  dispatch(cleanPaginated());

  try {
    parameters = {
      ...parameters,
      limit: parameters.limit || 10,
      total: parameters.total || 0,
      page: parameters.page || 1,
      orderBy: 'createdAt',
      isDESC: true,
      ...(parameters.categoryIdList && { categoryIdList: parameters.categoryIdList.join() }),
    };

    dispatch({
      type: ACTION_SAVE_ESTABLISHMENT_PAGINATED,
      payload: null,
    });

    const response = await EstablishmentRequests.getPaginated(parameters);

    dispatch({
      type: ACTION_SAVE_ESTABLISHMENT_PAGINATED,
      payload: response,
    });

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const getDetails = (id, callback) => async (dispatch) => {
  dispatch(addLoading());
  dispatch(cleanDetails());

  try {
    let response = null;
    dispatch({
      type: ACTION_SAVE_ESTABLISHMENT_DETAILS,
      payload: null,
    });

    if (id !== 'add') {
      response = await EstablishmentRequests.getDetails(id);

      dispatch({
        type: ACTION_SAVE_ESTABLISHMENT_DETAILS,
        payload: response,
      });
    }

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const update = (id, data, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const response = await EstablishmentRequests.update(id, data);

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    const errors = I18n.t('routes.panel.establishment.items.establishment.details.errors');
    if (errors[err.message] !== undefined) {
      message.error(I18n.t(`routes.panel.establishment.items.establishment.details.errors.${err.message}`));
    } else {
      message.error(I18n.t('shared.genericError'));
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const create = (data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    if (data && data.picture) delete data.picture;

    const response = await EstablishmentRequests.create(data);

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    const errors = I18n.t('routes.panel.establishment.items.establishment.details.errors');
    if (errors[err.message] !== undefined) {
      message.error(I18n.t(`routes.panel.establishment.items.establishment.details.errors.${err.message}`));
    } else {
      message.error(I18n.t('shared.genericError'));
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const remove = (id, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const response = await EstablishmentRequests.remove(id);
    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

/*
 * ADVANCED METHODS
 *
 * getPaginatedForSearch: get rows for <AdvancedAutoCompleteInput /> component
 */

export const getPaginatedForSearch = (parameters, callback) => async (dispatch) => {
  let response = null;
  dispatch(addLoadingSearch());

  try {
    parameters = {
      ...parameters,
      limit: parameters ? parameters.limit : 10,
      total: parameters ? parameters.total : 0,
      page: parameters ? parameters.page : 1,
      orderBy: 'createdAt',
      isDESC: true,
    };

    if (parameters && parameters.searchInput) {
      parameters = {
        ...parameters,
        name: parameters.searchInput,
      };
    }

    response = await EstablishmentRequests.getSelectives(parameters);

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoadingSearch());
  }

  return response;
};

export const exportReport = (params) => async () => {
  try {
    params = {
      dateInitial: `${moment(params.modalRange[0]).format()}`,
      dateEnd: `${moment(params.modalRange[1]).format()}`,
      isDESC: true,
    };

    await EstablishmentRequests.exportReport(params);
  } catch (err) {
    //
  }
};
