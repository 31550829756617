import React from 'react';
import {
  Divider,
  Layout,
  Popconfirm,
  Button,
  Row,
  Col,
  message, Breadcrumb, Icon,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from '@reach/router';

import { CategoryActions, AuthActions } from '../../../../../app/redux/actions';
import { CategorySelectors, AuthSelectors, LoadingSelectors } from '../../../../../app/redux/reducers';

import AdvancedButton from '../../../../../components/shared/AdvancedButton/AdvancedButton';
import QueryStringUtils from '../../../../../app/utils/queryString';

const qs = new QueryStringUtils();

const defaultParams = {
  name: null,
  email: null,
};

class CategoryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: defaultParams,
    };
  }

  componentDidMount() {
    this.getPaginatedData({});
  }

  getPaginatedData(params) {
    const { getPaginated } = this.props;
    getPaginated(params);
  }

  fieldChange(name, value) {
    const { params } = this.state;
    params[name] = value ? value.toString() : null;
    this.setState({ params });
  }

  remove(id) {
    this.props.remove(id, () => {
      message.success(I18n.t('routes.panel.establishment.items.category.messages.deleteSuccess'));
      this.getPaginatedData({});
    });
  }

  cleanAdvancedFilters() {
    qs.set({ params: undefined });
    window.location.reload();
  }

  render() {
    const { Content } = Layout;
    const {
      paginatedData,
    } = this.props;

    return (
      <div>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.establishment.items.category.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content">
          <div className="categorys">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    <Icon type={I18n.t('routes.panel.establishment.items.category.icon')} />
                  </span>
                  {I18n.t('routes.panel.establishment.items.category.pageTitle')}
                </h2>
              </Col>
              <Col
                className="text-right"
                span={12}
              >
                <AdvancedButton
                  text={I18n.t('routes.panel.establishment.items.category.addNewButtonText')}
                  href={`${I18n.t('routes.panel.establishment.items.category.url')}/add`}
                  icon={<Icon type={I18n.t('routes.panel.establishment.items.category.icon')} />}
                />
              </Col>
            </Row>

            <Divider />

            <div>
              <Row>
                {paginatedData && paginatedData.rows && paginatedData.rows.map((value) => (
                  <Col
                    span={5}
                    className="mb-3"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    key={value.id}
                  >
                    <p><strong>{value.title}</strong></p>

                    <div style={{
                      backgroundImage: `url(${value.picUrl})`,
                      width: '215px',
                      height: '125px',
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      borderRadius: '4px',
                    }}
                    />
                    <div className="dataTable__item--right">
                      <Popconfirm
                        placement="left"
                        title={I18n.t('shared.confirmTitle')}
                        onConfirm={() => this.remove(value.id)}
                        okText={I18n.t('shared.yes')}
                        cancelText={I18n.t('shared.no')}
                      >
                        <Button
                          type="link"
                        >
                          {I18n.t('routes.panel.establishment.items.category.dataTable.columns.actions.removeText')}
                          <DeleteOutlined />
                        </Button>
                      </Popconfirm>
                      <Link to={`${I18n.t('routes.panel.establishment.items.category.url')}/${value.id}`}>
                        {I18n.t('routes.panel.establishment.items.category.dataTable.columns.actions.goToDetailsText')}
                        <EditOutlined />
                      </Link>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  paginatedData: CategorySelectors.getPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters) => dispatch(CategoryActions.getPaginated(parameters)),
  remove: (id, callback) => dispatch(CategoryActions.remove(id, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CategoryList);
