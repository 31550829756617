import getInstance from './config';

export default class VisitApi {
  static async getPaginated(params) {
    const instance = await getInstance();
    const { data } = await instance.get('/v1/visit', { params });
    return data;
  }

  static async getDetails(id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/v1/visit/${id}`);
    return data;
  }

  static async remove(id) {
    const instance = await getInstance();
    const { data } = await instance.delete(`/v1/visit/${id}`);
    return data;
  }
}
