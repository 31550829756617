import React from 'react';
import {
  Row, Col, Layout, Card, message,
} from 'antd';
import { I18n } from 'react-redux-i18n';
import { Link } from '@reach/router';
import { connect } from 'react-redux';
import AdvancedInput from '../../components/shared/AdvancedInput';
import AdvancedButton from '../../components/shared/AdvancedButton';
import { AuthActions } from '../../app/redux/actions';
import * as AuthSelectors from '../../app/redux/reducers/auth';
import { LoadingSelectors } from '../../app/redux/reducers';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };
  }

  async componentDidMount() {
    const { isAuthenticated, verifyInitialFlow } = this.props;
    if (isAuthenticated) {
      try {
        await verifyInitialFlow();
      } catch (err) {
        message.error(err.message);
      }
    }
  }

  userAuth(e) {
    e.preventDefault();

    const { authenticate } = this.props;
    const { email, password } = this.state;

    if (!(email && password)) {
      return message.error(I18n.t('routes.login.errors.fields'));
    }

    authenticate({ email, password }, () => {
      message.success(I18n.t('routes.login.messages.welcome'));
    });
  }

  render() {
    const { Content } = Layout;
    const { email, password } = this.state;
    const { loading } = this.props;
    return (
      <Content className="login__container">
        <Row
          type="flex"
          justify="center"
        >
          <Col span={6}>
            <Row className="login__logo">
              <Col>
                <img
                  alt="login logo"
                  className="login__logo__img"
                  src="/assets/img/logo_black.svg"
                />
              </Col>
            </Row>
            <Card>
              <form onSubmit={(e) => this.userAuth(e)}>
                <Row className="login__input">
                  <Col>
                    <AdvancedInput
                      label={I18n.t('routes.login.content.email')}
                      placeholder={I18n.t('routes.login.content.emailPlaceholder')}
                      type="email"
                      value={email}
                      onChange={(value) => this.setState({ email: value })}
                      disabled={loading && loading > 0}
                    />
                  </Col>
                </Row>
                <Row className="login__input">
                  <Col>
                    <AdvancedInput
                      label={I18n.t('routes.login.content.password')}
                      placeholder={I18n.t('routes.login.content.passwordPlaceholder')}
                      type="password"
                      value={password}
                      onChange={(value) => this.setState({ password: value })}
                      disabled={loading && loading > 0}
                      isPassword
                    />
                  </Col>
                </Row>
                <Row className="login__input login__input__button">
                  <Col
                    className="text-right"
                    span={24}
                  >
                    <AdvancedButton
                      text={I18n.t('routes.login.content.continueButton')}
                      loading={loading && loading > 0}
                      htmlType="submit"
                      className="advanced-button advanced-button--full-width"
                    />
                  </Col>
                </Row>
                <Row className="login__input login__input__button">
                  <Col
                    span={24}
                    className="text-center"
                  >
                    <Link
                      className="login__input__button__back"
                      to={I18n.t('routes.forgotPassword.url')}
                    >
                      {I18n.t('routes.login.content.forgotPassword')}
                      <u>{I18n.t('routes.login.content.clickHere')}</u>
                    </Link>
                  </Col>
                </Row>
              </form>
            </Card>
          </Col>
        </Row>
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: AuthSelectors.isAuthenticated(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  authenticate: (user, callback) => dispatch(AuthActions.authenticate(user, callback)),
  verifyInitialFlow: () => dispatch(AuthActions.verifyInitialFlow()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
