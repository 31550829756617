import { Translate } from 'react-redux-i18n';
import React from 'react';

const ContractStatusType = {
  NOT_STARTED: 1,
  STARTED: 2,
  FINISHED: 3,
};

export const ContractStatusTypeArray = [
  { id: ContractStatusType.NOT_STARTED, name: <Translate value="enum.contractStatusType.notStarted" /> },
  { id: ContractStatusType.STARTED, name: <Translate value="enum.contractStatusType.started" /> },
  // { id: ContractStatusType.FINISHED, name: <Translate value="enum.contractStatusType.finished" /> },
];

export const GetContractStatusTypeTitle = (value) => (ContractStatusTypeArray.find((o) => o.id === value).name);

export default ContractStatusType;
