import { Translate } from 'react-redux-i18n';
import React from 'react';
import { Tag } from 'antd';

const TransactionStatusType = {
  CREATED: 1,
  APPROVED: 2,
  REFUSED: 3,
};

export const TransactionStatusTypeArray = [
  {
    id: TransactionStatusType.CREATED,
    name: <Translate value="enum.transactionStatusType.created" />,
    tag: <Tag color="blue"><Translate value="enum.transactionStatusType.created" /></Tag>,
    color: 'blue',
  },
  {
    id: TransactionStatusType.APPROVED,
    name: <Translate value="enum.transactionStatusType.approved" />,
    tag: <Tag color="green"><Translate value="enum.transactionStatusType.approved" /></Tag>,
    color: 'green',
  },
  {
    id: TransactionStatusType.REFUSED,
    name: <Translate value="enum.transactionStatusType.refused" />,
    tag: <Tag color="volcano"><Translate value="enum.transactionStatusType.refused" /></Tag>,
    color: 'volcano',
  },
];

export const GetTransactionStatusTypeTitle = (value) => (TransactionStatusTypeArray.find((o) => o.id === value).name);
export const GetTransactionStatusTypeTag = (value) => (TransactionStatusTypeArray.find((o) => o.id === value).tag);
export const GetTransactionStatusTypeColor = (value) => (TransactionStatusTypeArray.find((o) => o.id === value).color);

export default TransactionStatusType;
