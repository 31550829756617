/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  message,
  Divider,
} from 'antd';
import * as yup from 'yup';
import { I18n } from 'react-redux-i18n';
import { SaveOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';

import {
  AuthSelectors, LoadingSelectors, RecreationSelectors,
} from '../../redux/reducers';
import AdvancedTimePicker from '../../../components/shared/AdvancedTimePicker/AdvancedTimePicker';
import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';

class Transaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        name: '',
        description: '',
        price: '',
        hourClosing: '',
        hourOpening: '',
        hourInterval: '',
      },
    };
  }

  UNSAFE_componentWillReceiveProps() {
    const {
      details,
      id,
    } = this.props;

    if (details && id !== 'add') {
      this.setState({
        form: {
          name: details.name,
          description: details.description,
          price: `${parseFloat(details.price)}`,
          hourClosing: moment(details.hourClosing, 'HH:mm'),
          hourOpening: moment(details.hourOpening, 'HH:mm'),
          hourInterval: moment(details.hourInterval, 'HH:mm'),
        },
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    let { form } = this.state;

    const schema = yup.object().shape({
      name: yup.string().required(I18n.t('shared.errors.missing_name')),
      description: yup.string().required(I18n.t('shared.errors.missing_description')),
      hourClosing: yup.string().required(I18n.t('shared.errors.missing_hourClosing')),
      hourOpening: yup.string().required(I18n.t('shared.errors.missing_hourOpening')),
      hourInterval: yup.string().required(I18n.t('shared.errors.missing_hourInterval')),
    });

    schema.validate(form, { abortEarly: true })
      .then(() => {
        if (moment(form.hourClosing).isBefore(moment(form.hourOpening))) {
          return message.error(I18n.t('routes.panel.schedule.items.recreationArea.errors.invalid_initial_date'));
        }
        const maxHourPeriod = moment(form.hourClosing).diff(moment(form.hourOpening), 'hours');
        const maxMinutePeriod = moment(form.hourClosing).diff(moment(form.hourOpening), 'minutes') % 60;
        const hourPeriod = moment(form.hourInterval).format('HH:mm').split(':')[0];
        const minutePeriod = moment(form.hourInterval).format('HH:mm').split(':')[1];
        if (moment(`${maxHourPeriod}:${maxMinutePeriod}`, 'HH:mm').isBefore(moment(`${hourPeriod}:${minutePeriod}`, 'HH:mm'))) {
          return message.error(I18n.t('routes.panel.schedule.items.recreationArea.errors.invalid_interval'));
        }
        const { submitFunction } = this.props;
        form = {
          ...form,
          price: form.price ? `${form.price.replace('R$ ', '').replaceAll('.', '').replace(',', '.')}` : '0',
          ...(form.hourClosing && { hourClosing: moment(form.hourClosing).format('HH:mm') }),
          ...(form.hourOpening && { hourOpening: moment(form.hourOpening).format('HH:mm') }),
          ...(form.hourInterval && { hourInterval: moment(form.hourInterval).format('HH:mm') }),
        };
        submitFunction(form);
      })
      .catch((err) => message.error(err.message || I18n.t('shared.genericError')));
  }

  fieldChange(name, value) {
    const { form } = this.state;
    form[name] = value;
    this.setState({ form });
  }

  render() {
    const { loading } = this.props;
    const { form } = this.state;

    return (
      <Row>
        {loading === 0 && (
          <Col>
            <form
              name="form"
              onSubmit={(ev) => this.onSubmit(ev)}
            >
              <Row gutter={16}>
                <Col span={6}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.schedule.items.recreationArea.details.form.name.label')}
                    value={form && form.name}
                    onChange={(val) => this.fieldChange('name', val)}
                  />
                </Col>

                <Col span={6}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.schedule.items.recreationArea.details.form.price.label')}
                    value={form && form.price}
                    onChange={(val) => this.fieldChange('price', val)}
                    isMoney
                  />
                </Col>

                <Col span={4}>
                  <AdvancedTimePicker
                    label={I18n.t('routes.panel.schedule.items.recreationArea.details.form.hourOpening.label')}
                    value={form && form.hourOpening}
                    onChange={(val) => this.fieldChange('hourOpening', val)}
                    minuteStep={30}
                  />
                </Col>

                <Col span={4}>
                  <AdvancedTimePicker
                    label={I18n.t('routes.panel.schedule.items.recreationArea.details.form.hourClosing.label')}
                    value={form && form.hourClosing}
                    onChange={(val) => this.fieldChange('hourClosing', val)}
                    minuteStep={30}
                  />
                </Col>

                <Col span={4}>
                  <AdvancedTimePicker
                    label={I18n.t('routes.panel.schedule.items.recreationArea.details.form.hourInterval.label')}
                    value={form && form.hourInterval}
                    onChange={(val) => this.fieldChange('hourInterval', val)}
                    minuteStep={30}
                  />
                </Col>

                <Col span={24}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.schedule.items.recreationArea.details.form.description.label')}
                    value={form && form.description}
                    onChange={(val) => this.fieldChange('description', val)}
                    isTextArea
                    hasCountDown
                  />
                </Col>

              </Row>

              <Divider />

              <Row>
                <Col
                  span={24}
                  className="text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.backButtonText')}
                    href={I18n.t('routes.panel.users.items.doorman.url')}
                  />

                  <Divider
                    className="form-button-divider"
                    type="vertical"
                  />

                  <AdvancedButton
                    htmlType="submit"
                    text={I18n.t('shared.saveButtonText')}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </form>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  details: RecreationSelectors.getDetails(state),
});

export default connect(
  mapStateToProps,
  null,
)(Transaction);
