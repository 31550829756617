import React from 'react';
import { Translate } from 'react-redux-i18n';

export const ProfileType = {
  ADMIN: 1,
  DWELLER: 4,
  CONDOMINIUM_MANAGER: 5,
  DOORMAN: 6,
  CARETAKER: 7,
  ESTABLISHMENT_ADMIN: 8,
  ESTABLISHMENT: 9,
  MOTOBOY: 10,
  CONDOMINIUM_SUB_MANAGER: 11,
  FINANCIAL_ADMINISTRATOR: 12,
  ADVISOR: 13,
};

export const ProfileTypeArray = [
  { id: ProfileType.ADMIN, name: <Translate value="enum.profileType.admin" /> },
  { id: ProfileType.DWELLER, name: <Translate value="enum.profileType.dweller" /> },
  { id: ProfileType.CONDOMINIUM_MANAGER, name: <Translate value="enum.profileType.condominiumManager" /> },
  { id: ProfileType.DOORMAN, name: <Translate value="enum.profileType.doorman" /> },
  { id: ProfileType.CARETAKER, name: <Translate value="enum.profileType.caretaker" /> },
  { id: ProfileType.ESTABLISHMENT_ADMIN, name: <Translate value="enum.profileType.establishmentAdmin" /> },
  { id: ProfileType.ESTABLISHMENT, name: <Translate value="enum.profileType.establishment" /> },
  { id: ProfileType.MOTOBOY, name: <Translate value="enum.profileType.motoboy" /> },
  { id: ProfileType.CONDOMINIUM_SUB_MANAGER, name: <Translate value="enum.profileType.condominiumSubManager" /> },
  { id: ProfileType.FINANCIAL_ADMINISTRATOR, name: <Translate value="enum.profileType.financial" /> },
  { id: ProfileType.ADVISOR, name: <Translate value="enum.profileType.advisor" /> },
];

export const GetProfileTypeTitle = (value) => value && (ProfileTypeArray.find((o) => o.id === value).name);
