/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Divider,
  Layout,
  Row,
  Col,
  message,
  Breadcrumb,
  Icon,
  Popconfirm,
  Button,
  Spin,
  Table,
  Modal,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import { CondominiumActions, UserActions } from '../../../../app/redux/actions';
import {
  LoadingSelectors, AuthSelectors, CondominiumSelectors, UserSelectors,
} from '../../../../app/redux/reducers';

import { GetProfileTypeTitle, ProfileType } from '../../../../app/enum/profileType';

import AdvancedAutoCompleteInput from '../../../../components/shared/AdvancedAutoCompleteInput/AdvancedAutoCompleteInput';
import { isAdmin, isCondominiumManager } from '../../../../app/utils/permissions';

const defaultParams = {
  id: null,
};

class GoverningBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddModalOpen: false,
      editedUser: null,
      params: defaultParams,
    };
    this.dataTableRef = React.createRef();
  }

  async componentDidMount() {
    const { getDetails, id } = this.props;

    getDetails(id);
  }

  fieldChange(name, value) {
    const { params } = this.state;
    const { getUserDetails } = this.props;

    if (name === 'id' && value) {
      getUserDetails(value);
    }
    params[name] = value || null;
    this.setState({ params });
  }

  addDweller(profileType) {
    const { params } = this.state;
    const { update, id, me } = this.props;

    update(params.id, {
      profileType,
      ...(me.profileType === ProfileType.ADMIN && { condominiumId: id }),
    }, () => {
      message.success('Usuário foi atribuido com sucesso!');
      window.location.reload();
    });
  }

  remove(profileType) {
    const { update, id, me } = this.props;

    update(profileType, {
      profileType: ProfileType.DWELLER,
      ...(me.profileType !== ProfileType.CONDOMINIUM_MANAGER && { condominiumId: id }),
    }, () => {
      message.success('Usuário foi removido com sucesso!');
      window.location.reload();
    });
  }

  render() {
    const { Content } = Layout;
    const { isAddModalOpen, editedUser, params } = this.state;
    const {
      loading, details, getPaginatedCondominiumMemberForSearch, id, userDetails, cleanDetails, me,
    } = this.props;

    const columns = [
      {
        title: I18n.t('routes.panel.condominium.items.governingBody.dataTable.columns.profileType.title'),
        dataIndex: I18n.t('routes.panel.condominium.items.governingBody.dataTable.columns.profileType.key'),
        key: I18n.t('routes.panel.condominium.items.governingBody.dataTable.columns.profileType.key'),
        render: (value) => (value && GetProfileTypeTitle(value)) || '--',
      },
      {
        title: I18n.t('routes.panel.condominium.items.governingBody.dataTable.columns.name.title'),
        dataIndex: I18n.t('routes.panel.condominium.items.governingBody.dataTable.columns.name.key'),
        key: I18n.t('routes.panel.condominium.items.governingBody.dataTable.columns.name.key'),
        render: (value) => value || '--',
      },
      {
        title: I18n.t('routes.panel.condominium.items.governingBody.dataTable.columns.email.title'),
        dataIndex: I18n.t('routes.panel.condominium.items.governingBody.dataTable.columns.email.key'),
        key: I18n.t('routes.panel.condominium.items.governingBody.dataTable.columns.email.key'),
        render: (value) => value || '--',
      },
      {
        title: I18n.t('routes.panel.condominium.items.governingBody.dataTable.columns.cellphone.title'),
        dataIndex: I18n.t('routes.panel.condominium.items.governingBody.dataTable.columns.cellphone.key'),
        key: I18n.t('routes.panel.condominium.items.governingBody.dataTable.columns.cellphone.key'),
        render: (value) => (value && `+${value.substring(0, 2)} (${value.substring(2, 4)}) ${value.substring(4, 9)}-${value.substring(9, 13)}`) || '--',
      },
    ];

    if (isAdmin(me) || isCondominiumManager(me)) {
      columns.splice(4, 0, {
        key: 'action',
        render: (value) => (
          <div className="dataTable__item--right">
            {value.id && (
              <>
                <Popconfirm
                  placement="left"
                  title={I18n.t('shared.confirmTitle')}
                  onConfirm={() => this.remove(value.id)}
                  okText={I18n.t('shared.yes')}
                  cancelText={I18n.t('shared.no')}
                >
                  <Button
                    type="link"
                  >
                    {I18n.t('routes.panel.condominium.items.governingBody.dataTable.columns.actions.removeText')}
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
                <Button
                  type="link"
                  onClick={() => {
                    this.setState({
                      isAddModalOpen: true,
                      editedUser: value,
                    });
                    this.fieldChange('id', value.id);
                  }}
                >
                  {I18n.t('routes.panel.condominium.items.governingBody.dataTable.columns.actions.edit')}
                  <EditOutlined />
                </Button>
              </>
            )}
            {!value.id && (
              <Button
                type="link"
                onClick={() => this.setState({
                  isAddModalOpen: true,
                  editedUser: value,
                })}
              >
                {I18n.t('routes.panel.condominium.items.governingBody.dataTable.columns.actions.add')}
                <EditOutlined />
              </Button>
            )}
          </div>
        ),
      });
    }

    return (
      <div>
        {isAddModalOpen && (
          <Modal
            title={editedUser.id
              ? GetProfileTypeTitle(editedUser.profileType)
              : GetProfileTypeTitle(editedUser.profileType)}
            visible
            okText={I18n.t(`routes.panel.condominium.items.governingBody.modal.${editedUser.id ? 'changeButton' : 'addButton'}`)}
            onOk={() => this.addDweller(editedUser.profileType)}
            onCancel={() => {
              this.setState({
                isAddModalOpen: false,
                editedUser: null,
              });
              this.fieldChange('id', null);
              cleanDetails();
            }}
            width={700}
          >
            <Row gutter={16}>
              <Col>
                <AdvancedAutoCompleteInput
                  searchMethod={(parameters) => getPaginatedCondominiumMemberForSearch({
                    ...parameters,
                    condominiumId: id,
                  })}
                  data={I18n.t('advancedAutoComplete.condominiumUser')}
                  onSelect={(item) => this.fieldChange('id', item.id)}
                  onSelectSelectable={(userId) => this.fieldChange('id', userId)}
                  value={params && params.id}
                  disableSearch
                />
              </Col>
            </Row>
            {userDetails && (
              <>
                <Divider />
                <Row>
                  <Col span={12}>
                    <h2>
                      {I18n.t('routes.panel.condominium.items.governingBody.modal.userDateTitle')}
                    </h2>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <span className="text-margin">
                      {I18n.t('routes.panel.condominium.items.governingBody.modal.userData.profileType')}
                    </span>
                  </Col>
                  {GetProfileTypeTitle(userDetails.profileType)}
                </Row>
                <Row>
                  <Col span={12}>
                    <span className="text-margin">
                      {I18n.t('routes.panel.condominium.items.governingBody.modal.userData.email')}
                    </span>
                  </Col>
                  {userDetails.email}
                </Row>
                <Row>
                  <Col span={12}>
                    <span className="text-margin">
                      {I18n.t('routes.panel.condominium.items.governingBody.modal.userData.cellphone')}
                    </span>
                  </Col>
                  {(userDetails.cellphone && `+${userDetails.cellphone.substring(0, 2)} (${userDetails.cellphone.substring(2, 4)}) ${userDetails.cellphone.substring(4, 9)}-${userDetails.cellphone.substring(9, 13)}`) || '--'}
                </Row>
              </>
            )}
          </Modal>
        )}
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.condominium.items.governingBody.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content">
          <div className="administrators">
            <Spin
              spinning={loading > 0}
              tip={I18n.t('shared.loading')}
            >
              <Row>
                <Col span={12}>
                  <h2>
                    <span className="panel__layout__content__title__value__icon">
                      <Icon type={I18n.t('routes.panel.condominium.items.governingBody.icon')} />
                    </span>
                    {`${details && details.name} - ${I18n.t('routes.panel.condominium.items.governingBody.pageTitle')}`}
                  </h2>
                </Col>
              </Row>

              <Divider />
              <Table
                dataSource={details && [
                  {
                    profileType: ProfileType.CONDOMINIUM_MANAGER,
                    ...details.condominiumManager,
                  },
                  {
                    profileType: ProfileType.CONDOMINIUM_SUB_MANAGER,
                    ...details.condominiumSubManager,
                  },
                  {
                    profileType: ProfileType.FINANCIAL_ADMINISTRATOR,
                    ...details.financialAdministrator,
                  },
                  {
                    profileType: ProfileType.ADVISOR,
                    ...details.advisor,
                  },
                ]}
                loading={!details}
                columns={columns}
                size="middle"
                bordered={false}
                pagination={false}
              />
            </Spin>
          </div>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  details: CondominiumSelectors.getDetails(state),
  userDetails: UserSelectors.getDetails(state),
});

const mapDispatchToProps = (dispatch) => ({
  getDetails: (id) => dispatch(CondominiumActions.getDetails(id)),
  getPaginatedCondominiumMemberForSearch: (parameters) => dispatch(UserActions.getPaginatedCondominiumMemberForSearch(parameters)),
  getUserDetails: (id) => dispatch(UserActions.getCondominiumUserDetails(id)),
  update: (id, data, callback) => dispatch(UserActions.update(id, data, callback)),
  cleanDetails: () => dispatch(UserActions.cleanDetails()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GoverningBody);
