/* eslint-disable no-dupe-else-if */
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  Upload, message, Modal, Col, Row,
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { LoadingSelectors } from '../../../app/redux/reducers';
import FileUtils from '../../../app/utils/file';
import AdvancedButton from '../../shared/AdvancedButton/AdvancedButton';

const defaultValues = {
  jsonResult: null,
  companyId: null,
  file: null,
};

class DwellerImportModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = defaultValues;
  }

  customRequest(onSuccess, onError, file) {
    const reader = new FileReader();
    reader.readAsText(file, 'UTF-8');
    reader.onload = (evt) => {
      this.setState({
        jsonResult: FileUtils.csvToJson(evt.target.result),
        file,
      });

      return onSuccess(I18n.t('routes.panel.condominium.items.condominium.dwellerImportCsvModal.success'), file);
    };

    reader.onerror = () => {
      message.error(I18n.t('routes.panel.condominium.items.condominium.dwellerImportCsvModal.errors.generic'));
    };
  }

  uploadFile() {
    const { jsonResult } = this.state;
    const {
      importFunction, csvImportSuccess, isAdmin,
      importDwellers, selectedCondominium, isChannelImport,
    } = this.props;

    if (jsonResult && jsonResult.length <= 0) {
      return message.error(I18n.t('routes.panel.condominium.items.condominium.dwellerImportCsvModal.errors.empty'));
    }

    if (isChannelImport) {
      const translatedJsonResult = [];
      jsonResult.map((o) => translatedJsonResult.push(o.email));
      importFunction(translatedJsonResult);
    } else {
      const translatedJsonResult = [];
      jsonResult.forEach((o) => {
        if (o && o.nome && o.email && o.celular && o.bloco && o.numero) {
          translatedJsonResult.push({
            name: o.nome,
            email: o.email,
            cellphone: o.celular,
            block: o.bloco,
            number: o.numero,
            isOwner: true,
            canReceiveVisit: true,
            canReceiveOrder: true,
            canRegisterFamiliar: true,
          });
        }
      });

      importDwellers(
        isAdmin ? selectedCondominium.id : selectedCondominium,
        translatedJsonResult,
        csvImportSuccess,
      );
    }
  }

  render() {
    const {
      visible,
      handleCancel,
      loading,
      selectedCondominium,
    } = this.props;
    return (
      <Modal
        title={I18n.t('routes.panel.condominium.items.condominium.dwellerImportCsvModal.title')}
        visible={visible}
        onCancel={(ev) => handleCancel(ev)}
        footer={[
          <AdvancedButton
            type="link"
            onClick={(ev) => handleCancel(ev)}
            key="cancel"
            text={I18n.t('routes.panel.condominium.items.condominium.dwellerImportCsvModal.cancelText')}
          />,
          <AdvancedButton
            type="primary"
            loading={loading && loading > 0}
            onClick={() => this.uploadFile()}
            key="upload"
            text={I18n.t('routes.panel.condominium.items.condominium.dwellerImportCsvModal.okText')}
          />,
        ]}
      >
        <Row className="user-import-modal__template">
          <Col>
            <h2>{selectedCondominium && selectedCondominium.name}</h2>
          </Col>
        </Row>
        <Row className="user-import-modal__template">
          <Col>
            <a
              href="/assets/files/csv-import-template.csv"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>{I18n.t('routes.panel.condominium.items.condominium.dwellerImportCsvModal.templateButtonOne')}</strong>
            </a>, {I18n.t('routes.panel.condominium.items.condominium.dwellerImportCsvModal.templateButtonTwo')}
          </Col>
        </Row>
        <Row className="user-import-modal__input">
          <Col>
            <Upload.Dragger
              name="file"
              multiple={false}
              customRequest={({ onSuccess, onError, file }) => this.customRequest(onSuccess, onError, file)}
              accept=".csv"
              fileList={this.state.file ? [this.state.file] : []}
              onRemove={() => this.setState({ jsonResult: null, file: null })}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                {I18n.t('routes.panel.condominium.items.condominium.dwellerImportCsvModal.selectFile')}
              </p>
              <p className="ant-upload-hint">
                {I18n.t('routes.panel.condominium.items.condominium.dwellerImportCsvModal.selectFileDescription')}
              </p>
            </Upload.Dragger>
          </Col>
        </Row>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
});

export default connect(
  mapStateToProps,
)(
  DwellerImportModal,
);
