import { Translate } from 'react-redux-i18n';
import React from 'react';

export const NotificationType = {
  NEW_VISIT: 1,
  NEW_PACKAGE: 2,
  PAYMENT_APPROVED: 3,
  PACKAGE_RECEIVED: 4,
  ASSOCIATION_APPROVED: 5,
  PAYMENT_ERROR: 6,
  ASSOCIATION_REFUSED: 7,
  NEW_MESSAGE: 8,
  NEW_FAMILIAR_MEMBER: 9,
  DELETE_FAMILIAR_MEMBER: 10,
  SCHEDULING_RECREATION_SUCCESS: 11,
  SCHEDULING_RECREATION_CANCELED: 12,
};

export const NotificationTypeArray = [
  { id: NotificationType.NEW_VISIT, name: <Translate value="enum.notificationType.newVisit" /> },
  { id: NotificationType.NEW_PACKAGE, name: <Translate value="enum.notificationType.newPackage" /> },
  { id: NotificationType.PAYMENT_APPROVED, name: <Translate value="enum.notificationType.paymentApproved" /> },
  { id: NotificationType.PACKAGE_RECEIVED, name: <Translate value="enum.notificationType.packageReceived" /> },
  { id: NotificationType.ASSOCIATION_APPROVED, name: <Translate value="enum.notificationType.associationApproved" /> },
  { id: NotificationType.PAYMENT_ERROR, name: <Translate value="enum.notificationType.paymentError" /> },
  { id: NotificationType.ASSOCIATION_REFUSED, name: <Translate value="enum.notificationType.associationRefused" /> },
  { id: NotificationType.NEW_MESSAGE, name: <Translate value="enum.notificationType.newMessage" /> },
  { id: NotificationType.NEW_FAMILIAR_MEMBER, name: <Translate value="enum.notificationType.newFamiliarMember" /> },
  { id: NotificationType.DELETE_FAMILIAR_MEMBER, name: <Translate value="enum.notificationType.deleteFamiliarMember" /> },
  { id: NotificationType.SCHEDULING_RECREATION_SUCCESS, name: <Translate value="enum.notificationType.scheduling_recreation_success" /> },
  { id: NotificationType.SCHEDULING_RECREATION_CANCELED, name: <Translate value="enum.notificationType.scheduling_recreation_canceled" /> },
];

export const GetNotificationTypeTitle = (value) => value && NotificationTypeArray.find((o) => o.id === value).name;
