import React from 'react';
import {
  Breadcrumb,
  Col,
  Divider,
  Icon,
  Layout,
  message,
  Row,
  Spin,
} from 'antd';
import { connect } from 'react-redux';
import { Link, navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';

import { LoadingSelectors, RecreationSelectors } from '../../../../../app/redux/reducers';
import { RecreationActions } from '../../../../../app/redux/actions';
import { ViewType } from '../../../../../app/enum/viewType';
import RecreationForm from '../../../../../app/forms/recreationArea/RecreationForm';

class RecreationAreaDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType: ViewType.EDIT,
      areaId: null,
      categoryId: null,
    };
  }

  componentDidMount() {
    const { getDetails, id } = this.props;

    let areaId = window.location.href.split('/');
    areaId = areaId[areaId.length - 1];

    this.setState({
      viewType: areaId !== 'add' ? ViewType.EDIT : ViewType.ADD,
      areaId,
      categoryId: id,
    });

    getDetails(areaId);
  }

  save(data) {
    const { update, create } = this.props;
    const { viewType, areaId, categoryId } = this.state;
    try {
      if (viewType === ViewType.ADD) {
        create({ ...data, categoryId }, () => {
          message.success(I18n.t('routes.panel.schedule.items.recreationArea.messages.successCreate'));
          navigate(`${I18n.t('routes.panel.schedule.items.recreationArea.url')}/${categoryId}`);
        });
      } else {
        update(areaId, { ...data, categoryId }, () => {
          message.success(I18n.t('routes.panel.schedule.items.recreationArea.messages.successEdit'));
          navigate(`${I18n.t('routes.panel.schedule.items.recreationArea.url')}/${categoryId}`);
        });
      }
    } catch (e) {
      message.error(I18n.t('shared.genericError'));
    }
  }

  render() {
    const { Content } = Layout;
    const { viewType, categoryId, areaId } = this.state;
    const { loading } = this.props;

    return (
      <div>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" />
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={I18n.t('routes.panel.schedule.items.recreationArea.url')}>
                <span>{I18n.t('routes.panel.schedule.items.recreationArea.pageCategoryTitle')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${I18n.t('routes.panel.schedule.items.recreationArea.url')}/${categoryId}`}>
                <span>{I18n.t('routes.panel.schedule.items.recreationArea.pageTitle')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>
                {I18n.t(viewType === ViewType.ADD
                  ? 'routes.panel.schedule.items.recreationArea.details.pageTitleAdd'
                  : 'routes.panel.schedule.items.recreationArea.details.pageTitleEdit')}
              </span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content">
          <div>
            <Spin
              spinning={loading > 0}
              tip={I18n.t('shared.loading')}
            >
              <div className="panel__layout__content__title">
                <h2 className="panel__layout__content__title__value">
                  <span className="panel__layout__content__title__value__icon">
                    <Icon type={I18n.t('routes.panel.condominium.items.condominium.icon')} />
                  </span>
                  {I18n.t(viewType === ViewType.ADD
                    ? 'routes.panel.schedule.items.recreationArea.details.pageTitleAdd'
                    : 'routes.panel.schedule.items.recreationArea.details.pageTitleEdit')}
                </h2>
                <p className="panel__layout__content__title__value__description">
                  {I18n.t(viewType === ViewType.ADD
                    ? 'routes.panel.schedule.items.recreationArea.details.pageDescriptionAdd'
                    : 'routes.panel.schedule.items.recreationArea.details.pageDescriptionEdit')}
                </p>
              </div>

              <Divider />

              <div>
                <Row>
                  <Col>
                    <RecreationForm
                      id={areaId}
                      submitFunction={(data) => this.save(data)}
                      viewType={viewType}
                      loading={loading}
                    />
                  </Col>
                </Row>
              </div>
            </Spin>
          </div>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  recreationCategory: RecreationSelectors.getDetails(state),
});

const mapDispatchToProps = (dispatch) => ({
  getDetails: (id) => dispatch(RecreationActions.getDetails(id)),
  create: (data, callback) => dispatch(RecreationActions.create(data, callback)),
  update: (id, data, callback) => dispatch(RecreationActions.update(id, data, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecreationAreaDetails);
