import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import {
  addLoading,
  removeLoading,
} from './loading';
import SettingsRequests from '../../api/settings';

export const ACTION_SAVE_SETTINGS_PAGINATED = 'ACTION_SAVE_SETTINGS_PAGINATED';

export const ACTION_CLEAN_SETTINGS = 'ACTION_CLEAN_SETTINGS';

// cleaners
export const clean = () => ({
  type: ACTION_CLEAN_SETTINGS,
  payload: null,
});

export const cleanPaginated = () => ({
  type: ACTION_SAVE_SETTINGS_PAGINATED,
  payload: null,
});

export const getAll = (callback) => async (dispatch) => {
  dispatch(addLoading());
  dispatch(cleanPaginated());

  try {
    dispatch({
      type: ACTION_SAVE_SETTINGS_PAGINATED,
      payload: null,
    });

    const response = await SettingsRequests.getAll();

    dispatch({
      type: ACTION_SAVE_SETTINGS_PAGINATED,
      payload: response,
    });

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const update = (data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const response = await SettingsRequests.update(data);

    dispatch(getAll(() => {
      if (callback && typeof callback === 'function') {
        callback(response);
      }
    }));
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};
