import getInstance from './config';

export default class PushNotificationApi {
  static async getPaginated(params) {
    const instance = await getInstance();
    const { data } = await instance.get('/v1/notification', { params });
    return data;
  }

  static async getDetails(id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/v1/notification/${id}`);
    return data;
  }

  static async update(id, userData) {
    const instance = await getInstance();
    const { data } = await instance.put(`/v1/notification/${id}`, userData);
    return data;
  }

  static async create(userData) {
    const instance = await getInstance();
    const { data } = await instance.post('/v1/notification', userData);
    return data;
  }

  static async remove(id) {
    const instance = await getInstance();
    const { data } = await instance.delete(`/v1/notification/${id}`);
    return data;
  }

  static async getUsersForPushPaginated(params) {
    const instance = await getInstance();
    const { data } = await instance.get('/v1/user/selective', { params });
    return data;
  }

  static async getCondominiumsForPushPaginated(params) {
    const instance = await getInstance();
    const { data } = await instance.get('/v1/condominium', { params });
    return data;
  }

  static async getPushNotificationsPaginated(params) {
    const instance = await getInstance();
    const { data } = await instance.get('/v1/notification', { params });
    return data;
  }

  static async createNotification(notificationData) {
    const instance = await getInstance();
    const { data } = await instance.post('/v1/notification', notificationData);
    return data;
  }
}
