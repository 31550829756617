import React from 'react';
import {
  Breadcrumb,
  Col,
  Divider,
  Icon,
  Layout,
  message,
  Row,
  Spin,
  Descriptions,
  Timeline,
  Modal,
  Button,
} from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { Link, navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import { QRCode } from 'react-qr-svg';

import { TransactionActions } from '../../../../../app/redux/actions';
import { LoadingSelectors, TransactionSelectors } from '../../../../../app/redux/reducers';
import TransactionForm from '../../../../../app/forms/transaction/TransactionForm';
import { ViewType } from '../../../../../app/enum/viewType';
import { GetTransactionStatusTypeTag, GetTransactionStatusTypeTitle, GetTransactionStatusTypeColor } from '../../../../../app/enum/transactionStatusType';
import * as CurrencyUtils from '../../../../../app/utils/currency';
import * as DateUtils from '../../../../../app/utils/date';
import AdvancedButton from '../../../../../components/shared/AdvancedButton/AdvancedButton';

class TransactionDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType: ViewType.EDIT,
      modalContent: null,
    };
  }

  componentDidMount() {
    const { getDetails, id } = this.props;

    this.setState({
      viewType: id !== 'add' ? ViewType.EDIT : ViewType.ADD,
    });

    getDetails(id);
  }

  save(id, data) {
    const { update, create } = this.props;

    try {
      if (this.state.viewType === ViewType.ADD) {
        create(data, (response) => {
          message.success(I18n.t('routes.panel.establishment.items.transaction.details.messages.successCreate'));
          this.setState({ modalContent: response });
          if (data.sendPhone) {
            window.open(
              `https://api.whatsapp.com/send?phone=+55${data.sendPhone}&text=O link de sua compra é: ${window.location.host}/payment/${response && response.id.toString()}`,
            );
          }
        });
      } else {
        update(id, data, () => {
          message.success(I18n.t('routes.panel.establishment.items.transaction.details.messages.successEdit'));
          navigate(I18n.t('routes.panel.establishment.items.transaction.url'));
        });
      }
    } catch (e) {
      message.error(I18n.t('shared.genericError'));
    }
  }

  render() {
    const { Content } = Layout;
    const { viewType, modalContent } = this.state;
    const {
      details,
      loading,
      id,
    } = this.props;

    return (
      <div>
        <Modal
          visible={!!modalContent}
          onCancel={() => navigate(I18n.t('routes.panel.establishment.items.transaction.url'))}
          onOk={() => navigate(I18n.t('routes.panel.establishment.items.transaction.url'))}
          title={I18n.t('routes.panel.establishment.items.transaction.modalTitle')}
          bodyStyle={{ textAlign: 'center' }}
        >
          <Col>
            <QRCode
              bgColor="#FFFFFF"
              fgColor="#000000"
              level="Q"
              style={{ width: 256, marginBottom: '15px' }}
              value={modalContent && `${window.location.host}/payment/${modalContent && modalContent.id.toString()}`}
              id="qrCode-condominium"
              className="oi"
            />
            <Row>
              <Link to={`${window.location.host}/payment/${modalContent && modalContent.id.toString()}`}>
                {`${window.location.host}/payment/${modalContent && modalContent.id.toString()}`}
              </Link>
              <Button
                onClick={() => {
                  const copyText = document.createElement('textarea');
                  document.body.appendChild(copyText);
                  copyText.value = `${window.location.host}/payment/${modalContent && modalContent.id.toString()}`;
                  copyText.select();
                  document.execCommand('copy');
                  message.success(I18n.t('routes.panel.establishment.items.transaction.details.messages.successCopy'));
                }}
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: '5px',
                }}
              >
                <CopyOutlined />
              </Button>
            </Row>

          </Col>
        </Modal>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={I18n.t('routes.panel.establishment.items.transaction.url')}>
                <Icon type={I18n.t('routes.panel.establishment.items.transaction.icon')} /> <span>{I18n.t('routes.panel.establishment.items.transaction.pageTitle')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>
                {
                  I18n.t(
                    viewType === ViewType.ADD
                      ? 'routes.panel.establishment.items.transaction.pageTitleAdd'
                      : 'routes.panel.establishment.items.transaction.pageTitleEdit',
                  )
                }
              </span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content">
          <div>
            <Spin
              spinning={loading > 0}
              tip={I18n.t('shared.loading')}
            >
              <div className="panel__layout__content__title">
                <h2 className="panel__layout__content__title__value">
                  <span className="panel__layout__content__title__value__icon">
                    <Icon type={I18n.t('routes.panel.establishment.items.transaction.icon')} />
                  </span>
                  {
                    I18n.t(
                      viewType === ViewType.ADD
                        ? 'routes.panel.establishment.items.transaction.pageTitleAdd'
                        : 'routes.panel.establishment.items.transaction.pageTitleEdit',
                    )
                  }
                </h2>
                <p className="panel__layout__content__title__value__description">
                  {
                    I18n.t(
                      viewType === ViewType.ADD
                        ? 'routes.panel.establishment.items.transaction.pageDescriptionAdd'
                        : 'routes.panel.establishment.items.transaction.pageDescriptionEdit',
                    )
                  }
                </p>
              </div>

              <Divider />

              <div>
                <Row>
                  <Col>
                    {viewType === ViewType.ADD && (
                      <TransactionForm
                        id={id}
                        submitFunction={(dataId, data) => this.save(dataId, data)}
                        viewType={viewType}
                        loading={loading}
                      />
                    )}
                    {viewType === ViewType.EDIT && details && (
                      <Descriptions
                        title={`Transação #${details.code}`}
                        bordered
                      >
                        <Descriptions.Item label="Estabelecimento">
                          {details.establishment && (
                            <Link to={`${I18n.t('routes.panel.establishment.items.establishment.url')}/${details.establishment.id}`}>
                              {details.establishment.name}
                            </Link>
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Entregador">
                          {details.motoboy && (
                          <Link to={`${I18n.t('routes.panel.users.items.deliveryman.url')}/${details.motoboy.id}`}>
                            {details.motoboy.name}
                          </Link>
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Morador">
                          {details.user && details.user.name}
                        </Descriptions.Item>
                        <Descriptions.Item label="Condomínio">
                          {details.condominium && (
                          <Link to={`${I18n.t('routes.panel.condominium.items.condominium.url')}/${details.condominium.id}`}>
                            {details.condominium.name}
                          </Link>
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Nº de Parcelas">{details.installments}</Descriptions.Item>
                        <Descriptions.Item label="Valor">{CurrencyUtils.toCurrencyLocale(details.totalPrice)}</Descriptions.Item>
                        <Descriptions.Item
                          label="Status"
                        >
                          {GetTransactionStatusTypeTag(details.status)}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label="Criado em"
                        >
                          {DateUtils.humanizeDateTime(details.createdAt, 'DD/MM/YYYY [ás] HH:mm')}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label="Aprovado em"
                        >
                          {DateUtils.humanizeDateTime(details.approvedAt, 'DD/MM/YYYY [ás] HH:mm')}
                        </Descriptions.Item>
                        <Descriptions.Item label="Comissão BeAllBee">{CurrencyUtils.toCurrencyLocale(details.beAllBeeCommission)}</Descriptions.Item>
                        <Descriptions.Item label="Beecash">{CurrencyUtils.toCurrencyLocale(details.beecash)}</Descriptions.Item>
                        <Descriptions.Item label="Estabelecimento Recebe">{CurrencyUtils.toCurrencyLocale(details.establishmentCommission)}</Descriptions.Item>
                        <Descriptions.Item label="Histórico">
                          <Timeline pending={details.transactionHistory && details.transactionHistory.length === 1 ? 'Aguardando pagamento...' : null}>
                            {details.transactionHistory && details.transactionHistory.map((t, i) => {
                              const isFirst = t.status === 1;
                              return (
                                <Timeline.Item
                                  color={GetTransactionStatusTypeColor(t.status)}
                                  key={i.toString()}
                                >
                                  {isFirst
                                    ? `Criado em ${DateUtils.humanizeDateTime(t.createdAt, 'DD/MM/YYYY [ás] HH:mm')}`
                                    : `Status alterado para ${I18n.t(GetTransactionStatusTypeTitle(t.status).props.value)}  em ${DateUtils.humanizeDateTime(t.createdAt, 'DD/MM/YYYY [ás] HH:mm')}`}
                                </Timeline.Item>
                              );
                            })}
                          </Timeline>
                        </Descriptions.Item>
                      </Descriptions>
                    )}

                    {viewType !== ViewType.ADD && (
                      <>
                        <Divider />

                        <Row>
                          <Col
                            span={24}
                            className="text-right"
                          >
                            <AdvancedButton
                              type="link"
                              text={I18n.t('shared.backButtonText')}
                              href={I18n.t('routes.panel.establishment.items.transaction.url')}
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                  </Col>
                </Row>
              </div>
            </Spin>
          </div>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  details: TransactionSelectors.getDetails(state),
});

const mapDispatchToProps = (dispatch) => ({
  getDetails: (id) => dispatch(TransactionActions.getDetails(id)),
  create: (data, callback) => dispatch(TransactionActions.create(data, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransactionDetails);
