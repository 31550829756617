import React from 'react';
import { Table } from 'antd';
import { I18n } from 'react-redux-i18n';
import QueryStringUtils from '../../../app/utils/queryString';

export const defaultValues = {
  limit: 10,
  total: 0,
  page: 1,
  data: null,
};

const qs = new QueryStringUtils();

class DataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = defaultValues;
    const { notGetOnStart } = this.props;

    if (!notGetOnStart) {
      const { params } = qs.get();
      let finalParams = defaultValues;

      if (params) {
        finalParams = {
          ...finalParams,
          ...params,
        };
      }

      this.props.getMethod(finalParams);
    }
  }

  componentDidMount() {
    let finalValues = defaultValues;

    const { params } = qs.get();

    if (params) {
      finalValues = {
        ...finalValues,
        ...params,
      };
    }

    this.setState(finalValues);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      const { page } = this.state;

      this.setState({
        total: nextProps.data.count,
        page,
      });
    }

    this.setState({ data: nextProps.data });
  }

  getColumns(params) {
    return params.map((o) => ({
      title: o.title,
      dataIndex: o.key,
      key: o.key,
      render: o.render ? o.render : undefined,
    }));
  }

  getRows(data) {
    data = data.map((o) => ({
      ...o,
      key: o.id ? o.id.toString() : Math.random().toString(36),
    }));
    return data;
  }

  handleTableChange(pagination) {
    const { params } = qs.get();
    this.setState(
      {
        page: pagination.current,
      },
      () => {
        this.props.getMethod({
          ...params,
          offset: pagination.current > 1 ? pagination.current - 1 : 0,
          limit: this.state.limit,
        });
      },
    );
  }

  reset(hasGetMethod) {
    const { getMethod } = this.props;
    this.setState(defaultValues);
    if (hasGetMethod) {
      getMethod(defaultValues);
    }
  }

  rowSelection() {
    return {
      onChange: (selectedRowKeys, selectedRows) => {
        const { onSelectRow } = this.props;
        onSelectRow(selectedRows);
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        name: record.name,
      }),
    };
  }

  render() {
    const { columns, loading, selectable } = this.props;

    const {
      total, page, limit, data,
    } = this.state;

    return (
      <div className="dataTable__wrapper">
        {selectable ? (
          <Table
            rowSelection={{
              type: 'checkbox',
              ...this.rowSelection(),
            }}
            dataSource={data && this.getRows(data.rows)}
            columns={this.getColumns(columns)}
            bordered={false}
            loading={loading}
            pagination={{
              current: page,
              total,
              page,
              limit,
            }}
            size="middle"
            onChange={(ev) => this.handleTableChange(ev)}
            locale={{
              emptyText: I18n.t('shared.nothingToShow'),
            }}
          />
        ) : (
          <Table
            dataSource={data && this.getRows(data.rows)}
            columns={this.getColumns(columns)}
            bordered={false}
            loading={loading}
            pagination={{
              current: page,
              total,
              page,
              limit,
            }}
            size="middle"
            onChange={(ev) => this.handleTableChange(ev)}
            locale={{
              emptyText: I18n.t('shared.nothingToShow'),
            }}
          />
        )}
      </div>
    );
  }
}

export default DataTable;
