import { Translate } from 'react-redux-i18n';
import React from 'react';
import { Tag } from 'antd';

const VisitStatusType = {
  PENDING: 1,
  DONE: 2,
};

export const VisitStatusTypeArray = [
  {
    id: VisitStatusType.PENDING,
    name: <Translate value="enum.visitStatusType.pending" />,
    tag: <Tag color="red"><Translate value="enum.visitStatusType.pending" /></Tag>,

  },
  {
    id: VisitStatusType.DONE,
    name: <Translate value="enum.visitStatusType.done" />,
    tag: <Tag color="green"><Translate value="enum.visitStatusType.done" /></Tag>,
  },
];

export const GetVisitStatusTypeTitle = (value) => (VisitStatusTypeArray.find((o) => o.id === value).name);
export const GetVisitStatusTypeTag = (value) => (VisitStatusTypeArray.find((o) => o.id === value).tag);

export default VisitStatusType;
