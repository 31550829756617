import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose,
} from 'redux';
import { i18nReducer } from 'react-redux-i18n';
import thunk from 'redux-thunk';

import administratorReducer from './reducers/administrator';
import authReducer from './reducers/auth';
import loadingReducer from './reducers/loading';
import loadingSearchReducer from './reducers/loading-search';
import pushNotificationReducer from './reducers/push-notification';
import userReducer from './reducers/user';
import condominiumReducer from './reducers/condominium';
import recreationCategoryReducer from './reducers/recreation-category';
import recreationReducer from './reducers/recreation';
import schedulingRecreationReducer from './reducers/scheduling-recreation';
import condominiumAdministratorReducer from './reducers/condominiumAdministrator';
import channelsReducer from './reducers/channels';
import visitReducer from './reducers/visit';
import solicitationReducer from './reducers/solicitation';
import orderReducer from './reducers/order';
import categoryReducer from './reducers/category';
import messageReducer from './reducers/message';
import establishmentReducer from './reducers/establishment';
import settingsReducer from './reducers/settings';
import transactionReducer from './reducers/transaction';

export default () => {
  const store = createStore(
    combineReducers({
      i18n: i18nReducer,
      administrator: administratorReducer,
      auth: authReducer,
      loading: loadingReducer,
      loadingSearch: loadingSearchReducer,
      pushNotification: pushNotificationReducer,
      user: userReducer,
      condominium: condominiumReducer,
      recreationCategory: recreationCategoryReducer,
      schedulingRecreation: schedulingRecreationReducer,
      recreation: recreationReducer,
      condominiumAdministrator: condominiumAdministratorReducer,
      channels: channelsReducer,
      message: messageReducer,
      visit: visitReducer,
      solicitation: solicitationReducer,
      order: orderReducer,
      category: categoryReducer,
      establishment: establishmentReducer,
      settings: settingsReducer,
      transaction: transactionReducer,
    }),
    compose(
      applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f,
    ),
  );

  return store;
};
