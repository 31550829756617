import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import {
  addLoading,
  removeLoading,
} from './loading';
import OrderRequests from '../../api/order';

export const ACTION_SAVE_ORDER_PAGINATED = 'ACTION_SAVE_ORDER_PAGINATED';

export const ACTION_CLEAN_ORDER = 'ACTION_CLEAN_ORDER';

// cleaners
export const clean = () => ({
  type: ACTION_CLEAN_ORDER,
  payload: null,
});

export const cleanPaginated = () => ({
  type: ACTION_SAVE_ORDER_PAGINATED,
  payload: null,
});

/*
 * BASIC CRUD METHODS
 *
 * getPaginated: get rows for data table
 * getDetails: get details (get by id)
 * update: the PUT method
 * create: the POST method
 */

export const getPaginated = (parameters, callback) => async (dispatch) => {
  dispatch(addLoading());
  dispatch(cleanPaginated());

  try {
    parameters = {
      ...parameters,
      limit: parameters.limit || 10,
      total: parameters.total || 0,
      page: parameters.page || 1,
      orderBy: 'createdAt',
      isDESC: true,
    };

    dispatch({
      type: ACTION_SAVE_ORDER_PAGINATED,
      payload: null,
    });

    const response = await OrderRequests.getPaginated(parameters);

    dispatch({
      type: ACTION_SAVE_ORDER_PAGINATED,
      payload: response,
    });

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const remove = (id, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const response = await OrderRequests.remove(id);
    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};
