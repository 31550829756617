import {
  ACTION_SAVE_ORDER_PAGINATED,
} from '../actions/order';

const initialState = {
  paginatedData: null,
  details: null,
};

export default function order(state = initialState, action) {
  switch (action.type) {
    case ACTION_SAVE_ORDER_PAGINATED:
      state = {
        ...state,
        paginatedData: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getPaginated(state) {
  return state.order.paginatedData;
}
