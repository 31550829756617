import Axios from 'axios';
import { message } from 'antd';
import { I18n } from 'react-redux-i18n';

import AuthServices from '../services/auth';
import ApiUtil, { handleAxiosError } from '../utils/api';
import { axiosHandleUnauthorizedErr, cleanAuth } from '../../index';

function getInstance(isAuth) {
  const auth = AuthServices.get();

  const axiosInstance = Axios.create({
    baseURL: isAuth ? ApiUtil.getBaseUrlAuth() : ApiUtil.getBaseUrl(),
    timeout: 35000,
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth && auth.token ? `Bearer ${auth.token}` : undefined,
      'x-api-key': isAuth ? ApiUtil.getAuthToken() : undefined,
    },
  });

  axiosInstance.interceptors.response.use((response) => response,
    async (err) => {
      if (err.message === I18n.t('application.error.networkMessage')) {
        message.error(I18n.t('application.error.network'));
        cleanAuth();
      } else if (err.response.status === 401) {
        try {
          const token = await axiosHandleUnauthorizedErr();
          err.config.headers.Authorization = `Bearer ${token}`;
          return await Axios.request(err.config);
        } catch (error) {
          message.error(I18n.t('routes.login.errors.user_invalid_token'));
          cleanAuth();
        }
      } else if (err.response.status === 403) {
        message.error(I18n.t('routes.login.errors.user_no_access'));
        cleanAuth();
      }
      return Promise.reject(handleAxiosError(err));
    });

  return axiosInstance;
}

export default getInstance;
