import { FieldType } from '../app/enum/fieldType';
import { ProfileType } from '../app/enum/profileType';

const adminPermission = ProfileType.ADMIN.toString();
const establishmentPermission = ProfileType.ESTABLISHMENT_ADMIN.toString();
const adminAndCondominiumManagerPermission = [
  ProfileType.ADMIN,
  ProfileType.CONDOMINIUM_MANAGER,
  ProfileType.CONDOMINIUM_SUB_MANAGER,
].join(',');
const adminAndEstablishmentAdminPermission = [ProfileType.ADMIN, ProfileType.ESTABLISHMENT_ADMIN].join(',');

const governingBodyAndAdminPermission = [
  ProfileType.ADMIN,
  ProfileType.CONDOMINIUM_MANAGER,
  ProfileType.CONDOMINIUM_SUB_MANAGER,
  ProfileType.FINANCIAL_ADMINISTRATOR,
  ProfileType.ADVISOR,
].join(',');

const governingBodyPermission = [
  ProfileType.CONDOMINIUM_MANAGER,
  ProfileType.CONDOMINIUM_SUB_MANAGER,
  ProfileType.FINANCIAL_ADMINISTRATOR,
  ProfileType.ADVISOR,
].join(',');

const allUsersPermission = [
  ProfileType.ADMIN,
  ProfileType.ESTABLISHMENT_ADMIN,
  ProfileType.CONDOMINIUM_MANAGER,
  ProfileType.CONDOMINIUM_SUB_MANAGER,
  ProfileType.FINANCIAL_ADMINISTRATOR,
  ProfileType.ADVISOR,
].join(',');

export default {
  application: {
    name: 'BeAllBee - Dashboard',
    shortDescription: 'BeAllBee',
    title: 'BeAllBee',
    phoneCountryCode: '55',
    version: {
      title: 'Versão',
      number: '1.1.0',
    },
    footer: {
      poweredBy: 'Powered by',
      poweredByBrand: 'mb.labs',
      poweredByBrandUrl: 'https://mblabs.com.br',
    },
    currency: 'R$',
    percent: '%',
    error: {
      networkMessage: 'Network Error',
      network: 'O servidor se encontra com problemas, tente mais tarde!',
    },
  },
  component: {
    notImportedModal: {
      title: 'Usuários não importados para o condomínio',
      dataTable: {
        columns: {
          name: {
            key: 'name',
            title: 'Nome',
          },
          email: {
            key: 'email',
            title: 'E-mail',
          },
          cellphone: {
            key: 'cellphone',
            title: 'Celular',
          },
          reason: {
            key: 'reason',
            title: 'Razão',
          },
        },
      },
    },
    modalExport: {
      cancel: 'Cancelar',
      send: 'Enviar',
      title: 'Exportação de dados',
      warning: 'Atenção: os dados serão enviados diretamente para o e-mail:',
      selectPeriod: 'Selecione o período que deseja exportar',
      error: 'Insira um período para exportar os dados',
    },
    dwellerTableModal: {
      cancel: 'Cancelar',
      ok: 'Salvar',
      title: 'Selecione os usuários para adiciona-los no canal',
      add: 'Adicionar',
      delete: 'Remover',
    },
  },
  shared: {
    phoneMask: '(99) 99999-9999',
    zipCodeMask: '99999-999',
    rgMask: '99.999.999-*',
    cpfMask: '999.999.999-99',
    cnpjMask: '99.999.999/9999-99',
    moneyPrefix: 'R$',
    decimalSeparator: ',',
    thousandSeparator: '.',
    notFoundSearch: 'Nada encontrado.',
    selectSomeValue: 'Selecione...',
    typeToContinue: 'Digite algo para pesquisar...',
    type: 'Digite...',
    cancel: 'Cancelar',
    close: 'Fechar',
    useValues: 'Utilizar selecionados',
    yes: 'Sim',
    no: 'Não',
    loading: 'Carregando...',
    confirmTitle: 'Você tem certeza?',
    search: 'Pesquisar',
    send: 'Enviar',
    datatable: {
      showing: 'Exibindo',
      showingOf: 'de',
      showingResultsTotal: 'resultados',
    },
    logout: {
      menuText: 'Sair do sistema',
      confirmation: 'Você tem certeza que deseja sair?',
      confirmButton: 'Tenho certeza, sair.',
    },
    advancedFilter: {
      searchButtonText: 'Pesquisar',
      cleanButtonText: 'Limpar filtros',
    },
    nothingToShow: 'Nada para exibir, verifique os parâmetros e tente novamente.',
    hours: 'horas',
    minutes: 'minutos',
    seconds: 'segundos',
    distanceUnit: 'metros',
    distanceUnitSmall: 'm',
    cantAccess: 'Você não tem permissão para visualizar esta página.',
    searchSomething: 'Digite algo para pesquisar...',
    dateMask: 'DD/MM/YYYY',
    advancedFilters: {
      title: 'Filtros avançados',
      rangeLabel: 'Período',
      userOneLabel: 'Usuário 1 (nome ou registro)',
      userTwoLabel: 'Usuário 2 (nome ou registro)',
      loginLabel: 'Registro 1',
      loginTwoLabel: 'Registro 2',
      filterButtonText: 'Filtrar',
      exportButtonText: 'Exportar',
      clearButtonText: 'Limpar filtros',
      rangeStart: 'Data inicial',
      rangeEnd: 'Data final',
    },
    saveButtonText: 'Salvar',
    backButtonText: 'Voltar',
    genericSuccess: 'Sucesso!',
    genericError: 'Algo deu errado. Tente novamente mais tarde.',
    genericFieldsError: 'Verifique os campos e tente novamente.',
    errors: {
      missing_email: 'Digite o e-mail para continuar!',
      missing_name: 'Digite o nome para continuar!',
      missing_profileType: 'Selecione o tipo de funcionário para continuar!',
      missing_cellphone: 'Digite o celular para continuar!',
      missing_title: 'Digite o título para continuar!',
      missing_notificationTitle: 'Digite o título da notificação para continuar!',
      missing_categoryTitle: 'Digite o título da categoria para continuar!',
      missing_description: 'Digite a descrição para continuar!',
      missing_picture: 'Adicione uma foto para continuar!',
      missing_administratorName: 'Digite o nome da administradora para continuar!',
      missing_contactName: 'Digite o nome do contato para continuar!',
      missing_cnpj: 'Digite o CNPJ para continuar!',
      invalid_cnpj: 'CNPJ inválido!',
      missing_zipcode: 'Digite o CEP para continuar!',
      missing_neighborhood: 'Digite o bairro para continuar!',
      missing_street: 'Digite a rua para continuar!',
      missing_city: 'Digite a cidade para continuar!',
      missing_uf: 'Digite o estado para continuar!',
      missing_addressNumber: 'Digite o número do endereço para continuar!',
      missing_latitude: 'Digite latitude do endereço para continuar!',
      missing_longitude: 'Digite longitude do endereço para continuar!',
      missing_value: 'Digite o valor da transação para continuar!',
      missing_installments: 'Digite o número de transações máximas para continuar!',
      missing_hourOpening: 'Digite o horário de abertura para continuar!',
      missing_hourClosing: 'Digite o horário de fechamento para continuar!',
      missing_hourInterval: 'Digite o período de agendamento para continuar!',
      missing_categories: 'Selecione ao menos 1 categoria para continuar!',
      missing_bankCode: 'Selecione o banco para continuar!',
      missing_bankAgency: 'Digite a sua agência para continuar!',
      missing_accountType: 'Selecione o tipo de conta para continuar!',
      missing_bankAccount: 'Digite o número da conta para continuar!',
      missing_priceRange: 'Digite o valor máximo de venda para continuar!',
      missing_contractData: 'Cheque os dados de contrato para continuar',
      missing_isCommercial: 'Selecione se o condomínio é comercial ou não para continuar!',
      missing_condominiumType: 'Selecione o tipo do condomínio para continuar!',
      missing_condominiumId: 'Selecione um condomínio para continuar!',
      missing_establishmentId: 'Selecione o estabelecimento para continuar!',
      missing_channelId: 'Selecione um canal para continuar!',
      missing_condominiumAdministratorId: 'Selecione uma Administradora para continuar!',
      passwordsMismatch: 'A senha e confirmação de senha não estão iguais.',
      passwordsRequired: 'A senha e confirmação de senha são requeridas.',
      invalid_email: 'E-mail inválido!',
      invalid_cpf: 'CPF inválido!',
      invalid_cellphone: 'Celular inválido!',
    },
  },
  routes: {
    login: {
      url: '/',
      pageTitle: 'Entrar',
      content: {
        header: 'Entre com o e-mail e senha cadastrados',
        email: 'E-mail',
        emailPlaceholder: 'Seu e-mail cadastrado',
        password: 'Senha',
        passwordPlaceholder: 'Sua senha segura',
        company: 'Empresa',
        companyAll: 'Todas',
        continueButton: 'ENTRAR NO SISTEMA',
        welcome: 'Bem vindo(a) novamente.',
        forgotPassword: 'esqueci minha senha, ',
        subHeader: 'Entre com seu e-mail e senha cadastrados',
        clickHere: 'Clique aqui',
      },
      errors: {
        fields: 'Verifique os campos e tente novamente',
        invalid_credentials: 'Credenciais inválidas, verifique e tente novamente.',
        invalid_password: 'Credenciais inválidas, verifique e tente novamente.',
        user_not_found: 'Credenciais inválidas, verifique e tente novamente.',
        user_no_access: 'Você não tem acesso a essa tela. Entre em contato com um administrador.',
        user_invalid_token: 'Token inválido ou expirado. Entre novamente.',
      },
      messages: {
        welcome: 'Bem vindo(a) novamente.',
      },
    },

    forgotPassword: {
      url: '/esqueci-minha-senha',
      pageTitle: 'Esqueci minha senha',
      content: {
        header: 'Recuperar senha',
        subHeader: 'Iremos enviar os próximos passos no e-mail abaixo para prosseguir com a recuperação de sua senha',
        email: 'E-mail',
        emailPlaceholder: 'Seu e-mail cadastrado',
        continueButton: 'ENVIAR E-MAIL DE RECUPERAÇÃO',
        forgotPassword: 'Esqueci minha senha',
        backToLogin: 'para voltar ao login, ',
        clickHere: 'clique aqui ',
      },
      errors: {
        fields: 'Verifique os campos e tente novamente',
        user_not_found: 'Usuário não encontrado',
      },
      messages: {
        emailSent: 'E-mail de recuperação enviado (verifique também a caixa de spam).',
      },
    },

    forgotPasswordCode: {
      url: '/esqueci-minha-senha/definir',
      pageTitle: 'Definir nova senha',
      content: {
        header: 'Entre com o código recebido por e-mail',
        subHeader: 'Insira abaixo o código recebido por e-mail',
        email: 'E-mail',
        emailPlaceholder: 'E-mail',
        code: 'Código recebido por e-mail',
        codePlaceholder: 'Código recebido',
        continueButton: 'Alterar senha',
        notReceivedEmail: 'Não recebi o e-mail',
        passwordChanged: 'Senha alterada com sucesso.',
        password: 'Nova senha',
        passwordPlaceholder: 'Senha segura',
        passwordConfirm: 'Confirme a senha acima',
        passwordConfirmPlaceHolder: 'Confirmação',
        subHeaderPassword: 'Insira uma nova senha no campo abaixo, e também a sua confirmação',
        cancelButton: 'Cancelar',
      },
      errors: {
        fields: 'Verifique os campos e tente novamente',
        passwordMismatch: 'As senhas não conferem',
        passwordNotSecure: 'Senha deve ter pelo menos 6 carateres',
        invalid_send_to: 'Parâmetros inválidos',
      },
      messages: {
        validCode: 'Código válido, prossiga',
        passwordChanged: 'Senha alterada, entre com suas novas credenciais',
      },
    },

    deepLink: {
      url: '/payment',
      pageTitle: 'Pagamento',
      webError: 'Você não possui acesso a essa funcionalidade',
      initialTitle: 'Realize o pagamento do seu pedido',
      endTitle: 'pelo app BeAllBee',
      paymentButton: 'Pagar',
    },

    panel: {
      pageTitle: 'Painel',
      registers: 'Cadastros',
      users: {
        profileType: allUsersPermission,
        sidebarVisible: 'y',
        sidebarTitle: 'Usuarios',
        icon: 'user',
        items: {
          administrator: {
            profileType: adminPermission,
            url: '/painel/usuarios/administradores',
            sidebarTitle: 'Administradores',
            pageTitle: 'Gerenciar administradores',
            icon: 'lock',
            addNewButtonText: 'Adicionar administrador',
            pageTitleAdd: 'Adicionar administrador',
            pageTitleEdit: 'Editar administrador',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar um administrador',
            pageDescriptionEdit: 'Preencha os campos abaixo para editar um administrador',
            messages: {
              addSuccess: 'Adicionado com sucesso.',
              editSuccess: 'Editado com sucesso.',
              deleteSuccess: 'Removido com sucesso.',
            },
            advancedFilter: {
              panelTitle: 'Filtros avançados',
              name: {
                key: 'name',
                placeholder: 'Digite o nome...',
                label: 'Nome',
              },
              email: {
                key: 'email',
                label: 'E-mail',
              },
              cellphone: {
                key: 'cellphone',
                label: 'Telefone',
              },
            },
            details: {
              changePasswordPhrase: 'Desejo alterar a senha do administrador',

              form: {
                name: {
                  label: 'Nome completo',
                },
                email: {
                  label: 'E-mail',
                },
                password: {
                  label: 'Senha',
                },
                passwordConfirmation: {
                  label: 'Confirmação de senha',
                },
              },
              messages: {
                successCreate: 'Administrador adicionado com sucesso.',
                successEdit: 'Administrador atualizado com sucesso.',
              },
              errors: {
                email_already_registerd: 'O E-mail inserido já está sendo utilizado!',
                email_already_registered: 'O E-mail inserido já está sendo utilizado!',
                email_and_cellphone_already_registered: 'O email e o celular já estão sendo utilizados!',
                cellphone_already_used: 'O Celular inserido já está sendo utilizado!',
                cellphone_already_registered: 'O Celular inserido já está sendo utilizado!',
                document_already_used: 'O CPF inserido já está sendo utilizado!',
                document_already_registered: 'O CPF inserido já está sendo utilizado!',
                document_and_cellphone_already_registered: 'O CPF e o celular já estão sendo utilizados!',
                document_and_email_already_registered: 'O CPF e o email já estão sendo utilizados!',
                document_and_email_and_cellphone_already_registered: 'O CPF, email e celular já estão sendo utilizados!',
                invalid_password: 'A senha inserida deve conter no mínimo 6 caracteres',
                invalid_name: 'O nome inserido deve conter no mínimo 5 caracteres',
                generic: 'Algo deu errado. Tente novamente mais tarde.',
                email: 'E-mail inválido, verifique e tente novamente.',
                cpf: 'CPF inválido, verifique e tente novamente.',
                passwordsMismatch: 'A senha e confirmação de senha não estão iguais.',
                passwordsRequired: 'A senha e confirmação de senha são requeridas.',
              },
            },
            dataTable: {
              columns: {
                name: {
                  key: 'name',
                  title: 'Nome',
                },
                email: {
                  key: 'email',
                  title: 'E-mail',
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data de cadastro',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                  goToDetailsText: 'Detalhes',
                  removeText: 'Remover',
                },
              },
            },
          },
          deliveryman: {
            profileType: adminAndEstablishmentAdminPermission,
            url: '/painel/usuarios/entregadores',
            sidebarTitle: 'Entregadores',
            pageTitle: 'Gerenciar entregadores',
            icon: 'user',
            addNewButtonText: 'Adicionar entregador',
            pageTitleAdd: 'Adicionar entregador',
            pageTitleEdit: 'Editar entregador',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar um entregador',
            pageDescriptionEdit: 'Preencha os campos abaixo para editar um entregador',
            messages: {
              addSuccess: 'Adicionado com sucesso.',
              editSuccess: 'Editado com sucesso.',
              deleteSuccess: 'Removido com sucesso.',
            },
            advancedFilter: {
              panelTitle: 'Filtros avançados',
              name: {
                key: 'name',
                placeholder: 'Digite um nome...',
                label: 'Nome',
              },
              email: {
                key: 'email',
                placeholder: 'Digite um e-mail...',
                label: 'E-mail',
              },
            },
            details: {
              changePasswordPhrase: 'Desejo alterar a senha do entregador',
              form: {
                name: {
                  label: 'Nome completo',
                },
                email: {
                  label: 'E-mail',
                },
                password: {
                  label: 'Senha',
                },
                passwordConfirmation: {
                  label: 'Confirmação de senha',
                },
                establishment: {
                  label: 'Estabelecimento',
                },
              },
              messages: {
                successCreate: 'Entregador adicionado com sucesso.',
                successEdit: 'Entregador atualizado com sucesso.',
              },
              errors: {
                generic: 'Algo deu errado. Tente novamente mais tarde.',
              },
            },
            dataTable: {
              columns: {
                name: {
                  key: 'name',
                  title: 'Nome',
                },
                email: {
                  key: 'email',
                  title: 'E-mail',
                },
                establishment: {
                  key: 'establishment',
                  title: 'Estabelecimento',
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data de cadastro',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                  goToDetailsText: 'Detalhes',
                  removeText: 'Remover',
                },
              },
            },
          },
          establishmentAdmin: {
            profileType: adminPermission,
            url: '/painel/usuarios/fornecedores',
            sidebarTitle: 'Fornecedores',
            pageTitle: 'Gerenciar fornecedores',
            icon: 'user',
            addNewButtonText: 'Adicionar fornecedor',
            pageTitleAdd: 'Adicionar fornecedor',
            pageTitleEdit: 'Editar fornecedor',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar um fornecedor',
            pageDescriptionEdit: 'Preencha os campos abaixo para editar um fornecedor',
            messages: {
              addSuccess: 'Adicionado com sucesso.',
              editSuccess: 'Editado com sucesso.',
              deleteSuccess: 'Removido com sucesso.',
            },
            advancedFilter: {
              panelTitle: 'Filtros avançados',
              name: {
                key: 'name',
                placeholder: 'Digite o nome...',
                label: 'Nome',
              },
              email: {
                key: 'email',
                label: 'E-mail',
              },
              cellphone: {
                key: 'cellphone',
                label: 'Telefone',
              },
            },
            details: {
              changePasswordPhrase: 'Desejo alterar a senha do fornecedor',

              form: {
                name: {
                  label: 'Nome completo',
                },
                email: {
                  label: 'E-mail',
                },
                password: {
                  label: 'Senha',
                },
                passwordConfirmation: {
                  label: 'Confirmação de senha',
                },
              },
              messages: {
                successCreate: 'Fornecedor adicionado com sucesso.',
                successEdit: 'Fornecedor atualizado com sucesso.',
              },
              errors: {
                email_already_registerd: 'O E-mail inserido já está sendo utilizado!',
                email_already_registered: 'O E-mail inserido já está sendo utilizado!',
                invalid_password: 'A senha inserida deve conter no mínimo 6 caracteres',
                generic: 'Algo deu errado. Tente novamente mais tarde.',
                email: 'E-mail inválido, verifique e tente novamente.',
                passwordsMismatch: 'A senha e confirmação de senha não estão iguais.',
                passwordsRequired: 'A senha e confirmação de senha são requeridas.',
              },
            },
            dataTable: {
              columns: {
                name: {
                  key: 'name',
                  title: 'Nome',
                },
                email: {
                  key: 'email',
                  title: 'E-mail',
                },
                establishment: {
                  key: 'establishment',
                  title: 'Estabelecimento',
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data de cadastro',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                  goToDetailsText: 'Detalhes',
                  removeText: 'Remover',
                },
              },
            },
          },
          dweller: {
            profileType: governingBodyAndAdminPermission,
            url: '/painel/usuarios/moradores',
            sidebarTitle: 'Moradores',
            pageTitle: 'Gerenciar Moradores',
            icon: 'user',
            addNewButtonText: 'Importar moradores',
            pageTitleAdd: 'Adicionar morador',
            pageTitleEdit: 'Editar morador',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar um morador',
            pageDescriptionEdit: 'Preencha os campos abaixo para editar um morador',
            messages: {
              addSuccess: 'Adicionado com sucesso.',
              editSuccess: 'Editado com sucesso.',
              deleteSuccess: 'Removido com sucesso.',
            },
            advancedFilter: {
              panelTitle: 'Filtros avançados',
              name: {
                key: 'name',
                placeholder: 'Digite o nome...',
                label: 'Nome',
              },
              email: {
                key: 'email',
                label: 'E-mail',
              },
            },
            details: {
              changePasswordPhrase: 'Desejo alterar a senha do morador',

              form: {
                name: {
                  label: 'Nome completo',
                },
                email: {
                  label: 'E-mail',
                },
                password: {
                  label: 'Senha',
                },
                passwordConfirmation: {
                  label: 'Confirmação de senha',
                },
                condominium: {
                  label: 'Condomínio',
                },
              },
              messages: {
                successCreate: 'Morador adicionado com sucesso.',
                successEdit: 'Morador atualizado com sucesso.',
              },
              errors: {
                email_already_registerd: 'O E-mail inserido já está sendo utilizado!',
                email_already_registered: 'O E-mail inserido já está sendo utilizado!',
                invalid_password: 'A senha inserida deve conter no mínimo 6 caracteres',
                generic: 'Algo deu errado. Tente novamente mais tarde.',
                email: 'E-mail inválido, verifique e tente novamente.',
                passwordsMismatch: 'A senha e confirmação de senha não estão iguais.',
                passwordsRequired: 'A senha e confirmação de senha são requeridas.',
              },
            },
            dataTable: {
              columns: {
                name: {
                  key: 'name',
                  title: 'Nome',
                },
                email: {
                  key: 'email',
                  title: 'E-mail',
                },
                condominiumUser: {
                  key: 'condominiumUser',
                  title: 'Condomínio',
                },
                cellphone: {
                  key: 'cellphone',
                  title: 'Telefone',
                },
                doc: {
                  key: 'doc',
                  title: 'CPF',
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data de cadastro',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                  goToDetailsText: 'Detalhes',
                  removeText: 'Remover da Residência',
                  deleteText: 'Remover da Plataforma',
                },
              },
            },
          },
          doorman: {
            profileType: governingBodyAndAdminPermission,
            url: '/painel/usuarios/funcionario',
            sidebarTitle: 'Funcionários',
            pageTitle: 'Gerenciar funcionários',
            icon: 'user',
            addNewButtonText: 'Adicionar funcionários',
            pageTitleAdd: 'Adicionar funcionários',
            pageTitleEdit: 'Editar funcionários',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar um funcionário',
            pageDescriptionEdit: 'Preencha os campos abaixo para editar um funcionário',
            messages: {
              addSuccess: 'Adicionado com sucesso.',
              editSuccess: 'Editado com sucesso.',
              deleteSuccess: 'Removido com sucesso.',
            },
            advancedFilter: {
              panelTitle: 'Filtros avançados',
              name: {
                key: 'name',
                placeholder: 'Digite o nome...',
                label: 'Nome',
              },
              email: {
                key: 'email',
                label: 'E-mail',
              },
            },
            details: {
              changePasswordPhrase: 'Desejo alterar a senha do funcionário',

              form: {
                name: {
                  label: 'Nome completo',
                },
                email: {
                  label: 'E-mail',
                },
                password: {
                  label: 'Senha',
                },
                passwordConfirmation: {
                  label: 'Confirmação de senha',
                },
                profileType: {
                  label: 'Tipo de funcionário',
                },
                condominium: {
                  label: 'Condomínio',
                },
                profilePicUrl: {
                  label: 'Foto de perfil',
                },
              },
              messages: {
                successCreate: 'Funcionário adicionado com sucesso.',
                successEdit: 'Funcionário atualizado com sucesso.',
              },
              errors: {
                email_already_registerd: 'O E-mail inserido já está sendo utilizado!',
                email_already_registered: 'O E-mail inserido já está sendo utilizado!',
                invalid_password: 'A senha inserida deve conter no mínimo 6 caracteres',
                generic: 'Algo deu errado. Tente novamente mais tarde.',
                email: 'E-mail inválido, verifique e tente novamente.',
                passwordsMismatch: 'A senha e confirmação de senha não estão iguais.',
                passwordsRequired: 'A senha e confirmação de senha são requeridas.',
              },
            },
            dataTable: {
              columns: {
                name: {
                  key: 'name',
                  title: 'Nome',
                },
                email: {
                  key: 'email',
                  title: 'E-mail',
                },
                profileType: {
                  key: 'profileType',
                  title: 'Tipo',
                },
                condominium: {
                  key: 'condominium',
                  title: 'Condomínio',
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data de cadastro',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                  goToDetailsText: 'Detalhes',
                  removeText: 'Remover',
                },
              },
            },
          },
          condominiumManager: {
            profileType: adminPermission,
            url: '/painel/usuarios/sindicos',
            sidebarTitle: 'Sindicos',
            pageTitle: 'Gerenciar síndicos',
            icon: 'user',
            addNewButtonText: 'Adicionar síndico',
            pageTitleAdd: 'Adicionar síndico',
            pageTitleEdit: 'Editar síndico',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar um síndico',
            pageDescriptionEdit: 'Preencha os campos abaixo para editar um síndico',
            messages: {
              addSuccess: 'Adicionado com sucesso.',
              editSuccess: 'Editado com sucesso.',
              deleteSuccess: 'Removido com sucesso.',
            },
            advancedFilter: {
              panelTitle: 'Filtros avançados',
              name: {
                key: 'name',
                placeholder: 'Digite o nome...',
                label: 'Nome',
              },
              email: {
                key: 'email',
                label: 'E-mail',
              },
              cellphone: {
                key: 'cellphone',
                label: 'Telefone',
              },
            },
            details: {
              changePasswordPhrase: 'Desejo alterar a senha do síndico',

              form: {
                name: {
                  label: 'Nome completo',
                },
                email: {
                  label: 'E-mail',
                },
                doc: {
                  label: 'CPF',
                },
                password: {
                  label: 'Senha',
                },
                passwordConfirmation: {
                  label: 'Confirmação de senha',
                },
                condominium: {
                  label: 'Condomínio',
                },
                cellphone: {
                  label: 'Celular',
                },
              },
              messages: {
                successCreate: 'Síndico adicionado com sucesso.',
                successEdit: 'Síndico atualizado com sucesso.',
              },
              errors: {
                email_already_registerd: 'O E-mail inserido já está sendo utilizado!',
                email_already_registered: 'O E-mail inserido já está sendo utilizado!',
                invalid_password: 'A senha inserida deve conter no mínimo 6 caracteres',
                generic: 'Algo deu errado. Tente novamente mais tarde.',
                email: 'E-mail inválido, verifique e tente novamente.',
                cpf: 'CPF inválido, verifique e tente novamente.',
                passwordsMismatch: 'A senha e confirmação de senha não estão iguais.',
                passwordsRequired: 'A senha e confirmação de senha são requeridas.',
              },
            },
            dataTable: {
              columns: {
                name: {
                  key: 'name',
                  title: 'Nome',
                },
                email: {
                  key: 'email',
                  title: 'E-mail',
                },
                doc: {
                  key: 'doc',
                  title: 'CPF',
                },
                cellphone: {
                  key: 'cellphone',
                  title: 'Celular',
                },
                condominium: {
                  key: 'condominium',
                  title: 'Condomínio',
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data de cadastro',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                  goToDetailsText: 'Detalhes',
                  removeText: 'Remover',
                },
              },
            },
          },
        },
      },
      pushNotification: {
        profileType: governingBodyAndAdminPermission,
        sidebarVisible: 'y',
        sidebarTitle: 'Notificações',
        icon: 'bell',
        items: {
          send: {
            profileType: adminAndCondominiumManagerPermission,
            url: '/painel/notificacoes/envio',
            urlReturn: '/painel/notificacoes/relatorio',
            sidebarTitle: 'Enviar',
            pageTitle: 'Notificações',
            addNewButtonText: 'Enviar notificação',
            pageTitleAdd: 'Enviar notificação',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar uma notificação',
            messages: {
              addSuccess: 'Adicionado com sucesso.',
              editSuccess: 'Editado com sucesso.',
              deleteSuccess: 'Removido com sucesso.',
              successCreate: 'Notificação criada com sucesso.',
              errors: {
                generic: 'Preencha todos os campos, e tente novamente.',
                emptyUser: 'Nenhum usuário selecionado.',
                emptyCondominium: 'Nenhum condomínio selecionado.',
              },
            },
            advancedFilter: {
              panelTitle: 'Filtros avançados',
              name: {
                key: 'name',
                placeholder: 'Digite o nome...',
                label: 'Nome',
              },
            },
            form: {
              blockTitle: 'Detalhes da notificação',
              types: {
                label: 'Desejo enviar para',
              },
              title: {
                label: 'Título da notificação',
              },
              description: {
                label: 'Descrição',
              },
              type: {
                label: 'Tipo da notificação',
              },
              sendPush: {
                label: 'Enviar push?',
              },
              sendAll: {
                label: 'Enviar para todos?',
              },
              image: {
                dragger: {
                  title: 'Imagem do corpo da notificação',
                  tip: 'Clique aqui ou arraste e solte uma imagem dentro da área tracejada',
                },
              },
              datatable: {
                title: 'Pesquisar por Morador',
                name: {
                  key: 'name',
                  label: 'Nome',
                },
                email: {
                  key: 'email',
                  label: 'E-mail',
                },
                cellphone: {
                  key: 'cellphone',
                  label: 'Telefone',
                },
                createdAt: {
                  key: 'createdAt',
                  label: 'Data cadastro',
                },
              },
              dataTableAdmin: {
                title: 'Pesquisar por Condomínio',
                name: {
                  key: 'name',
                  title: 'Nome',
                },
                neighborhood: {
                  key: 'neighborhood',
                  title: 'Bairro',
                },
                city: {
                  key: 'city',
                  title: 'Cidade',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                  goToDetailsText: 'Detalhes',
                  removeText: 'Remover',
                  importDwellers: 'Importar moradores',
                },
              },
            },
            details: {
              form: {
                title: {
                  label: 'Título',
                  errorOne: 'O campo "Título" é requerido. Verifique e tente novamente.',
                },
                notificationType: {
                  label: 'Tipo',
                  errorOne: 'O campo "Tipo" é requerido. Verifique e tente novamente.',
                },
                isSchedule: {
                  label: 'Envio agendado?',
                },
                sendForAllUsers: {
                  label: 'Enviar para todos os usuários',
                },
                scheduledDate: {
                  label: 'Data de agendamento',
                  sendNow: 'Envio imediato',
                  errorOne: 'O campo "Data de agendamento" é requerido. Verifique e tente novamente.',
                  errorTwo: 'A data de agendamento não pode ser no passado. Verifique e tente novamente.',
                },
                userIdList: {
                  errorOne: 'Selecione pelo menos um usuário. Verifique e tente novamente.',
                },
              },
            },
            dataTable: {
              columns: {
                title: {
                  key: 'title',
                  title: 'Título',
                },
                notificationType: {
                  key: 'type',
                  title: 'Tipo',
                },
                scheduledDate: {
                  key: 'sendDate',
                  title: 'Agendamento',
                  sendNow: 'Envio imediato',
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data de cadastro',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                  goToDetailsText: 'Detalhes',
                  removeText: 'Remover',
                },
              },
            },
          },
          report: {
            profileType: governingBodyAndAdminPermission,
            url: '/painel/notificacoes/relatorio',
            sidebarTitle: 'Relatório',
            pageTitle: 'Notificações',
            icon: 'bell',
            urlAddNotification: '/painel/notificacoes/envio',
            addNewButtonText: 'Adicionar notificação',
            pageTitleAdd: 'Adicionar notificação',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar uma notificação',
            messages: {
              addSuccess: 'Adicionada com sucesso.',
              editSuccess: 'Editada com sucesso.',
              deleteSuccess: 'Removida com sucesso.',
            },
            advancedFilter: {
              panelTitle: 'Filtros avançados',
              title: {
                key: 'title',
                placeholder: 'Digite um título...',
                label: 'Título',
              },
              notificationType: {
                key: 'notificationType',
                label: 'Tipo',
              },
            },
            details: {
              form: {
                title: {
                  label: 'Título',
                  errorOne: 'O campo "Título" é requerido. Verifique e tente novamente.',
                },
                notificationType: {
                  label: 'Tipo',
                  errorOne: 'O campo "Tipo" é requerido. Verifique e tente novamente.',
                },
                isSchedule: {
                  label: 'Envio agendado?',
                },
                sendForAllUsers: {
                  label: 'Enviar para todos os usuários',
                },
                scheduledDate: {
                  label: 'Data de agendamento',
                  sendNow: 'Envio imediato',
                  errorOne: 'O campo "Data de agendamento" é requerido. Verifique e tente novamente.',
                  errorTwo: 'A data de agendamento não pode ser no passado. Verifique e tente novamente.',
                },
                userIdList: {
                  errorOne: 'Selecione pelo menos um usuário. Verifique e tente novamente.',
                },
              },
              messages: {
                successCreate: 'Notificação adicionada com sucesso.',
                successEdit: 'Notificação atualizada com sucesso.',
              },
              errors: {
                email_already_registerd: 'O E-mail inserido já está sendo utilizado!',
                email_already_registered: 'O E-mail inserido já está sendo utilizado!',
                invalid_password: 'A senha inserida deve conter no mínimo 6 caracteres',
                generic: 'Algo deu errado. Tente novamente mais tarde.',
                email: 'E-mail inválido, verifique e tente novamente.',
                passwordsMismatch: 'A senha e confirmação de senha não estão iguais.',
                passwordsRequired: 'A senha e confirmação de senha são requeridas.',
              },
            },
            dataTable: {
              columns: {
                title: {
                  key: 'title',
                  title: 'Título',
                },
                notificationType: {
                  key: 'type',
                  title: 'Tipo',
                },
                scheduledDate: {
                  key: 'sendDate',
                  title: 'Agendamento',
                  sendNow: 'Envio imediato',
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data de cadastro',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                  goToDetailsText: 'Detalhes',
                  removeText: 'Remover',
                },
              },
            },
          },
        },
        form: {
          blockTitle: 'Detalhes da notificação',
          types: {
            label: 'Desejo enviar para',
          },
          title: {
            label: 'Título da notificação',
          },
          description: {
            label: 'Descrição',
          },
          hasImage: {
            label: 'Incluir imagem no corpo da notificação?',
          },
          image: {
            dragger: {
              title: 'Imagem do corpo da notificação',
              tip: 'Clique aqui ou arraste e solte uma imagem dentro da área tracejada',
            },
          },
          datatable: {
            title: 'Usuários',
            name: {
              key: 'name',
              label: 'Nome',
            },
            email: {
              key: 'email',
              label: 'E-mail',
            },
            cellphone: {
              key: 'cellphone',
              label: 'Telefone',
            },
            createdAt: {
              key: 'createdAt',
              label: 'Data cadastro',
            },
          },
        },
        types: {
          one: {
            id: '1',
            name: 'Clientes',
          },
          two: {
            id: '2',
            name: 'Usuários X',
          },
          three: {
            id: '3',
            name: 'Usuários Y',
          },
        },
        preview: {
          titlePlaceHolder: 'Digite um título',
          descriptionPlaceHolder: 'Digite uma descrição',
        },
      },
      establishment: {
        profileType: adminAndEstablishmentAdminPermission,
        sidebarVisible: 'y',
        sidebarTitle: 'Estabelecimento',
        icon: 'shop',
        items: {
          category: {
            profileType: adminPermission,
            url: '/painel/estabelecimento/categorias',
            sidebarTitle: 'Categorias',
            pageTitle: 'Gerenciar categorias',
            icon: 'appstore',
            addNewButtonText: 'Adicionar categoria',
            pageTitleAdd: 'Adicionar categoria',
            pageTitleEdit: 'Editar categoria',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar uma categoria',
            pageDescriptionEdit: 'Preencha os campos abaixo para editar uma categoria',
            messages: {
              addSuccess: 'Adicionado com sucesso.',
              editSuccess: 'Editado com sucesso.',
              deleteSuccess: 'Removido com sucesso.',
            },
            advancedFilter: {
              panelTitle: 'Filtros avançados',
              name: {
                key: 'name',
                placeholder: 'Digite o nome...',
                label: 'Nome',
              },
            },
            details: {
              categorySection: 'Dados do categoria',
              form: {
                title: {
                  label: 'Título',
                },
                picUrl: {
                  label: 'Imagem',
                },
                selectFile: 'Clique aqui ou arraste arquivos',
                selectFileDescription: 'Tipo de arquivo permitido: .csv de até 2mb',
              },
              messages: {
                successCreate: 'Categoria adicionada com sucesso.',
                successEdit: 'Categoria atualizada com sucesso.',
              },
              errors: {
                generic: 'Algo deu errado. Tente novamente mais tarde.',
              },
            },
            dataTable: {
              columns: {
                title: {
                  key: 'title',
                  title: 'Nome',
                },
                picUrl: {
                  key: 'picUrl',
                  title: 'Imagem',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                  goToDetailsText: 'Detalhes',
                  removeText: 'Remover',
                  importDwellers: 'Importar moradores',
                },
              },
            },
          },
          establishment: {
            profileType: adminPermission,
            url: '/painel/estabelecimento/estabelecimentos',
            sidebarTitle: 'Estabelecimentos',
            pageTitle: 'Gerenciar estabelecimentos',
            icon: 'shop',
            addNewButtonText: 'Adicionar estabelecimento',
            pageTitleAdd: 'Adicionar estabelecimento',
            pageTitleEdit: 'Editar estabelecimento',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar um estabelecimento',
            pageDescriptionEdit: 'Preencha os campos abaixo para editar um estabelecimento',
            messages: {
              addSuccess: 'Adicionado com sucesso.',
              editSuccess: 'Editado com sucesso.',
              deleteSuccess: 'Removido com sucesso.',
            },
            advancedFilter: {
              panelTitle: 'Filtros avançados',
              city: {
                key: 'city',
                placeholder: 'Digite a cidade...',
                label: 'Cidade',
              },
              name: {
                key: 'name',
                placeholder: 'Digite o nome...',
                label: 'Nome',
              },
              categoryIdList: {
                key: 'categoryIdList',
                placeholder: 'Selecione as categorias...',
                label: 'Categorias',
              },
            },
            details: {
              establishmentSection: 'Dados do estabelecimento',
              contractSection: 'Dados do contrato',
              subAccountSection: 'Dados da conta bancária',
              contactSection: 'Dados de contato',
              daysSection: 'Dados de funcionamento',
              settingsSection: 'Configurações',
              form: {
                name: {
                  label: 'Nome',
                },
                description: {
                  label: 'Descrição',
                },
                doc: {
                  label: 'CNPJ',
                },
                zipcode: {
                  label: 'CEP',
                },
                neighborhood: {
                  label: 'Bairro',
                },
                street: {
                  label: 'Rua',
                },
                city: {
                  label: 'Cidade',
                },
                uf: {
                  label: 'UF',
                },
                addressNumber: {
                  label: 'Número',
                },
                latitude: {
                  label: 'Latitude',
                },
                longitude: {
                  label: 'Longitude',
                },
                type: {
                  label: 'Tipo de condomínio',
                },
                contractStartAt: {
                  label: 'Início do contrato',
                },
                contractEndAt: {
                  label: 'Fim do contrato',
                },
                contractValue: {
                  label: 'Valor da mensalidade',
                },
                openingHours: {
                  label: 'Horário de abertura',
                },
                closingHours: {
                  label: 'Horário de fechamento',
                },
                discount: {
                  label: 'Valor da Porcentagem Beecash',
                },
                beAllBeeCommission: {
                  label: 'Valor da Comissão BeAllBee',
                },
                picUrl: {
                  label: 'Imagem do estabelecimento',
                },
                categories: {
                  label: 'Categorias',
                },
                email: {
                  label: 'E-mail',
                },
                phone: {
                  label: 'Celular',
                },
                cellphone: {
                  label: 'Whatsapp',
                },
                installments: {
                  label: 'Número de parcelas por pagamento',
                },
                whatsapp: {
                  label: 'Utilizar o mesmo número para Whatsapp',
                },
                messageCopyValueDate: {
                  label: 'Copiar valor para os outros dias',
                },
                calculator: {
                  label: 'Calculadora',
                },
                bank: {
                  label: 'Banco',
                },
                bankAgency: {
                  label: 'Agência bancária',
                },
                accountType: {
                  label: 'Tipo de Conta',
                },
                bankAccount: {
                  label: 'Conta bancária *',
                  observation: `*OBS: Se você inseriu corretamente o número da Conta bancária e a máscara não ficou completa, insira
                  o número "0" a esquerda até a máscara ficar completa. Por exemplo: Transforme "1234_-_" para
                  "00123-4"`,
                },
                priceRangeMin: {
                  label: 'Valor mínimo de venda',
                },
                priceRangeMax: {
                  label: 'Valor máximo de venda',
                },
              },
              messages: {
                successCreate: 'Estabelecimento adicionado com sucesso.',
                successEdit: 'Estabelecimento atualizado com sucesso.',
              },
              errors: {
                generic: 'Algo deu errado. Tente novamente mais tarde.',
                invalid_contract_end_at: 'Data de contrato inválida',
                invalid_contract_start_at: 'Data de contrato inválida',
                invalid_contract_value: 'Valor da mensalidade inválido',
                invalid_phone: 'Celular inválido',
                error_creating_sub_account: 'Ocorreu um erro para criar a subconta! Verifique os dados de pagamento!',
              },
            },
            dataTable: {
              columns: {
                name: {
                  key: 'name',
                  title: 'Nome',
                },
                categories: {
                  key: 'categories',
                  title: 'Categorias',
                },
                discount: {
                  key: 'discount',
                  title: 'Beecash',
                },
                street: {
                  key: 'street',
                  title: 'Rua',
                },
                neighborhood: {
                  key: 'neighborhood',
                  title: 'Bairro',
                },
                city: {
                  key: 'city',
                  title: 'Cidade',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                  goToDetailsText: 'Detalhes',
                  removeText: 'Remover',
                  importDwellers: 'Importar moradores',
                },
              },
            },
          },
          myEstablishment: {
            profileType: establishmentPermission,
            url: '/painel/estabelecimento/dados',
            sidebarTitle: 'Meu Estabelecimento',
            pageTitle: 'Gerenciar meu estabelecimento',
            icon: 'shop',
            addNewButtonText: 'Adicionar estabelecimento',
            pageTitleAdd: 'Adicionar estabelecimento',
            pageTitleEdit: 'Editar estabelecimento',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar um estabelecimento',
            pageDescriptionEdit: 'Preencha os campos abaixo para editar um estabelecimento',
            messages: {
              addSuccess: 'Adicionado com sucesso.',
              editSuccess: 'Editado com sucesso.',
              deleteSuccess: 'Removido com sucesso.',
            },
            advancedFilter: {
              panelTitle: 'Filtros avançados',
              city: {
                key: 'city',
                placeholder: 'Digite a cidade...',
                label: 'Cidade',
              },
              name: {
                key: 'name',
                placeholder: 'Digite o nome...',
                label: 'Nome',
              },
              categoryIdList: {
                key: 'categoryIdList',
                placeholder: 'Selecione as categorias...',
                label: 'Categorias',
              },
            },
            details: {
              establishmentSection: 'Dados do estabelecimento',
              contractSection: 'Dados do contrato',
              contactSection: 'Dados de contato',
              daysSection: 'Dados de funcionamento',
              form: {
                name: {
                  label: 'Nome',
                },
                description: {
                  label: 'Descrição',
                },
                zipcode: {
                  label: 'CEP',
                },
                neighborhood: {
                  label: 'Bairro',
                },
                street: {
                  label: 'Rua',
                },
                city: {
                  label: 'Cidade',
                },
                uf: {
                  label: 'UF',
                },
                addressNumber: {
                  label: 'Número',
                },
                latitude: {
                  label: 'Latitude',
                },
                longitude: {
                  label: 'Longitude',
                },
                type: {
                  label: 'Tipo de condomínio',
                },
                contractStartAt: {
                  label: 'Início do contrato',
                },
                contractEndAt: {
                  label: 'Fim do contrato',
                },
                openingHours: {
                  label: 'Horário de abertura',
                },
                closingHours: {
                  label: 'Horário de fechamento',
                },
                discount: {
                  label: 'Beecash',
                },
                picUrl: {
                  label: 'Imagem do estabelecimento',
                },
                categories: {
                  label: 'Categorias',
                },
                email: {
                  label: 'E-mail',
                },
                phone: {
                  label: 'Telefone',
                },
                cellphone: {
                  label: 'Whatsapp',
                },
              },
              messages: {
                successCreate: 'Estabelecimento adicionado com sucesso.',
                successEdit: 'Estabelecimento atualizado com sucesso.',
              },
              errors: {
                generic: 'Algo deu errado. Tente novamente mais tarde.',
              },
            },
            dataTable: {
              columns: {
                name: {
                  key: 'name',
                  title: 'Nome',
                },
                categories: {
                  key: 'categories',
                  title: 'Categorias',
                },
                discount: {
                  key: 'discount',
                  title: 'Beecash',
                },
                openingHours: {
                  key: 'openingHours',
                  title: 'Horário de funcionamento',
                },
                street: {
                  key: 'street',
                  title: 'Rua',
                },
                neighborhood: {
                  key: 'neighborhood',
                  title: 'Bairro',
                },
                city: {
                  key: 'city',
                  title: 'Cidade',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                  goToDetailsText: 'Detalhes',
                  removeText: 'Remover',
                  importDwellers: 'Importar moradores',
                },
              },
            },
          },
          transaction: {
            profileType: adminAndEstablishmentAdminPermission,
            url: '/painel/estabelecimento/transacoes',
            sidebarTitle: 'Transações',
            pageTitle: 'Gerenciar transações',
            icon: 'dollar',
            pageTitleAdd: 'Cria transação',
            pageTitleEdit: 'Visualizar transação',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar uma transação',
            pageDescriptionEdit: 'Preencha os campos abaixo para editar uma transação',
            addNewButtonText: 'Criar Transação',
            exportButtonText: 'Exportar Transações',
            messages: {
              addSuccess: 'Adicionado com sucesso.',
              editSuccess: 'Editado com sucesso.',
              deleteSuccess: 'Removido com sucesso.',
            },
            advancedFilter: {
              panelTitle: 'Filtros avançados',
              city: {
                key: 'city',
                placeholder: 'Digite a cidade...',
                label: 'Cidade',
              },
              name: {
                key: 'name',
                placeholder: 'Digite o nome...',
                label: 'Nome',
              },
              categoryIdList: {
                key: 'categoryIdList',
                placeholder: 'Selecione as categorias...',
                label: 'Categorias',
              },
            },
            modalTitle: 'Formas de Pagamento via BeAllBee',
            details: {
              form: {
                'establishment.name': {
                  label: 'Estabelecimento',
                },
                dweller: {
                  label: 'Morador',
                },
                'motoboy.name': {
                  label: 'Entregador',
                },
                code: {
                  label: 'Código',
                },
                createdAt: {
                  label: 'Data',
                },
                totalPrice: {
                  label: 'Valor',
                },
                comission: {
                  label: 'Comissão BeAllBee',
                },
                beecash: {
                  label: 'Beecash BeAllBee',
                },
                provider: {
                  label: 'Beecash Estab',
                },
                installments: {
                  label: 'Parcelas',
                },
                sendPhone: {
                  label: 'Celular para envio de mensagem no WhatsApp',
                },
                isSendingToWhatsApp: {
                  label: 'Deseja mandar a mensagem para o WhatsApp do cliente',
                },
              },
              messages: {
                successCreate: 'Transação adicionado com sucesso.',
                successEdit: 'Transação atualizado com sucesso.',
                successCopy: 'Link copiado com sucesso.',
              },
              errors: {
                generic: 'Algo deu errado. Tente novamente mais tarde.',
                invalid_phone: 'Digite um número de celular para enviar a mensagem para o cliente',
              },
            },
            dataTable: {
              columns: {
                establishment: {
                  key: 'establishment.name',
                  title: 'Estabelecimento',
                },
                dweller: {
                  key: 'user.name',
                  title: 'Morador',
                },
                condominium: {
                  key: 'condominium',
                  title: 'Condomínio',
                },
                motoboy: {
                  key: 'motoboy.name',
                  title: 'Entregador',
                },
                code: {
                  key: 'code',
                  title: 'Código',
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data',
                },
                totalPrice: {
                  key: 'totalPrice',
                  title: 'Valor',
                },
                commission: {
                  key: 'beAllBeeCommission',
                  title: 'Comissão BeAllBee',
                },
                beecash: {
                  key: 'beecash',
                  title: 'Beecash',
                },
                taxGateway: {
                  key: 'taxGateway',
                  title: 'Taxa de Pagamento',
                },
                provider: {
                  key: 'establishmentCommission',
                  title: 'Valor Recebido',
                },
                installments: {
                  key: 'installments',
                  title: 'Nº Parcelas',
                },
                status: {
                  key: 'status',
                  title: 'Status',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                  goToDetailsText: 'Detalhes',
                  removeText: 'Remover',
                  importDwellers: 'Importar moradores',
                },
              },
            },
          },
        },
      },
      condominium: {
        profileType: governingBodyAndAdminPermission,
        sidebarVisible: 'y',
        sidebarTitle: 'Condominio',
        icon: 'home',
        items: {
          condominium: {
            profileType: adminPermission,
            url: '/painel/condominio/condominios',
            sidebarTitle: 'Condomínios',
            pageTitle: 'Gerenciar condomínios',
            icon: 'home',
            addNewButtonText: 'Adicionar condomínio',
            pageTitleAdd: 'Adicionar condomínio',
            pageTitleEdit: 'Editar condomínio',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar um condomínio',
            pageDescriptionEdit: 'Preencha os campos abaixo para editar um condomínio',
            pageDescription: '',
            messages: {
              addSuccess: 'Adicionado com sucesso.',
              editSuccess: 'Editado com sucesso.',
              deleteSuccess: 'Removido com sucesso.',
            },
            advancedFilter: {
              panelTitle: 'Filtros avançados',
              name: {
                key: 'name',
                placeholder: 'Digite o nome...',
                label: 'Nome',
              },
              zipcode: {
                key: 'zipcode',
                label: 'CEP',
              },
              neighborhood: {
                key: 'neighborhood',
                label: 'Bairro',
              },
              street: {
                key: 'street',
                label: 'Rua',
              },
              city: {
                key: 'city',
                label: 'Cidade',
              },
              uf: {
                key: 'uf',
                label: 'UF',
              },
              type: {
                key: 'type',
                label: 'Tipo de condomínio',
              },
            },
            details: {
              condominiumSection: 'Dados do condomínio',
              contractSection: 'Dados do contrato',
              condominiumGoverningBody: 'Corpo Diretivo',
              importSection: 'Importação de moradores (bloco,unidade e número)',
              form: {
                name: {
                  label: 'Nome',
                },
                description: {
                  label: 'Descrição',
                },
                phone: {
                  label: 'Celular para contato',
                },
                doc: {
                  label: 'CNPJ',
                },
                isCommercial: {
                  label: 'Condomínio Residencial ou Comercial?',
                },
                zipcode: {
                  label: 'CEP',
                },
                neighborhood: {
                  label: 'Bairro',
                },
                street: {
                  label: 'Rua',
                },
                city: {
                  label: 'Cidade',
                },
                uf: {
                  label: 'UF',
                },
                addressNumber: {
                  label: 'Número',
                },
                latitude: {
                  label: 'Latitude',
                },
                longitude: {
                  label: 'Longitude',
                },
                type: {
                  label: 'Tipo de condomínio',
                },
                contractStartAt: {
                  label: 'Início do contrato',
                },
                contractEndAt: {
                  label: 'Fim do contrato',
                },
                contractStatus: {
                  label: 'Status do contrato',
                },
                monthlyPayment: {
                  label: 'Valor da mensalidade',
                },
                condominiumManagerPhone: {
                  label: 'Celular do Síndico',
                },
                condominiumCode: {
                  label: 'Código Condomínio: ',
                },
                selectFile: 'Clique aqui ou arraste arquivos',
                selectFileDescription: 'Tipo de arquivo permitido: .csv de até 2mb',
              },
              messages: {
                successCreate: 'Condomínio adicionado com sucesso.',
                successEdit: 'Condomínio atualizado com sucesso.',
              },
              errors: {
                generic: 'Algo deu errado. Tente novamente mais tarde.',
              },
            },
            dataTable: {
              columns: {
                code: {
                  key: 'code',
                  title: 'Código',
                },
                name: {
                  key: 'name',
                  title: 'Nome',
                },
                type: {
                  key: 'type',
                  title: 'Tipo',
                },
                street: {
                  key: 'street',
                  title: 'Endereço',
                },
                neighborhood: {
                  key: 'neighborhood',
                  title: 'Bairro',
                },
                city: {
                  key: 'city',
                  title: 'Cidade',
                },
                uf: {
                  key: 'uf',
                  title: 'UF',
                },
                zipcode: {
                  key: 'zipcode',
                  title: 'CEP',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                  goToDetailsText: 'Detalhes',
                  goToGoverningBodyText: 'Editar Corpo Diretivo',
                  removeText: 'Remover',
                  importDwellers: 'Importar moradores',
                },
              },
            },
            dwellerImportCsvModal: {
              okText: 'Realizar upload',
              cancelText: 'Cancelar',
              title: 'Importar moradores por CSV',
              templateButtonOne: 'Clique aqui',
              templateButtonTwo: 'para realizar o download do template de importação.',
              selectFile: 'Clique aqui ou arraste o arquivo',
              selectFileDescription: 'Tipo de arquivo permitido: .csv de até 4mb',
              errors: {
                invalidFileSize: 'Arquivo muito grande, divida em um menor e tente novamente.',
                maxSelectedFiles: 'Apenas um arquivo por vez.',
                generic: 'Algo deu errado ao tentar ler o arquivo, verifique e tente novamente.',
                fileEmpty: 'Verifique o formato do arquivo, ele está sem registros válidos',
                invalidDateFormat: 'Existe uma data com formato não suportado.',
                invalidLoginFormat: 'Existe um login com formato não suportado.',
                companyImportingUser: 'Um processo de importação já está acontecendo, tente novamente mais tarde',
                company_importing_user: 'Um processo de importação já está acontecendo, tente novamente mais tarde',
                genericUpload: 'Algo deu errado ao tentar subir o arquivo, verifique e tente novamente.',
                login_already_used: 'Já existe um usuário com o login informado, verifique e tente novamente.',
                invalid_id: 'Selecione a empresa.',
                invalid_name: 'O documento apresenta algum registro com nome inválido!',
                invalid_email: 'O documento apresenta algum registro com email inválido!',
                invalid_cellphone: 'O documento apresenta algum registro com celular inválido!',
                invalid_block: 'O documento apresenta algum registro com bloco inválido!',
                invalid_tower: 'O documento apresenta algum registro com torre inválido!',
                invalid_number: 'O documento apresenta algum registro com número inválido!',
                duplicated_email: 'O documento apresenta dois ou mais usuários com e-mail duplicados!',
                duplicated_cellphone: 'O documento apresenta dois ou mais usuários com celular duplicados!',
                empty: 'O documento enviado não pode estar vazio',
              },
              success: 'Os condomínios estão sendo importados, quando finalizar você irá receber um email',
            },
          },
          myCondominium: {
            profileType: governingBodyPermission,
            url: '/painel/condominio/meu-condominio',
            sidebarTitle: 'Meu Condomínio',
            pageTitle: 'Meu Condomínio',
            icon: 'home',
          },
          transaction: {
            profileType: governingBodyPermission,
            url: '/painel/condominio/transacoes',
            sidebarTitle: 'Transações',
            pageTitle: 'Gerenciar transações',
          },
          condominiumAdministrator: {
            profileType: adminPermission,
            url: '/painel/condominio/administradora',
            icon: 'home',
            sidebarTitle: 'Administradora',
            pageTitle: 'Gerenciar administradoras',
            addNewButtonText: 'Adicionar administradora',
            pageTitleAdd: 'Adicionar administradora',
            pageTitleEdit: 'Editar administradora',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar uma administradora',
            pageDescriptionEdit: 'Preencha os campos abaixo para editar uma administradora',
            messages: {
              addSuccess: 'Adicionada com sucesso.',
              editSuccess: 'Editada com sucesso.',
              deleteSuccess: 'Removida com sucesso.',
            },
            advancedFilter: {
              panelTitle: 'Filtros avançados',
              administratorName: {
                key: 'administratorName',
                placeholder: 'Digite o nome...',
                label: 'Nome da Administradora',
              },
              contactName: {
                key: 'contactName',
                placeholder: 'Digite o nome...',
                label: 'Nome de Contato',
              },
              email: {
                key: 'email',
                placeholder: 'Digite o nome...',
                label: 'E-mail',
              },
            },
            details: {
              condominiumSection: 'Dados da administradora',
              streetSection: 'Dados da localização',
              form: {
                administratorName: {
                  label: 'Nome da Administradora',
                },
                name: {
                  label: 'Nome do contato',
                },
                email: {
                  label: 'E-mail',
                },
                cellphone: {
                  label: 'Celular',
                },
                document: {
                  label: 'CNPJ',
                },
                zipcode: {
                  label: 'CEP',
                },
                neighborhood: {
                  label: 'Bairro',
                },
                street: {
                  label: 'Rua',
                },
                city: {
                  label: 'Cidade',
                },
                uf: {
                  label: 'UF',
                },
                addressNumber: {
                  label: 'Número',
                },
                complement: {
                  label: 'Complemento',
                },
              },
              messages: {
                successCreate: 'Administradora adicionado com sucesso.',
                successEdit: 'Administradora atualizado com sucesso.',
              },
              errors: {
                generic: 'Algo deu errado. Tente novamente mais tarde.',
              },
            },
            dataTable: {
              columns: {
                administratorName: {
                  key: 'administratorName',
                  title: 'Nome da Administradora',
                },
                contactName: {
                  key: 'contactName',
                  title: 'Nome do Contato',
                },
                email: {
                  title: 'E-mail',
                  key: 'email',
                },
                cellphone: {
                  title: 'Telefone',
                  key: 'phone',
                },
                document: {
                  title: 'CNPJ',
                  key: 'doc',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                  goToDetailsText: 'Detalhes',
                  removeText: 'Remover',
                },
              },
            },
          },
          governingBody: {
            profileType: governingBodyPermission,
            url: '/painel/condominio/corpo-diretivo',
            sidebarTitle: 'Corpo Diretivo',
            pageTitle: 'Gerenciar Corpo Diretivo',
            icon: 'team',
            modal: {
              addButton: 'Adicionar',
              changeButton: 'Trocar',
              userDateTitle: 'Dados do Usuário',
              userData: {
                profileType: 'Atual função no condomínio:',
                email: 'E-mail',
                cellphone: 'Celular',
              },
            },
            messages: {
              deleteSuccess: 'Entrega deletada com sucesso.',
            },
            advancedFilter: {
              panelTitle: 'Filtros avançados',
              packageType: {
                label: 'Tipo do pacote',
              },
              packageStatusType: {
                label: 'Status',
              },
            },
            dataTable: {
              columns: {
                profileType: {
                  key: 'profileType',
                  title: 'Tipo de Morador',
                },
                name: {
                  key: 'name',
                  title: 'Nome',
                },
                email: {
                  key: 'email',
                  title: 'E-mail',
                },
                cellphone: {
                  key: 'cellphone',
                  title: 'Celular',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                  add: 'Adicionar',
                  edit: 'Editar',
                  removeText: 'Remover',
                },
              },
            },
          },
          order: {
            profileType: governingBodyAndAdminPermission,
            url: '/painel/condominio/entregas',
            sidebarTitle: 'Entregas',
            pageTitle: 'Gerenciar entregas',
            icon: 'code-sandbox',
            messages: {
              deleteSuccess: 'Entrega deletada com sucesso.',
            },
            advancedFilter: {
              panelTitle: 'Filtros avançados',
              packageType: {
                label: 'Tipo do pacote',
              },
              packageStatusType: {
                label: 'Status',
              },
            },
            dataTable: {
              columns: {
                receiver: {
                  key: 'receiver',
                  title: 'Destinatário',
                },
                packageType: {
                  key: 'packageType',
                  title: 'Tipo do pacote',
                },
                withdrawerName: {
                  key: 'withdrawerName',
                  title: 'Nome do recebedor',
                },
                withdrawerCpf: {
                  key: 'withdrawerCpf',
                  title: 'CPF do recebedor',
                },
                signaturePhotoUrl: {
                  key: 'signaturePhotoUrl',
                  title: 'Assinatura',
                },
                status: {
                  key: 'status',
                  title: 'Status',
                },
                withdrawalAt: {
                  key: 'withdrawalAt',
                  title: 'Entregue em',
                },
                condominiumUser: {
                  key: 'condominiumUser',
                  title: 'Condomínio',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                  goToDetailsText: 'Detalhes',
                  removeText: 'Remover',
                },
              },
            },
          },
          solicitations: {
            profileType: governingBodyAndAdminPermission,
            url: '/painel/condominio/solicitacoes',
            sidebarTitle: 'Solicitações de associação',
            pageTitle: 'Gerenciar solicitações de associação',
            icon: 'info-circle',
            messages: {
              editSuccess: 'Solicitação aprovada com sucesso.',
              deleteSuccess: 'Solicitação negada com sucesso.',
            },
            modal: {
              title: 'Registrar permissões',
              isOwner: 'Dono da família',
              canReceiveVisit: 'Pode cadastrar visitas?',
              canReceiveOrder: 'Pode receber entregas?',
              canRegisterFamiliar: 'Pode editar outros familiares',
            },
            advancedFilter: {
              panelTitle: 'Filtros avançados',
              status: {
                key: 'status',
                placeholder: 'Selecione o status...',
                label: 'Status da solicitação',
              },
              number: {
                key: 'number',
                placeholder: 'Digite o número...',
                label: 'Número da residência',
              },
              tower: {
                key: 'tower',
                placeholder: 'Digite a torre...',
                label: 'Torre da residência',
              },
              block: {
                key: 'block',
                placeholder: 'Digite o bloco...',
                label: 'Bloco da residência',
              },
            },
            dataTable: {
              columns: {
                profilePicUrl: {
                  key: 'profilePicUrl',
                  title: 'Foto',
                },
                name: {
                  key: 'name',
                  title: 'Nome',
                },
                cellphone: {
                  key: 'cellphone',
                  title: 'Celular',
                },
                email: {
                  key: 'email',
                  title: 'E-mail',
                },
                condominium: {
                  key: 'condominium',
                  title: 'Condomínio',
                },
                block: {
                  key: 'block',
                  title: 'Bloco/Rua',
                },
                tower: {
                  key: 'tower',
                  title: 'Torre',
                },
                number: {
                  key: 'number',
                  title: 'Número',
                },
                status: {
                  key: 'status',
                  title: 'Status',
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data da solicitação',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                  goToDetailsText: 'Detalhes',
                  removeText: 'Remover',
                  approve: 'Aprovar',
                  disapprove: 'Negar',
                },
              },
            },
          },
          visits: {
            profileType: governingBodyAndAdminPermission,
            url: '/painel/condominio/visitas',
            sidebarTitle: 'Visitas',
            pageTitle: 'Gerenciar visitas',
            icon: 'user',
            messages: {
              deleteSuccess: 'Visita deletada com sucesso.',
            },
            advancedFilter: {
              panelTitle: 'Filtros avançados',
              name: {
                key: 'nameVisit',
                placeholder: 'Digite o nome...',
                label: 'Nome',
              },
              rg: {
                key: 'rg',
                placeholder: 'Digite o RG...',
                label: 'RG',
              },
              visitDate: {
                key: 'visitDate',
                placeholder: 'Selecione a daa...',
                label: 'Data da visita',
              },
            },
            dataTable: {
              columns: {
                name: {
                  key: 'name',
                  title: 'Nome',
                },
                rg: {
                  key: 'rg',
                  title: 'RG',
                },
                status: {
                  key: 'status',
                  title: 'Status',
                },
                isEmployee: {
                  key: 'isEmployee',
                  title: 'Prestador de Serviços',
                },
                isPermanent: {
                  key: 'isPermanent',
                  title: 'Permissão Permanente',
                },
                period: {
                  key: 'period',
                  title: 'Período',
                },
                visitDate: {
                  key: 'visitDate',
                  title: 'Data da visita',
                },
                condominiumUser: {
                  key: 'condominiumUser',
                  title: 'Condomínio',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                  goToDetailsText: 'Detalhes',
                  removeText: 'Remover',
                },
              },
            },
          },
        },
      },
      schedule: {
        profileType: governingBodyAndAdminPermission,
        sidebarVisible: 'y',
        sidebarTitle: 'Agendamentos',
        icon: 'book',
        items: {
          recreationArea: {
            profileType: governingBodyAndAdminPermission,
            url: '/painel/agendamento/area-de-lazer',
            sidebarTitle: 'Áreas de Lazer',
            pageCategoryTitle: 'Tipos de Área de Lazer',
            pageTitle: 'Área de Lazer',
            icon: 'home',
            addCategoryNewButtonText: 'Adicionar Tipo de Área de Lazer',
            addNewButtonText: 'Adicionar nova Área de Lazer',
            messages: {
              deleteSuccess: 'Deletado com sucesso!',
              successCreate: 'Criado com sucesso!',
              successEdit: 'Editado com sucesso!',
              successBlock: 'Bloqueio agendado com sucesso!',
            },
            errors: {
              invalid_initial_date: 'Horário de abertura é mais tarde que o de fechamento',
              invalid_interval: 'O periodo de agendamento é maior que o periodo aberto da Área de Lazer',
            },
            categoryModal: {
              title: 'Adicionar Tipo de Área de Lazer',
              editTitle: 'Editar Tipo de Área de Lazer',
              inputName: 'Nome',
              preDefined: 'Nomes pré definidos',
              checkboxEdit: 'Ativar Tipo de Área de Lazer',
              checkboxCreate: 'Adicionar um tipo pré definido?',
              okText: 'Salvar',
            },
            condominiumModal: {
              title: 'Selecione o condomínio',
              okText: 'Continuar',
              error: 'Selecione um condomínio para continuar',
            },
            scheduleBlockModal: {
              title: 'Bloquear Período de Agendamento',
              dateInitial: 'Data Inicial',
              dateEnd: 'Data Final',
              description: 'Justificativa',
              okText: 'Criar',
            },
            dataTable: {
              columns: {
                name: {
                  key: 'name',
                  title: 'Nome',
                },
                active: {
                  key: 'active',
                  title: 'Ativado',
                },
                price: {
                  key: 'price',
                  title: 'Preço',
                },
                hourOpening: {
                  key: 'hourOpening',
                  title: 'Horário de Abertura',
                },
                hourClosing: {
                  key: 'hourClosing',
                  title: 'Horário de Fechamento',
                },
                actions: {
                  key: 'id',
                  goToRecreationArea: 'Ver Áreas de Lazer',
                  blockSchedule: 'Bloquear Periodo de Agendamento',
                  goToDetailsText: 'Detalhes',
                  removeText: 'Remover',
                },
              },
            },
            details: {
              pageTitleAdd: 'Adicionar Área de Lazer',
              pageTitleEdit: 'Editar Área de Lazer',
              pageDescriptionAdd: 'Preencha os campos abaixo para adicionar uma área de lazer',
              pageDescriptionEdit: 'Preencha os campos abaixo para editar uma área de lazer',
              form: {
                name: {
                  label: 'Nome',
                },
                description: {
                  label: 'Descrição',
                },
                price: {
                  label: 'Preço',
                },
                hourOpening: {
                  label: 'Horário de Abertura',
                },
                hourClosing: {
                  label: 'Horário de Fechamento',
                },
                hourInterval: {
                  label: 'Período de Agendamento',
                },
              },
            },
          },
          recreationAreaReport: {
            profileType: governingBodyAndAdminPermission,
            url: '/painel/agendamento/relatorio',
            sidebarTitle: 'Relátorios',
            pageTitle: 'Relatório dos Agendamentos',
            icon: 'file',
            addNewButtonText: 'Exportar Relatório',
            messages: {
              successExport: 'Exportado com sucesso!',
              deleteSuccess: 'Agendamento cancelado com suceddo!',
            },
            dataTable: {
              columns: {
                name: {
                  key: 'recreationName',
                  title: 'Área de Lazer',
                },
                price: {
                  key: 'recreationPrice',
                  title: 'Preço',
                },
                scheduleType: {
                  key: 'scheduleType',
                  title: 'Tipo de Agendamento',
                },
                schedulingRecurringRecreation: {
                  key: 'schedulingRecurringRecreation',
                  title: 'Horário Inicial',
                  titleEnd: 'Horário Final',
                },
                scheduledBy: {
                  key: 'scheduledBy',
                  title: 'Agendado por',
                },
                condominiumUser: {
                  key: 'condominiumUser',
                  title: 'Residência',
                },
                status: {
                  key: 'isCanceled',
                  title: 'Status',
                },
                actions: {
                  removeText: 'Cancelar Agendamento',
                },
              },
            },
            advancedFilter: {
              panelTitle: 'Filtros avançados',
              recreationName: {
                key: 'recreationName',
                placeholder: 'Digite o nome...',
                label: 'Nome da Área de Lazer',
              },
              dateInitial: {
                key: 'dateInitial',
                placeholder: 'Digite o nome...',
                label: 'Período do Agendamento',
              },
            },
          },
        },
      },
      communication: {
        profileType: governingBodyAndAdminPermission,
        sidebarVisible: 'y',
        sidebarTitle: 'Comunicação',
        icon: 'message',
        items: {
          channel: {
            profileType: governingBodyAndAdminPermission,
            url: '/painel/comunicacao/canais',
            sidebarTitle: 'Canais',
            pageTitle: 'Gerenciar canais',
            icon: 'message',
            addNewButtonText: 'Adicionar canal',
            pageTitleAdd: 'Adicionar canal',
            pageTitleEdit: 'Editar canal',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar um canal',
            pageDescriptionEdit: 'Preencha os campos abaixo para editar um canal',
            messages: {
              addSuccess: 'Adicionada com sucesso.',
              editSuccess: 'Editada com sucesso.',
              deleteSuccess: 'Removida com sucesso.',
            },
            advancedFilter: {
              panelTitle: 'Filtros avançados',
              name: {
                key: 'name',
                placeholder: 'Digite um nome...',
                label: 'Nome do Canal',
              },
              isFixed: {
                key: 'isFixed',
                label: 'Fixado',
              },
              isHome: {
                key: 'isHome',
                label: 'Pertence ao Carrossel',
              },
            },
            details: {
              form: {
                name: {
                  label: 'Nome',
                },
                title: {
                  label: 'Título',
                },
                description: {
                  label: 'Descrição',
                },
                iconUrl: {
                  label: 'Icone',
                },
                isDefault: {
                  label: 'Canal padrão',
                },
                isHome: {
                  label: 'Adicionar ao Carrossel',
                },
                isFixed: {
                  label: 'Manter canal fixo',
                },
                importCsv: {
                  label: 'Importar moradores',
                },
                condominiumId: {
                  label: 'Condomínio',
                },
              },
              selectRemove: 'Remover',
              remove: 'Pronto para remover',
              radioButton: {
                title: 'Modo importação',
                firstOptionAdmin: 'Selecionar condomínio',
                firstOption: 'Adicionar o condominio todo',
                secondOption: 'Adicionar Usuários',
              },
              import: 'Selecionar usuários',
              messages: {
                successCreate: 'Canal adicionado com sucesso.',
                successEdit: 'Canal atualizado com sucesso.',
                successImport: 'Usuários importados com sucesso!',
              },
              errors: {
                missing_condominium: 'Adicione um condomínio para importar o CSV',
                missing_csv: 'Selecione os usuários que deseja adicionar nesse canal!',
                missing_photo: 'Adicione uma foto para o canal!',
                generic: 'Algo deu errado. Tente novamente mais tarde.',
              },
            },
            dataTable: {
              columns: {
                iconUrl: {
                  key: 'iconUrl',
                  title: 'Foto do Canal',
                },
                name: {
                  key: 'name',
                  title: 'Nome',
                },
                condominiumId: {
                  key: 'condominium.name',
                  title: 'Condomínio',
                },
                userCount: {
                  key: 'userCount',
                  title: 'Número de Usuários',
                },
                isHome: {
                  key: 'isHome',
                  title: 'Pertence ao Carrossel',
                },
                isFixed: {
                  key: 'isFixed',
                  title: 'Canal Fixo',
                },
                isDefault: {
                  key: 'isDefault',
                  title: 'Canal Padrão',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                  goToDetailsText: 'Detalhes',
                  removeText: 'Remover',
                },
              },
            },
          },
          message: {
            profileType: governingBodyAndAdminPermission,
            url: '/painel/comunicacao/mensagens',
            sidebarTitle: 'Mensagens',
            pageTitle: 'Gerenciar mensagens',
            icon: 'message',
            addNewButtonText: 'Adicionar mensagem',
            pageTitleAdd: 'Adicionar mensagem',
            pageTitleEdit: 'Editar mensagem',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar uma mensagem',
            pageDescriptionEdit: 'Preencha os campos abaixo para editar uma mensagem',
            messages: {
              addSuccess: 'Adicionada com sucesso.',
              editSuccess: 'Editada com sucesso.',
              deleteSuccess: 'Removida com sucesso.',
            },
            advancedFilter: {
              panelTitle: 'Filtros avançados',
              period: {
                key: 'period',
                label: 'Período da Mensagem',
              },
              title: {
                key: 'title',
                placeholder: 'Digite um título...',
                label: 'Título da Mensagem',
              },
            },
            details: {
              form: {
                name: {
                  label: 'Nome',
                },
                title: {
                  label: 'Título',
                },
                description: {
                  label: 'Descrição',
                },
                iconUrl: {
                  label: 'Icone',
                },
                isDefault: {
                  label: 'Canal padrão',
                },
                isHome: {
                  label: 'Adicionar canal no carrossel',
                },
                isFixed: {
                  label: 'Manter canal fixo',
                },
                importCsv: {
                  label: 'Importar moradores',
                },
                condominiumId: {
                  label: 'Condomínio',
                },
              },
              messages: {
                successCreate: 'Mensagem adicionada com sucesso.',
                successEdit: 'Mensagem atualizada com sucesso.',
              },
              errors: {
                generic: 'Algo deu errado. Tente novamente mais tarde.',
              },
            },
            dataTable: {
              columns: {
                condominiumId: {
                  key: 'channel',
                  title: 'Condomínio',
                },
                channelId: {
                  key: 'channel',
                  title: 'Canal',
                },
                sender: {
                  key: 'sender',
                  title: 'Quem enviou',
                },
                title: {
                  key: 'title',
                  title: 'Título',
                },
                quantityReceive: {
                  key: 'quantityReceive',
                  title: 'Receberam',
                },
                quantityView: {
                  key: 'quantityView',
                  title: 'Vizualizaram',
                },
                quantityLike: {
                  key: 'quantityLike',
                  title: 'Likes',
                },
                quantityDislike: {
                  key: 'quantityDislike',
                  title: 'Dislike',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                  goToDetailsText: 'Detalhes',
                  removeText: 'Remover',
                },
              },
            },
          },
        },
      },
      settings: {
        profileType: adminPermission,
        sidebarVisible: 'n',
        sidebarTitle: 'Configurações',
        icon: 'setting',
        items: {
          platform: {
            url: '/painel/configuracoes/plataforma',
            sidebarTitle: 'Plataforma',
            pageTitle: 'Gerenciar configurações da plataforma',
            icon: 'setting',
            addNewButtonText: 'Adicionar configuração',
            pageTitleAdd: 'Adicionar configuração',
            pageTitleEdit: 'Editar configurações',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar uma configuração',
            pageDescriptionEdit: 'Preencha os campos abaixo para editar uma configuração',
            messages: {
              addSuccess: 'Adicionada com sucesso.',
              editSuccess: 'Editada com sucesso.',
              deleteSuccess: 'Removida com sucesso.',
            },
            details: {
              form: {
                beAllBeeCommission: {
                  label: 'Taxa Comissão BeAllBee',
                },
              },
              messages: {
                successCreate: 'Configuração adicionada com sucesso.',
                successEdit: 'Configuração atualizada com sucesso.',
              },
              errors: {
                generic: 'Algo deu errado. Tente novamente mais tarde.',
              },
            },
          },
        },
      },
    },
  },
  advancedAutoComplete: {
    condominium: {
      inputLabel: 'Condomínio',
      modal: {
        title: 'Selecionar condomínio',
        advancedSearch: {
          name: {
            label: 'Nome do Condomínio',
            type: FieldType.TEXT,
          },
        },
        dataTable: {
          name: {
            label: 'Nome',
          },
        },
      },
    },
    condominiumAdministrator: {
      inputLabel: 'Administradora',
      modal: {
        title: 'Selecionar administradora',
        advancedSearch: {
          administratorName: {
            label: 'Nome da administradora',
            type: FieldType.TEXT,
          },
        },
        dataTable: {
          administratorName: {
            label: 'Nome da Administradora',
          },
        },
      },
    },
    establishment: {
      inputLabel: 'Estabelecimento',
      modal: {
        title: 'Selecionar estabelecimento',
        advancedSearch: {
          name: {
            label: 'Nome do estabelecimento',
            type: FieldType.TEXT,
          },
        },
        dataTable: {
          name: {
            label: 'Nome',
          },
        },
      },
    },
    channel: {
      inputLabel: 'Canal',
      modal: {
        title: 'Selecionar canal',
        advancedSearch: {
          name: {
            label: 'Nome do canal',
            type: FieldType.TEXT,
          },
        },
        dataTable: {
          name: {
            label: 'Nome',
          },
        },
      },
    },
    user: {
      inputLabel: 'Morador',
      modal: {
        title: 'Selecionar morador',
        advancedSearch: {
          name: {
            label: 'Nome do morador',
            type: FieldType.TEXT,
          },
          email: {
            label: 'E-mail do morador',
            type: FieldType.TEXT,
          },
        },
        dataTable: {
          name: {
            label: 'Nome',
          },
          email: {
            label: 'E-mail',
          },
        },
      },
    },
    condominiumUser: {
      inputLabel: 'Morador',
      modal: {
        title: 'Selecionar morador',
        advancedSearch: {
          name: {
            label: 'Nome do morador',
            type: FieldType.TEXT,
          },
        },
        dataTable: {
          name: {
            label: 'Nome',
          },
          email: {
            label: 'E-mail',
          },
        },
      },
    },
    condominiumManager: {
      inputLabel: 'Síndico',
      modal: {
        title: 'Selecionar síndico',
        advancedSearch: {
          name: {
            label: 'Nome do síndico',
            type: FieldType.TEXT,
          },
          email: {
            label: 'E-mail do síndico',
            type: FieldType.TEXT,
          },
        },
        dataTable: {
          name: {
            label: 'Nome',
          },
          email: {
            label: 'E-mail',
          },
        },
      },
    },
    deliveryMan: {
      inputLabel: 'Entregador',
      modal: {
        title: 'Selecionar entregador',
        advancedSearch: {
          name: {
            label: 'Nome do entregador',
            type: FieldType.TEXT,
          },
          email: {
            label: 'E-mail do entregador',
            type: FieldType.TEXT,
          },
        },
        dataTable: {
          name: {
            label: 'Nome',
          },
          email: {
            label: 'E-mail',
          },
        },
      },
    },
  },
  advancedHtmlEditor: {
    title: 'Editor de Texto',
    cancelButtonText: 'Cancelar',
    updateButtonText: 'Criar',
  },
  enum: {
    profileType: {
      admin: 'Administrador',
      dweller: 'Morador',
      condominiumManager: 'Síndico',
      doorman: 'Porteiro',
      establishmentAdmin: 'Fornecedor',
      caretaker: 'Zelador',
      establishment: 'Estab',
      motoboy: 'Entregador',
      condominiumSubManager: 'Sub-Síndico',
      financial: 'Administrador Financeiro',
      advisor: 'Conselheiro',
    },
    deliveryType: {
      fast: 'Rápido',
      scheduled: 'Agendado',
    },
    orderStatusType: {
      pendingPayment: 'Pagamento pendente',
      paied: 'Pago',
      inTransit: 'Em trânsito',
      fueling: 'Motorista no local',
      provided: 'Abastecido',
      canceled: 'Cancelado',
    },
    addressType: {
      house: 'Casa',
      work: 'Trabalho',
      outro: 'Outro',
    },
    notificationType: {
      newVisit: 'Nova visita',
      newPackage: 'Novo pacote',
      paymentApproved: 'Pagamento Aprovado',
      packageReceived: 'Pagamento Reprovado',
      associationApproved: 'Associação Aprovada',
      paymentError: 'Erro de pagamento',
      associationRefused: 'Associação recusada',
      newMessage: 'Nova mensagem',
      newFamiliarMember: 'Novo membro familiar',
      deleteFamiliarMember: 'Membro familiar deletado',
      scheduling_recreation_success: 'Agendamento criado com sucesso',
      scheduling_recreation_canceled: 'Agendamento cancelado com sucesso',
    },
    condominiumType: {
      horizontal: 'Horizontal',
      vertical: 'Vertical',
    },
    ChannelsBoolean: {
      true: 'Sim',
      false: 'Não',
    },
    contractStatusType: {
      notStarted: 'Não iniciado',
      started: 'Iniciado',
      finished: 'Finalizado',
    },
    visitPeriodType: {
      morning: 'Manhã',
      afternoon: 'Tarde',
      night: 'Noite',
      morning_and_afternoon: 'Manhã e tarde',
      morning_and_night: 'Manhã e noite',
      afternoon_and_night: 'Tarde e noite',
      all: 'Todos',
    },
    visitStatusType: {
      pending: 'Pendente',
      done: 'Finalizado',
    },
    visitIsEmployee: {
      yes: 'Sim',
      no: 'Não',
    },
    condominiumUserStatusType: {
      pending: 'Pendente',
      approved: 'Aprovado',
      refused: 'Recusado',
    },
    packageType: {
      box: 'Caixa',
      envelope: 'Envelope',
      letter: 'Carta',
      other: 'Outro',
    },
    packageStatusType: {
      pending: 'Pendente',
      withdraw: 'Entregue',
    },
    daysType: {
      sunday: 'Domingo',
      monday: 'Segunda-feira',
      tuesday: 'Terça-feira',
      wednesday: 'Quarta-feira',
      thursday: 'Quinta-feira',
      friday: 'Sexta-feira',
      saturday: 'Sábado',
    },
    transactionStatusType: {
      created: 'Criado',
      approved: 'Aprovado',
      refused: 'Recusado',
    },
    notImportedType: {
      email_cellphone: 'Email e celular já cadastrado na residência escolhida',
      email: 'Email já cadastrado na residência escolhida',
      cellphone: 'Celular já cadastrado na residência escolhida',
    },
    recreationCategory: {
      movie: 'Cinema',
      bbq: 'Churrasqueira',
      halls: 'Salões',
      swimming: 'Piscina',
      block: 'Quadra',
      gym: 'Academia',
      steamRoom: 'Sauna',
      playroom: 'Brinquedoteca',
    },
    scheduleType: {
      block: 'Bloqueio',
      reserve: 'Reserva',
    },
    scheduleStatus: {
      pendent: 'Pendente',
      finalized: 'Finalizado',
      canceled: 'Cancelado',
    },
    agencyType: {
      itau: 'Itaú',
      bradesco: 'Bradesco',
      caixa: 'Caixa Econômica',
      bancoDoBrasil: 'Banco do Brasil',
      santander: 'Santander',
      banrisul: 'Banrisul',
      sincredi: 'Sicredi',
      sicoob: 'Sicoob',
      inter: 'Inter',
      brb: 'BRB',
      viaCredi: 'Via Credi',
      neon: 'Neon',
      votorantim: 'Votorantim',
      nubank: 'Nubank',
      pagseguro: 'Pagseguro',
      bancoOriginal: 'Banco Original',
      safra: 'Safra',
      modal: 'Modal',
      banestes: 'Banestes',
      unicred: 'Unicred',
      moneyPlus: 'Money Plus',
      marcantil: 'Mercantil do Brasil',
      jpMorgan: 'JP Morgan',
      gerencianet: 'Gerencianet Pagamentos do Brasil',
      c6: 'Banco C6',
      bs2: 'BS2',
      topazio: 'Banco Topazio',
      uniprime: 'Uniprime',
      stone: 'Stone',
      daycoval: 'Banco Daycoval',
      rendimento: 'Rendimento',
      nordeste: 'Banco do Nordeste',
      citibank: 'Citibank',
    },
    accountType: {
      normalSavings: 'Conta Poupança',
      currentAccounts: 'Conta Corrente',
    },
  },
};
