import React from 'react';
import { connect } from 'react-redux';
import {
  Row, Col, message, Divider,
} from 'antd';
import * as yup from 'yup';
import { I18n } from 'react-redux-i18n';
import { SaveOutlined } from '@ant-design/icons';

import { CategorySelectors, AuthSelectors, LoadingSelectors } from '../../redux/reducers';
import { UtilsActions } from '../../redux/actions';

import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedUpload from '../../../components/shared/AdvancedUpload/AdvancedUpload';

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('Você só pode enviar arquivos no formato JPG/PNG!');
  }
  const isLt2M = file.size / 1024 / 1024 < 3;
  if (!isLt2M) {
    message.error('A imagem deve ser menor ou igual a 3MB!');
  }
  return isJpgOrPng && isLt2M;
}

class CategoryForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        title: '',
        picUrl: null,
      },
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps() {
    const { details, id } = this.props;

    if (details && id !== 'add') {
      this.setState({
        form: {
          title: details && details.title,
          picUrl: details && details.picUrl,
        },
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const { form } = this.state;

    const schema = yup.object().shape({
      title: yup.string().required(I18n.t('shared.errors.missing_categoryTitle')),
    });

    schema.validate(form, { abortEarly: true })
      .then(() => {
        const { details, submitFunction } = this.props;

        const payload = {
          ...form,
          ...(form && form.picture && { picUrl: form.picture.url }),
        };

        if (!payload.picUrl) return message.error(I18n.t('shared.errors.missing_picture'));

        submitFunction(details ? details.id : null, payload);
      })
      .catch((err) => message.error(err.message || I18n.t('shared.genericError')));
  }

  fieldChange(name, value) {
    const { form } = this.state;
    form[name] = value || '';
    this.setState({ form });
  }

  render() {
    const { loading, uploadImage } = this.props;

    const { form } = this.state;

    return (
      <Row>
        {loading === 0 && (
          <Col>
            <form
              name="form"
              onSubmit={(ev) => this.onSubmit(ev)}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.establishment.items.category.details.form.title.label')}
                    value={form && form.title}
                    onChange={(val) => this.fieldChange('title', val)}
                    maxLength={25}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <AdvancedUpload
                    label={I18n.t('routes.panel.establishment.items.category.details.form.picUrl.label')}
                    pictureUrl={form && ((form.picture && form.picture.base64) || form.picUrl)}
                    compressImage={false}
                    onUpload={(photoData, photoName, photoType) => uploadImage(photoData, photoName, photoType)}
                    onChange={(val) => this.fieldChange('picture', val)}
                    beforeUpload={beforeUpload}
                  />
                </Col>
              </Row>

              <Divider />

              <Row>
                <Col
                  span={24}
                  className="text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.backButtonText')}
                    href={I18n.t('routes.panel.establishment.items.category.url')}
                  />

                  <Divider
                    className="form-button-divider"
                    type="vertical"
                  />

                  <AdvancedButton
                    htmlType="submit"
                    text={I18n.t('shared.saveButtonText')}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </form>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  details: CategorySelectors.getDetails(state),
});
const mapDispatchToProps = (dispatch) => ({
  uploadImage: (photoData, photoName, photoType, callback) => dispatch(UtilsActions.uploadImage(photoData, photoName, photoType, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryForm);
