import { Translate } from 'react-redux-i18n';
import React from 'react';

const ChannelsBoolean = {
  TRUE: true,
  FALSE: false,
};

export const ChannelsBooleanArray = [
  { id: ChannelsBoolean.TRUE, name: <Translate value="enum.ChannelsBoolean.true" /> },
  { id: ChannelsBoolean.FALSE, name: <Translate value="enum.ChannelsBoolean.false" /> },
];

export const GetChannelsBooleanTitle = (value) => (ChannelsBooleanArray.find((o) => o.id === value).name);

export default ChannelsBoolean;
