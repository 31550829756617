import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import {
  addLoading,
  removeLoading,
} from './loading';
import UtilsRequests from '../../api/utils';

export const getAddressByCep = (cep) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const { data } = await UtilsRequests.getAddressByCep(cep);
    return data;
  } catch (err) {
    message.error(I18n.t('shared.notFoundSearch'));
  } finally {
    dispatch(removeLoading());
  }
};

export const uploadImage = (photoData, photoName, photoType, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    let response = null;
    if (photoData) {
      const data = await UtilsRequests.getImageUrl(photoName);
      await UtilsRequests.uploadImage(data.url, photoData, photoType);
      response = data;
    }

    if (callback && typeof callback === 'function') {
      callback(response);
    }

    return response;
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const uploadMessageContent = (content, name, type) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const data = await UtilsRequests.getImageUrl(name);
    await UtilsRequests.uploadImage(data.url, content, type);
    let fixedData = data && data.url && data.url.indexOf('?');
    if (fixedData && fixedData > 0) {
      fixedData = data.url.split('?');
    }
    return fixedData[0];
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};
