import { Translate } from 'react-redux-i18n';
import React from 'react';

const AgencyType = {
  ITAU: '341',
  BRADESCO: '237',
  CAIXA_ECONOMICA: '104',
  BANCO_DO_BRASIL: '001',
  SANTANDER: '033',
  BANRISUL: '041',
  SICREDI: '748',
  SICOOB: '756',
  INTER: '077',
  BRB: '070',
  VIA_CREDI: '085',
  NEON: '655',
  NUBANK: '260',
  PAGSEGURO: '290',
  BANCO_ORIGINAL: '212',
  SAFRA: '422',
  MODAL: '746',
  BANESTES: '021',
  UNICRED: '136',
  MONEY_PLUS: '274',
  MERCANTIL_DO_BRASIL: '389',
  JP_MORGAN: '376',
  GERENCIANET_PAGAMENTOS_DO_BRASIL: '364',
  BANCO_C6: '336',
  BS2: '218',
  BANCO_TOPAZIO: '082',
  UNIPRIME: '099',
  STONE: '197',
  BANCO_DAYCOVAL: '707',
  RENDIMENTO: '633',
  BANCO_DO_NORDESTE: '004',
  CITIBANK: '745',
};

export const AgencyTypeArray = [
  {
    id: AgencyType.ITAU,
    name: 'Itaú',
    agencyMask: '9999',
    accountMask: '99999-9',
  },
  {
    id: AgencyType.BRADESCO,
    name: 'Bradesco',
    agencyMask: '9999-9',
    accountMask: '9999999-9',
  },
  {
    id: AgencyType.CAIXA_ECONOMICA,
    name: 'Caixa Econômica',
    agencyMask: '9999',
    accountMask: '99999999999-9',
  },
  {
    id: AgencyType.BANCO_DO_BRASIL,
    name: 'Banco do Brasil',
    agencyMask: '9999-9',
    accountMask: '99999999-9',
  },
  {
    id: AgencyType.SANTANDER,
    name: 'Santander',
    agencyMask: '9999-9',
    accountMask: '99999999-9',
  },
  {
    id: AgencyType.BANRISUL,
    name: 'Banrisul',
    agencyMask: '9999',
    accountMask: '999999999-9',
  },
  {
    id: AgencyType.SICREDI,
    name: 'Sicredi',
    agencyMask: '9999',
    accountMask: '9999999',
  },
  {
    id: AgencyType.SICOOB,
    name: 'Sicoob',
    agencyMask: '9999',
    accountMask: '999999999-9',
  },
  {
    id: AgencyType.INTER,
    name: 'Inter',
    agencyMask: '9999',
    accountMask: '999999999-9',
  },
  {
    id: AgencyType.BRB,
    name: 'BRB',
    agencyMask: '9999',
    accountMask: '999999999-9',
  },
  {
    id: AgencyType.VIA_CREDI,
    name: 'Via Credi',
    agencyMask: '9999',
    accountMask: '99999999999-9',
  },
  {
    id: AgencyType.NEON,
    name: 'Neon',
    agencyMask: '9999',
    accountMask: '9999999-9',
  },
  {
    id: AgencyType.NUBANK,
    name: 'Nubank',
    agencyMask: '9999',
    accountMask: '9999999999-9',
  },
  {
    id: AgencyType.PAGSEGURO,
    name: 'Pagseguro',
    agencyMask: '9999',
    accountMask: '99999999-9',
  },
  {
    id: AgencyType.BANCO_ORIGINAL,
    name: 'Banco Original',
    agencyMask: '9999',
    accountMask: '9999999-9',
  },
  {
    id: AgencyType.SAFRA,
    name: 'Safra',
    agencyMask: '9999',
    accountMask: '99999999-9',
  },
  {
    id: AgencyType.MODAL,
    name: 'Modal',
    agencyMask: '9999',
    accountMask: '999999999-9',
  },
  {
    id: AgencyType.BANESTES,
    name: 'Banestes',
    agencyMask: '9999',
    accountMask: '99999999-9',
  },
  {
    id: AgencyType.UNICRED,
    name: 'Unicred',
    agencyMask: '9999',
    accountMask: '99999999-9',
  },
  {
    id: AgencyType.MONEY_PLUS,
    name: 'Money Plus',
    agencyMask: '9',
    accountMask: '99999999-9',
  },
  {
    id: AgencyType.MERCANTIL_DO_BRASIL,
    name: 'Mercantil do Brasil',
    agencyMask: '9999',
    accountMask: '99999999-9',
  },
  {
    id: AgencyType.JP_MORGAN,
    name: 'JP Morgan',
    agencyMask: '9999',
    accountMask: '99999999999-9',
  },
  {
    id: AgencyType.GERENCIANET_PAGAMENTOS_DO_BRASIL,
    name: 'Gerencianet Pagamentos do Brasil',
    agencyMask: '9999',
    accountMask: '99999999-9',
  },
  {
    id: AgencyType.BANCO_C6,
    name: 'Banco C6',
    agencyMask: '9999',
    accountMask: '9999999-9',
  },
  {
    id: AgencyType.BS2,
    name: 'BS2',
    agencyMask: '9999',
    accountMask: '999999-9',
  },
  {
    id: AgencyType.BANCO_TOPAZIO,
    name: 'Banco Topazio',
    agencyMask: '9999',
    accountMask: '99999-9',
  },
  {
    id: AgencyType.UNIPRIME,
    name: 'Uniprime',
    agencyMask: '9999',
    accountMask: '99999-9',
  },
  {
    id: AgencyType.STONE,
    name: 'Stone',
    agencyMask: '9999',
    accountMask: '999999-9',
  },
  {
    id: AgencyType.BANCO_DAYCOVAL,
    name: 'Banco Daycoval',
    agencyMask: '9999',
    accountMask: '999999-9',
  },
  {
    id: AgencyType.RENDIMENTO,
    name: 'Rendimento',
    agencyMask: '9999-9',
    accountMask: '9999999999',
  },
  {
    id: AgencyType.BANCO_DO_NORDESTE,
    name: 'Banco do Nordeste',
    agencyMask: '999',
    accountMask: '999999-9',
  },
  {
    id: AgencyType.CITIBANK,
    name: 'Citibank',
    agencyMask: '999',
    accountMask: '99999999',
  },
];

export const GetAgencyTypeId = (value) => value && (AgencyTypeArray.find((o) => o.name === value).id);
export const GetAgencyTypeName = (value) => value && (AgencyTypeArray.find((o) => o.id === value).name);
export const GetAgencyTypeAgencyMask = (value) => {
  const response = value && (AgencyTypeArray.find((o) => o.id === value));
  return response && response.agencyMask;
};
export const GetAgencyTypeAccountMask = (value) => {
  const response = value && (AgencyTypeArray.find((o) => o.id === value));
  return response && response.accountMask;
};

export const AccountType = {
  NORMAL_SAVINGS: 1,
  CURRENT_ACCOUNTS: 2,
};

export const AccountTypeArray = [
  {
    id: AccountType.NORMAL_SAVINGS,
    name: <Translate value="enum.accountType.normalSavings" />,
  },
  {
    id: AccountType.CURRENT_ACCOUNTS,
    name: <Translate value="enum.accountType.currentAccounts" />,
  },
];

export default AgencyType;
