/* eslint-disable no-dupe-else-if */
import React from 'react';
import CurrencyInput from 'react-currency-input';
import { I18n } from 'react-redux-i18n';

import { formatCurrency } from '../../../app/utils/currency';

const TaxCard = ({
  title, value, readonly = false, onChange,
}) => (
  <div className="tax__card">
    <p className="tax__card__title">{title.toUpperCase()}</p>
    <CurrencyInput
      className={`tax__card__input ${readonly ? 'tax__card__input--readonly' : ''}`}
      prefix={`${I18n.t('shared.moneyPrefix')} `}
      decimalSeparator={`${I18n.t('shared.decimalSeparator')}`}
      thousandSeparator={`${I18n.t('shared.thousandSeparator')}`}
      value={value}
      // onChangeEvent={(ev) => onChange(ev.target.value)}
      onChangeEvent={onChange}
      inputType="tel"
      disabled={!!readonly}
    />
  </div>
);

class TaxesCalculator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 100,
    };
  }

  render() {
    const {
      beAllBeeCommission = 0,
      beecashEstablishment = 0,
      cardTax1 = 2.51,
      cardTax2 = 3.21,
      cardTax3 = 3.55,
    } = this.props;
    const { value } = this.state;

    const totalBeAllBeeCommission = (Number(beAllBeeCommission) / 100) * value;
    const totalBeecashEstablishment = (Number(beecashEstablishment) / 100) * value;
    const totalCardTax1 = (Number(cardTax1) / 100) * value;
    const totalCardTax2 = (Number(cardTax2) / 100) * value;
    const totalCardTax3 = (Number(cardTax3) / 100) * value;

    const establishmentReceives = value - totalBeAllBeeCommission - totalBeecashEstablishment;
    const condominiumReceives = totalBeecashEstablishment;

    const formatFee = (fee) => (fee ? Number(fee).toFixed(2) : 0);

    return (
      <>
        <h2>Taxas</h2>
        <div className="tax">
          <TaxCard
            title="Valor da conta"
            value={value}
            onChange={(e) => this.setState({ value: formatCurrency(e.target.value) })}
          />
          <TaxCard
            title={`Comissão BeAllBee ${beAllBeeCommission ? `(${formatFee(beAllBeeCommission)}%)` : ''}`}
            value={totalBeAllBeeCommission}
            readonly
          />
          <TaxCard
            title={`BeeCash Estabelecimento ${beecashEstablishment ? `(${formatFee(beecashEstablishment)}%)` : ''}`}
            value={totalBeecashEstablishment}
            readonly
          />
          <TaxCard
            title={`Taxa de pagamento a vista ${cardTax1 ? `(${formatFee(cardTax1)}%)` : ''}`}
            value={totalCardTax1}
            readonly
          />
          <TaxCard
            title={`Taxa de pagamento parcelado até 6x ${cardTax2 ? `(${formatFee(cardTax2)}%)` : ''}`}
            value={totalCardTax2}
            readonly
          />
          <TaxCard
            title={`Taxa de pagamento parcelado até 12x ${cardTax3 ? `(${formatFee(cardTax3)}%)` : ''}`}
            value={totalCardTax3}
            readonly
          />
        </div>
        <h2>Recebimento</h2>
        <div className="tax">
          <TaxCard
            title="Fornecedor Recebe (Método a vista)"
            value={establishmentReceives - totalCardTax1}
            readonly
          />
          <TaxCard
            title="Fornecedor Recebe (Parcelado em até 6x)"
            value={establishmentReceives - totalCardTax2}
            readonly
          />
          <TaxCard
            title="Fornecedor Recebe (Parcelado em até 12x)"
            value={establishmentReceives - totalCardTax3}
            readonly
          />
          <TaxCard
            title="Condomínio Recebe"
            value={condominiumReceives}
            readonly
          />
        </div>
      </>
    );
  }
}

export default TaxesCalculator;
