import { ProfileType } from '../enum/profileType';

export function isAdmin(me) {
  return !!(me && me.profileType === ProfileType.ADMIN);
}

export function isCondominiumManager(me) {
  return !!(me && (me.profileType === ProfileType.CONDOMINIUM_MANAGER || me.profileType === ProfileType.CONDOMINIUM_SUB_MANAGER));
}

export function isGoverningBody(me) {
  return !!(me && (me.profileType === ProfileType.CONDOMINIUM_MANAGER
    || me.profileType === ProfileType.CONDOMINIUM_SUB_MANAGER
    || me.profileType === ProfileType.ADVISOR
    || me.profileType === ProfileType.FINANCIAL_ADMINISTRATOR));
}

export function isEstablishmentAdmin(me) {
  return !!(me && me.profileType === ProfileType.ESTABLISHMENT_ADMIN);
}
