import React from 'react';
import { connect } from 'react-redux';
import {
  Row, Col, message, Divider, Upload,
} from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import { SaveOutlined, InboxOutlined, CheckCircleOutlined } from '@ant-design/icons';
import * as yup from 'yup';
import { I18n } from 'react-redux-i18n';
import moment from 'moment-timezone';

import AppConfig from '../../config/app.config';

import { CondominiumSelectors, AuthSelectors, LoadingSelectors } from '../../redux/reducers';
import { UtilsActions, CondominiumActions, ChannelsActions } from '../../redux/actions';
// import { removeSpecialChars } from '../../utils/string';
// import { formatCurrency } from '../../utils/currency';

import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedSwitch from '../../../components/shared/AdvancedSwitch/AdvancedSwitch';
import AdvancedDatePicker from '../../../components/shared/AdvancedDatePicker/AdvancedDatePicker';
import AdvancedRadioGroup from '../../../components/shared/AdvancedRadioGroup/AdvancedRadioGroup';
import AdvancedAutoCompleteInput from '../../../components/shared/AdvancedAutoCompleteInput/AdvancedAutoCompleteInput';
import { isAdmin } from '../../utils/permissions';

const { Dragger } = Upload;

const MessagesType = {
  HTML: 1,
  FILE: 2,
  AUDIO: 3,
  VIDEO: 4,
};

const PeriodicType = {
  DAY: 1,
  WEEK: 2,
  MONTH: 3,
  YEAR: 4,
};

class CondominiumForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSchedule: false,
      isPeriodic: false,
      form: {
        title: '',
        messageType: MessagesType.HTML,
        scheduleDate: null,
        periodicType: null,
        sendPush: false,
        isFixed: false,
        isEternal: false,
        condominiumId: null,
        channelId: '',
        htmlMessage: null,
        fileUrl: null,
      },
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps() {
    const { details, id } = this.props;

    if (details && id !== 'add') {
      this.setState({
        form: {
          title: null,
          messageType: MessagesType.HTML,
          scheduleDate: null,
          periodicType: null,
          condominiumId: null,
          channelId: null,
          htmlMessage: null,
        },
      });
    }
  }

  async onSubmit(e) {
    e.preventDefault();

    const { form } = this.state;
    const { uploadFile } = this.props;

    const schema = yup.object().shape({
      title: yup.string().required(I18n.t('shared.errors.missing_title')),
      channelId: yup.string().required(I18n.t('shared.errors.missing_channelId')),
    });

    schema.validate(form, { abortEarly: true })
      .then(async () => {
        const { details, submitFunction } = this.props;
        const { isSchedule, isPeriodic } = this.state;

        if (isSchedule && form && !form.scheduleDate) {
          return message.error('Digite uma data de agendamento para a mensagem');
        }

        if (isPeriodic && form && !form.periodicType) {
          return message.error('Selecione uma forma de recorrência de envio');
        }

        if (form && !form.htmlMessage && !form.fileUrl) {
          return message.error('Adicione o conteudo da mensagem');
        }

        if (form.messageType === MessagesType.HTML && form.htmlMessage) {
          const response = await uploadFile(form.htmlMessage, 'teste.html', 'text/html');
          this.fieldChange('fileUrl', response);
        }

        const data = {
          ...form,
          channelIdList: [form.channelId],
          type: form.messageType,
          ...(form && form.messageType !== MessagesType.HTML && { message: form.fileUrl }),
          ...(form && form.messageType === MessagesType.HTML && { message: form.htmlMessage }),
          ...(isPeriodic && form && form.periodicType
            ? { isRecurrent: true, recurrenceType: form.periodicType }
            : { isRecurrent: false }),
          ...(isSchedule && form && form.scheduleDate && { scheduleDate: moment(form.scheduleDate).format() }),
          ...(isPeriodic && !isSchedule && { scheduleDate: moment().format() }),
        };

        if (details) {
          submitFunction(details.id, data);
        } else {
          submitFunction(null, data);
        }
      })
      .catch((err) => message.error(err.message || I18n.t('shared.genericError')));
  }

  handleHtmlChange(htmlMessage) {
    this.fieldChange('htmlMessage', htmlMessage);
  }

  fieldChange(name, value) {
    const { form } = this.state;
    form[name] = value;
    this.setState({ form });
  }

  async uploadFile(file) {
    const { uploadFile } = this.props;
    if (file.type === 'video/mp4' || file.type === 'audio/mpeg') {
      const response = await uploadFile(file, file.name, file.type);
      this.fieldChange('fileUrl', response);
    } else if (file.type === 'application/pdf') {
      const response = await uploadFile(file, file.name, file.type);
      this.fieldChange('fileUrl', response);
    } else {
      message.error('Formato de arquivo não valido');
    }
  }

  render() {
    const {
      loading, getChannelPaginatedForSearch, me,
      getCondominiumPaginatedForSearch,
    } = this.props;
    const {
      form, htmlMessage, isSchedule, isPeriodic,
    } = this.state;

    return (
      <Row>
        {loading === 0 && (
          <Col>
            <form
              name="form"
              onSubmit={(ev) => this.onSubmit(ev)}
            >
              <Row gutter={16}>
                {isAdmin(me) && (
                  <Col span={8}>
                    <AdvancedAutoCompleteInput
                      searchMethod={(parameters) => getCondominiumPaginatedForSearch(parameters)}
                      data={I18n.t('advancedAutoComplete.condominium')}
                      onSelect={(item) => this.fieldChange('condominiumId', item.id)}
                      onSelectSelectable={(id) => this.fieldChange('condominiumId', id)}
                      value={form && form.condominiumId}
                      disableSearch
                    />
                  </Col>
                )}
                <Col span={8}>
                  <AdvancedAutoCompleteInput
                    searchMethod={(parameters) => getChannelPaginatedForSearch({
                      ...parameters,
                      ...(form.condominiumId && { condominiumId: form.condominiumId }),
                      ...(!isAdmin(me) && { condominiumId: me.condominiumId }),
                    })}
                    data={I18n.t('advancedAutoComplete.channel')}
                    onSelect={(item) => this.fieldChange('channelId', item.id)}
                    onSelectSelectable={(id) => this.fieldChange('channelId', id)}
                    value={form && form.channelId}
                    disableSearch
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.communication.items.channel.details.form.title.label')}
                    value={form && form.title}
                    onChange={(val) => this.fieldChange('title', val)}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col
                  span={24}
                >
                  <AdvancedRadioGroup
                    label="Formato da Mensagem"
                    onChange={(e) => this.fieldChange('messageType', e.target.value)}
                    value={form && form.messageType}
                    options={[
                      { id: MessagesType.HTML, name: 'Texto' },
                      { id: MessagesType.FILE, name: 'PDF' },
                      { id: MessagesType.AUDIO, name: 'Áudio' },
                      { id: MessagesType.VIDEO, name: 'Vídeo' },
                    ]}
                  />
                </Col>
              </Row>

              {form && form.messageType === MessagesType.HTML && (
                <Col span={24}>
                  <Editor
                    value={htmlMessage}
                    apiKey={AppConfig.tinyApiKey}
                    init={AppConfig.tinyMCEOptions}
                    onEditorChange={(val) => this.handleHtmlChange(val)}
                  />
                </Col>
              )}

              {form && form.messageType !== MessagesType.HTML && (
                <Col span={24}>
                  <Dragger
                    customRequest={({ file }) => this.uploadFile(file)}
                    multiple={false}
                    disabled={form && form.fileUrl}
                  >
                    <p className="ant-upload-drag-icon">
                      {form && form.fileUrl ? <CheckCircleOutlined /> : <InboxOutlined />}
                    </p>
                    <p className="ant-upload-text">
                      {form && form.fileUrl
                        ? 'Arquivo pronto para envio!'
                        : 'Clique ou arraste para adicionar o arquivo para fazer o upload de arquivo'}
                    </p>
                  </Dragger>
                </Col>
              )}

              <Divider />

              <Row gutter={16}>
                <Col
                  span={6}
                >
                  <AdvancedSwitch
                    label="Agendar mensagem"
                    value={isSchedule}
                    onChange={(val) => this.setState({ isSchedule: val })}
                  />
                </Col>
                {isSchedule && (
                  <Col
                    span={7}
                  >
                    <AdvancedDatePicker
                      onChange={(val) => this.fieldChange('scheduleDate', val)}
                      value={form && form.scheduleDate}
                      showTime={{ format: 'HH:mm' }}
                      format="DD/MM/YYYY HH:mm"
                    />
                  </Col>
                )}

              </Row>

              <Row gutter={16}>
                <Col
                  span={6}
                >
                  <AdvancedSwitch
                    label="Recorrência de envio"
                    value={isPeriodic}
                    onChange={(val) => {
                      this.setState({ isPeriodic: val });
                      this.fieldChange('periodicType', null);
                    }}
                  />
                </Col>
                {isPeriodic && (
                  <Col
                    span={12}
                  >
                    <AdvancedRadioGroup
                      onChange={(e) => this.fieldChange('periodicType', e.target.value)}
                      value={form && form.periodicType}
                      options={[
                        { id: PeriodicType.DAY, name: 'Todo dia' },
                        { id: PeriodicType.WEEK, name: 'Semanal' },
                        { id: PeriodicType.MONTH, name: 'Mensal' },
                        { id: PeriodicType.YEAR, name: 'Anual' },
                      ]}
                    />
                  </Col>
                )}
              </Row>

              <Row gutter={16}>
                <Col
                  span={6}
                >
                  <AdvancedSwitch
                    label="Enviar push"
                    value={form && form.sendPush}
                    onChange={(val) => this.fieldChange('sendPush', val)}
                  />
                </Col>
              </Row>

              <Divider />

              <Row gutter={16}>
                <Col
                  span={24}
                  className="text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.backButtonText')}
                    href={I18n.t('routes.panel.communication.items.channel.url')}
                  />

                  <Divider
                    className="form-button-divider"
                    type="vertical"
                  />

                  <AdvancedButton
                    htmlType="submit"
                    text={I18n.t('shared.saveButtonText')}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </form>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  details: CondominiumSelectors.getDetails(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCondominiumPaginatedForSearch: (parameters) => dispatch(CondominiumActions.getPaginatedForSearch(parameters)),
  getChannelPaginatedForSearch: (parameters) => dispatch(ChannelsActions.getPaginatedForSearch(parameters)),
  uploadImage: (photoData, photoName, photoType, callback) => dispatch(UtilsActions
    .uploadImage(photoData, photoName, photoType, callback)),
  uploadFile: (type, content) => dispatch(UtilsActions.uploadMessageContent(type, content)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CondominiumForm);
