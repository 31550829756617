import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import {
  addLoading,
  removeLoading,
} from './loading';
import PushNotificationRequests from '../../api/push-notification';
import { addLoadingSearch, removeLoadingSearch } from './loading-search';

import { ProfileType } from '../../enum/profileType';

export const ACTION_SAVE_PUSH_NOTIFICATION_PAGINATED = 'ACTION_SAVE_PUSH_NOTIFICATION_PAGINATED';
export const ACTION_SAVE_PUSH_NOTIFICATION_DETAILS = 'ACTION_SAVE_PUSH_NOTIFICATION_DETAILS';

export const ACTION_CLEAN_PUSH_NOTIFICATION = 'ACTION_CLEAN_PUSH_NOTIFICATION';

export const ACTION_SAVE_PUSH_PAGINATED = 'ACTION_SAVE_PUSH_PAGINATED';
export const ACTION_SAVE_USERS_FOR_PUSH_PAGINATED = 'ACTION_SAVE_USERS_FOR_PUSH_PAGINATED';

export const ACTION_SAVE_CONDOMINIUMS_FOR_PUSH_PAGINATED = 'ACTION_SAVE_CONDOMINIUMS_FOR_PUSH_PAGINATED';

// cleaners
export const clean = () => ({
  type: ACTION_CLEAN_PUSH_NOTIFICATION,
  payload: null,
});

export const cleanDetails = () => ({
  type: ACTION_SAVE_PUSH_NOTIFICATION_DETAILS,
  payload: null,
});

export const cleanPaginated = () => ({
  type: ACTION_SAVE_PUSH_NOTIFICATION_PAGINATED,
  payload: null,
});

/*
 * BASIC CRUD METHODS
 *
 * getPaginated: get rows for data table
 * getDetails: get details (get by id)
 * update: the PUT method
 * create: the POST method
 */

export const getPaginated = (parameters, callback) => async (dispatch) => {
  dispatch(addLoading());
  dispatch(cleanPaginated());

  try {
    parameters = {
      ...parameters,
      limit: parameters.limit || 10,
      total: parameters.total || 0,
      page: parameters.page || 1,
      orderBy: 'createdAt',
      isDESC: true,
    };

    if (parameters && parameters.searchInput) {
      parameters = {
        ...parameters,
        title: parameters.searchInput,
      };
    }

    dispatch({
      type: ACTION_SAVE_PUSH_NOTIFICATION_PAGINATED,
      payload: null,
    });

    const response = await PushNotificationRequests.getPaginated(parameters);

    dispatch({
      type: ACTION_SAVE_PUSH_NOTIFICATION_PAGINATED,
      payload: response,
    });

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const getDetails = (id, callback) => async (dispatch) => {
  dispatch(addLoading());
  dispatch(cleanDetails());

  try {
    let response = null;
    dispatch({
      type: ACTION_SAVE_PUSH_NOTIFICATION_DETAILS,
      payload: null,
    });

    if (id !== 'add') {
      response = await PushNotificationRequests.getDetails(id);
      dispatch({
        type: ACTION_SAVE_PUSH_NOTIFICATION_DETAILS,
        payload: response,
      });
    }

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const update = (id, data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const response = await PushNotificationRequests.update(id, data);
    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const create = (data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const response = await PushNotificationRequests.create(data);
    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const remove = (id, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const response = await PushNotificationRequests.remove(id);
    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

/*
 * ADVANCED METHODS
 *
 * getPaginatedForSearch: get rows for <AdvancedAutoCompleteInput /> component
 */

export const getPaginatedForSearch = (parameters, callback) => async (dispatch) => {
  let response = null;
  dispatch(addLoadingSearch());

  try {
    parameters = {
      ...parameters,
      limit: parameters ? parameters.limit : 10,
      total: parameters ? parameters.total : 0,
      page: parameters ? parameters.page : 1,
      orderBy: 'createdAt',
      isDESC: true,
    };

    response = await PushNotificationRequests.getPaginated(parameters);

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoadingSearch());
  }

  return response;
};

/*
*New notification
*/

export const getPushNotificationsPaginated = (parameters) => async (dispatch) => {
  dispatch(addLoading());
  parameters = {
    ...parameters,
    limit: parameters.limit || 20,
    pageSize: parameters.pageSize || 10,
    total: parameters.total || 0,
    page: parameters.page || 1,
    isDESC: true,
  };

  try {
    dispatch({
      type: ACTION_SAVE_PUSH_PAGINATED,
      payload: null,
    });
    const pushPaginated = await PushNotificationRequests.getPushNotificationsPaginated(parameters);
    dispatch({
      type: ACTION_SAVE_PUSH_PAGINATED,
      payload: pushPaginated,
    });
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const getUsersForPushNotification = (parameters, me) => async (dispatch) => {
  // dispatch(addLoading());
  parameters = {
    ...parameters,
    limit: parameters.limit || 20,
    pageSize: parameters.pageSize || 10,
    total: parameters.total || 0,
    page: parameters.page || 1,
    orderBy: 'createdAt',
    desc: true,
    ...(parameters.profileType && { profileType: parameters.profileType }),
    ...(parameters.profileTypeList && { profileTypeList: parameters.profileTypeList }),
    ...(me && me.profileType !== ProfileType.ADMIN && { companyId: me.companyId }),
  };

  try {
    dispatch({
      type: ACTION_SAVE_USERS_FOR_PUSH_PAGINATED,
      payload: null,
    });
    const usersPaginated = await PushNotificationRequests.getUsersForPushPaginated(parameters);
    dispatch({
      type: ACTION_SAVE_USERS_FOR_PUSH_PAGINATED,
      payload: usersPaginated,
    });
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    // dispatch(removeLoading());
  }
};

export const getCondominiumsForPushNotification = (parameters, me) => async (dispatch) => {
  dispatch(addLoading());
  parameters = {
    ...parameters,
    limit: parameters.limit || 20,
    pageSize: parameters.pageSize || 10,
    total: parameters.total || 0,
    page: parameters.page || 1,
    orderBy: 'createdAt',
    desc: true,
    ...(parameters.profileType && { profileType: parameters.profileType }),
    ...(parameters.profileTypeList && { profileTypeList: parameters.profileTypeList }),
    ...(me && me.profileType !== ProfileType.ADMIN && { companyId: me.companyId }),
  };

  try {
    dispatch({
      type: ACTION_SAVE_CONDOMINIUMS_FOR_PUSH_PAGINATED,
      payload: null,
    });
    const condominiumsPaginated = await PushNotificationRequests.getCondominiumsForPushPaginated(parameters);
    dispatch({
      type: ACTION_SAVE_CONDOMINIUMS_FOR_PUSH_PAGINATED,
      payload: condominiumsPaginated,
    });
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const createNotification = (notification, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await PushNotificationRequests.createNotification(notification);

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};
