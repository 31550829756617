import React from 'react';
import {
  Radio,
} from 'antd';

const AdvancedRadioGroup = ({
  label, options, onChange, value,
}) => (
  <div className="advanced-input">
    <label className="advanced-input__label">
      <span className="advanced-input__label__inner">{label}</span>
      <Radio.Group
        onChange={onChange}
        defaultValue={value}
      >
        {options && options.map((i) => (
          <Radio.Button
            value={i.id}
            key={i.id}
          >
            {i.name}
          </Radio.Button>
        ))}
      </Radio.Group>
    </label>
  </div>
);

export default AdvancedRadioGroup;
