import { Translate } from 'react-redux-i18n';
import React from 'react';
import { Tag } from 'antd';

const VisitIsEmployee = {
  YES: true,
  NO: false,
};

export const VisitStatusTypeArray = [
  {
    id: VisitIsEmployee.YES,
    name: <Translate value="enum.visitIsEmployee.yes" />,
    tag: <Tag color="green"><Translate value="enum.visitIsEmployee.yes" /></Tag>,
  },
  {
    id: VisitIsEmployee.NO,
    name: <Translate value="enum.visitIsEmployee.no" />,
    tag: <Tag color="red"><Translate value="enum.visitIsEmployee.no" /></Tag>,
  },
];

export const GetVisitIsEmployeeTitle = (value) => (VisitStatusTypeArray.find((o) => o.id === value).name);
export const GetVisitIsEmployeeTag = (value) => (VisitStatusTypeArray.find((o) => o.id === value).tag);

export default VisitIsEmployee;
