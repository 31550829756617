import React from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  message,
  Divider,
  Checkbox,
} from 'antd';
import * as yup from 'yup';
import { I18n } from 'react-redux-i18n';
import { SaveOutlined } from '@ant-design/icons';

import { EstablishmentActions } from '../../redux/actions';
import { UserSelectors, AuthSelectors, LoadingSelectors } from '../../redux/reducers';

import { ViewType } from '../../enum/viewType';
import { validateEmail } from '../../utils/string';
import { ProfileType } from '../../enum/profileType';

import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedAutoCompleteInput from '../../../components/shared/AdvancedAutoCompleteInput/AdvancedAutoCompleteInput';

class EstablishmentAdminForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changePasswordVisible: false,
      form: {
        name: '',
        email: '',
        password: null,
        passwordConfirmation: null,
        profileType: ProfileType.ESTABLISHMENT_ADMIN,
        establishmentId: '',
      },
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps() {
    const {
      details,
      id,
    } = this.props;

    if (details && id !== 'add') {
      this.setState({
        changePasswordVisible: false,
        form: {
          name: details && details.name,
          email: details && details.email,
          password: null,
          passwordConfirmation: null,
          establishmentId: details && details.establishmentId,
        },
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const { form } = this.state;
    const { viewType } = this.props;

    const schema = yup.object().shape({
      name: yup.string().required(I18n.t('shared.errors.missing_name')),
      email: yup.string().required(I18n.t('shared.errors.missing_email')),
      establishmentId: yup.string().required(I18n.t('shared.errors.missing_establishmentId')),
    });

    schema.validate(form, { abortEarly: true })
      .then(() => {
        const { details, submitFunction } = this.props;

        if (!validateEmail(form.email)) {
          return message.error(I18n.t('shared.errors.invalid_email'));
        }

        if (viewType === ViewType.ADD || this.state.changePasswordVisible) {
          if (!(form.password && form.passwordConfirmation)) {
            return message.error(I18n.t('shared.errors.passwordsRequired'));
          }
          if (form.password !== form.passwordConfirmation) {
            return message.error(I18n.t('shared.errors.passwordsMismatch'));
          }
        }

        if (viewType === ViewType.EDIT && !this.state.changePasswordVisible) {
          delete form.password;
          delete form.passwordConfirmation;
        } else if (viewType === ViewType.EDIT) delete form.passwordConfirmation;

        if (details) {
          submitFunction(details.id, form);
        } else {
          submitFunction(null, form);
        }
      })
      .catch((err) => message.error(err.message || I18n.t('shared.genericError')));
  }

  fieldChange(name, value) {
    const { form } = this.state;
    form[name] = value || '';
    this.setState({ form });
  }

  render() {
    const {
      loading,
      viewType,
    } = this.props;

    const {
      form,
      changePasswordVisible,
    } = this.state;

    return (
      <Row>
        {loading === 0 && (
          <Col>
            <form
              name="form"
              onSubmit={(ev) => this.onSubmit(ev)}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.users.items.establishmentAdmin.details.form.email.label')}
                    value={form && form.email}
                    onChange={(val) => this.fieldChange('email', val)}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>
                <Col span={12}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.users.items.establishmentAdmin.details.form.name.label')}
                    value={form && form.name}
                    onChange={(val) => this.fieldChange('name', val)}
                  />
                </Col>
              </Row>

              {viewType === ViewType.EDIT && (
                <Row
                  gutter={16}
                  className="form-password-divider"
                >
                  <Col span={24}>
                    <Checkbox
                      onChange={(e) => this.setState({ changePasswordVisible: e.target.checked })}
                    >
                      {I18n.t('routes.panel.users.items.establishmentAdmin.details.changePasswordPhrase')}
                    </Checkbox>
                  </Col>
                </Row>
              )}

              {(viewType === ViewType.ADD || changePasswordVisible) && (
                <Row gutter={16}>
                  <Col span={12}>
                    <AdvancedInput
                      label={I18n.t('routes.panel.users.items.establishmentAdmin.details.form.password.label')}
                      value={form && form.password}
                      onChange={(val) => this.fieldChange('password', val)}
                      isPassword
                    />
                  </Col>
                  <Col span={12}>
                    <AdvancedInput
                      label={I18n.t('routes.panel.users.items.establishmentAdmin.details.form.passwordConfirmation.label')}
                      value={form && form.passwordConfirmation}
                      onChange={(val) => this.fieldChange('passwordConfirmation', val)}
                      isPassword
                    />
                  </Col>
                </Row>
              )}

              <Row gutter={16}>
                <Col span={12}>
                  <AdvancedAutoCompleteInput
                    searchMethod={(parameters) => this.props.getEstablishmentPaginatedForSearch(parameters)}
                    data={I18n.t('advancedAutoComplete.establishment')}
                    onSelect={(item) => this.fieldChange('establishmentId', item.id)}
                    onSelectSelectable={(id) => this.fieldChange('establishmentId', id)}
                    value={form && form.establishmentId}
                    disableSearch
                  />
                </Col>
              </Row>

              <Divider />

              <Row>
                <Col
                  span={24}
                  className="text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.backButtonText')}
                    href={I18n.t('routes.panel.users.items.establishmentAdmin.url')}
                  />

                  <Divider
                    className="form-button-divider"
                    type="vertical"
                  />

                  <AdvancedButton
                    htmlType="submit"
                    text={I18n.t('shared.saveButtonText')}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </form>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  details: UserSelectors.getDetails(state),
});

const mapDispatchToProps = (dispatch) => ({
  getEstablishmentPaginatedForSearch: () => dispatch(EstablishmentActions.getPaginatedForSearch()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EstablishmentAdminForm);
