/* eslint-disable react/sort-comp */
import React from 'react';
import moment from 'moment-timezone';
import { Divider, Modal } from 'antd';
import { ArrowRightOutlined, SearchOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';

import AdvancedButton from '../AdvancedButton';
import AdvancedInput from '../AdvancedInput';
import AdvancedDatePicker from '../AdvancedDatePicker';
import { FieldType } from '../../../app/enum/fieldType';
import SearchDataTable from '../SearchDataTable';
import { LoadingSearchSelectors } from '../../../app/redux/reducers';
import AdvancedSelectAutoComplete from '../AdvancedSelectAutoComplete';
import AdvancedSelect from '../AdvancedSelect/AdvancedSelect';

export const defaultValues = {
  pageSize: 10,
  total: 0,
  page: 1,
  data: null,
};

class AdvancedAutoCompleteInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      activeFilters: {},
      options: null,
      optionsSelectable: [],
      selectedRows: null,
      selectedValue: null,
    };
    this.dataTableRefSearch = React.createRef();
  }

  async componentDidMount() {
    const { hideInput, defaultAdvancedSearch } = this.props;

    if (defaultAdvancedSearch) {
      this.setState({ activeFilters: defaultAdvancedSearch });
    }

    if (hideInput) {
      await this.toggleModalVisibility(true);
    }

    await this.handleSearchSelectable();
  }

  onSelect(selectedValue) {
    const { onSelect } = this.props;

    const { modalVisible, optionsSelectable } = this.state;
    if (modalVisible) {
      this.toggleModalVisibility();
    }

    const optionsSelectableUpdated = optionsSelectable;
    optionsSelectableUpdated.push(selectedValue);

    this.setState({
      selectedValue: selectedValue.id,
      optionsSelectable: optionsSelectableUpdated,
    });

    onSelect(selectedValue);
  }

  async onChangeAdvancedFilter(name, value) {
    const { activeFilters } = this.state;

    if (value) {
      activeFilters[name] = value;
    } else {
      delete activeFilters[name];
    }

    this.setState({ activeFilters });
  }

  async handleSearch(e) {
    if (e) {
      e.preventDefault();
    }
    const { searchMethod } = this.props;
    const { activeFilters } = this.state;
    const options = await searchMethod({
      ...defaultValues,
      ...activeFilters,
    });
    this.setState({ options });
  }

  onSelectRow(selectedRows) {
    this.setState({ selectedRows });
  }

  async toggleModalVisibility(value) {
    const { modalVisible } = this.state;
    const { onClose } = this.props;

    if (value === 'toggleClose') {
      this.setState({ modalVisible: false });
      if (onClose) {
        onClose();
      }
    }

    this.setState({
      modalVisible: !modalVisible,
    }, async () => {
      if (this.state.modalVisible) {
        const { searchMethod } = this.props;
        const { activeFilters } = this.state;
        const options = await searchMethod({
          ...defaultValues,
          ...activeFilters,
        });
        this.setState({ options });
      }
    });
  }

  renderAdvancedFilterInput(key, filters) {
    const { activeFilters } = this.state;
    const { selectables } = this.props;

    switch (filters[key].type) {
      case FieldType.DATE_PICKER:
        return (
          <AdvancedDatePicker
            onChange={(value) => this.onChangeAdvancedFilter(key, value ? moment(value).format() : null)}
            value={activeFilters[key] ? moment(activeFilters[key]) : null}
            label={filters[key].label}
          />
        );

      case FieldType.TEXT:
        return (
          <AdvancedInput
            onChange={(value) => this.onChangeAdvancedFilter(key, value)}
            value={activeFilters[key]}
            label={filters[key].label}
          />
        );

      case FieldType.SELECTABLE:
        return (
          <AdvancedSelect
            onChange={(value) => this.onChangeAdvancedFilter(key, value)}
            value={activeFilters[key]}
            label={filters[key].label}
            options={selectables[key]}
          />
        );

      default:
        return (
          <AdvancedInput
            onChange={(value) => this.onChangeAdvancedFilter(key, value)}
            value={activeFilters[key]}
            label={filters[key].label}
          />
        );
    }
  }

  parseRows(rows, data) {
    const { actions, formatters, disableSingleSelector } = this.props;

    let result = rows.map((row) => ({
      key: row,
      title: data.modal.dataTable[row].label,
      row: data.modal.dataTable[row],
    }));

    if (formatters) {
      result = result.map((o) => ({
        ...o,
        render: formatters[o.key],
      }));
    }

    if (!disableSingleSelector) {
      result.push({
        key: Math.random().toString(36),
        render: (value, row) => (
          <div className="action-column text-right">
            {actions && actions.length > 0 ? (
              <div>
                {actions.map((a, aIndex) => (
                  <a
                    key={aIndex.toString()}
                    type="link"
                    rel="noreferrer noopener"
                    target="_blank"
                    href={a.isId ? `${a.url}${row.id}` : a.url}
                  >
                    {a.label}
                  </a>
                ))}
              </div>
            ) : (
              <div className="action-column">
                <AdvancedButton
                  type="link"
                  icon={<ArrowRightOutlined />}
                  onClick={() => this.onSelect(row)}
                />
              </div>
            )}
          </div>
        ),
      });
    }

    return result;
  }

  async handleSearchSelectable(value) {
    const { searchMethod, hideInput } = this.props;
    if (!hideInput) {
      const optionsSelectable = await searchMethod({ name: value });
      if (optionsSelectable && optionsSelectable.rows && optionsSelectable.rows.length > 0) {
        this.setState({ optionsSelectable: optionsSelectable.rows });
      }
    }
  }

  onSelectSelectable(selectedValue) {
    if (this.props.onSelectSelectable) {
      this.props.onSelectSelectable(selectedValue);
    }
    this.setState({ selectedValue });
  }

  hasFooter() {
    const { multiple } = this.props;

    if (!multiple) {
      return {
        footer: null,
      };
    }
  }

  render() {
    const {
      placeholder,
      value,
      disabled,
      data,
      searchMethod,
      loadingSearch,
      multiple,
      hideInput,
      disableSearch,
      onSelect,
      disableButton,
    } = this.props;

    const {
      modalVisible,
      optionsSelectable,
      activeFilters,
      selectedValue,
      selectedRows,
    } = this.state;

    const advancedSearch = data.modal.advancedSearch ? Object.keys(data.modal.advancedSearch) : null;
    const dataTableRows = Object.keys(data.modal.dataTable);

    return (
      <div className={`advanced-auto-complete-input ${hideInput ? 'pb-0' : ''}`}>

        <Modal
          className="advanced-auto-complete-input__modal"
          title={data.modal.title}
          visible={modalVisible}
          onOk={() => onSelect(selectedRows)}
          onCancel={() => this.toggleModalVisibility('toggleClose')}
          cancelText={I18n.t('shared.cancel')}
          okText={I18n.t('shared.useValues')}
          width="75vw"
          {...this.hasFooter()}
        >
          <div>
            {!disableSearch && (
              <div className="advanced-auto-complete-input__modal__search">
                <Search
                  onChange={(ev) => this.onChangeAdvancedFilter('searchInput', ev.target.value)}
                  placeholder={I18n.t('shared.searchSomething')}
                  autoFocus
                />
              </div>
            )}

            {advancedSearch && (
              <div className="advanced-auto-complete-input__modal__advanced-filters">
                <div className="row">
                  {advancedSearch && advancedSearch.map((key, index) => (
                    <div
                      className="col-4"
                      key={index.toString()}
                    >
                      {this.renderAdvancedFilterInput(key, data.modal.advancedSearch)}
                    </div>
                  ))}
                </div>
                <div className="row">
                  <div className="col-12 text-right">
                    <AdvancedButton
                      text={I18n.t('shared.search')}
                      icon={<SearchOutlined />}
                      htmlType="button"
                      onClick={() => this.handleSearch()}
                    />
                    <Divider />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="advanced-auto-complete-input__modal__list">
            <SearchDataTable
              getMethod={(parameters, callback) => searchMethod(parameters, callback)}
              data={this.state.options}
              activeFilters={activeFilters}
              ref={this.dataTableRefSearch}
              loading={loadingSearch > 0}
              onSelect={(id) => this.onSelect(id)}
              onSelectRow={(val) => this.onSelectRow(val)}
              columns={this.parseRows(dataTableRows, data)}
              multiple={multiple || false}
              hideInput={hideInput || undefined}
            />
          </div>
        </Modal>

        {!hideInput && (
          <label className="advanced-auto-complete-input__label">
            <AdvancedSelectAutoComplete
              options={optionsSelectable}
              onChange={(ev) => this.onSelectSelectable(ev)}
              onSearch={(val) => this.handleSearchSelectable(val)}
              label={data.inputLabel}
              value={selectedValue || (optionsSelectable && optionsSelectable.length > 0 ? value : null)}
              disabled={disabled}
              placeholder={placeholder}
              onFocus={() => this.handleSearchSelectable()}
              hasValue
            />
            <span className="advanced-auto-complete-input__label__modal-toggle">
              <AdvancedButton
                className="advanced-auto-complete-input__label__modal-toggle__button"
                onClick={() => this.toggleModalVisibility()}
                icon={<SearchOutlined />}
                disabled={disableButton}
              />
            </span>
          </label>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loadingSearch: LoadingSearchSelectors.getLoadingSearch(state),
});

export default connect(
  mapStateToProps,
  null,
)(AdvancedAutoCompleteInput);
