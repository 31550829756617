import { Redirect, Router } from '@reach/router';
import React from 'react';
import { I18n } from 'react-redux-i18n';

import Panel from './Panel';
import Public from './Public';

import Login from '../../containers/login';
import ForgotPassword from '../../containers/forgotPassword';
import ForgotPasswordCode from '../../containers/forgotPasswordCode';
// USERS
import AdministratorList from '../../containers/panel/users/administrator/list';
import AdministratorDetails from '../../containers/panel/users/administrator/details';
import EstablishmentAdminList from '../../containers/panel/users/establishmentAdmin/list';
import EstablishmentAdminDetails from '../../containers/panel/users/establishmentAdmin/details';
import DeliveryManList from '../../containers/panel/users/deliveryman/list';
import DeliveryManDetails from '../../containers/panel/users/deliveryman/details';
import DoormanList from '../../containers/panel/users/doorman/list';
import DoormanDetails from '../../containers/panel/users/doorman/details';
import CondominiumManagerList from '../../containers/panel/users/condominiumManager/list';
import CondominiumManagerDetails from '../../containers/panel/users/condominiumManager/details';
import DwellerList from '../../containers/panel/users/dweller/list';
import PushNotificationList from '../../containers/panel/pushNotification/list';
import PushNotificationDetails from '../../containers/panel/pushNotification/details/PushNotificationDetails';

// ESTABLISHMENT
import CategoryList from '../../containers/panel/establishment/category/list';
import CategoryDetails from '../../containers/panel/establishment/category/details';
import EstablishmentList from '../../containers/panel/establishment/establishment/list';
import EstablishmentDetails from '../../containers/panel/establishment/establishment/details';
import TransactionList from '../../containers/panel/establishment/transaction/list';
import TransactionDetails from '../../containers/panel/establishment/transaction/details';

// CONDOMINIUM
import VisitsList from '../../containers/panel/condominium/visits/list';
import OrderList from '../../containers/panel/condominium/order/list';
import GoverningBody from '../../containers/panel/condominium/governingBody';
import SolicitationsList from '../../containers/panel/condominium/solicitations/list';
import CondominiumList from '../../containers/panel/condominium/condominium/list';
import CondominiumDetails from '../../containers/panel/condominium/condominium/details';
import CondominiumAdministratorList from '../../containers/panel/condominium/condominiumAdministrator/list';
import CondominiumAdministratorDetails from '../../containers/panel/condominium/condominiumAdministrator/details';

// RECREATION
import RecreationCategoryList from '../../containers/panel/condominium/recreationArea/categoryList/RecreationCategoryList';
import RecreationReport from '../../containers/panel/condominium/recreationArea/report/RecreationAreaReport';
import RecreationAreaList from '../../containers/panel/condominium/recreationArea/list/RecreationAreaList';
import RecreationAreaDetails from '../../containers/panel/condominium/recreationArea/details/RecreationAreaDetails';

// COMMUNICATION
import ChannelList from '../../containers/panel/communication/channel/list';
import ChannelDetails from '../../containers/panel/communication/channel/details';
import MessageList from '../../containers/panel/communication/message/list';
import MessageDetails from '../../containers/panel/communication/message/details';

// SETTINGS
// import Platform from '../../containers/panel/settings/platform';

// DEEP_LINK
import DeepLink from '../../containers/deepLink/DeepLink';

class Content extends React.PureComponent {
  render() {
    return (
      <Router>
        {/* PUBLIC */}
        <Public
          path={I18n.t('routes.login.url')}
          container={<Login />}
          title={`${I18n.t('routes.login.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Public
          path={I18n.t('routes.forgotPassword.url')}
          container={<ForgotPassword />}
          title={`${I18n.t('routes.forgotPassword.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Public
          path={I18n.t('routes.forgotPasswordCode.url')}
          container={<ForgotPasswordCode />}
          title={`${I18n.t('routes.forgotPasswordCode.pageTitle')} - ${I18n.t('application.name')}`}
        />

        {/* PUBLIC - DEEP_LINK */}
        <Public
          path={`${I18n.t('routes.deepLink.url')}/:id`}
          container={<DeepLink />}
          title={`${I18n.t('routes.deepLink.pageTitle')} - ${I18n.t('application.name')}`}
        />

        {/* PANEL - USERS */}

        <Panel
          path={I18n.t('routes.panel.users.items.administrator.url')}
          container={<AdministratorList />}
          title={`${I18n.t('routes.panel.users.items.administrator.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.users.items.administrator.url')}/:id`}
          container={<AdministratorDetails />}
          title={`${I18n.t('routes.panel.users.items.administrator.pageTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          path={I18n.t('routes.panel.users.items.condominiumManager.url')}
          container={<CondominiumManagerList />}
          title={`${I18n.t('routes.panel.users.items.condominiumManager.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.users.items.condominiumManager.url')}/:id`}
          container={<CondominiumManagerDetails />}
          title={`${I18n.t('routes.panel.users.items.condominiumManager.pageTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          path={I18n.t('routes.panel.users.items.doorman.url')}
          container={<DoormanList />}
          title={`${I18n.t('routes.panel.users.items.doorman.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.users.items.doorman.url')}/:id`}
          container={<DoormanDetails />}
          title={`${I18n.t('routes.panel.users.items.doorman.pageTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          path={I18n.t('routes.panel.users.items.dweller.url')}
          container={<DwellerList />}
          title={`${I18n.t('routes.panel.users.items.dweller.pageTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          path={I18n.t('routes.panel.users.items.establishmentAdmin.url')}
          container={<EstablishmentAdminList />}
          title={`${I18n.t('routes.panel.users.items.establishmentAdmin.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.users.items.establishmentAdmin.url')}/:id`}
          container={<EstablishmentAdminDetails />}
          title={`${I18n.t('routes.panel.users.items.establishmentAdmin.pageTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          path={I18n.t('routes.panel.users.items.deliveryman.url')}
          container={<DeliveryManList />}
          title={`${I18n.t('routes.panel.users.items.deliveryman.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.users.items.deliveryman.url')}/:id`}
          container={<DeliveryManDetails />}
          title={`${I18n.t('routes.panel.users.items.deliveryman.pageTitle')} - ${I18n.t('application.name')}`}
        />

        {/* PANEL - NOTIFICATION */}

        <Panel
          path={I18n.t('routes.panel.pushNotification.items.send.url')}
          container={<PushNotificationDetails />}
          title={`${I18n.t('routes.panel.pushNotification.items.send.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={I18n.t('routes.panel.pushNotification.items.report.url')}
          container={<PushNotificationList />}
          title={`${I18n.t('routes.panel.pushNotification.items.report.pageTitle')} - ${I18n.t('application.name')}`}
        />

        {/* PANEL - ESTABLISHMENT */}

        <Panel
          path={I18n.t('routes.panel.establishment.items.establishment.url')}
          container={<EstablishmentList />}
          title={`${I18n.t('routes.panel.establishment.items.establishment.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.establishment.items.myEstablishment.url')}`}
          container={<EstablishmentDetails />}
          title={`${I18n.t('routes.panel.establishment.items.myEstablishment.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.establishment.items.establishment.url')}/:id`}
          container={<EstablishmentDetails />}
          title={`${I18n.t('routes.panel.establishment.items.establishment.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />

        <Panel
          path={I18n.t('routes.panel.establishment.items.category.url')}
          container={<CategoryList />}
          title={`${I18n.t('routes.panel.establishment.items.category.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.establishment.items.category.url')}/:id`}
          container={<CategoryDetails />}
          title={`${I18n.t('routes.panel.establishment.items.category.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={I18n.t('routes.panel.establishment.items.transaction.url')}
          container={<TransactionList />}
          title={`${I18n.t('routes.panel.establishment.items.transaction.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.establishment.items.transaction.url')}/:id`}
          container={<TransactionDetails />}
          title={`${I18n.t('routes.panel.establishment.items.transaction.pageTitle')} - ${I18n.t('application.name')}`}
        />

        {/* PANEL - CONDOMINIUM */}

        <Panel
          path={I18n.t('routes.panel.condominium.items.condominium.url')}
          container={<CondominiumList />}
          title={`${I18n.t('routes.panel.condominium.items.condominium.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.condominium.items.condominium.url')}/:id`}
          container={<CondominiumDetails />}
          title={`${I18n.t('routes.panel.condominium.items.condominium.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.condominium.items.myCondominium.url')}/:id`}
          container={<CondominiumDetails />}
          title={`${I18n.t('routes.panel.condominium.items.myCondominium.pageTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          path={I18n.t('routes.panel.condominium.items.transaction.url')}
          container={<TransactionList />}
          title={`${I18n.t('routes.panel.condominium.items.transaction.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.condominium.items.transaction.url')}/:id`}
          container={<TransactionDetails />}
          title={`${I18n.t('routes.panel.condominium.items.transaction.pageTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          path={I18n.t('routes.panel.condominium.items.condominiumAdministrator.url')}
          container={<CondominiumAdministratorList />}
          title={`${I18n.t('routes.panel.condominium.items.condominiumAdministrator.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.condominium.items.condominiumAdministrator.url')}/:id`}
          container={<CondominiumAdministratorDetails />}
          title={`${I18n.t('routes.panel.condominium.items.condominiumAdministrator.pageTitle')} - ${I18n.t('application.name')}`}
        />

        <Panel
          path={`${I18n.t('routes.panel.condominium.items.governingBody.url')}/:id`}
          container={<GoverningBody />}
          title={`${I18n.t('routes.panel.condominium.items.governingBody.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={I18n.t('routes.panel.condominium.items.visits.url')}
          container={<VisitsList />}
          title={`${I18n.t('routes.panel.condominium.items.visits.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={I18n.t('routes.panel.condominium.items.order.url')}
          container={<OrderList />}
          title={`${I18n.t('routes.panel.condominium.items.order.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={I18n.t('routes.panel.condominium.items.solicitations.url')}
          container={<SolicitationsList />}
          title={`${I18n.t('routes.panel.condominium.items.solicitations.pageTitle')} - ${I18n.t('application.name')}`}
        />

        {/* PANEL - RECREATION */}

        <Panel
          path={I18n.t('routes.panel.schedule.items.recreationArea.url')}
          container={<RecreationCategoryList />}
          title={`${I18n.t('routes.panel.schedule.items.recreationArea.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.schedule.items.recreationArea.url')}/:id`}
          container={<RecreationAreaList />}
          title={`${I18n.t('routes.panel.schedule.items.recreationArea.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.schedule.items.recreationArea.url')}/:id/:areaId`}
          container={<RecreationAreaDetails />}
          title={`${I18n.t('routes.panel.schedule.items.recreationArea.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={I18n.t('routes.panel.schedule.items.recreationAreaReport.url')}
          container={<RecreationReport />}
          title={`${I18n.t('routes.panel.schedule.items.recreationAreaReport.pageTitle')} - ${I18n.t('application.name')}`}
        />

        {/* PANEL - COMMUNICATION */}

        <Panel
          path={I18n.t('routes.panel.communication.items.channel.url')}
          container={<ChannelList />}
          title={`${I18n.t('routes.panel.communication.items.channel.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.communication.items.channel.url')}/:id`}
          container={<ChannelDetails />}
          title={`${I18n.t('routes.panel.communication.items.channel.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={I18n.t('routes.panel.communication.items.message.url')}
          container={<MessageList />}
          title={`${I18n.t('routes.panel.communication.items.message.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.communication.items.message.url')}/:id`}
          container={<MessageDetails />}
          title={`${I18n.t('routes.panel.communication.items.message.pageTitle')} - ${I18n.t('application.name')}`}
        />

        {/* PANEL - SETTINGS */}
        {/* <Panel
          path={I18n.t('routes.panel.settings.items.platform.url')}
          container={<Platform />}
          title={`${I18n.t('routes.panel.settings.items.platform.pageTitle')} - ${I18n.t('application.name')}`}
        /> */}

        {/* ERROR 404 */}
        <Redirect
          from={I18n.t('routes.panel.url')}
          to={I18n.t('routes.login.url')}
        />
      </Router>
    );
  }
}

export default Content;
