import React from 'react';
import { TimePicker } from 'antd';
import { I18n } from 'react-redux-i18n';

const format = 'HH:mm';

class AdvancedTimePicker extends React.PureComponent {
  render() {
    const {
      label,
      placeholder,
      onChange,
      value,
      disabled,
      minuteStep,
    } = this.props;
    return (
      <div className="advanced-date-picker">
        <label className="advanced-date-picker__label">
          <span className="advanced-date-picker__label__inner">{label}</span>
          <TimePicker
            className="advanced-date-picker__label__input"
            onChange={onChange}
            placeholder={placeholder || I18n.t('shared.selectSomeValue')}
            value={value || undefined}
            disabled={disabled || false}
            format={format}
            minuteStep={minuteStep || 1}
          />
        </label>
      </div>
    );
  }
}

export default AdvancedTimePicker;
