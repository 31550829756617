import React from 'react';
import {
  Breadcrumb, Col, Icon, Layout, Row, Spin,
} from 'antd';
import { connect } from 'react-redux';
import { Link } from '@reach/router';
import { I18n } from 'react-redux-i18n';

import { PushNotificationActions } from '../../../../app/redux/actions';
import { LoadingSelectors } from '../../../../app/redux/reducers';

import PushNotificationForm from '../../../../app/forms/pushNotification/PushNotificationForm';

class PushNotificationDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { Content } = Layout;
    const { loading } = this.props;

    return (
      <div>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" />
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={I18n.t('routes.panel.pushNotification.items.send.urlReturn')}>
                <Icon type={I18n.t('routes.panel.pushNotification.items.send.icon')} />{' '}
                <span>{I18n.t('routes.panel.pushNotification.items.send.pageTitle')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>
                {I18n.t('routes.panel.pushNotification.items.send.pageTitleAdd')}
              </span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content">
          <div>
            <Spin
              spinning={loading > 0}
              tip={I18n.t('shared.loading')}
            >
              <div>
                <Row>
                  <Col>
                    <PushNotificationForm />
                  </Col>
                </Row>
              </div>
            </Spin>
          </div>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getDetails: (id) => dispatch(PushNotificationActions.getDetails(id)),
  update: (id, data, callback) => dispatch(PushNotificationActions.update(id, data, callback)),
  create: (data, callback) => dispatch(PushNotificationActions.create(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PushNotificationDetails);
