import { Translate } from 'react-redux-i18n';
import React from 'react';
import { Tag } from 'antd';

export const CondominiumUserStatusType = {
  PENDING: 1,
  APPROVED: 2,
  REFUSED: 3,
};

export const CondominiumUserStatusTypeArray = [
  {
    id: CondominiumUserStatusType.PENDING,
    name: <Translate value="enum.condominiumUserStatusType.pending" />,
    tag: <Tag color="gold"><Translate value="enum.condominiumUserStatusType.pending" /></Tag>,
  },
  {
    id: CondominiumUserStatusType.APPROVED,
    name: <Translate value="enum.condominiumUserStatusType.approved" />,
    tag: <Tag color="green"><Translate value="enum.condominiumUserStatusType.approved" /></Tag>,
  },
  {
    id: CondominiumUserStatusType.REFUSED,
    name: <Translate value="enum.condominiumUserStatusType.refused" />,
    tag: <Tag color="red"><Translate value="enum.condominiumUserStatusType.refused" /></Tag>,
  },
];

export const GetCondominiumUserStatusTypeTitle = (value) => (CondominiumUserStatusTypeArray.find((o) => o.id === value).tag);

export default CondominiumUserStatusType;
