import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import moment from 'moment-timezone';
import {
  addLoading,
  removeLoading,
} from './loading';
import VisitRequests from '../../api/visit';
import { ProfileType } from '../../enum/profileType';
import { addLoadingSearch, removeLoadingSearch } from './loading-search';
import { isAdmin, isCondominiumManager } from '../../utils/permissions';

export const ACTION_SAVE_VISIT_PAGINATED = 'ACTION_SAVE_VISIT_PAGINATED';
export const ACTION_SAVE_VISIT_DETAILS = 'ACTION_SAVE_VISIT_DETAILS';

export const ACTION_CLEAN_VISIT = 'ACTION_CLEAN_VISIT';

// cleaners
export const clean = () => ({
  type: ACTION_CLEAN_VISIT,
  payload: null,
});

export const cleanDetails = () => ({
  type: ACTION_SAVE_VISIT_DETAILS,
  payload: null,
});

export const cleanPaginated = () => ({
  type: ACTION_SAVE_VISIT_PAGINATED,
  payload: null,
});

/*
 * BASIC CRUD METHODS
 *
 * getPaginated: get rows for data table
 * getDetails: get details (get by id)
 * update: the PUT method
 * create: the POST method
 */

export const getPaginated = (parameters, callback) => async (dispatch, getState) => {
  dispatch(addLoading());
  dispatch(cleanPaginated());

  try {
    const { me } = getState().auth;

    parameters = {
      ...parameters,
      limit: parameters.limit || 10,
      total: parameters.total || 0,
      page: parameters.page || 1,
      orderBy: 'visitDate',
      isDESC: true,
      ...(parameters.visitDate && { visitDate: `${moment(parameters.visitDate[0]).format()},${moment(parameters.visitDate[1]).format()}` }),
    };

    if (parameters.condominiumId && !isAdmin(me)) {
      delete parameters.condominiumId;
    }

    if (isCondominiumManager(me) && me.condominiumId) {
      parameters = {
        ...parameters,
        condominiumId: me.condominiumId,
      };
    }

    dispatch({
      type: ACTION_SAVE_VISIT_PAGINATED,
      payload: null,
    });

    const response = await VisitRequests.getPaginated(parameters);

    dispatch({
      type: ACTION_SAVE_VISIT_PAGINATED,
      payload: response,
    });

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const getDetails = (id, callback) => async (dispatch) => {
  dispatch(addLoading());
  dispatch(cleanDetails());

  try {
    let response = null;
    dispatch({
      type: ACTION_SAVE_VISIT_DETAILS,
      payload: null,
    });

    if (id !== 'add') {
      response = await VisitRequests.getDetails(id);
      dispatch({
        type: ACTION_SAVE_VISIT_DETAILS,
        payload: response,
      });
    }

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const remove = (id, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const response = await VisitRequests.remove(id);
    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

/*
 * ADVANCED METHODS
 *
 * getPaginatedForSearch: get rows for <AdvancedAutoCompleteInput /> component
 */

export const getPaginatedForSearch = (parameters, callback) => async (dispatch) => {
  let response = null;
  dispatch(addLoadingSearch());

  try {
    parameters = {
      ...parameters,
      limit: parameters ? parameters.limit : 10,
      total: parameters ? parameters.total : 0,
      page: parameters ? parameters.page : 1,
      orderBy: 'createdAt',
      isDESC: true,
      profileType: ProfileType.ADMIN,
    };

    response = await VisitRequests.getPaginated(parameters);

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoadingSearch());
  }

  return response;
};
