import { message } from 'antd';
import { I18n } from 'react-redux-i18n';

const handleResponseError = (err) => {
  const errors = I18n.t('routes.panel.users.items.administrator.details.errors');
  if (err && err.response && err.response.isSDK) {
    if (err.response.response.error === 'email_already_used') {
      message.error(I18n.t('routes.panel.users.items.administrator.details.errors.email_already_registered'));
    } else if (err.response.response.error === 'cellphone_already_used') {
      message.error(I18n.t('routes.panel.users.items.administrator.details.errors.cellphone_already_used'));
    } else if (err.response.response.error === 'cellphone_already_registered') {
      message.error(I18n.t('routes.panel.users.items.administrator.details.errors.cellphone_already_used'));
    } else {
      message.error(I18n.t('shared.genericError'));
    }
  } else if (err && err.message && errors[err.message] !== undefined) {
    message.error(I18n.t(`routes.panel.users.items.administrator.details.errors.${err.message}`));
  } else {
    message.error(I18n.t('shared.genericError'));
  }
};

export default handleResponseError;
