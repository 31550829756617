import React from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  message,
  Divider,
  Radio,
  Table,
  Icon,
  Button,
  Modal,
} from 'antd';
import {
  SaveOutlined, QrcodeOutlined,
} from '@ant-design/icons';
import * as yup from 'yup';
import { I18n } from 'react-redux-i18n';
import moment from 'moment-timezone';
import { QRCode } from 'react-qr-svg';

import { CondominiumSelectors, AuthSelectors, LoadingSelectors } from '../../redux/reducers';
import { CondominiumAdministratorActions, UtilsActions } from '../../redux/actions';
import { CondominiumTypeArray } from '../../enum/condominiumType';
import { ContractStatusTypeArray } from '../../enum/contractStatusType';
import { ProfileType, GetProfileTypeTitle } from '../../enum/profileType';
import {
  AgencyTypeArray, AccountTypeArray, GetAgencyTypeAgencyMask, GetAgencyTypeAccountMask,
} from '../../enum/agencyType';

import { removeSpecialChars } from '../../utils/string';
import { formatCurrency } from '../../utils/currency';
import { isAdmin } from '../../utils/permissions';

import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedMaskedInput from '../../../components/shared/AdvancedMaskedInput/AdvancedMaskedInput';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedDatePicker from '../../../components/shared/AdvancedDatePicker';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import AdvancedAutoCompleteInput from '../../../components/shared/AdvancedAutoCompleteInput/AdvancedAutoCompleteInput';

const saveSvgAsPng = require('save-svg-as-png');

class CondominiumForm extends React.Component {
  constructor(props) {
    super(props);
    const { me } = this.props;
    this.state = {
      isGoverningBody: !isAdmin(me),
      showModalQrcode: false,
      form: {
        code: null,
        name: '',
        description: '',
        // condominiumManagerPhone: null,
        zipcode: '',
        neighborhood: '',
        street: '',
        city: '',
        uf: '',
        addressNumber: '',
        latitude: '',
        longitude: '',
        type: null,
        contractStartAt: null,
        contractEndAt: null,
        contractStatus: null,
        monthlyPayment: null,
        isCommercial: null,
        doc: '',
        phone: '',
        condominiumAdministratorId: '',
        bankCode: '',
        bankAgency: '',
        accountType: '',
        bankAccount: '',
      },
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps() {
    const {
      details,
      id,
    } = this.props;

    if (details && id !== 'add') {
      this.setState({
        form: {
          code: details.code,
          name: details.name,
          description: (details.subAccount && details.subAccount.businessDescription) || '',
          // condominiumManagerPhone: details.condominiumManagerPhone && details.condominiumManagerPhone.substr(2, details.condominiumManagerPhone.length),
          zipcode: details.zipcode,
          neighborhood: details.neighborhood,
          street: details.street,
          city: details.city,
          uf: details.uf,
          addressNumber: details.addressNumber,
          latitude: details.latitude,
          longitude: details.longitude,
          type: details.type,
          contractStartAt: moment(details.contractStartAt, 'YYYY-MM-DD'),
          contractEndAt: moment(details.contractEndAt, 'YYYY-MM-DD'),
          contractStatus: details.contractStatus,
          monthlyPayment: parseFloat(details.monthlyPayment),
          isCommercial: details.isCommercial,
          doc: details.doc,
          condominiumAdministratorId: details.condominiumAdministratorId,
          phone: (details.subAccount && details.subAccount.cellphone) || '',
          bankCode: (details.subAccount && details.subAccount.bankCode) || '',
          bankAgency: (details.subAccount && details.subAccount.bankAgency) || '',
          accountType: (details.subAccount && details.subAccount.accountType) || '',
          bankAccount: (details.subAccount && details.subAccount.bankAccount) || '',
        },
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const { form } = this.state;

    const schema = yup.object().shape({
      name: yup.string().required(I18n.t('shared.errors.missing_name')),
      description: yup.string().required(I18n.t('shared.errors.missing_description')),
      condominiumAdministratorId: yup.string().required(I18n.t('shared.errors.missing_condominiumAdministratorId')),
      zipcode: yup.string().required(I18n.t('shared.errors.missing_zipcode')),
      neighborhood: yup.string().required(I18n.t('shared.errors.missing_neighborhood')),
      street: yup.string().required(I18n.t('shared.errors.missing_street')),
      city: yup.string().required(I18n.t('shared.errors.missing_city')),
      uf: yup.string().required(I18n.t('shared.errors.missing_uf')),
      addressNumber: yup.string().required(I18n.t('shared.errors.missing_addressNumber')),
      latitude: yup.string().required(I18n.t('shared.errors.missing_latitude')),
      longitude: yup.string().required(I18n.t('shared.errors.missing_longitude')),
      doc: yup.string().required(I18n.t('shared.errors.missing_cnpj')),
      phone: yup.string().required(I18n.t('shared.errors.missing_cellphone')),
      bankAccount: yup.string().required(I18n.t('shared.errors.missing_bankAccount')),
      accountType: yup.string().required(I18n.t('shared.errors.missing_accountType')),
      bankAgency: yup.string().required(I18n.t('shared.errors.missing_bankAgency')),
      bankCode: yup.string().required(I18n.t('shared.errors.missing_bankCode')),
    });

    schema.validate(form, { abortEarly: true })
      .then(() => {
        const { details, submitFunction } = this.props;

        if (!(form.contractStartAt && form.contractEndAt)) return message.error(I18n.t('shared.errors.missing_contractData'));
        if (!(moment(form.contractStartAt).isValid() && moment(form.contractEndAt).isValid())) return message.error(I18n.t('shared.errors.missing_contractData'));
        if (moment(form.contractEndAt).isBefore(moment(form.contractStartAt))) return message.error(I18n.t('shared.errors.missing_contractData'));
        if (!form.contractStatus) return message.error(I18n.t('shared.errors.missing_contractData'));
        if (form.type === null) return message.error(I18n.t('shared.errors.missing_condominiumType'));
        if (form.isCommercial === null) return message.error(I18n.t('shared.errors.missing_isCommercial'));
        // if (!validateCnpj(form.doc)) return message.error(I18n.t('shared.errors.invalid_cnpj'));

        const data = {
          ...form,
          // ...(form.condominiumManagerPhone && { condominiumManagerPhone: `55${removeSpecialChars(form.condominiumManagerPhone)}` }),
          ...(form.doc && { doc: removeSpecialChars(form.doc) }),
          zipcode: removeSpecialChars(form.zipcode),
          contractStartAt: moment(form.contractStartAt).format('YYYY-MM-DD'),
          contractEndAt: moment(form.contractEndAt).format('YYYY-MM-DD'),
          monthlyPayment: form.monthlyPayment ? formatCurrency(form.monthlyPayment) : null,
          subAccount: {
            bankCode: form.bankCode,
            bankAgency: `${removeSpecialChars(form.bankAgency)}`,
            accountType: form.accountType,
            bankAccount: `${form.bankAccount}`,
            businessDescription: form.description,
            priceRange: 'Mais que R$ 500,00',
            responsibleSubAccount: {
              cellphone: `${removeSpecialChars(form.phone)}`,
              name: form.name,
              doc: removeSpecialChars(form.doc),
            },
          },
        };

        delete data.bankAccount;
        delete data.bankCode;
        delete data.bankAgency;
        delete data.accountType;
        delete data.phone;
        delete data.code;

        if (details) {
          submitFunction(details.id, data);
        } else {
          submitFunction(null, data);
        }
      })
      .catch((err) => message.error(err.message || I18n.t('shared.genericError')));
  }

  async getAddressByCep(cep) {
    const { getAddressByCep } = this.props;
    const completeAddress = await getAddressByCep(cep);
    if (!completeAddress.error) {
      this.fieldChange('uf', completeAddress.uf);
      this.fieldChange('city', completeAddress.localidade);
      this.fieldChange('street', completeAddress.logradouro);
      this.fieldChange('neighborhood', completeAddress.bairro);
      this.fieldChange('zipcode', completeAddress.cep);
    }
  }

  fieldChange(name, value) {
    const { form } = this.state;
    form[name] = value;
    this.setState({ form });
  }

  render() {
    const {
      loading, getCondominiumAdministratorPaginatedForSearch, details, id,
    } = this.props;
    const { form, isGoverningBody } = this.state;

    return (
      <Row>
        <Modal
          title="QR Code do condomínio"
          visible={this.state.showModalQrcode}
          onCancel={() => this.setState({ showModalQrcode: false })}
          onOk={() => {
            saveSvgAsPng.saveSvgAsPng(document.getElementById('qrCode-condominium'), `${form.code}.png`, {
              scale: 15,
              encoderOptions: 1,
              backgroundColor: 'white',
            });

            this.setState({ showModalQrcode: false });
          }}
          okText="Baixar"
        >
          <Row>
            <Col className="text-center">
              {form && form.code && (
                <QRCode
                  bgColor="#FFFFFF"
                  fgColor="#000000"
                  level="Q"
                  style={{ width: 256 }}
                  value={form.code.toString()}
                  id="qrCode-condominium"
                  className="oi"
                />
              )}
            </Col>
          </Row>
        </Modal>
        {loading === 0 && (
          <Col>
            <form
              name="form"
              onSubmit={(ev) => this.onSubmit(ev)}
            >
              <Row gutter={16}>
                <Col>
                  <h5>{I18n.t('routes.panel.condominium.items.condominium.details.condominiumSection')}</h5>
                </Col>
              </Row>
              {form.code && (
                <Row
                  gutter={16}
                  className="mb-3"
                >
                  <Col>
                    <Button
                      type="dashed"
                      onClick={() => this.setState({ showModalQrcode: true })}
                      style={{ paddingRight: 25 }}
                    >
                      <strong>
                        {I18n.t('routes.panel.condominium.items.condominium.details.form.condominiumCode.label')}{form.code}
                      </strong>
                      <QrcodeOutlined
                        style={{
                          position: 'absolute',
                          bottom: '25%',
                          right: '3%',
                        }}
                      />
                    </Button>
                  </Col>
                </Row>
              )}
              <Row gutter={16}>
                <Col span={6}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.condominium.items.condominium.details.form.name.label')}
                    value={form && form.name}
                    onChange={(val) => this.fieldChange('name', val)}
                    disabled={isGoverningBody}
                  />
                </Col>
                <Col span={6}>
                  <AdvancedAutoCompleteInput
                    searchMethod={(parameters) => getCondominiumAdministratorPaginatedForSearch(parameters)}
                    data={I18n.t('advancedAutoComplete.condominiumAdministrator')}
                    onSelect={(item) => this.fieldChange('condominiumAdministratorId', item.id)}
                    onSelectSelectable={(condominiumId) => this.fieldChange('condominiumAdministratorId', condominiumId)}
                    value={form && form.condominiumAdministratorId}
                    disableSearch
                    disabled={isGoverningBody}
                  />
                </Col>
                <Col span={6}>
                  <div className="advanced-input">
                    <label className="advanced-input__label">
                      <span className="advanced-input__label__inner">
                        {I18n.t('routes.panel.condominium.items.condominium.details.form.type.label')}
                      </span>
                      <Radio.Group
                        onChange={(e) => this.fieldChange('type', e.target.value)}
                        defaultValue={form && form.type}
                        disabled={isGoverningBody}
                      >
                        {CondominiumTypeArray.map((i) => (
                          <Radio.Button
                            value={i.id}
                            key={i.id}
                          >
                            {i.name}
                          </Radio.Button>
                        ))}
                      </Radio.Group>
                    </label>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="advanced-input">
                    <label className="advanced-input__label">
                      <span className="advanced-input__label__inner">
                        {I18n.t('routes.panel.condominium.items.condominium.details.form.isCommercial.label')}
                      </span>
                      <Radio.Group
                        onChange={(e) => this.fieldChange('isCommercial', e.target.value)}
                        defaultValue={form && form.isCommercial}
                        disabled={isGoverningBody}
                      >
                        <Radio.Button
                          value={!!true}
                        >
                          Comercial
                        </Radio.Button>
                        <Radio.Button
                          value={!!false}
                        >
                          Residencial
                        </Radio.Button>
                      </Radio.Group>
                    </label>
                  </div>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={6}>
                  <AdvancedMaskedInput
                    label={I18n.t('routes.panel.condominium.items.condominium.details.form.doc.label')}
                    value={form && form.doc}
                    onChange={(val) => this.fieldChange('doc', val)}
                    mask={I18n.t('shared.cnpjMask')}
                    disabled={isGoverningBody}
                  />
                </Col>
                <Col span={18}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.condominium.items.condominium.details.form.description.label')}
                    value={form && form.description}
                    onChange={(val) => this.fieldChange('description', val)}
                    disabled={isGoverningBody}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={6}>
                  <AdvancedMaskedInput
                    label={I18n.t('routes.panel.condominium.items.condominium.details.form.zipcode.label')}
                    value={form && form.zipcode}
                    onChange={(val) => {
                      this.fieldChange('zipcode', val);
                      if (val && removeSpecialChars(val).length === 8) {
                        this.getAddressByCep(removeSpecialChars(val));
                      }
                    }}
                    mask={I18n.t('shared.zipCodeMask')}
                    disabled={isGoverningBody}
                  />
                </Col>
                <Col span={6}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.condominium.items.condominium.details.form.neighborhood.label')}
                    value={form && form.neighborhood}
                    onChange={(val) => this.fieldChange('neighborhood', val)}
                    disabled={isGoverningBody}
                  />
                </Col>
                <Col span={6}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.condominium.items.condominium.details.form.street.label')}
                    value={form && form.street}
                    onChange={(val) => this.fieldChange('street', val)}
                    disabled={isGoverningBody}
                  />
                </Col>
                <Col span={6}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.condominium.items.condominium.details.form.city.label')}
                    value={form && form.city}
                    onChange={(val) => this.fieldChange('city', val)}
                    disabled={isGoverningBody}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={3}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.condominium.items.condominium.details.form.uf.label')}
                    value={form && form.uf}
                    onChange={(val) => this.fieldChange('uf', val)}
                    maxLength={2}
                    disabled={isGoverningBody}
                  />
                </Col>
                <Col span={3}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.condominium.items.condominium.details.form.addressNumber.label')}
                    value={form && form.addressNumber}
                    onChange={(val) => this.fieldChange('addressNumber', val)}
                    disabled={isGoverningBody}
                  />
                </Col>
                <Col span={6}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.condominium.items.condominium.details.form.latitude.label')}
                    value={form && form.latitude}
                    onChange={(val) => {
                      val.replace(/,/, '.');
                      this.fieldChange('latitude', val.replace(/,/, '.'));
                    }}
                    disabled={isGoverningBody}
                  />
                </Col>
                <Col span={6}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.condominium.items.condominium.details.form.longitude.label')}
                    value={form && form.longitude}
                    onChange={(val) => {
                      this.fieldChange('longitude', val.replace(/,/, '.'));
                    }}
                    disabled={isGoverningBody}
                  />
                </Col>

                {/* <Col span={6}>
                  <AdvancedMaskedInput
                    label={I18n.t('routes.panel.condominium.items.condominium.details.form.condominiumManagerPhone.label')}
                    onChange={(val) => this.fieldChange('condominiumManagerPhone', val)}
                    value={form && form.condominiumManagerPhone}
                    mask={I18n.t('shared.phoneMask')}
                    disabled={isGoverningBody}
                  />
                </Col> */}
              </Row>
              {id !== 'add' && (
                <>
                  <Divider />
                  <Row
                    gutter={16}
                    align="middle"
                    className=" margin-bottom"
                  >
                    <Col span={12}>
                      <h5>{I18n.t('routes.panel.condominium.items.condominium.details.condominiumGoverningBody')}</h5>
                    </Col>
                    <Col
                      className="text-right"
                      span={12}
                    >
                      <AdvancedButton
                        text={I18n.t('routes.panel.condominium.items.condominium.dataTable.columns.actions.goToGoverningBodyText')}
                        href={`${I18n.t('routes.panel.condominium.items.governingBody.url')}/${id}`}
                        icon={<Icon type={I18n.t('routes.panel.condominium.items.condominium.icon')} />}
                      />
                    </Col>
                  </Row>

                  <Table
                    dataSource={details && [
                      {
                        profileType: ProfileType.CONDOMINIUM_MANAGER,
                        ...details.condominiumManager,
                      },
                      {
                        profileType: ProfileType.CONDOMINIUM_SUB_MANAGER,
                        ...details.condominiumSubManager,
                      },
                      {
                        profileType: ProfileType.FINANCIAL_ADMINISTRATOR,
                        ...details.financialAdministrator,
                      },
                      {
                        profileType: ProfileType.ADVISOR,
                        ...details.advisor,
                      },
                    ]}
                    loading={!details}
                    columns={[
                      {
                        title: 'Tipo de Morador',
                        dataIndex: 'profileType',
                        key: 'profileType',
                        render: (value) => (value && GetProfileTypeTitle(value)) || '--',
                      },
                      {
                        title: 'Nome',
                        dataIndex: 'name',
                        key: 'name',
                        render: (value) => value || '--',
                      },
                      {
                        title: 'E-mail',
                        dataIndex: 'email',
                        key: 'email',
                        render: (value) => value || '--',
                      },
                      {
                        title: 'Celular',
                        dataIndex: 'cellphone',
                        key: 'cellphone',
                        render: (value) => (value && `+${value.substring(0, 2)} (${value.substring(2, 4)}) ${value.substring(4, 9)}-${value.substring(9, 13)}`) || '--',
                      },
                    ]}
                    size="middle"
                    bordered={false}
                    pagination={false}
                  />
                </>
              )}

              <Divider />

              <Row gutter={16}>
                <Col>
                  <h5>{I18n.t('routes.panel.condominium.items.condominium.details.contractSection')}</h5>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={5}>
                  <AdvancedDatePicker
                    label={I18n.t('routes.panel.condominium.items.condominium.details.form.contractStartAt.label')}
                    onChange={(val) => this.fieldChange('contractStartAt', val)}
                    value={form && form.contractStartAt}
                    format="DD/MM/YYYY"
                    disabled={isGoverningBody}
                  />
                </Col>

                <Col span={5}>
                  <AdvancedDatePicker
                    label={I18n.t('routes.panel.condominium.items.condominium.details.form.contractEndAt.label')}
                    onChange={(val) => this.fieldChange('contractEndAt', val)}
                    value={form && form.contractEndAt}
                    format="DD/MM/YYYY"
                    disabled={isGoverningBody}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedSelect
                    label={I18n.t('routes.panel.condominium.items.condominium.details.form.contractStatus.label')}
                    value={form && form.contractStatus}
                    onChange={(val) => this.fieldChange('contractStatus', val)}
                    options={ContractStatusTypeArray}
                    disabled={isGoverningBody}
                  />
                </Col>
                <Col span={6}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.condominium.items.condominium.details.form.monthlyPayment.label')}
                    value={form && form.monthlyPayment}
                    onChange={(val) => this.fieldChange('monthlyPayment', val)}
                    isMoney
                    disabled={isGoverningBody}
                  />
                </Col>
              </Row>

              <Divider />

              <Row gutter={16}>
                <Col>
                  <h5>{I18n.t('routes.panel.establishment.items.establishment.details.subAccountSection')}</h5>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={6}>
                  <AdvancedMaskedInput
                    label={I18n.t('routes.panel.condominium.items.condominium.details.form.phone.label')}
                    value={form && form.phone}
                    onChange={(val) => this.fieldChange('phone', val)}
                    mask={I18n.t('shared.phoneMask')}
                    disabled={id !== 'add'}
                  />
                </Col>
                <Col span={6}>
                  <AdvancedSelect
                    options={AgencyTypeArray}
                    label={I18n.t('routes.panel.establishment.items.establishment.details.form.bank.label')}
                    placeholder={I18n.t('shared.selectSomeValue')}
                    onChange={(val) => this.fieldChange('bankCode', val)}
                    hasValue
                    value={form && form.bankCode}
                    disabled={id !== 'add'}
                  />
                </Col>

                <Col span={6}>
                  <AdvancedSelect
                    options={AccountTypeArray}
                    label={I18n.t('routes.panel.establishment.items.establishment.details.form.accountType.label')}
                    placeholder={I18n.t('shared.selectSomeValue')}
                    onChange={(val) => this.fieldChange('accountType', val)}
                    hasValue
                    value={form && form.accountType}
                    disabled={id !== 'add'}
                  />
                </Col>

                {form && form.bankCode && (
                  <>
                    <Col span={6}>
                      {id !== 'add' ? (
                        <AdvancedInput
                          label={I18n.t('routes.panel.establishment.items.establishment.details.form.bankAgency.label')}
                          value={form && form.bankAgency}
                          disabled
                        />
                      ) : (
                        <AdvancedMaskedInput
                          label={I18n.t('routes.panel.establishment.items.establishment.details.form.bankAgency.label')}
                          value={form && form.bankAgency}
                          onChange={(val) => this.fieldChange('bankAgency', val)}
                          mask={GetAgencyTypeAgencyMask(form && form.bankCode)}
                        />
                      )}
                    </Col>

                    <Col span={6}>
                      {id === 'add' && (
                        <AdvancedMaskedInput
                          label={I18n.t('routes.panel.establishment.items.establishment.details.form.bankAccount.label')}
                          value={form && form.bankAccount}
                          onChange={(val) => this.fieldChange('bankAccount', val)}
                          mask={GetAgencyTypeAccountMask(form && form.bankCode)}
                        />
                      )}
                    </Col>
                  </>
                )}
              </Row>
              {id !== 'add' && (
                <Row gutter={16}>
                  <Col span={6}>
                    <AdvancedInput
                      label={I18n.t('routes.panel.establishment.items.establishment.details.form.bankAccount.label')}
                      value={form && form.bankAccount}
                      disabled
                    />
                  </Col>
                </Row>
              )}

              {form && form.bankCode && (
                <p className="input-observation">
                  {I18n.t('routes.panel.establishment.items.establishment.details.form.bankAccount.observation')}
                </p>
              )}

              {!isGoverningBody && (
                <>
                  <Divider />
                  <Row>
                    <Col
                      span={24}
                      className="text-right"
                    >
                      <AdvancedButton
                        type="link"
                        text={I18n.t('shared.backButtonText')}
                        href={I18n.t('routes.panel.condominium.items.condominium.url')}
                      />

                      <Divider
                        className="form-button-divider"
                        type="vertical"
                      />

                      <AdvancedButton
                        htmlType="submit"
                        text={I18n.t('shared.saveButtonText')}
                        icon={<SaveOutlined />}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </form>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  details: CondominiumSelectors.getDetails(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCondominiumAdministratorPaginatedForSearch: (parameters) => dispatch(CondominiumAdministratorActions.getPaginatedForSearch(parameters)),
  getAddressByCep: (cep) => dispatch(UtilsActions.getAddressByCep(cep)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CondominiumForm);
