/* eslint-disable no-dupe-else-if */
import React from 'react';
import { I18n } from 'react-redux-i18n';
import {
  message, Modal, Col, Row,
} from 'antd';
import {
  CloudDownloadOutlined,
} from '@ant-design/icons';

import AdvancedButton from '../../shared/AdvancedButton/AdvancedButton';
import AdvancedRangePicker from '../../shared/AdvancedRangePicker/AdvancedRangePicker';

class ExportDataModal extends React.Component {
  onSubmitExportModal() {
    const { exportFunction, onChangeVisible, data } = this.props;

    try {
      if (!data) return message.error(I18n.t('component.modalExport.error'));
      exportFunction();
      onChangeVisible(false);
      message.success(I18n.t('shared.genericSuccess'));
    } catch (error) {
      message.error(I18n.t('shared.genericError'));
    }
  }

  render() {
    const {
      loading,
      me,
      isVisible,
      onChangeVisible,
      onChangeField,
    } = this.props;
    return (
      <Modal
        visible={isVisible}
        onOk={() => onChangeVisible(false)}
        onCancel={() => onChangeVisible(false)}
        footer={[
          <AdvancedButton
            key="submit"
            text={I18n.t('component.modalExport.cancel')}
            loading={loading && loading > 0}
            type="default"
            onClick={() => onChangeVisible(false)}
          />,
          <AdvancedButton
            key="submit"
            text={I18n.t('component.modalExport.send')}
            loading={loading && loading > 0}
            onClick={() => this.onSubmitExportModal()}
          />,
        ]}
      >
        <Row>
          <Col span={24}>
            <h4>
              <CloudDownloadOutlined style={{
                color: 'blue',
                marginRight: '10px',
                position: 'relative',
                top: '-4px',
              }}
              />
              {I18n.t('component.modalExport.title')}
            </h4>
          </Col>
          <Col
            span={24}
            className="mt-2"
          >
            <span>
              {I18n.t('component.modalExport.warning')}
              <br />
              <strong>
                {me && me.email}
              </strong>
            </span>
          </Col>
          <Col
            span={24}
            className="mt-2"
          >
            <AdvancedRangePicker
              label={I18n.t('component.modalExport.selectPeriod')}
              onChange={(val) => onChangeField(val)}
              placeholder={I18n.t('shared.type')}
            />
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default ExportDataModal;
