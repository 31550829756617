import React from 'react';
import {
  Divider,
  Layout,
  Popconfirm,
  Button,
  Row,
  Col,
  message,
  Breadcrumb,
  Icon,
  Collapse,
} from 'antd';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Link } from '@reach/router';

import { AuthActions, UserActions, CondominiumActions } from '../../../../../app/redux/actions';
import { AuthSelectors, LoadingSelectors, UserSelectors } from '../../../../../app/redux/reducers';

import * as DateUtils from '../../../../../app/utils/date';
import { ProfileType, GetProfileTypeTitle } from '../../../../../app/enum/profileType';
import { isAdmin, isCondominiumManager } from '../../../../../app/utils/permissions';

import DataTable from '../../../../../components/shared/DataTable/DataTable';
import AdvancedButton from '../../../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedInput from '../../../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedSelect from '../../../../../components/shared/AdvancedSelect/AdvancedSelect';
import AdvancedAutoCompleteInput from '../../../../../components/shared/AdvancedAutoCompleteInput/AdvancedAutoCompleteInput';
import QueryStringUtils from '../../../../../app/utils/queryString';

export const ProfileTypeArray = [
  { id: ProfileType.DOORMAN, name: <Translate value="enum.profileType.doorman" /> },
  { id: ProfileType.CARETAKER, name: <Translate value="enum.profileType.caretaker" /> },
];

const qs = new QueryStringUtils();
const { Panel } = Collapse;

const defaultParams = {
  name: null,
  email: null,
  condominiumId: null,
  profileType: null,
};

class DoormanList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: defaultParams,
    };
    this.dataTableRef = React.createRef();
  }

  componentDidMount() {
    const { params } = qs.get();

    if (params) {
      this.setState({ params });
    }
  }

  getPaginatedData(params) {
    const { me } = this.props;

    const { getPaginated } = this.props;
    params = {
      ...params,
      ...(isCondominiumManager(me) && { condominiumId: me.condominiumId }),
      ...(params && !params.profileType && { profileType: `${ProfileType.DOORMAN},${ProfileType.CARETAKER}` }),
    };
    getPaginated(params, me);
  }

  getPaginatedDataAndRefreshTable() {
    const { params } = this.state;
    if (this.dataTableRef) {
      this.dataTableRef.current.reset(false);
      this.getPaginatedData(params);
    }
  }

  fieldChange(name, value) {
    const { params } = this.state;
    if (name !== 'profileType') params[name] = value ? value.toString() : null;
    else params[name] = value || null;
    this.setState({ params });
  }

  remove(id) {
    this.props.remove(id, () => {
      message.success(I18n.t('routes.panel.users.items.doorman.messages.deleteSuccess'));
      this.getPaginatedDataAndRefreshTable();
    });
  }

  applyAdvancedFilters() {
    let { params } = this.state;
    params = {
      ...params,
      page: 1,
      offset: 0,
    };

    qs.set({ params });

    this.getPaginatedDataAndRefreshTable(params);
  }

  cleanAdvancedFilters() {
    qs.set({ params: undefined });
    window.location.reload();
  }

  render() {
    const { Content } = Layout;
    const { params } = this.state;

    const {
      usersPaginated,
      loading,
      me,
    } = this.props;

    const columns = [
      {
        key: I18n.t('routes.panel.users.items.doorman.dataTable.columns.name.key'),
        title: I18n.t('routes.panel.users.items.doorman.dataTable.columns.name.title'),
        render: (value) => value || '--',
      },
      {
        key: I18n.t('routes.panel.users.items.doorman.dataTable.columns.email.key'),
        title: I18n.t('routes.panel.users.items.doorman.dataTable.columns.email.title'),
        render: (value) => value || '--',
      },
      {
        key: I18n.t('routes.panel.users.items.doorman.dataTable.columns.profileType.key'),
        title: I18n.t('routes.panel.users.items.doorman.dataTable.columns.profileType.title'),
        render: (value) => (value && GetProfileTypeTitle(value)) || '--',
      },
      {
        key: I18n.t('routes.panel.users.items.doorman.dataTable.columns.condominium.key'),
        title: I18n.t('routes.panel.users.items.doorman.dataTable.columns.condominium.title'),
        render: (value) => (value && value.name) || '--',
      },
      {
        key: I18n.t('routes.panel.users.items.doorman.dataTable.columns.createdAt.key'),
        title: I18n.t('routes.panel.users.items.doorman.dataTable.columns.createdAt.title'),
        render: (createdAt) => DateUtils.humanizeDateTime(createdAt, 'DD/MM/YYYY HH:mm'),
      },
    ];

    if (isAdmin(me) || isCondominiumManager(me)) {
      columns.push({
        key: I18n.t('routes.panel.users.items.doorman.dataTable.columns.actions.key'),
        title: '',
        render: (id) => (
          <div className="dataTable__item--right">
            <Popconfirm
              placement="left"
              title={I18n.t('shared.confirmTitle')}
              onConfirm={() => this.remove(id)}
              okText={I18n.t('shared.yes')}
              cancelText={I18n.t('shared.no')}
              disabled={me && me.id === id}
            >
              <Button
                type="link"
                disabled={me && me.id === id}
              >
                {I18n.t('routes.panel.users.items.doorman.dataTable.columns.actions.removeText')}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
            <Link to={`${I18n.t('routes.panel.users.items.doorman.url')}/${id}`}>
              {I18n.t('routes.panel.users.items.doorman.dataTable.columns.actions.goToDetailsText')}
              &nbsp;&nbsp;<EditOutlined />
            </Link>
          </div>
        ),
      });
    }

    return (
      <div>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" />
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.users.items.doorman.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse
            className="advanced-filter"
          >
            <Panel
              header={<strong>{I18n.t('routes.panel.users.items.doorman.advancedFilter.panelTitle')}</strong>}
              key="1"
            >
              <Row gutter={16}>
                {isAdmin(me) && (
                  <Col span={8}>
                    <AdvancedAutoCompleteInput
                      searchMethod={(parameters) => this.props.getCondominiumPaginatedForSearch(parameters)}
                      data={I18n.t('advancedAutoComplete.condominium')}
                      onSelect={(item) => this.fieldChange('condominiumId', item.id)}
                      onSelectSelectable={(id) => this.fieldChange('condominiumId', id)}
                      value={params && params.condominiumId}
                      disableSearch
                    />
                  </Col>
                )}
                <Col span={8}>
                  <AdvancedSelect
                    options={ProfileTypeArray}
                    label={I18n.t('routes.panel.users.items.doorman.details.form.profileType.label')}
                    placeholder={I18n.t('shared.selectSomeValue')}
                    onChange={(val) => this.fieldChange('profileType', val)}
                    disabled={loading && loading > 0}
                    disableSearch
                    hasValue
                    value={params && params.profileType}
                  />
                </Col>

                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.users.items.doorman.advancedFilter.name.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.users.items.doorman.advancedFilter.name.key'), val)}
                    value={params && params.name}
                  />
                </Col>

                {!isAdmin(me) && (
                  <Col span={8}>
                    <AdvancedInput
                      label={I18n.t('routes.panel.users.items.doorman.advancedFilter.email.label')}
                      onChange={(val) => this.fieldChange(I18n.t('routes.panel.users.items.doorman.advancedFilter.email.key'), val)}
                      value={params && params.email}
                    />
                  </Col>
                )}

              </Row>
              {isAdmin(me) && (
                <Row gutter={16}>
                  <Col span={8}>
                    <AdvancedInput
                      label={I18n.t('routes.panel.users.items.doorman.advancedFilter.email.label')}
                      onChange={(val) => this.fieldChange(I18n.t('routes.panel.users.items.doorman.advancedFilter.email.key'), val)}
                      value={params && params.email}
                    />
                  </Col>
                </Row>
              )}

              <Row gutter={16}>
                <Col
                  span={24}
                  className="advanced-filter__search-button text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.advancedFilter.cleanButtonText')}
                    onClick={() => this.cleanAdvancedFilters()}
                  />
                  <AdvancedButton
                    text={I18n.t('shared.advancedFilter.searchButtonText')}
                    icon={<SearchOutlined />}
                    onClick={() => this.applyAdvancedFilters()}
                  />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="administrators">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    <Icon type={I18n.t('routes.panel.users.items.doorman.icon')} />
                  </span>
                  {I18n.t('routes.panel.users.items.doorman.pageTitle')}
                </h2>
              </Col>
              {(isAdmin(me) || isCondominiumManager(me)) && (
                <Col
                  className="text-right"
                  span={12}
                >
                  <AdvancedButton
                    text={I18n.t('routes.panel.users.items.doorman.addNewButtonText')}
                    href={`${I18n.t('routes.panel.users.items.doorman.url')}/add`}
                    icon={<Icon type={I18n.t('routes.panel.users.items.doorman.icon')} />}
                  />
                </Col>
              )}
            </Row>

            <Divider />

            <div>
              <DataTable
                getMethod={(parameters) => this.getPaginatedData(parameters)}
                data={usersPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                columns={columns}
              />
            </div>
          </div>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  usersPaginated: UserSelectors.getPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters, me) => dispatch(UserActions.getPaginated(parameters, me)),
  remove: (id, callback) => dispatch(UserActions.remove(id, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
  getCondominiumPaginatedForSearch: (parameters) => dispatch(CondominiumActions.getPaginatedForSearch(parameters)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DoormanList);
