import React from 'react';
import {
  Divider, Layout, message, Icon, Row, Col, Breadcrumb, Collapse, Button, Popconfirm,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons';

import { AuthActions, PushNotificationActions, CondominiumActions } from '../../../../app/redux/actions';
import { AuthSelectors, LoadingSelectors, PushNotificationSelectors } from '../../../../app/redux/reducers';

import { GetNotificationTypeTitle, NotificationTypeArray } from '../../../../app/enum/notificationType';
import * as DateUtils from '../../../../app/utils/date';
import { isAdmin, isCondominiumManager } from '../../../../app/utils/permissions';

import AdvancedSelect from '../../../../components/shared/AdvancedSelect/AdvancedSelect';
import DataTable from '../../../../components/shared/DataTable';
import AdvancedButton from '../../../../components/shared/AdvancedButton';
import AdvancedInput from '../../../../components/shared/AdvancedInput';
import AdvancedAutoCompleteInput from '../../../../components/shared/AdvancedAutoCompleteInput/AdvancedAutoCompleteInput';
import QueryStringUtils from '../../../../app/utils/queryString';

const qs = new QueryStringUtils();
const { Panel } = Collapse;

const defaultParams = {
  title: null,
  notificationType: null,
};

class PushNotificationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: defaultParams,
    };
    this.dataTableRef = React.createRef();
  }

  componentDidMount() {
    const { params } = qs.get();

    if (params) {
      this.setState({ params });
    }
  }

  getPaginatedData(params) {
    const { me, getPaginated } = this.props;
    getPaginated(params, me);
  }

  getPaginatedDataAndRefreshTable() {
    const { params } = this.state;
    if (this.dataTableRef) {
      this.dataTableRef.current.reset(false);
      this.getPaginatedData(params);
    }
  }

  remove(id) {
    this.props.remove(id, () => {
      message.success(I18n.t('routes.panel.pushNotification.items.report.messages.deleteSuccess'));
      this.dataTableRef.current.reset();
      window.location.reload();
    });
  }

  fieldChange(name, value) {
    const { params } = this.state;
    params[name] = value ? value.toString() : null;
    this.setState({ params });
  }

  applyAdvancedFilters() {
    let { params } = this.state;
    params = {
      ...params,
      ...(params.notificationType && { type: params.notificationType }),
      page: 1,
      offset: 0,
    };

    qs.set({ params });

    this.getPaginatedDataAndRefreshTable(params);
  }

  cleanAdvancedFilters() {
    qs.set({ params: undefined });
    window.location.reload();
  }

  render() {
    const { Content } = Layout;
    const { params } = this.state;
    const { paginatedData, loading, me } = this.props;

    const columns = [
      {
        key: I18n.t('routes.panel.pushNotification.items.report.dataTable.columns.title.key'),
        title: I18n.t('routes.panel.pushNotification.items.report.dataTable.columns.title.title'),
        render: (value) => value || '--',
      },
      {
        key: I18n.t('routes.panel.pushNotification.items.report.dataTable.columns.notificationType.key'),
        title: I18n.t(
          'routes.panel.pushNotification.items.report.dataTable.columns.notificationType.title',
        ),
        render: (value) => (value && GetNotificationTypeTitle(value)) || '--',
      },
      {
        key: I18n.t('routes.panel.pushNotification.items.report.dataTable.columns.scheduledDate.key'),
        title: I18n.t('routes.panel.pushNotification.items.report.dataTable.columns.scheduledDate.title'),
        render: (value) => (value
          ? DateUtils.humanizeDateTime(value, 'DD/MM/YYYY HH:mm')
          : I18n.t('routes.panel.pushNotification.items.report.dataTable.columns.scheduledDate.sendNow')),
      },
      {
        key: I18n.t('routes.panel.pushNotification.items.report.dataTable.columns.createdAt.key'),
        title: I18n.t('routes.panel.pushNotification.items.report.dataTable.columns.createdAt.title'),
        render: (value) => DateUtils.humanizeDateTime(value, 'DD/MM/YYYY HH:mm'),
      },
    ];

    if (isAdmin(me) || isCondominiumManager(me)) {
      columns.push({
        key: I18n.t('routes.panel.pushNotification.items.report.dataTable.columns.actions.key'),
        title: '',
        render: (id) => (
          <div className="dataTable__item--right">
            <Popconfirm
              placement="left"
              title={I18n.t('shared.confirmTitle')}
              onConfirm={() => this.remove(id)}
              okText={I18n.t('shared.yes')}
              cancelText={I18n.t('shared.no')}
              disabled={me && me.id === id}
            >
              <Button
                type="link"
                disabled={me && me.id === id}
              >
                {I18n.t('routes.panel.pushNotification.items.report.dataTable.columns.actions.removeText')}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </div>
        ),
      });
    }

    return (
      <div>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" />
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.pushNotification.items.report.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse
            className="advanced-filter"
          >
            <Panel
              header={<strong>{I18n.t('routes.panel.pushNotification.items.report.advancedFilter.panelTitle')}</strong>}
              key="1"
            >
              <Row gutter={16}>
                {isAdmin(me) && (
                  <Col span={8}>
                    <AdvancedAutoCompleteInput
                      searchMethod={(parameters) => this.props.getCondominiumPaginatedForSearch(parameters)}
                      data={I18n.t('advancedAutoComplete.condominium')}
                      onSelect={(item) => this.fieldChange('condominiumId', item.id)}
                      onSelectSelectable={(id) => this.fieldChange('condominiumId', id)}
                      value={params && params.condominiumId}
                      disableSearch
                    />
                  </Col>
                )}
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.pushNotification.items.report.advancedFilter.title.label')}
                    onChange={(val) => this.fieldChange(
                      I18n.t('routes.panel.pushNotification.items.report.advancedFilter.title.key'),
                      val,
                    )}
                    placeholder={I18n.t('routes.panel.pushNotification.items.report.advancedFilter.title.placeholder')}
                    value={params && params.title}
                  />
                </Col>

                <Col span={8}>
                  <AdvancedSelect
                    label={I18n.t('routes.panel.pushNotification.items.report.advancedFilter.notificationType.label')}
                    options={NotificationTypeArray}
                    onChange={(value) => this.fieldChange('type', value)}
                    value={params && params.type && parseInt(params.type, 10)}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col
                  span={24}
                  className="advanced-filter__search-button text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.advancedFilter.cleanButtonText')}
                    onClick={() => this.cleanAdvancedFilters()}
                  />
                  <AdvancedButton
                    text={I18n.t('shared.advancedFilter.searchButtonText')}
                    icon={<SearchOutlined />}
                    onClick={() => this.applyAdvancedFilters()}
                  />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="orders">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    <Icon type={I18n.t('routes.panel.pushNotification.items.report.icon')} />
                  </span>
                  {I18n.t('routes.panel.pushNotification.items.report.pageTitle')}
                </h2>
              </Col>
              {(isAdmin(me) || isCondominiumManager(me)) && (
                <Col
                  className="text-right"
                  span={12}
                >
                  <AdvancedButton
                    text={I18n.t('routes.panel.pushNotification.items.report.addNewButtonText')}
                    href={`${I18n.t('routes.panel.pushNotification.items.report.urlAddNotification')}`}
                    icon={<Icon type={I18n.t('routes.panel.pushNotification.items.report.icon')} />}
                  />
                </Col>
              )}

            </Row>

            <Divider />

            <div>
              <DataTable
                getMethod={(parameters) => this.getPaginatedData(parameters)}
                data={paginatedData}
                loading={loading > 0}
                ref={this.dataTableRef}
                columns={columns}
              />
            </div>
          </div>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  paginatedData: PushNotificationSelectors.getPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters, me) => dispatch(PushNotificationActions.getPaginated(parameters, me)),
  remove: (id, callback) => dispatch(PushNotificationActions.remove(id, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
  getCondominiumPaginatedForSearch: (parameters) => dispatch(CondominiumActions.getPaginatedForSearch(parameters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PushNotificationList);
