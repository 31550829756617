import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import moment from 'moment-timezone';
import {
  addLoading,
  removeLoading,
} from './loading';
import SchedulingRecreationRequests from '../../api/scheduling-recreation';

export const ACTION_SAVE_SCHEDULING_RECREATION_PAGINATED = 'ACTION_SAVE_SCHEDULING_RECREATION_PAGINATED';
export const ACTION_SAVE_SCHEDULING_RECREATION_DETAILS = 'ACTION_SAVE_SCHEDULING_RECREATION_DETAILS';

export const ACTION_CLEAN_SCHEDULING_RECREATION = 'ACTION_CLEAN_SCHEDULING_RECREATION';

// cleaners
export const clean = () => ({
  type: ACTION_CLEAN_SCHEDULING_RECREATION,
  payload: null,
});

export const cleanDetails = () => ({
  type: ACTION_SAVE_SCHEDULING_RECREATION_DETAILS,
  payload: null,
});

export const cleanPaginated = () => ({
  type: ACTION_SAVE_SCHEDULING_RECREATION_PAGINATED,
  payload: null,
});

/*
 * BASIC CRUD METHODS
 *
 * getPaginated: get rows for data table
 * getDetails: get details (get by id)
 * update: the PUT method
 * create: the POST method
 */

export const getPaginated = (parameters, callback) => async (dispatch) => {
  dispatch(addLoading());
  dispatch(cleanPaginated());

  try {
    parameters = {
      ...parameters,
      limit: parameters.limit || 10,
      total: parameters.total || 0,
      page: parameters.page || 1,
      orderBy: 'createdAt',
      isDESC: true,
      ...(parameters.dateInitial && {
        dateInitial: moment(parameters.dateInitial[0]).set({
          hour: 0, minute: 0, second: 0,
        }).format(),
        dateEnd: moment(parameters.dateInitial[1]).set({
          hour: 23, minute: 59, second: 59,
        }).format(),
      }),
    };

    dispatch({
      type: ACTION_SAVE_SCHEDULING_RECREATION_PAGINATED,
      payload: null,
    });

    const response = await SchedulingRecreationRequests.getPaginated(parameters);

    dispatch({
      type: ACTION_SAVE_SCHEDULING_RECREATION_PAGINATED,
      payload: { rows: response.rows, count: response.count },
    });

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const getDetails = (id, callback) => async (dispatch) => {
  dispatch(addLoading());
  dispatch(cleanDetails());

  try {
    let response = null;
    dispatch({
      type: ACTION_SAVE_SCHEDULING_RECREATION_DETAILS,
      payload: null,
    });

    if (id !== 'add') {
      response = await SchedulingRecreationRequests.getDetails(id);

      dispatch({
        type: ACTION_SAVE_SCHEDULING_RECREATION_DETAILS,
        payload: response,
      });
    }

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const update = (id, data, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const response = await SchedulingRecreationRequests.update(id, data);

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const create = (data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const response = await SchedulingRecreationRequests.create(data);

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const remove = (id, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const response = await SchedulingRecreationRequests.remove(id);
    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const exportReport = (params) => async () => {
  try {
    params = {
      dateInitial: `${moment(params.modalRange[0]).format()}`,
      dateEnd: `${moment(params.modalRange[1]).format()}`,
    };

    await SchedulingRecreationRequests.exportReport(params);
  } catch (err) {
    //
  }
};
