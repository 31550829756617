import React from 'react';
import {
  Divider,
  Layout,
  Popconfirm,
  Button,
  Row,
  Col,
  message,
  Breadcrumb,
  Icon,
  Collapse,
  Modal,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  DeleteOutlined, EditOutlined, SearchOutlined,
  CloudUploadOutlined,
} from '@ant-design/icons';
import { Link } from '@reach/router';
import { QRCode } from 'react-qr-svg';

import {
  AuthSelectors, LoadingSelectors, CondominiumSelectors,
} from '../../../../../app/redux/reducers';
import { AuthActions, CondominiumActions } from '../../../../../app/redux/actions';
import { CondominiumTypeArray, GetCondominiumTypeTitle } from '../../../../../app/enum/condominiumType';

import DataTable from '../../../../../components/shared/DataTable';
import AdvancedButton from '../../../../../components/shared/AdvancedButton';
import AdvancedInput from '../../../../../components/shared/AdvancedInput';
import AdvancedSelect from '../../../../../components/shared/AdvancedSelect/AdvancedSelect';
import DwellerImportModal from '../../../../../components/panel/dwellerImportModal/DwellerImportModal';
import NotImportedModal from '../../../../../components/panel/notImportedModal/notImportedModal';
import QueryStringUtils from '../../../../../app/utils/queryString';
import { isAdmin } from '../../../../../app/utils/permissions';

const saveSvgAsPng = require('save-svg-as-png');

const qs = new QueryStringUtils();
const { Panel } = Collapse;

const defaultParams = {
  name: null,
  zipcode: null,
  neighborhood: null,
  street: null,
  city: null,
  uf: null,
  type: null,
};

class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: defaultParams,
      importModalVisible: false,
      selectedCondominium: null,
      showModalQrcode: false,
      notImportedModalVisible: null,
    };
    this.dataTableRef = React.createRef();
  }

  componentDidMount() {
    const { params } = qs.get();

    if (params) {
      this.setState({ params });
    }
  }

  onSelectCondominium(condominium) {
    this.setState({
      showModalQrcode: true,
      selectedCondominium: condominium,
    });
  }

  getPaginatedData(params) {
    const { me } = this.props;
    const { getPaginated } = this.props;

    getPaginated(params, me);
  }

  getPaginatedDataAndRefreshTable() {
    const { params } = this.state;
    if (this.dataTableRef) {
      this.dataTableRef.current.reset(false);
      this.getPaginatedData(params);
    }
  }

  handleCancel() {
    this.setState({
      showModalQrcode: false,
      selectedCondominium: null,
    });
  }

  fieldChange(name, value) {
    const { params } = this.state;
    params[name] = value || null;
    this.setState({ params });
  }

  remove(id) {
    this.props.remove(id, () => {
      message.success(I18n.t('routes.panel.condominium.items.condominium.messages.deleteSuccess'));
      this.getPaginatedDataAndRefreshTable();
    });
  }

  applyAdvancedFilters() {
    let { params } = this.state;
    params = {
      ...params,
      page: 1,
      offset: 0,
    };

    qs.set({ params });

    this.getPaginatedDataAndRefreshTable(params);
  }

  cleanAdvancedFilters() {
    qs.set({ params: undefined });
    window.location.reload();
  }

  csvImportSuccess(response) {
    const { notImported } = response;
    this.setState({ importModalVisible: false, selectedCondominium: null });

    if (notImported && notImported.length === 0) {
      message.success(I18n.t('routes.panel.condominium.items.condominium.dwellerImportCsvModal.success'));
      this.dataTableRef.current.reset();
    } else {
      this.setState({ notImportedModalVisible: notImported });
    }
  }

  render() {
    const { Content } = Layout;
    const {
      params, importModalVisible, selectedCondominium, notImportedModalVisible,
    } = this.state;

    const {
      condominiumsPaginated,
      loading,
      me,
      importDwellers,
    } = this.props;

    return (
      <div>
        {notImportedModalVisible && (
          <NotImportedModal
            onOkFunction={() => {
              this.dataTableRef.current.reset();
              this.setState({ notImportedModalVisible: null });
            }}
            onCancelFunction={() => {
              this.dataTableRef.current.reset();
              this.setState({ notImportedModalVisible: null });
            }}
            dataSource={notImportedModalVisible}
          />
        )}
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" />
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.condominium.items.condominium.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse
            className="advanced-filter"
          >
            <Panel
              header={<strong>{I18n.t('routes.panel.condominium.items.condominium.advancedFilter.panelTitle')}</strong>}
              key="1"
            >
              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.condominium.items.condominium.advancedFilter.name.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.condominium.items.condominium.advancedFilter.name.key'), val)}
                    value={params && params.name}
                  />
                </Col>

                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.condominium.items.condominium.advancedFilter.zipcode.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.condominium.items.condominium.advancedFilter.zipcode.key'), val)}
                    value={params && params.zipcode}
                  />
                </Col>

                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.condominium.items.condominium.advancedFilter.neighborhood.label')}
                    value={params && params.neighborhood}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.condominium.items.condominium.advancedFilter.neighborhood.key'), val)}
                  />
                </Col>

              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.condominium.items.condominium.advancedFilter.street.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.condominium.items.condominium.advancedFilter.street.key'), val)}
                    value={params && params.street}
                  />
                </Col>

                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.condominium.items.condominium.advancedFilter.city.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.condominium.items.condominium.advancedFilter.city.key'), val)}
                    value={params && params.city}
                  />
                </Col>

                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.condominium.items.condominium.advancedFilter.uf.label')}
                    value={params && params.uf}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.condominium.items.condominium.advancedFilter.uf.key'), val)}
                    maxLength={2}
                  />
                </Col>

                <Col span={8}>
                  <AdvancedSelect
                    label={I18n.t('routes.panel.condominium.items.condominium.advancedFilter.type.label')}
                    value={params && params.type}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.condominium.items.condominium.advancedFilter.type.key'), val)}
                    options={CondominiumTypeArray}
                  />
                </Col>

              </Row>

              <Row gutter={16}>
                <Col
                  span={24}
                  className="advanced-filter__search-button text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.advancedFilter.cleanButtonText')}
                    onClick={() => this.cleanAdvancedFilters()}
                  />
                  <AdvancedButton
                    text={I18n.t('shared.advancedFilter.searchButtonText')}
                    icon={<SearchOutlined />}
                    onClick={() => this.applyAdvancedFilters()}
                  />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="administrators">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    <Icon type={I18n.t('routes.panel.condominium.items.condominium.icon')} />
                  </span>
                  {I18n.t('routes.panel.condominium.items.condominium.pageTitle')}
                </h2>
              </Col>
              <Col
                className="text-right"
                span={12}
              >
                <AdvancedButton
                  text={I18n.t('routes.panel.condominium.items.condominium.addNewButtonText')}
                  href={`${I18n.t('routes.panel.condominium.items.condominium.url')}/add`}
                  icon={<Icon type={I18n.t('routes.panel.condominium.items.condominium.icon')} />}
                />
              </Col>
            </Row>

            <Divider />

            {importModalVisible && (
              <DwellerImportModal
                isAdmin={isAdmin(me)}
                visible
                selectedCondominium={selectedCondominium}
                handleCancel={() => this.setState({ importModalVisible: false, selectedCondominium: null })}
                importDwellers={(condominiumId, users, callback) => importDwellers(condominiumId, users, callback)}
                csvImportSuccess={(response) => this.csvImportSuccess(response)}
              />
            )}

            <div>
              <DataTable
                getMethod={(parameters) => this.getPaginatedData(parameters)}
                data={condominiumsPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                columns={
                  [
                    {
                      key: I18n.t('routes.panel.condominium.items.condominium.dataTable.columns.code.key'),
                      title: I18n.t('routes.panel.condominium.items.condominium.dataTable.columns.code.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.condominium.items.condominium.dataTable.columns.name.key'),
                      title: I18n.t('routes.panel.condominium.items.condominium.dataTable.columns.name.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.condominium.items.condominium.dataTable.columns.type.key'),
                      title: I18n.t('routes.panel.condominium.items.condominium.dataTable.columns.type.title'),
                      render: (value) => (value && GetCondominiumTypeTitle(value)) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.condominium.items.condominium.dataTable.columns.street.key'),
                      title: I18n.t('routes.panel.condominium.items.condominium.dataTable.columns.street.title'),
                      render: (value, record) => (value && `${value}, ${record.addressNumber}`) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.condominium.items.condominium.dataTable.columns.neighborhood.key'),
                      title: I18n.t('routes.panel.condominium.items.condominium.dataTable.columns.neighborhood.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.condominium.items.condominium.dataTable.columns.city.key'),
                      title: I18n.t('routes.panel.condominium.items.condominium.dataTable.columns.city.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.condominium.items.condominium.dataTable.columns.uf.key'),
                      title: I18n.t('routes.panel.condominium.items.condominium.dataTable.columns.uf.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.condominium.items.condominium.dataTable.columns.zipcode.key'),
                      title: I18n.t('routes.panel.condominium.items.condominium.dataTable.columns.zipcode.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.condominium.items.condominium.dataTable.columns.actions.key'),
                      title: '',
                      render: (id, record) => (
                        <div className="dataTable__item--right">
                          <Popconfirm
                            placement="left"
                            title={I18n.t('shared.confirmTitle')}
                            onConfirm={() => this.remove(id)}
                            okText={I18n.t('shared.yes')}
                            cancelText={I18n.t('shared.no')}
                            disabled={me && me.id === id}
                          >
                            <Button
                              type="link"
                              disabled={me && me.id === id}
                            >
                              {I18n.t('routes.panel.condominium.items.condominium.dataTable.columns.actions.removeText')}
                              <DeleteOutlined />
                            </Button>
                          </Popconfirm>
                          <Link to={`${I18n.t('routes.panel.condominium.items.condominium.url')}/${id}`}>
                            {I18n.t('routes.panel.condominium.items.condominium.dataTable.columns.actions.goToDetailsText')}
                            &nbsp;&nbsp;<EditOutlined />&nbsp;&nbsp;
                          </Link>
                          <Button
                            type="link"
                            onClick={() => this.setState({
                              importModalVisible: true,
                              selectedCondominium: record,
                            })}
                          >
                            {I18n.t('routes.panel.condominium.items.condominium.dataTable.columns.actions.importDwellers')}
                            <CloudUploadOutlined />
                          </Button>
                          {/* <Button
                            type="link"
                            onClick={() => this.onSelectCondominium(record)}
                          >
                            Ver QRCode
                            <QrcodeOutlined />
                          </Button> */}
                        </div>
                      ),
                    },
                  ]
                }
              />
            </div>
          </div>
        </Content>

        <Modal
          title="QR Code do condomínio"
          visible={this.state.showModalQrcode}
          onCancel={() => this.handleCancel()}
          onOk={() => {
            saveSvgAsPng.saveSvgAsPng(document.getElementById('qrCode-condominium'), `${selectedCondominium.code}.png`, {
              scale: 15,
              encoderOptions: 1,
              backgroundColor: 'white',
            });

            this.handleCancel();
          }}
          okText="Baixar"
        >
          <Row>
            <Col className="text-center">
              {selectedCondominium && selectedCondominium.code && (
                <QRCode
                  bgColor="#FFFFFF"
                  fgColor="#000000"
                  level="Q"
                  style={{ width: 256 }}
                  value={selectedCondominium.code.toString()}
                  id="qrCode-condominium"
                  className="oi"
                />
              )}
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  condominiumsPaginated: CondominiumSelectors.getPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters, me) => dispatch(CondominiumActions.getPaginated(parameters, me)),
  remove: (id, callback) => dispatch(CondominiumActions.remove(id, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
  importDwellers: (condominiumId, users, callback) => dispatch(
    CondominiumActions.importDwellers(condominiumId, users, callback),
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserList);
