import React from 'react';
import {
  Divider,
  Layout,
  Popconfirm,
  Button,
  Row,
  Col,
  message,
  Breadcrumb,
  Icon,
  Modal,
} from 'antd';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  DeleteOutlined, EditOutlined, CalendarOutlined,
} from '@ant-design/icons';
import { Link } from '@reach/router';
import moment from 'moment-timezone';

import {
  AuthSelectors, LoadingSelectors, RecreationSelectors, RecreationCategorySelectors,
} from '../../../../../app/redux/reducers';
import {
  RecreationActions, RecreationCategoryActions, SchedulingRecreationActions,
} from '../../../../../app/redux/actions';

import AdvancedDatePicker from '../../../../../components/shared/AdvancedDatePicker/AdvancedDatePicker';
import AdvancedInput from '../../../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedButton from '../../../../../components/shared/AdvancedButton/AdvancedButton';
import DataTable from '../../../../../components/shared/DataTable/DataTable';
import { isAdmin, isCondominiumManager } from '../../../../../app/utils/permissions';
import { toCurrencyLocale } from '../../../../../app/utils/currency';

class RecreationAreaList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryId: null,
      recreationArea: null,
      dateInitial: null,
      dateEnd: null,
      description: null,
    };
  }

  componentDidMount() {
    const { id } = this.props;

    this.getDetails();
    this.setState({ categoryId: id });
  }

  getDetails() {
    const {
      getDetails, id,
    } = this.props;

    const param = id;

    getDetails(param);
  }

  getPaginatedData(params, redirect) {
    const { getPaginated } = this.props;
    const { categoryId } = this.state;

    getPaginated({ ...params, categoryId }, () => {
      if (redirect) this.setState({ recreationArea: null });
    });
  }

  remove(id) {
    const { remove } = this.props;
    const { categoryId } = this.state;

    remove(id, () => {
      message.success(I18n.t('routes.panel.schedule.items.recreationArea.messages.deleteSuccess'));
      this.getPaginatedData({ categoryId });
    });
  }

  async createSchedule() {
    const {
      recreationArea, dateInitial, dateEnd, description, categoryId,
    } = this.state;
    const { createBlockSchedule, getSchedules } = this.props;

    const schema = yup.object().shape({
      dateInitial: yup.string().required(),
      dateEnd: yup.string().required(),
      description: yup.string().required(),
    });

    schema
      .isValid({ dateInitial, dateEnd, description })
      .then(async (valid) => {
        if (!valid) {
          message.error(I18n.t('shared.genericFieldsError'));
        } else if (moment(dateInitial).isBefore(moment())) {
          message.error('Data inicial inválida!');
        } else if (moment(dateEnd).isBefore(moment())) {
          message.error('Data final inválida!');
        } else if (moment(dateEnd).isBefore(moment(dateInitial))) {
          message.error('Data final é anterior a data inicial!');
        } else {
          let alreadyBooked = null;

          const form = {
            scheduleType: 1,
            recreationId: recreationArea,
            description,
            dateInitial: moment(dateInitial).format(),
            dateEnd: moment(dateEnd).format(),
          };

          if (moment(dateInitial).isSame(moment(), 'day')) {
            await getSchedules({
              dateInitial: [moment().format(), moment().format()],
              recreationId: recreationArea,
            }, (response) => {
              const schedules = response.rows;
              if (schedules.length > 0) {
                alreadyBooked = schedules.find((item) => item.scheduleType === 2 && item.schedulingRecurringRecreation
                  && moment(dateInitial).isBetween(moment(item.schedulingRecurringRecreation.dateInitial),
                    moment(item.schedulingRecurringRecreation.dateEnd))
                  && moment().isBetween(moment(item.schedulingRecurringRecreation.dateInitial),
                    moment(item.schedulingRecurringRecreation.dateEnd)));
              }
            });
          }

          if (alreadyBooked) {
            const endHour = moment(alreadyBooked.schedulingRecurringRecreation.dateEnd).format('HH:mm');
            return message.error(`Existe um agendamento ocorrendo hoje até as ${endHour}. Troque o horário de bloqueio para concluir a ação`);
          }

          createBlockSchedule(form, () => {
            message.success(I18n.t('routes.panel.schedule.items.recreationArea.messages.successBlock'));
            this.getPaginatedData({ categoryId });
          });

          this.setState({
            recreationArea: null,
            dateInitial: null,
            dateEnd: null,
            description: null,
          });
        }
      }).catch(() => I18n.t('shared.genericError'));
  }

  render() {
    const { Content } = Layout;

    const {
      categoryId, recreationArea, dateInitial, dateEnd, description,
    } = this.state;
    const {
      loading, me, recreationPaginated, recreationCategory,
    } = this.props;

    const columns = [
      {
        title: I18n.t('routes.panel.schedule.items.recreationArea.dataTable.columns.name.title'),
        key: I18n.t('routes.panel.schedule.items.recreationArea.dataTable.columns.name.key'),
      },
      {
        title: I18n.t('routes.panel.schedule.items.recreationArea.dataTable.columns.price.title'),
        key: I18n.t('routes.panel.schedule.items.recreationArea.dataTable.columns.price.key'),
        render: (value) => (value && toCurrencyLocale(value.replace('.', ','))) || '--',
      },
      {
        title: I18n.t('routes.panel.schedule.items.recreationArea.dataTable.columns.hourOpening.title'),
        key: I18n.t('routes.panel.schedule.items.recreationArea.dataTable.columns.hourOpening.key'),
        render: (value) => (value && moment(value, 'HH:mm:ss').format('HH:mm')) || '--',
      },
      {
        title: I18n.t('routes.panel.schedule.items.recreationArea.dataTable.columns.hourClosing.title'),
        key: I18n.t('routes.panel.schedule.items.recreationArea.dataTable.columns.hourClosing.key'),
        render: (value) => (value && moment(value, 'HH:mm:ss').format('HH:mm')) || '--',
      },
    ];

    if (isCondominiumManager(me) || isAdmin(me)) {
      columns.splice(4, 0, {
        key: I18n.t('routes.panel.schedule.items.recreationArea.dataTable.columns.actions.key'),
        title: '',
        render: (id) => (
          <div className="dataTable__item--right">
            <Button
              type="link"
              onClick={() => this.setState({ recreationArea: id })}
            >
              {I18n.t('routes.panel.schedule.items.recreationArea.dataTable.columns.actions.blockSchedule')}
              <CalendarOutlined />
            </Button>
            <Link to={`${I18n.t('routes.panel.schedule.items.recreationArea.url')}/${categoryId}/${id}`}>
              {I18n.t('routes.panel.schedule.items.recreationArea.dataTable.columns.actions.goToDetailsText')}
              &nbsp;&nbsp;<EditOutlined />&nbsp;&nbsp;
            </Link>
            <Popconfirm
              placement="left"
              title={I18n.t('shared.confirmTitle')}
              onConfirm={() => this.remove(id)}
              okText={I18n.t('shared.yes')}
              cancelText={I18n.t('shared.no')}
            >
              <Button type="link">
                {I18n.t('routes.panel.schedule.items.recreationArea.dataTable.columns.actions.removeText')}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </div>
        ),
      });
    }

    return (
      <div>
        <Modal
          visible={!!recreationArea}
          onCancel={() => this.setState({ recreationArea: null })}
          onOk={() => this.createSchedule()}
          okText={I18n.t('routes.panel.schedule.items.recreationArea.scheduleBlockModal.okText')}
          title={I18n.t('routes.panel.schedule.items.recreationArea.scheduleBlockModal.title')}
        >
          <AdvancedDatePicker
            label={I18n.t('routes.panel.schedule.items.recreationArea.scheduleBlockModal.dateInitial')}
            onChange={(val) => this.setState({ dateInitial: val })}
            value={dateInitial}
            showTime={{ format: 'HH:mm' }}
            format="DD/MM/YYYY HH:mm"
          />
          <AdvancedDatePicker
            label={I18n.t('routes.panel.schedule.items.recreationArea.scheduleBlockModal.dateEnd')}
            onChange={(val) => this.setState({ dateEnd: val })}
            value={dateEnd}
            showTime={{ format: 'HH:mm' }}
            format="DD/MM/YYYY HH:mm"
          />
          <AdvancedInput
            label={I18n.t('routes.panel.schedule.items.recreationArea.details.form.description.label')}
            value={description}
            onChange={(val) => this.setState({ description: val })}
            isTextArea
            hasCountDown
          />
        </Modal>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" />
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={I18n.t('routes.panel.schedule.items.recreationArea.url')}>
                <span>{I18n.t('routes.panel.schedule.items.recreationArea.pageCategoryTitle')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.schedule.items.recreationArea.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content">
          <div className="administrators">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    <Icon type={I18n.t('routes.panel.schedule.items.recreationArea.icon')} />
                  </span>
                  {recreationCategory && `${recreationCategory.name} - `}
                  {I18n.t('routes.panel.schedule.items.recreationArea.pageTitle')}
                </h2>
              </Col>
              {(isCondominiumManager(me) || isAdmin(me)) && (
                <Col
                  className="text-right"
                  span={12}
                >
                  <AdvancedButton
                    text={I18n.t('routes.panel.schedule.items.recreationArea.addNewButtonText')}
                    icon={<Icon type={I18n.t('routes.panel.schedule.items.recreationArea.icon')} />}
                    href={`${I18n.t('routes.panel.schedule.items.recreationArea.url')}/${categoryId}/add`}
                  />
                </Col>
              )}
            </Row>
          </div>

          <Divider />
          {categoryId && (
            <div>
              <DataTable
                getMethod={(parameters) => this.getPaginatedData(parameters)}
                data={recreationPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                columns={columns}
              />
            </div>
          )}
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  recreationPaginated: RecreationSelectors.getPaginated(state),
  recreationCategory: RecreationCategorySelectors.getDetails(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getSchedules: (parameters, callback) => dispatch(SchedulingRecreationActions.getPaginated(parameters, callback)),
  getDetails: (id) => dispatch(RecreationCategoryActions.getDetails(id)),
  getPaginated: (parameters, me) => dispatch(RecreationActions.getPaginated(parameters, me)),
  remove: (id, callback) => dispatch(RecreationActions.remove(id, callback)),
  createBlockSchedule: (data, callback) => dispatch(RecreationActions.createBlockSchedule(data, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecreationAreaList);
