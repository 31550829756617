import React from 'react';
import {
  message,
  Upload,
} from 'antd';
import ImgCrop from 'antd-img-crop';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Buffer } from 'buffer';

import { getBase64, compressFileAndGetBase64 } from '../../../app/utils/image';

const customRequest = async ({ file, onSuccess, onError }, onChange, onUpload, compressImage) => {
  try {
    if (!file) return;

    let base64 = null;
    if (compressImage) {
      const compressedFile = await compressFileAndGetBase64(file);
      base64 = compressedFile.base64;
    } else {
      base64 = await getBase64(file);
    }

    const buffer = Buffer.from(base64.replace(/data:image\/\w+;base64,/, ''), 'base64');
    const { url } = await onUpload(buffer, file.name, file.type);
    onSuccess(file);
    onChange({ base64, url });
  } catch (error) {
    message.error('Formato do arquivo está inválido');
    onError(error);
  }
};

const AdvancedUpload = ({
  label,
  loading,
  pictureUrl,
  compressImage = true,
  aspect,
  shape,
  onChange,
  onUpload,
  beforeUpload,
}) => {
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  return (
    <div className="advanced-checkbox">
      <span className="d-block mb-2">{label}</span>
      <ImgCrop
        rotate
        grid
        aspect={aspect || 1.5}
        shape={shape || 'rect'}
        beforeCrop={beforeUpload || null}
      >
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          customRequest={(ev) => customRequest(ev, onChange, onUpload, compressImage)}
          beforeUpload={beforeUpload || null}
        >
          {pictureUrl ? (
            <img
              src={pictureUrl}
              alt="avatar"
              style={{ width: '100%' }}
            />
          ) : uploadButton}
        </Upload>
      </ImgCrop>
    </div>
  );
};

export default AdvancedUpload;
