import React from 'react';
import {
  Switch,
} from 'antd';

const AdvancedSwitch = ({ value, label, onChange }) => (
  <div className="my-2">
    <span className="mr-2">{label}</span>
    <Switch
      checked={value}
      onChange={onChange}
    />
  </div>
);

export default AdvancedSwitch;
