import React from 'react';
import {
  Divider,
  Layout,
  Row,
  Col,
  Breadcrumb,
  Icon,
  Collapse,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { SearchOutlined, EditOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { Link } from '@reach/router';

import {
  AuthSelectors, LoadingSelectors, TransactionSelectors,
} from '../../../../../app/redux/reducers';
import {
  TransactionActions, EstablishmentActions, UserActions,
} from '../../../../../app/redux/actions';

import QueryStringUtils from '../../../../../app/utils/queryString';
import * as DateUtils from '../../../../../app/utils/date';
import * as CurrencyUtils from '../../../../../app/utils/currency';
import { GetTransactionStatusTypeTag, TransactionStatusTypeArray } from '../../../../../app/enum/transactionStatusType';
import { isAdmin, isEstablishmentAdmin, isGoverningBody } from '../../../../../app/utils/permissions';
import { ProfileType } from '../../../../../app/enum/profileType';

import DataTable from '../../../../../components/shared/DataTable/DataTable';
import AdvancedButton from '../../../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedAutoCompleteInput from '../../../../../components/shared/AdvancedAutoCompleteInput/AdvancedAutoCompleteInput';
import AdvancedRangePicker from '../../../../../components/shared/AdvancedRangePicker/AdvancedRangePicker';
import AdvancedSelect from '../../../../../components/shared/AdvancedSelect/AdvancedSelect';
import ExportDataModal from '../../../../../components/panel/exportDataModal/ExportDataModal';

const qs = new QueryStringUtils();
const { Panel } = Collapse;

const defaultParams = {
  establishmentId: null,
  userId: null,
  motoboyId: null,
  status: null,
  createdAtRange: null,
};

class TransactionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: defaultParams,
      exportModalVisible: false,
      modalRange: false,
    };
    this.dataTableRef = React.createRef();
  }

  componentDidMount() {
    const { params } = qs.get();

    if (params) {
      this.setState({ params });
    }
  }

  getPaginatedData(params) {
    const { me } = this.props;
    const { getPaginated } = this.props;
    if (me && me.profileType === ProfileType.ESTABLISHMENT_ADMIN) params = { ...params, establishmentId: me.establishmentId };
    getPaginated(params, me);
  }

  getPaginatedDataAndRefreshTable() {
    const { params } = this.state;
    if (this.dataTableRef) {
      this.dataTableRef.current.reset(false);
      this.getPaginatedData(params);
    }
  }

  fieldChange(name, value) {
    const { params } = this.state;
    params[name] = value || null;
    this.setState({ params });
  }

  applyAdvancedFilters() {
    let { params } = this.state;
    params = {
      ...params,
      page: 1,
      offset: 0,
    };

    qs.set({ params });

    this.getPaginatedDataAndRefreshTable(params);
  }

  cleanAdvancedFilters() {
    qs.set({ params: undefined });
    window.location.reload();
  }

  render() {
    const { Content } = Layout;
    const {
      params, exportModalVisible, modalRange,
    } = this.state;

    const {
      me,
      transactionsPaginated,
      loading,
      getTransactionReportExport,
    } = this.props;

    const columns = [
      {
        key: I18n.t('routes.panel.establishment.items.transaction.dataTable.columns.code.key'),
        title: I18n.t('routes.panel.establishment.items.transaction.dataTable.columns.code.title'),
        render: (value) => value || '--',
      },
      {
        key: I18n.t('routes.panel.establishment.items.transaction.dataTable.columns.establishment.key'),
        title: I18n.t('routes.panel.establishment.items.transaction.dataTable.columns.establishment.title'),
        render: (value) => value || '--',
      },
      {
        key: I18n.t('routes.panel.establishment.items.transaction.dataTable.columns.condominium.key'),
        title: I18n.t('routes.panel.establishment.items.transaction.dataTable.columns.condominium.title'),
        render: (value) => (value && value.name) || '--',
      },
      {
        key: I18n.t('routes.panel.establishment.items.transaction.dataTable.columns.dweller.key'),
        title: I18n.t('routes.panel.establishment.items.transaction.dataTable.columns.dweller.title'),
        render: (value) => value || '--',
      },
      {
        key: I18n.t('routes.panel.establishment.items.transaction.dataTable.columns.motoboy.key'),
        title: I18n.t('routes.panel.establishment.items.transaction.dataTable.columns.motoboy.title'),
        render: (value) => value || '--',
      },
      {
        key: I18n.t('routes.panel.establishment.items.transaction.dataTable.columns.status.key'),
        title: I18n.t('routes.panel.establishment.items.transaction.dataTable.columns.status.title'),
        render: (value) => (value && GetTransactionStatusTypeTag(value)) || '--',
      },
      {
        key: I18n.t('routes.panel.establishment.items.transaction.dataTable.columns.totalPrice.key'),
        title: I18n.t('routes.panel.establishment.items.transaction.dataTable.columns.totalPrice.title'),
        render: (value) => (CurrencyUtils.toCurrencyLocale(value)) || '--',
      },
      {
        key: I18n.t('routes.panel.establishment.items.transaction.dataTable.columns.commission.key'),
        title: I18n.t('routes.panel.establishment.items.transaction.dataTable.columns.commission.title'),
        render: (value) => (CurrencyUtils.toCurrencyLocale(value)) || '--',
      },
      {
        key: I18n.t('routes.panel.establishment.items.transaction.dataTable.columns.beecash.key'),
        title: I18n.t('routes.panel.establishment.items.transaction.dataTable.columns.beecash.title'),
        render: (value) => (CurrencyUtils.toCurrencyLocale(value)) || '--',
      },
      {
        key: I18n.t('routes.panel.establishment.items.transaction.dataTable.columns.taxGateway.key'),
        title: I18n.t('routes.panel.establishment.items.transaction.dataTable.columns.taxGateway.title'),
        render: (value) => (CurrencyUtils.toCurrencyLocale(value)) || '--',
      },
      {
        key: I18n.t('routes.panel.establishment.items.transaction.dataTable.columns.provider.key'),
        title: I18n.t('routes.panel.establishment.items.transaction.dataTable.columns.provider.title'),
        render: (value) => (CurrencyUtils.toCurrencyLocale(value)) || '--',
      },
      {
        key: I18n.t('routes.panel.establishment.items.transaction.dataTable.columns.installments.key'),
        title: I18n.t('routes.panel.establishment.items.transaction.dataTable.columns.installments.title'),
        render: (value) => value || '--',
      },
      {
        key: I18n.t('routes.panel.establishment.items.transaction.dataTable.columns.createdAt.key'),
        title: I18n.t('routes.panel.establishment.items.transaction.dataTable.columns.createdAt.title'),
        render: (value) => (value && DateUtils.humanizeDateTime(value, 'DD/MM/YYYY HH:mm')) || '--',
      },
      {
        key: I18n.t('routes.panel.establishment.items.transaction.dataTable.columns.actions.key'),
        title: '',
        render: (id) => (
          <div className="dataTable__item--right">
            <Link to={`${I18n.t('routes.panel.establishment.items.transaction.url')}/${id}`}>
              {I18n.t('routes.panel.users.items.doorman.dataTable.columns.actions.goToDetailsText')}
              &nbsp;&nbsp;<EditOutlined />
            </Link>
          </div>
        ),
      },
    ];

    if (isGoverningBody(me)) {
      columns.splice(0, 1);
      columns.splice(1, 1);
      columns.splice(3, 1);
      columns.splice(3, 2);
      columns.splice(4, 2);
      columns.pop();
    }

    if (isEstablishmentAdmin(me)) {
      columns.splice(1, 1);
    }

    return (
      <div>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" />
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.establishment.items.transaction.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse
            className="advanced-filter"
          >
            <Panel
              header={<strong>{I18n.t('routes.panel.establishment.items.transaction.advancedFilter.panelTitle')}</strong>}
              key="1"
            >
              <Row gutter={16}>
                {isAdmin(me) && (
                <Col span={8}>
                  <AdvancedAutoCompleteInput
                    searchMethod={(parameters) => this.props.getEstablishmentPaginatedForSearch(parameters)}
                    data={I18n.t('advancedAutoComplete.establishment')}
                    onSelect={(item) => this.fieldChange('establishmentId', item.id)}
                    onSelectSelectable={(id) => this.fieldChange('establishmentId', id)}
                    value={params && params.establishmentId}
                    disableSearch
                  />
                </Col>
                )}
                {isAdmin(me) && (
                  <Col span={8}>
                    <AdvancedAutoCompleteInput
                      searchMethod={(parameters) => this.props.getDwellerPaginatedForSearch(parameters)}
                      data={I18n.t('advancedAutoComplete.user')}
                      onSelect={(item) => this.fieldChange('userId', item.id)}
                      onSelectSelectable={(id) => this.fieldChange('userId', id)}
                      value={params && params.userId}
                      disableSearch
                    />
                  </Col>
                )}
                {!isGoverningBody(me) && (
                  <Col span={8}>
                    <AdvancedAutoCompleteInput
                      searchMethod={(parameters) => this.props.getDeliveryManPaginatedForSearch(parameters)}
                      data={I18n.t('advancedAutoComplete.deliveryMan')}
                      onSelect={(item) => this.fieldChange('motoboyId', item.id)}
                      onSelectSelectable={(id) => this.fieldChange('motoboyId', id)}
                      value={params && params.motoboyId}
                      disableSearch
                    />
                  </Col>
                )}
                {!isGoverningBody(me) && (
                  <Col span={8}>
                    <AdvancedSelect
                      label={I18n.t('routes.panel.establishment.items.transaction.dataTable.columns.status.title')}
                      onChange={(val) => this.fieldChange('status', val)}
                      value={params && params.status}
                      options={TransactionStatusTypeArray}
                    />
                  </Col>
                )}
                <Col span={8}>
                  <AdvancedRangePicker
                    label="Período"
                    onChange={(val) => this.fieldChange('createdAtRange', val)}
                    placeholder={I18n.t('shared.type')}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col
                  span={24}
                  className="advanced-filter__search-button text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.advancedFilter.cleanButtonText')}
                    onClick={() => this.cleanAdvancedFilters()}
                  />
                  <AdvancedButton
                    text={I18n.t('shared.advancedFilter.searchButtonText')}
                    icon={<SearchOutlined />}
                    onClick={() => this.applyAdvancedFilters()}
                  />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="administrators">
            <Row gutter={16}>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    <Icon type={I18n.t('routes.panel.establishment.items.transaction.icon')} />
                  </span>
                  {I18n.t('routes.panel.establishment.items.transaction.pageTitle')}
                </h2>
              </Col>
              {(isEstablishmentAdmin(me) || isAdmin(me)) && (
                <Col
                  className="text-right"
                  span={12}
                >
                  <AdvancedButton
                    text={I18n.t('routes.panel.establishment.items.transaction.exportButtonText')}
                    icon={<CloudDownloadOutlined />}
                    onClick={() => this.setState({ exportModalVisible: true })}
                    className="advanced-button margin-right"
                  />
                  {isEstablishmentAdmin(me) && (
                    <AdvancedButton
                      text={I18n.t('routes.panel.establishment.items.transaction.addNewButtonText')}
                      href={`${I18n.t('routes.panel.establishment.items.transaction.url')}/add`}
                      icon={<Icon type={I18n.t('routes.panel.establishment.items.transaction.icon')} />}
                    />
                  )}
                </Col>
              )}
            </Row>

            <Divider />

            <div>
              <DataTable
                getMethod={(parameters) => this.getPaginatedData(parameters)}
                data={transactionsPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                columns={columns}
              />
            </div>

            <ExportDataModal
              onChangeVisible={(visible) => this.setState({ exportModalVisible: visible })}
              exportFunction={() => getTransactionReportExport({ modalRange })}
              onChangeField={(val) => this.setState({ modalRange: val })}
              isVisible={exportModalVisible}
              loading={loading}
              me={me}
              data={modalRange}
            />
          </div>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  transactionsPaginated: TransactionSelectors.getPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters, me) => dispatch(TransactionActions.getPaginated(parameters, me)),

  getEstablishmentPaginatedForSearch: () => dispatch(EstablishmentActions.getPaginatedForSearch()),
  getDwellerPaginatedForSearch: (params) => dispatch(UserActions.getPaginatedCondominiumMemberForSearch({ ...params })),
  getDeliveryManPaginatedForSearch: (params) => dispatch(UserActions.getPaginatedForSearch({ ...params, profileType: ProfileType.MOTOBOY })),
  getTransactionReportExport: (params) => dispatch(EstablishmentActions.exportReport(params)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransactionList);
