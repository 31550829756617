import { Translate } from 'react-redux-i18n';
import React from 'react';

export const RecreationCategoryType = [
  { id: 'Cinema', name: <Translate value="enum.recreationCategory.movie" /> },
  { id: 'Churrasqueira', name: <Translate value="enum.recreationCategory.bbq" /> },
  { id: 'Salões', name: <Translate value="enum.recreationCategory.halls" /> },
  { id: 'Piscina', name: <Translate value="enum.recreationCategory.swimming" /> },
  { id: 'Quadra', name: <Translate value="enum.recreationCategory.block" /> },
  { id: 'Academia', name: <Translate value="enum.recreationCategory.gym" /> },
  { id: 'Sauna', name: <Translate value="enum.recreationCategory.steamRoom" /> },
  { id: 'Brinquedoteca', name: <Translate value="enum.recreationCategory.playroom" /> },
];
