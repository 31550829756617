import { Translate } from 'react-redux-i18n';
import React from 'react';

const CondominiumType = {
  HORIZONTAL: 1,
  VERTICAL: 2,
};

export const CondominiumTypeArray = [
  { id: CondominiumType.HORIZONTAL, name: <Translate value="enum.condominiumType.horizontal" /> },
  { id: CondominiumType.VERTICAL, name: <Translate value="enum.condominiumType.vertical" /> },
];

export const GetCondominiumTypeTitle = (value) => (CondominiumTypeArray.find((o) => o.id === value).name);

export default CondominiumType;
