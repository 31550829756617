/* eslint-disable no-dupe-else-if */
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Modal, Button } from 'antd';

import {
  PushNotificationSelectors,
} from '../../../app/redux/reducers';
import {
  PushNotificationActions,
} from '../../../app/redux/actions';

import DataTable from '../../shared/DataTable/DataTable';

class DwellerTableModal extends React.PureComponent {
  render() {
    const {
      visible, condominiumId, handleCancel, usersForPushNotificationPaginated,
      getUsersForPushNotificationPaginated, applyOnImportUser, importUsers,
    } = this.props;

    return (
      <Modal
        title={I18n.t('component.dwellerTableModal.title')}
        visible={visible}
        onCancel={(ev) => handleCancel(ev)}
        onOk={() => handleCancel()}
        okText={I18n.t('component.dwellerTableModal.ok')}
        width={1000}
      >
        <DataTable
          getMethod={(parameters) => getUsersForPushNotificationPaginated({ ...parameters, condominiumId })}
          data={usersForPushNotificationPaginated}
          loading={!usersForPushNotificationPaginated}
          ref={this.dataTableRef}
          columns={[
            {
              key: I18n.t('routes.panel.pushNotification.items.send.form.datatable.name.key'),
              title: I18n.t('routes.panel.pushNotification.items.send.form.datatable.name.label'),
              render: (value) => value || '--',
            },
            {
              key: I18n.t('routes.panel.pushNotification.items.send.form.datatable.email.key'),
              title: I18n.t('routes.panel.pushNotification.items.send.form.datatable.email.label'),
              render: (value) => value,
            },
            {
              key: I18n.t('routes.panel.pushNotification.items.send.form.datatable.cellphone.key'),
              title: I18n.t('routes.panel.pushNotification.items.send.form.datatable.cellphone.label'),
              render: (value) => value || '--',
            },
            {
              title: '',
              render: (userEmail, body) => (
                <div className="dataTable__item--right">
                  <Button
                    type="link"
                    onClick={() => applyOnImportUser(body.email)}
                  >
                    {importUsers.find((item) => item === body.email)
                      ? I18n.t('component.dwellerTableModal.delete')
                      : I18n.t('component.dwellerTableModal.add')}
                  </Button>
                </div>
              ),
            },
          ]}
          bordered={false}
          pagination={false}
          size="middle"
          locale={{
            emptyText: I18n.t('shared.nothingToShow'),
          }}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  usersForPushNotificationPaginated: PushNotificationSelectors.getUsersForPushNotificationPaginated(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUsersForPushNotificationPaginated: (parameters, me) => dispatch(
    PushNotificationActions.getUsersForPushNotification(parameters, me),
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  DwellerTableModal,
);
