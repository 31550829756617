import React from 'react';
import {
  Divider,
  Layout,
  Popconfirm,
  Button,
  Row,
  Col,
  message,
  Breadcrumb,
  Icon,
  Collapse,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  DeleteOutlined, EditOutlined, SearchOutlined,
} from '@ant-design/icons';
import { Link } from '@reach/router';

import {
  AuthSelectors, LoadingSelectors, CondominiumAdministratorSelectors,
} from '../../../../../app/redux/reducers';
import { AuthActions, CondominiumAdministratorActions } from '../../../../../app/redux/actions';

import DataTable from '../../../../../components/shared/DataTable';
import AdvancedButton from '../../../../../components/shared/AdvancedButton';
import AdvancedInput from '../../../../../components/shared/AdvancedInput';
import QueryStringUtils from '../../../../../app/utils/queryString';

const qs = new QueryStringUtils();
const { Panel } = Collapse;

const defaultParams = {
  administratorName: null,
  contactName: null,
  email: null,
};

class CondominiumAdministratorList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: defaultParams,
    };
    this.dataTableRef = React.createRef();
  }

  componentDidMount() {
    const { params } = qs.get();

    if (params) {
      this.setState({ params });
    }
  }

  getPaginatedData(params) {
    const { me } = this.props;
    const { getPaginated } = this.props;

    getPaginated(params, me);
  }

  getPaginatedDataAndRefreshTable() {
    const { params } = this.state;
    if (this.dataTableRef) {
      this.dataTableRef.current.reset(false);
      this.getPaginatedData(params);
    }
  }

  fieldChange(name, value) {
    const { params } = this.state;
    params[name] = value || null;
    this.setState({ params });
  }

  remove(id) {
    this.props.remove(id, () => {
      message.success(I18n.t('routes.panel.condominium.items.condominiumAdministrator.messages.deleteSuccess'));
      this.getPaginatedDataAndRefreshTable();
    });
  }

  applyAdvancedFilters() {
    let { params } = this.state;
    params = {
      ...params,
      page: 1,
      offset: 0,
    };

    qs.set({ params });

    this.getPaginatedDataAndRefreshTable(params);
  }

  cleanAdvancedFilters() {
    qs.set({ params: undefined });
    window.location.reload();
  }

  render() {
    const { Content } = Layout;
    const {
      params,
    } = this.state;

    const {
      condominiumsAdministratorPaginated,
      loading,
      me,
    } = this.props;
    return (
      <div>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" />
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.condominium.items.condominiumAdministrator.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse
            className="advanced-filter"
          >
            <Panel
              header={<strong>{I18n.t('routes.panel.condominium.items.condominiumAdministrator.advancedFilter.panelTitle')}</strong>}
              key="1"
            >
              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.condominium.items.condominiumAdministrator.advancedFilter.administratorName.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.condominium.items.condominiumAdministrator.advancedFilter.administratorName.key'), val)}
                    value={params && params.administratorName}
                  />
                </Col>

                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.condominium.items.condominiumAdministrator.advancedFilter.contactName.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.condominium.items.condominiumAdministrator.advancedFilter.contactName.key'), val)}
                    value={params && params.contactName}
                  />
                </Col>

                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.condominium.items.condominiumAdministrator.advancedFilter.email.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.condominium.items.condominiumAdministrator.advancedFilter.email.key'), val)}
                    value={params && params.email}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col
                  span={24}
                  className="advanced-filter__search-button text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.advancedFilter.cleanButtonText')}
                    onClick={() => this.cleanAdvancedFilters()}
                  />
                  <AdvancedButton
                    text={I18n.t('shared.advancedFilter.searchButtonText')}
                    icon={<SearchOutlined />}
                    onClick={() => this.applyAdvancedFilters()}
                  />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="administrators">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    <Icon type={I18n.t('routes.panel.condominium.items.condominiumAdministrator.icon')} />
                  </span>
                  {I18n.t('routes.panel.condominium.items.condominiumAdministrator.pageTitle')}
                </h2>
              </Col>
              <Col
                className="text-right"
                span={12}
              >
                <AdvancedButton
                  text={I18n.t('routes.panel.condominium.items.condominiumAdministrator.addNewButtonText')}
                  href={`${I18n.t('routes.panel.condominium.items.condominiumAdministrator.url')}/add`}
                  icon={<Icon type={I18n.t('routes.panel.condominium.items.condominiumAdministrator.icon')} />}
                />
              </Col>
            </Row>

            <Divider />

            <div>
              <DataTable
                getMethod={(parameters) => this.getPaginatedData(parameters)}
                data={condominiumsAdministratorPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                columns={
                  [
                    {
                      key: I18n.t('routes.panel.condominium.items.condominiumAdministrator.dataTable.columns.administratorName.key'),
                      title: I18n.t('routes.panel.condominium.items.condominiumAdministrator.dataTable.columns.administratorName.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.condominium.items.condominiumAdministrator.dataTable.columns.contactName.key'),
                      title: I18n.t('routes.panel.condominium.items.condominiumAdministrator.dataTable.columns.contactName.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.condominium.items.condominiumAdministrator.dataTable.columns.email.key'),
                      title: I18n.t('routes.panel.condominium.items.condominiumAdministrator.dataTable.columns.email.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.condominium.items.condominiumAdministrator.dataTable.columns.cellphone.key'),
                      title: I18n.t('routes.panel.condominium.items.condominiumAdministrator.dataTable.columns.cellphone.title'),
                      render: (value) => (value && `+${value.substring(0, 2)} (${value.substring(2, 4)}) ${value.substring(4, 9)}-${value.substring(9, 13)}`) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.condominium.items.condominiumAdministrator.dataTable.columns.document.key'),
                      title: I18n.t('routes.panel.condominium.items.condominiumAdministrator.dataTable.columns.document.title'),
                      render: (value) => (value && `${value.substring(0, 2)}.${value.substring(2, 5)}.${value.substring(5, 8)}/${value.substring(8, 12)}-${value.substring(12, 14)}`) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.condominium.items.condominiumAdministrator.dataTable.columns.actions.key'),
                      title: '',
                      render: (id) => (
                        <div className="dataTable__item--right">
                          <Popconfirm
                            placement="left"
                            title={I18n.t('shared.confirmTitle')}
                            onConfirm={() => this.remove(id)}
                            okText={I18n.t('shared.yes')}
                            cancelText={I18n.t('shared.no')}
                            disabled={me && me.id === id}
                          >
                            <Button
                              type="link"
                              disabled={me && me.id === id}
                            >
                              {I18n.t('routes.panel.condominium.items.condominiumAdministrator.dataTable.columns.actions.removeText')}
                              <DeleteOutlined />
                            </Button>
                          </Popconfirm>
                          <Link to={`${I18n.t('routes.panel.condominium.items.condominiumAdministrator.url')}/${id}`}>
                            {I18n.t('routes.panel.condominium.items.condominiumAdministrator.dataTable.columns.actions.goToDetailsText')}
                            &nbsp;&nbsp;<EditOutlined />
                          </Link>
                        </div>
                      ),
                    },
                  ]
                }
              />
            </div>
          </div>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  condominiumsAdministratorPaginated: CondominiumAdministratorSelectors.getPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters, me) => dispatch(CondominiumAdministratorActions.getPaginated(parameters, me)),
  remove: (id, callback) => dispatch(CondominiumAdministratorActions.remove(id, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CondominiumAdministratorList);
