import { AuthActions } from '../actions';

const initialState = {
  authenticated: null,
  me: null,
};

export default (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case AuthActions.ACTION_AUTH_LOGIN:
      return { authenticated: true };
    case AuthActions.ACTION_ME:
      state = {
        ...state,
        me: action.payload,
      };
      return state;
    case AuthActions.ACTION_AUTH_LOGOUT:
      return {};
    default:
      return state;
  }
};

export function isAuthenticated(state) {
  return state.auth.authenticated;
}

export function getMe(state) {
  return state.auth.me;
}
