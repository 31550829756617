import getInstance from './config';

export default class SchedulingRecreationApi {
  static async getPaginated(params) {
    const instance = await getInstance();
    const { data } = await instance.get('/v1/scheduling-recreation', { params });
    return data;
  }

  static async getDetails(id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/v1/scheduling-recreation/${id}`);
    return data;
  }

  static async update(id, userData) {
    const instance = await getInstance();
    const { data } = await instance.put(`/v1/scheduling-recreation/${id}`, userData);
    return data;
  }

  static async create(userData) {
    const instance = await getInstance();
    const { data } = await instance.post('/v1/scheduling-recreation', userData);
    return data;
  }

  static async remove(id) {
    const instance = await getInstance();
    const { data } = await instance.delete(`/v1/scheduling-recreation/${id}`);
    return data;
  }

  static async exportReport(params) {
    const instance = await getInstance();
    const { data } = await instance.get('/v1/scheduling-recreation/export', { params });
    return data;
  }
}
