import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import {
  addLoading,
  removeLoading,
} from './loading';
import ChannelsRequests from '../../api/channels';
import { addLoadingSearch, removeLoadingSearch } from './loading-search';

export const ACTION_SAVE_CHANNELS_PAGINATED = 'ACTION_SAVE_CHANNELS_PAGINATED';
export const ACTION_SAVE_CHANNELS_DETAILS = 'ACTION_SAVE_CHANNELS_DETAILS';
export const ACTION_SAVE_USER_CHANNELS_PAGINATED = 'ACTION_SAVE_USER_CHANNELS_PAGINATED';

export const ACTION_CLEAN_CONDOMINIUM = 'ACTION_CLEAN_CONDOMINIUM';

// cleaners
export const clean = () => ({
  type: ACTION_CLEAN_CONDOMINIUM,
  payload: null,
});

export const cleanDetails = () => ({
  type: ACTION_SAVE_CHANNELS_DETAILS,
  payload: null,
});

export const cleanPaginated = () => ({
  type: ACTION_SAVE_CHANNELS_PAGINATED,
  payload: null,
});

/*
 * BASIC CRUD METHODS
 *
 * getPaginated: get rows for data table
 * getDetails: get details (get by id)
 * update: the PUT method
 * create: the POST method
 */

export const getPaginated = (parameters, callback) => async (dispatch) => {
  dispatch(addLoading());
  dispatch(cleanPaginated());

  try {
    parameters = {
      ...parameters,
      limit: parameters.limit || 10,
      total: parameters.total || 0,
      page: parameters.page || 1,
      orderBy: 'createdAt',
      isDESC: true,
    };

    dispatch({
      type: ACTION_SAVE_CHANNELS_PAGINATED,
      payload: null,
    });

    const response = await ChannelsRequests.getPaginated(parameters);

    dispatch({
      type: ACTION_SAVE_CHANNELS_PAGINATED,
      payload: response,
    });

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const getDetails = (id, callback) => async (dispatch) => {
  dispatch(addLoading());
  dispatch(cleanDetails());

  try {
    let response = null;
    dispatch({
      type: ACTION_SAVE_CHANNELS_DETAILS,
      payload: null,
    });

    if (id !== 'add') {
      response = await ChannelsRequests.getDetails(id);

      dispatch({
        type: ACTION_SAVE_CHANNELS_DETAILS,
        payload: response,
      });
    }

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const update = (id, data, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    if (data.email) {
      delete data.email;
    }
    if (data.cellphone) {
      delete data.cellphone;
    }

    const response = await ChannelsRequests.update(id, data);

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const create = (data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const response = await ChannelsRequests.create(data);

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const remove = (id, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const response = await ChannelsRequests.remove(id);
    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

/*
 * ADVANCED METHODS
 *
 * getPaginatedForSearch: get rows for <AdvancedAutoCompleteInput /> component
 */

export const getPaginatedForSearch = (parameters, callback) => async (dispatch) => {
  let response = null;
  dispatch(addLoadingSearch());

  try {
    parameters = {
      ...parameters,
      limit: parameters ? parameters.limit : 10,
      total: parameters ? parameters.total : 0,
      page: parameters ? parameters.page : 1,
      orderBy: 'createdAt',
      isDESC: true,
    };

    if (parameters && parameters.searchInput) {
      parameters = {
        ...parameters,
        name: parameters.searchInput,
      };
    }

    response = await ChannelsRequests.getSelectives(parameters);

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoadingSearch());
  }

  return response;
};

export const getUsersPaginated = (id, parameters, callback) => async (dispatch) => {
  try {
    parameters = {
      ...parameters,
      limit: parameters.limit || 10,
      total: parameters.total || 0,
      page: parameters.page || 1,
      orderBy: 'createdAt',
      isDESC: true,
    };

    dispatch({
      type: ACTION_SAVE_USER_CHANNELS_PAGINATED,
      payload: null,
    });
    const response = await ChannelsRequests.getUsersPaginated(id, parameters);

    dispatch({
      type: ACTION_SAVE_USER_CHANNELS_PAGINATED,
      payload: response,
    });

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  }
};
