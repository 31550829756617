/* eslint-disable react/no-unused-state */
import React from 'react';
import {
  Divider,
  Layout,
  Popconfirm,
  Button,
  Row,
  Col,
  message,
  Breadcrumb,
  Icon,
  Modal,
  Checkbox,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  DeleteOutlined, EditOutlined, ProfileOutlined,
} from '@ant-design/icons';
import { Link } from '@reach/router';

import {
  AuthSelectors, LoadingSelectors, RecreationCategorySelectors,
} from '../../../../../app/redux/reducers';
import {
  AuthActions, RecreationCategoryActions, CondominiumActions,
} from '../../../../../app/redux/actions';
import { GetVisitIsEmployeeTag } from '../../../../../app/enum/visitIsEmployee';
import { RecreationCategoryType } from '../../../../../app/enum/recreationCategoryType';

import AdvancedSelect from '../../../../../components/shared/AdvancedSelect/AdvancedSelect';
import AdvancedButton from '../../../../../components/shared/AdvancedButton/AdvancedButton';
import DataTable from '../../../../../components/shared/DataTable/DataTable';
import AdvancedAutoCompleteInput from '../../../../../components/shared/AdvancedAutoCompleteInput/AdvancedAutoCompleteInput';
import AdvancedInput from '../../../../../components/shared/AdvancedInput/AdvancedInput';
import { isAdmin, isCondominiumManager, isGoverningBody } from '../../../../../app/utils/permissions';

class RecreationAreaList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      isAdminModalVisible: true,
      recreationCategoryName: '',
      isEditModal: null,
      isPreDefined: false,
      preDefinedValue: null,
      condominiumId: null,
    };
  }

  componentDidMount() {
    const { me } = this.props;

    if (isGoverningBody(me)) this.setState({ isAdminModalVisible: false, condominiumId: me.condominiumId });
  }

  getPaginatedData(params, redirect) {
    const { getPaginated } = this.props;

    getPaginated(params, () => {
      if (redirect) this.setState({ isModalVisible: false });
    });
  }

  remove(id) {
    const { remove } = this.props;
    const { condominiumId } = this.state;

    remove(id, () => {
      message.success(I18n.t('routes.panel.schedule.items.recreationArea.messages.deleteSuccess'));
      this.getPaginatedData({ condominiumId }, true);
    });
  }

  save() {
    const { update, create, me } = this.props;
    const {
      recreationCategoryName, isEditModal, preDefinedValue, isPreDefined, condominiumId,
    } = this.state;

    try {
      if (!isEditModal) {
        const data = [];
        if (isPreDefined) {
          preDefinedValue.forEach((item) => {
            data.push({
              name: item,
              active: true,
            });
          });
        } else {
          data.push({
            name: recreationCategoryName,
            active: true,
          });
        }
        create({
          categories: data,
          ...(isAdmin(me) && { condominiumId }),
        }, (response) => {
          if (response.notAdd.length > 0) {
            message.warning(`O${response.notAdd.length !== 1 ? 's' : ''} tipo${response.notAdd.length !== 1 ? 's' : ''}: ${response.notAdd.toString()} já existe${response.notAdd.length !== 1 ? 'm' : ''}`);
          } else message.success(I18n.t('routes.panel.schedule.items.recreationArea.messages.successCreate'));
          this.getPaginatedData({ condominiumId }, true);
        });
      } else {
        update(isEditModal, { name: recreationCategoryName, active: isPreDefined }, () => {
          message.success(I18n.t('routes.panel.schedule.items.recreationArea.messages.successEdit'));
          this.getPaginatedData({ condominiumId }, true);
        });
      }
    } catch (e) {
      message.error(I18n.t('shared.genericError'));
    }
  }

  render() {
    const { Content } = Layout;

    const {
      isModalVisible, recreationCategoryName, isEditModal, isPreDefined, preDefinedValue,
      condominiumId, isAdminModalVisible,
    } = this.state;
    const {
      loading, me, recreationCategoryPaginated, getCondominiumPaginatedForSearch,
    } = this.props;

    return (
      <div>
        {isModalVisible && (
          <Modal
            title={I18n.t(`routes.panel.schedule.items.recreationArea.categoryModal.${isEditModal ? 'editTitle' : 'title'}`)}
            visible
            onOk={() => this.save()}
            okText={I18n.t('routes.panel.schedule.items.recreationArea.categoryModal.okText')}
            onCancel={() => this.setState({
              isModalVisible: false,
              isEditModal: null,
              recreationCategoryName: '',
              isPreDefined: false,
              preDefinedValue: null,
            })}
          >
            {(isPreDefined && !isEditModal) && (
              <AdvancedSelect
                options={RecreationCategoryType}
                label={I18n.t('routes.panel.schedule.items.recreationArea.categoryModal.preDefined')}
                placeholder={I18n.t('shared.selectSomeValue')}
                onChange={(val) => this.setState({ preDefinedValue: val })}
                disableSearch
                mode="multiple"
                hasValue
                value={preDefinedValue}
              />
            )}
            {(!isPreDefined || isEditModal) && (
              <AdvancedInput
                label={I18n.t('routes.panel.schedule.items.recreationArea.categoryModal.inputName')}
                onChange={(val) => this.setState({ recreationCategoryName: val })}
                value={recreationCategoryName}
              />
            )}
            <Checkbox
              defaultChecked={isPreDefined}
              value={isPreDefined}
              onChange={() => this.setState((prevState) => ({ isPreDefined: !prevState.isPreDefined, preDefinedValue: null }))}
            >
              {I18n.t(`routes.panel.schedule.items.recreationArea.categoryModal.${isEditModal ? 'checkboxEdit' : 'checkboxCreate'}`)}
            </Checkbox>
          </Modal>
        )}

        {isAdminModalVisible && (
          <Modal
            title={I18n.t('routes.panel.schedule.items.recreationArea.condominiumModal.title')}
            visible
            onOk={() => {
              if (!condominiumId) return message.error(I18n.t('routes.panel.schedule.items.recreationArea.condominiumModal.error'));
              this.setState({ isAdminModalVisible: false });
            }}
            okText={I18n.t('routes.panel.schedule.items.recreationArea.condominiumModal.okText')}
            onCancel={() => {
              if (!condominiumId) return message.error(I18n.t('routes.panel.schedule.items.recreationArea.condominiumModal.error'));
              this.setState({ isAdminModalVisible: false });
            }}
          >
            <AdvancedAutoCompleteInput
              searchMethod={(parameters) => getCondominiumPaginatedForSearch(parameters)}
              data={I18n.t('advancedAutoComplete.condominium')}
              onSelect={(item) => this.setState({ condominiumId: item.id })}
              onSelectSelectable={(id) => this.setState({ condominiumId: id })}
              value={condominiumId}
              disableSearch
            />
          </Modal>
        )}

        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" />
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.schedule.items.recreationArea.pageCategoryTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content">
          <div className="administrators">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    <Icon type={I18n.t('routes.panel.schedule.items.recreationArea.icon')} />
                  </span>
                  {I18n.t('routes.panel.schedule.items.recreationArea.pageCategoryTitle')}
                </h2>
              </Col>
              {(isCondominiumManager(me) || isAdmin(me)) && (
                <Col
                  className="text-right"
                  span={12}
                >
                  <AdvancedButton
                    text={I18n.t('routes.panel.schedule.items.recreationArea.addCategoryNewButtonText')}
                    icon={<Icon type={I18n.t('routes.panel.schedule.items.recreationArea.icon')} />}
                    onClick={() => this.setState({ isModalVisible: true })}
                  />
                </Col>
              )}
            </Row>
          </div>

          <Divider />
          <div>
            {((isAdmin(me) && !isAdminModalVisible) || (isGoverningBody(me) && condominiumId)) && (
              <DataTable
                getMethod={(parameters) => this.getPaginatedData({ ...parameters, condominiumId })}
                data={recreationCategoryPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                columns={[
                  {
                    title: I18n.t('routes.panel.schedule.items.recreationArea.dataTable.columns.name.title'),
                    key: I18n.t('routes.panel.schedule.items.recreationArea.dataTable.columns.name.key'),
                  },
                  {
                    title: I18n.t('routes.panel.schedule.items.recreationArea.dataTable.columns.active.title'),
                    key: I18n.t('routes.panel.schedule.items.recreationArea.dataTable.columns.active.key'),
                    render: (value) => (value && GetVisitIsEmployeeTag(value)) || GetVisitIsEmployeeTag(false),
                  },
                  {
                    key: I18n.t('routes.panel.schedule.items.recreationArea.dataTable.columns.actions.key'),
                    title: '',
                    render: (id, body) => (
                      <div className="dataTable__item--right">
                        <Link to={`${I18n.t('routes.panel.schedule.items.recreationArea.url')}/${id}`}>
                          {I18n.t('routes.panel.schedule.items.recreationArea.dataTable.columns.actions.goToRecreationArea')}
                          &nbsp;&nbsp;<ProfileOutlined />&nbsp;&nbsp;
                        </Link>
                        {(isCondominiumManager(me) || isAdmin(me)) && (
                          <>
                            <Button
                              type="link"
                              disabled={me && me.id === id}
                              onClick={() => this.setState({
                                recreationCategoryName: body.name,
                                isPreDefined: body.active,
                                isModalVisible: true,
                                isEditModal: body.id,
                              })}
                            >
                              {I18n.t('routes.panel.schedule.items.recreationArea.dataTable.columns.actions.goToDetailsText')}
                              <EditOutlined />
                            </Button>
                            <Popconfirm
                              placement="left"
                              title={I18n.t('shared.confirmTitle')}
                              onConfirm={() => this.remove(id)}
                              okText={I18n.t('shared.yes')}
                              cancelText={I18n.t('shared.no')}
                              disabled={me && me.id === id}
                            >
                              <Button
                                type="link"
                                disabled={me && me.id === id}
                              >
                                {I18n.t('routes.panel.schedule.items.recreationArea.dataTable.columns.actions.removeText')}
                                <DeleteOutlined />
                              </Button>
                            </Popconfirm>
                          </>
                        )}
                      </div>
                    ),
                  },
                ]}
              />
            )}
          </div>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  recreationCategoryPaginated: RecreationCategorySelectors.getPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters, callback) => dispatch(RecreationCategoryActions.getPaginated(parameters, callback)),
  getCondominiumPaginatedForSearch: (parameters) => dispatch(CondominiumActions.getPaginatedForSearch(parameters)),
  remove: (id, callback) => dispatch(RecreationCategoryActions.remove(id, callback)),
  create: (data, callback) => dispatch(RecreationCategoryActions.create(data, callback)),
  update: (id, data, callback) => dispatch(RecreationCategoryActions.update(id, data, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RecreationAreaList);
