import getInstance from './config';

export default class CategoryApi {
  static async getPaginated(params) {
    const instance = await getInstance();
    const { data } = await instance.get('/v1/category', { params });
    return data;
  }

  static async getDetails(id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/v1/category/${id}`);
    return data;
  }

  static async update(id, categoryData) {
    const instance = await getInstance();
    const { data } = await instance.put(`/v1/category/${id}`, categoryData);
    return data;
  }

  static async create(categoryData) {
    const instance = await getInstance();
    const { data } = await instance.post('/v1/category', categoryData);
    return data;
  }

  static async remove(id) {
    const instance = await getInstance();
    const { data } = await instance.delete(`/v1/category/${id}`);
    return data;
  }
}
