import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import {
  addLoading,
  removeLoading,
} from './loading';
import RecreationCategoryRequests from '../../api/recreation-category';

export const ACTION_SAVE_RECREATION_CATEGORY_PAGINATED = 'ACTION_SAVE_RECREATION_CATEGORY_PAGINATED';
export const ACTION_SAVE_RECREATION_CATEGORY_DETAILS = 'ACTION_SAVE_RECREATION_CATEGORY_DETAILS';

export const ACTION_CLEAN_RECREATION_CATEGORY = 'ACTION_CLEAN_RECREATION_CATEGORY';

// cleaners
export const clean = () => ({
  type: ACTION_CLEAN_RECREATION_CATEGORY,
  payload: null,
});

export const cleanDetails = () => ({
  type: ACTION_SAVE_RECREATION_CATEGORY_DETAILS,
  payload: null,
});

export const cleanPaginated = () => ({
  type: ACTION_SAVE_RECREATION_CATEGORY_PAGINATED,
  payload: null,
});

/*
 * BASIC CRUD METHODS
 *
 * getPaginated: get rows for data table
 * getDetails: get details (get by id)
 * update: the PUT method
 * create: the POST method
 */

export const getPaginated = (parameters, callback) => async (dispatch) => {
  dispatch(addLoading());
  dispatch(cleanPaginated());

  try {
    parameters = {
      ...parameters,
      limit: parameters.limit || 10,
      total: parameters.total || 0,
      page: parameters.page || 1,
      orderBy: 'createdAt',
      isDESC: true,
    };

    dispatch({
      type: ACTION_SAVE_RECREATION_CATEGORY_PAGINATED,
      payload: null,
    });

    const response = await RecreationCategoryRequests.getPaginated(parameters);

    dispatch({
      type: ACTION_SAVE_RECREATION_CATEGORY_PAGINATED,
      payload: { rows: response, count: response.length },
    });

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const getDetails = (id, callback) => async (dispatch) => {
  dispatch(addLoading());
  dispatch(cleanDetails());

  try {
    let response = null;
    dispatch({
      type: ACTION_SAVE_RECREATION_CATEGORY_DETAILS,
      payload: null,
    });

    if (id !== 'add') {
      response = await RecreationCategoryRequests.getDetails(id);

      dispatch({
        type: ACTION_SAVE_RECREATION_CATEGORY_DETAILS,
        payload: response,
      });
    }

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const update = (id, data, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const response = await RecreationCategoryRequests.update(id, data);

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const create = (data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const response = await RecreationCategoryRequests.create(data);

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const remove = (id, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const response = await RecreationCategoryRequests.remove(id);
    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};
