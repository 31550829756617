import { I18n } from 'react-redux-i18n';

export function formatCurrency(currency) {
  if (typeof currency !== 'number') {
    currency = currency.replace(I18n.t('shared.moneyPrefix'), '');
    currency = currency.replace(I18n.t('shared.thousandSeparator'), '');
    currency = currency.replace(I18n.t('shared.decimalSeparator'), '.');
    currency = parseFloat(currency);
  }
  return currency;
}

export function replaceAtIndex(str, index, chr) {
  str = str.toString();
  if (index > str.length - 1) return str;
  return str.substr(0, index) + chr + str.substr(index + 1);
}
export function toCurrencyLocale(value) {
  if (!value) return 'R$ 0,00';
  if (value === '0,00') return 'Gratuito';

  const numero = parseFloat(value).toFixed(2).split('.');
  numero[0] = `R$ ${numero[0].split(/(?=(?:...)*$)/).join('.')}`;
  return numero.join(',');
}
