import React from 'react';
import {
  Button,
} from 'antd';
import { Link } from '@reach/router';

const AdvancedButton = (
  {
    text,
    onClick,
    type,
    clickValue,
    disabled,
    icon,
    shape,
    loading,
    htmlType,
    href,
    target,
    iconLeft,
    className,
    size,
  },
) => (
  <Button
    className={className || 'advanced-button'}
    type={type || 'primary'}
    onClick={() => (onClick ? onClick(clickValue || null) : undefined)}
    shape={shape || undefined}
    disabled={disabled || false}
    loading={loading || false}
    htmlType={htmlType || 'button'}
    target={target || '_self'}
    size={size || 'default'}
  >
    {href ? (
      <>
        {target ? (
          <a
            href={href}
            target={target || '_self'}
          >
            {iconLeft && icon} {text} {!iconLeft && icon && icon}
          </a>
        ) : (
          <Link
            to={href}
          >
            {iconLeft && icon} {text} {!iconLeft && icon && icon}
          </Link>
        )}
      </>
    ) : (
      <span>{iconLeft && icon} {text} {!iconLeft && icon && icon}</span>
    )}
  </Button>
);
export default AdvancedButton;
