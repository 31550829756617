import React from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  message,
  Divider,
  Checkbox,
} from 'antd';
import * as yup from 'yup';
import { I18n } from 'react-redux-i18n';
import { SaveOutlined } from '@ant-design/icons';

import {
  AuthSelectors, LoadingSelectors, UserSelectors,
} from '../../redux/reducers';
import AdvancedMaskedInput from '../../../components/shared/AdvancedMaskedInput/AdvancedMaskedInput';
import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import { removeSpecialChars } from '../../utils/string';

class Transaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      installmentsOptions: [],
      isSendingToWhatsApp: false,
      form: {
        value: '',
        installments: 1,
        sendPhone: '',
      },
    };
  }

  componentDidMount() {
    const { me } = this.props;

    const numberOfInstallments = (me.establishment && me.establishment.installments) || 1;
    const installmentsOptions = [];
    for (let i = 0; i < numberOfInstallments; i += 1) {
      installmentsOptions.push({ id: i + 1, name: `${i + 1} X` });
    }
    this.setState({ installmentsOptions });
  }

  onSubmit(e) {
    e.preventDefault();
    let { form } = this.state;
    const { isSendingToWhatsApp } = this.state;

    const schema = yup.object().shape({
      value: yup.string().required(I18n.t('shared.errors.missing_value')),
      installments: yup.number().required(I18n.t('shared.errors.missing_installments')),
    });

    schema.validate(form, { abortEarly: true })
      .then(() => {
        const sendPhone = form.sendPhone && removeSpecialChars(form.sendPhone);
        if (isSendingToWhatsApp && (!sendPhone || sendPhone.trim() === '' || sendPhone.length < 11)) {
          return message.error(I18n.t('routes.panel.establishment.items.transaction.details.errors.invalid_phone'));
        }

        const { details, submitFunction } = this.props;
        form = {
          installments: form.installments,
          ...(form.value && { price: form.value.replace('R$ ', '').replaceAll('.', '').replace(',', '.') }),
          ...(sendPhone && { sendPhone }),
        };
        submitFunction(details ? details.id : null, form);
      })
      .catch((err) => message.error(err.message || I18n.t('shared.genericError')));
  }

  fieldChange(name, value) {
    const { form } = this.state;
    form[name] = value;
    this.setState({ form });
  }

  render() {
    const { loading } = this.props;
    const { installmentsOptions } = this.state;
    const { form, isSendingToWhatsApp } = this.state;

    return (
      <Row>
        {loading === 0 && (
          <Col>
            <form
              name="form"
              onSubmit={(ev) => this.onSubmit(ev)}
            >
              <Row gutter={16}>
                <Col span={7}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.establishment.items.transaction.details.form.totalPrice.label')}
                    value={form && form.value}
                    onChange={(val) => this.fieldChange('value', val)}
                    isMoney
                  />
                </Col>

                <Col span={6}>
                  <AdvancedSelect
                    options={installmentsOptions}
                    label={I18n.t('routes.panel.establishment.items.transaction.details.form.installments.label')}
                    placeholder={I18n.t('shared.selectSomeValue')}
                    onChange={(val) => this.fieldChange('installments', val)}
                    disabled={loading && loading > 0}
                    disableSearch
                    hasValue
                    value={form && form.installments}
                  />
                </Col>
                {isSendingToWhatsApp && (
                  <Col span={7}>
                    <AdvancedMaskedInput
                      label={I18n.t('routes.panel.establishment.items.transaction.details.form.sendPhone.label')}
                      value={form && form.sendPhone}
                      onChange={(val) => this.fieldChange('sendPhone', val)}
                      mask={I18n.t('shared.phoneMask')}
                    />
                  </Col>
                )}
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Checkbox
                    value={isSendingToWhatsApp}
                    onChange={() => this.setState((prevState) => ({ isSendingToWhatsApp: !prevState.isSendingToWhatsApp }))}
                  >
                    {I18n.t('routes.panel.establishment.items.transaction.details.form.isSendingToWhatsApp.label')}
                  </Checkbox>
                </Col>
              </Row>

              <Divider />

              <Row>
                <Col
                  span={24}
                  className="text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.backButtonText')}
                    href={I18n.t('routes.panel.users.items.doorman.url')}
                  />

                  <Divider
                    className="form-button-divider"
                    type="vertical"
                  />

                  <AdvancedButton
                    htmlType="submit"
                    text={I18n.t('shared.saveButtonText')}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </form>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  details: UserSelectors.getDetails(state),
});

export default connect(
  mapStateToProps,
  null,
)(Transaction);
