import React from 'react';
import {
  Divider,
  Layout,
  Row,
  Col,
  message,
  Breadcrumb,
  Icon,
  Collapse,
  Popconfirm,
  Button,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { SearchOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from '@reach/router';

import {
  AuthSelectors, LoadingSelectors, ChannelsSelectors,
} from '../../../../../app/redux/reducers';
import { AuthActions, ChannelsActions, CondominiumActions } from '../../../../../app/redux/actions';
import { ChannelsBooleanArray, GetChannelsBooleanTitle } from '../../../../../app/enum/channelsType';
import { isAdmin, isCondominiumManager } from '../../../../../app/utils/permissions';

import DataTable from '../../../../../components/shared/DataTable/DataTable';
import AdvancedButton from '../../../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedAutoCompleteInput from '../../../../../components/shared/AdvancedAutoCompleteInput/AdvancedAutoCompleteInput';
import AdvancedInput from '../../../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedSelect from '../../../../../components/shared/AdvancedSelect/AdvancedSelect';
import QueryStringUtils from '../../../../../app/utils/queryString';

const qs = new QueryStringUtils();
const { Panel } = Collapse;

const defaultParams = {
  name: null,
  isFixed: null,
  isHome: null,
  condominiumId: null,
};

class ChannelList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: defaultParams,
    };
    this.dataTableRef = React.createRef();
  }

  componentDidMount() {
    const { params } = qs.get();

    if (params) {
      this.setState({ params });
    }
  }

  getPaginatedData(params) {
    const { me } = this.props;
    const { getPaginated } = this.props;

    getPaginated(params, me);
  }

  getPaginatedDataAndRefreshTable() {
    const { params } = this.state;
    if (this.dataTableRef) {
      this.dataTableRef.current.reset(false);
      this.getPaginatedData(params);
    }
  }

  fieldChange(name, value) {
    const { params } = this.state;
    if (name === 'isFixed' || name === 'isHome') params[name] = value;
    else params[name] = value || null;
    this.setState({ params });
  }

  remove(id) {
    this.props.remove(id, () => {
      message.success(I18n.t('routes.panel.communication.items.channel.messages.deleteSuccess'));
      this.getPaginatedDataAndRefreshTable();
    });
  }

  applyAdvancedFilters() {
    let { params } = this.state;
    params = {
      ...params,
      page: 1,
      offset: 0,
    };

    qs.set({ params });

    this.getPaginatedDataAndRefreshTable(params);
  }

  cleanAdvancedFilters() {
    qs.set({ params: undefined });
    window.location.reload();
  }

  render() {
    const { Content } = Layout;
    const { params } = this.state;

    const {
      channelsPaginated,
      loading,
      me,
      getCondominiumPaginatedForSearch,
    } = this.props;

    const columns = [
      {
        key: I18n.t('routes.panel.communication.items.channel.dataTable.columns.iconUrl.key'),
        title: I18n.t('routes.panel.communication.items.channel.dataTable.columns.iconUrl.title'),
        render: (value) => (value && (
          <div
            style={{
              backgroundImage: `url(${value})`,
              width: '50px',
              height: '50px',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              borderRadius: '4px',
            }}
          />
        )) || '--',
      },
      {
        key: I18n.t('routes.panel.communication.items.channel.dataTable.columns.name.key'),
        title: I18n.t('routes.panel.communication.items.channel.dataTable.columns.name.title'),
        render: (value) => value || '--',
      },
      {
        key: I18n.t('routes.panel.communication.items.channel.dataTable.columns.isHome.key'),
        title: I18n.t('routes.panel.communication.items.channel.dataTable.columns.isHome.title'),
        render: (value) => (value && GetChannelsBooleanTitle(value)) || 'Não',
      },
      {
        key: I18n.t('routes.panel.communication.items.channel.dataTable.columns.isFixed.key'),
        title: I18n.t('routes.panel.communication.items.channel.dataTable.columns.isFixed.title'),
        render: (value) => (value && GetChannelsBooleanTitle(value)) || 'Não',
      },
      {
        key: I18n.t('routes.panel.communication.items.channel.dataTable.columns.isDefault.key'),
        title: I18n.t('routes.panel.communication.items.channel.dataTable.columns.isDefault.title'),
        render: (value) => (value && GetChannelsBooleanTitle(value)) || 'Não',
      },
    ];

    if (isAdmin(me)) {
      columns.splice(2, 0, {
        key: I18n.t('routes.panel.communication.items.channel.dataTable.columns.condominiumId.key'),
        title: I18n.t('routes.panel.communication.items.channel.dataTable.columns.condominiumId.title'),
        render: (value) => value || '--',
      });
    }

    if (isAdmin(me) || isCondominiumManager(me)) {
      columns.push({
        key: I18n.t('routes.panel.users.items.administrator.dataTable.columns.actions.key'),
        title: '',
        render: (id) => (
          <div className="dataTable__item--right">
            <Popconfirm
              placement="left"
              title={I18n.t('shared.confirmTitle')}
              onConfirm={() => this.remove(id)}
              okText={I18n.t('shared.yes')}
              cancelText={I18n.t('shared.no')}
              disabled={me && me.id === id}
            >
              <Button
                type="link"
                disabled={me && me.id === id}
              >
                {I18n.t('routes.panel.communication.items.channel.dataTable.columns.actions.removeText')}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
            <Link to={`${I18n.t('routes.panel.communication.items.channel.url')}/${id}`}>
              {I18n.t('routes.panel.communication.items.channel.dataTable.columns.actions.goToDetailsText')}
              &nbsp;&nbsp;<EditOutlined />
            </Link>
          </div>
        ),
      });
    }

    return (
      <div>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" />
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.communication.items.channel.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse
            className="advanced-filter"
          >
            <Panel
              header={<strong>{I18n.t('routes.panel.communication.items.channel.advancedFilter.panelTitle')}</strong>}
              key="1"
            >
              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.communication.items.channel.advancedFilter.name.label')}
                    placeholder={I18n.t('routes.panel.communication.items.channel.advancedFilter.name.placeholder')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.communication.items.channel.advancedFilter.name.key'), val)}
                    value={params && params.name}
                  />
                </Col>

                <Col span={8}>
                  <AdvancedSelect
                    label={I18n.t('routes.panel.communication.items.channel.advancedFilter.isFixed.label')}
                    value={params && params.isFixed}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.communication.items.channel.advancedFilter.isFixed.key'), val)}
                    options={ChannelsBooleanArray}
                  />
                </Col>

                <Col span={8}>
                  <AdvancedSelect
                    label={I18n.t('routes.panel.communication.items.channel.advancedFilter.isHome.label')}
                    value={params && params.isHome}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.communication.items.channel.advancedFilter.isHome.key'), val)}
                    options={ChannelsBooleanArray}
                  />
                </Col>

              </Row>
              <Row gutter={16}>

                {isAdmin(me) && (
                  <Col span={8}>
                    <AdvancedAutoCompleteInput
                      searchMethod={(parameters) => getCondominiumPaginatedForSearch(parameters)}
                      data={I18n.t('advancedAutoComplete.condominium')}
                      onSelect={(item) => this.fieldChange('condominiumId', item.id)}
                      onSelectSelectable={(id) => this.fieldChange('condominiumId', id)}
                      value={params && params.condominiumId}
                      disableSearch
                    />
                  </Col>
                )}

              </Row>

              <Row gutter={16}>
                <Col
                  span={24}
                  className="advanced-filter__search-button text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.advancedFilter.cleanButtonText')}
                    onClick={() => this.cleanAdvancedFilters()}
                  />
                  <AdvancedButton
                    text={I18n.t('shared.advancedFilter.searchButtonText')}
                    icon={<SearchOutlined />}
                    onClick={() => this.applyAdvancedFilters()}
                  />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="administrators">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    <Icon type={I18n.t('routes.panel.communication.items.channel.icon')} />
                  </span>
                  {I18n.t('routes.panel.communication.items.channel.pageTitle')}
                </h2>
              </Col>
              {(isAdmin(me) || isCondominiumManager(me)) && (
                <Col
                  className="text-right"
                  span={12}
                >
                  <AdvancedButton
                    text={I18n.t('routes.panel.communication.items.channel.addNewButtonText')}
                    href={`${I18n.t('routes.panel.communication.items.channel.url')}/add`}
                    icon={<Icon type={I18n.t('routes.panel.communication.items.channel.icon')} />}
                  />
                </Col>
              )}
            </Row>

            <Divider />

            <div>
              <DataTable
                getMethod={(parameters) => this.getPaginatedData(parameters)}
                data={channelsPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                columns={columns}
              />
            </div>
          </div>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  channelsPaginated: ChannelsSelectors.getPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters, me) => dispatch(ChannelsActions.getPaginated(parameters, me)),
  remove: (id, callback) => dispatch(ChannelsActions.remove(id, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
  getCondominiumPaginatedForSearch: (parameters) => dispatch(CondominiumActions.getPaginatedForSearch(parameters)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChannelList);
