export default {
  localhost: 'http://localhost:3048/api',
  development: 'https://bab-api-dev.mblabs.site/api',
  homolog: 'https://bab-api-hom.mblabs.site/api',
  production: 'https://bab-api-dev.mblabs.site/api',

  localhostAuth: 'https://auth.mblabs.com.br/api',
  developmentAuth: 'https://auth.mblabs.com.br/api',
  homologAuth: 'https://auth.mblabs.com.br/api',
  productionAuth: 'https://auth.mblabs.com.br/api',

  authApiKey: 'cba9c7bb-d920-488b-8199-731f2ccb3960',
  authApiKeyDev: 'cba9c7bb-d920-488b-8199-731f2ccb3960',
  authApiKeyHom: '900d3816-ced9-438c-ad2b-222b294398c6',
};
