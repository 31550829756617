import React from 'react';
import {
  Divider,
  Layout,
  Row,
  Col,
  message, Breadcrumb, Icon, Collapse,
  Popconfirm, Button,
  Avatar,
  Modal,
  Checkbox,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  SearchOutlined, CheckCircleOutlined, CloseCircleOutlined, UserOutlined,
} from '@ant-design/icons';

import { SolicitationActions, CondominiumActions } from '../../../../../app/redux/actions';
import { SolicitationSelectors, LoadingSelectors, AuthSelectors } from '../../../../../app/redux/reducers';

import QueryStringUtils from '../../../../../app/utils/queryString';
import * as DateUtils from '../../../../../app/utils/date';
import {
  CondominiumUserStatusTypeArray,
  CondominiumUserStatusType,
  GetCondominiumUserStatusTypeTitle,
} from '../../../../../app/enum/condominiumUserStatusType';
import { isAdmin, isCondominiumManager } from '../../../../../app/utils/permissions';

import DataTable from '../../../../../components/shared/DataTable/DataTable';
import AdvancedButton from '../../../../../components/shared/AdvancedButton/AdvancedButton';
import AdvancedInput from '../../../../../components/shared/AdvancedInput/AdvancedInput';
import AdvancedSelect from '../../../../../components/shared/AdvancedSelect/AdvancedSelect';
import AdvancedAutoCompleteInput from '../../../../../components/shared/AdvancedAutoCompleteInput/AdvancedAutoCompleteInput';

const qs = new QueryStringUtils();
const { Panel } = Collapse;

const defaultParams = {
  status: CondominiumUserStatusType.PENDING,
  number: null,
  block: null,
  condominiumId: null,
};

const getCondominiumUserStatus = () => {
  const options = CondominiumUserStatusTypeArray;
  return options.concat({ id: 10, name: 'Todos' });
};

class SolicitationsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: defaultParams,
      isModalVisible: false,
      isOwner: false,
      associationId: null,
    };
    this.dataTableRef = React.createRef();
  }

  getPaginatedData(params) {
    const { getPaginated } = this.props;
    const newParams = { ...params };

    getPaginated(newParams);
  }

  getPaginatedDataAndRefreshTable() {
    const { params } = this.state;
    if (this.dataTableRef) {
      this.dataTableRef.current.reset(false);
      this.getPaginatedData(params);
    }
  }

  fieldChange(name, value) {
    const { params } = this.state;
    params[name] = value || null;
    this.setState({ params });
  }

  remove(id) {
    const { update } = this.props;

    update(id, { status: CondominiumUserStatusType.REFUSED }, () => {
      message.success(I18n.t('routes.panel.condominium.items.solicitations.messages.deleteSuccess'));
      this.getPaginatedDataAndRefreshTable();
      this.setState({ isModalVisible: false });
    });
  }

  update(id) {
    const { update } = this.props;
    const {
      isOwner,
    } = this.state;

    const updateData = {
      status: CondominiumUserStatusType.APPROVED,
      isOwner,
      canReceiveVisit: isOwner,
      canReceiveOrder: isOwner,
      canRegisterFamiliar: isOwner,
    };

    update(id, updateData, () => {
      message.success(I18n.t('routes.panel.condominium.items.solicitations.messages.editSuccess'));
      this.setState({ isModalVisible: false });
      this.getPaginatedDataAndRefreshTable();
    });
  }

  applyAdvancedFilters() {
    let { params } = this.state;
    params = {
      ...params,
      page: 1,
      offset: 0,
    };

    qs.set({ params });

    this.getPaginatedDataAndRefreshTable(params);
  }

  cleanAdvancedFilters() {
    qs.set({ params: undefined });
    window.location.reload();
  }

  render() {
    const { Content } = Layout;
    const {
      params,
      isModalVisible,
      isOwner,
      associationId,
    } = this.state;
    const { paginatedData, loading, me } = this.props;

    const columns = [
      {
        key: I18n.t('routes.panel.condominium.items.solicitations.dataTable.columns.profilePicUrl.key'),
        title: I18n.t('routes.panel.condominium.items.solicitations.dataTable.columns.profilePicUrl.title'),
        render: (value, record) => {
          const props = { size: 64, shape: 'square' };
          if (record && record.user && record.user.profilePicUrl) {
            props.src = record.user.profilePicUrl;
          } else {
            props.icon = <UserOutlined style={{ fontSize: '36px !important' }} />;
          }
          return (
            <Avatar {...props} />);
        },
      },
      {
        key: I18n.t('routes.panel.condominium.items.solicitations.dataTable.columns.name.key'),
        title: I18n.t('routes.panel.condominium.items.solicitations.dataTable.columns.name.title'),
        render: (value, record) => (record && record.user && record.user.name) || '--',
      },
      {
        key: I18n.t('routes.panel.condominium.items.solicitations.dataTable.columns.cellphone.key'),
        title: I18n.t('routes.panel.condominium.items.solicitations.dataTable.columns.cellphone.title'),
        render: (value, record) => (record && record.user && record.user.cellphone) || '--',
      },
      {
        key: I18n.t('routes.panel.condominium.items.solicitations.dataTable.columns.email.key'),
        title: I18n.t('routes.panel.condominium.items.solicitations.dataTable.columns.email.title'),
        render: (value, record) => (record && record.user && record.user.email) || '--',
      },
      {
        key: I18n.t('routes.panel.condominium.items.solicitations.dataTable.columns.block.key'),
        title: I18n.t('routes.panel.condominium.items.solicitations.dataTable.columns.block.title'),
        render: (value) => (value) || '--',
      },
      {
        key: I18n.t('routes.panel.condominium.items.solicitations.dataTable.columns.number.key'),
        title: I18n.t('routes.panel.condominium.items.solicitations.dataTable.columns.number.title'),
        render: (value) => (value) || '--',
      },
      {
        key: I18n.t('routes.panel.condominium.items.solicitations.dataTable.columns.status.key'),
        title: I18n.t('routes.panel.condominium.items.solicitations.dataTable.columns.status.title'),
        render: (value) => (value && GetCondominiumUserStatusTypeTitle(value)) || '--',
      },
      {
        key: I18n.t('routes.panel.condominium.items.solicitations.dataTable.columns.createdAt.key'),
        title: I18n.t('routes.panel.condominium.items.solicitations.dataTable.columns.createdAt.title'),
        render: (value) => (value && DateUtils.humanizeDateTime(value, 'DD/MM/YYYY HH:mm')) || '--',
      },
    ];

    if (isAdmin(me)) {
      columns.splice(4, 0, {
        key: I18n.t('routes.panel.condominium.items.solicitations.dataTable.columns.condominium.key'),
        title: I18n.t('routes.panel.condominium.items.solicitations.dataTable.columns.condominium.title'),
        render: (value) => (value && value.name) || '--',
      });
    }

    if (isAdmin(me) || isCondominiumManager(me)) {
      columns.push({
        key: I18n.t('routes.panel.condominium.items.solicitations.dataTable.columns.actions.key'),
        title: '',
        render: (id, body) => (
          <>
            {body.status === CondominiumUserStatusType.PENDING && (
              <>
                <Popconfirm
                  placement="left"
                  title={I18n.t('shared.confirmTitle')}
                  onConfirm={() => this.setState({ isModalVisible: true, associationId: id })}
                  okText={I18n.t('shared.yes')}
                  cancelText={I18n.t('shared.no')}
                  disabled={me && me.id === id}
                >
                  <Button
                    type="link"
                    style={{
                      color: '#097309',
                    }}
                  >
                    {I18n.t('routes.panel.condominium.items.solicitations.dataTable.columns.actions.approve')}
                    <CheckCircleOutlined />
                  </Button>
                </Popconfirm>
                <Divider type="vertical" />
                <Popconfirm
                  placement="left"
                  title={I18n.t('shared.confirmTitle')}
                  onConfirm={() => this.remove(id)}
                  okText={I18n.t('shared.yes')}
                  cancelText={I18n.t('shared.no')}
                  disabled={me && me.id === id}
                >
                  <Button
                    type="link"
                    disabled={me && me.id === id}
                    style={{
                      color: '#ff0000',
                    }}
                  >
                    {I18n.t('routes.panel.condominium.items.solicitations.dataTable.columns.actions.disapprove')}
                    <CloseCircleOutlined />
                  </Button>
                </Popconfirm>
              </>
            )}
          </>
        ),
      });
    }

    return (
      <>
        <Modal
          title={I18n.t('routes.panel.condominium.items.solicitations.modal.title')}
          visible={isModalVisible}
          onOk={() => this.update(associationId)}
          onCancel={() => this.setState({
            isModalVisible: false,
            isOwner: false,
            associationId: null,
          })}
        >
          <Row gutter={16}>
            <Checkbox
              value={isOwner}
              onChange={() => this.setState((prevState) => ({ isOwner: !prevState.isOwner }))}
            >
              {I18n.t('routes.panel.condominium.items.solicitations.modal.isOwner')}
            </Checkbox>
          </Row>
        </Modal>
        <div>
          <Content className="panel__layout__content panel__layout__content--breadcrumb">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Icon type="dashboard" /> <span>{I18n.t('routes.panel.pageTitle')}</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <span>{I18n.t('routes.panel.condominium.items.solicitations.pageTitle')}</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Content>

          <Content className="panel__layout__content panel__layout__content--advanced-filter">
            <Collapse
              className="advanced-filter"
            >
              <Panel
                header={<strong>{I18n.t('routes.panel.condominium.items.solicitations.advancedFilter.panelTitle')}</strong>}
                key="1"
              >
                {isAdmin(me) && (
                <Row gutter={16}>
                  <Col span={8}>
                    <AdvancedAutoCompleteInput
                      searchMethod={(parameters) => this.props.getCondominiumPaginatedForSearch(parameters)}
                      data={I18n.t('advancedAutoComplete.condominium')}
                      onSelect={(item) => this.fieldChange('condominiumId', item.id)}
                      onSelectSelectable={(id) => this.fieldChange('condominiumId', id)}
                      value={params && params.condominiumId}
                      disableSearch
                    />
                  </Col>
                </Row>
                )}

                <Row gutter={16}>
                  <Col span={6}>
                    <AdvancedSelect
                      label={I18n.t('routes.panel.condominium.items.solicitations.advancedFilter.status.label')}
                      value={params && params.status}
                      onChange={(val) => this.fieldChange(I18n.t('routes.panel.condominium.items.solicitations.advancedFilter.status.key'), val)}
                      options={getCondominiumUserStatus()}
                    />
                  </Col>

                  <Col span={6}>
                    <AdvancedInput
                      label={I18n.t('routes.panel.condominium.items.solicitations.advancedFilter.number.label')}
                      onChange={(val) => this.fieldChange(I18n.t('routes.panel.condominium.items.solicitations.advancedFilter.number.key'), val)}
                      value={params && params.number}
                    />
                  </Col>
                  <Col span={6}>
                    <AdvancedInput
                      label={I18n.t('routes.panel.condominium.items.solicitations.advancedFilter.tower.label')}
                      onChange={(val) => this.fieldChange(I18n.t('routes.panel.condominium.items.solicitations.advancedFilter.tower.key'), val)}
                      value={params && params.tower}
                    />
                  </Col>
                  <Col span={6}>
                    <AdvancedInput
                      label={I18n.t('routes.panel.condominium.items.solicitations.advancedFilter.block.label')}
                      onChange={(val) => this.fieldChange(I18n.t('routes.panel.condominium.items.solicitations.advancedFilter.block.key'), val)}
                      value={params && params.block}
                    />
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col
                    span={24}
                    className="advanced-filter__search-button text-right"
                  >
                    <AdvancedButton
                      type="link"
                      text={I18n.t('shared.advancedFilter.cleanButtonText')}
                      onClick={() => this.cleanAdvancedFilters()}
                    />
                    <AdvancedButton
                      text={I18n.t('shared.advancedFilter.searchButtonText')}
                      icon={<SearchOutlined />}
                      onClick={() => this.applyAdvancedFilters()}
                    />
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </Content>

          <Content className="panel__layout__content">
            <div className="administrators">
              <Row>
                <Col span={12}>
                  <h2>
                    <span className="panel__layout__content__title__value__icon">
                      <Icon type={I18n.t('routes.panel.condominium.items.solicitations.icon')} />
                    </span>
                    {I18n.t('routes.panel.condominium.items.solicitations.pageTitle')}
                  </h2>
                </Col>
              </Row>

              <Divider />

              <div>
                <DataTable
                  getMethod={(parameters) => this.getPaginatedData(parameters)}
                  data={paginatedData}
                  loading={loading > 0}
                  ref={this.dataTableRef}
                  columns={columns}
                />
              </div>
            </div>
          </Content>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  paginatedData: SolicitationSelectors.getPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters) => dispatch(SolicitationActions.getPaginated(parameters)),
  getCondominiumPaginatedForSearch: (parameters) => dispatch(CondominiumActions.getPaginatedForSearch(parameters)),
  update: (id, data, callback) => dispatch(SolicitationActions.update(id, data, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SolicitationsList);
