import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import { navigate } from '@reach/router';
import AuthRequests from '../../api/auth';
import AuthService from '../../services/auth';

import { addLoading, removeLoading } from './loading';

import * as UserActions from './user';
import { ProfileType } from '../../enum/profileType';

export const ACTION_AUTH_LOGIN = 'ACTION_AUTH_LOGIN';
export const ACTION_AUTH_LOGOUT = 'ACTION_AUTH_LOGOUT';

export const ACTION_ME = 'ACTION_ME';

export const saveAuthentication = (data) => ({
  type: ACTION_AUTH_LOGIN,
  auth: data,
});

export const cleanAuth = () => async (dispatch) => {
  dispatch({
    type: ACTION_AUTH_LOGOUT,
  });
  dispatch(UserActions.clean());
  AuthService.reset();
  AuthService.redirectToLogin();
};

export const refreshToken = (token) => async (dispatch) => {
  try {
    const auth = await AuthRequests.refresh(token);
    await AuthService.create(auth);
    await dispatch(saveAuthentication(auth));
    return auth;
  } catch (err) {
    throw new Error(I18n.t('screen.login.errors.undefined'));
  }
};

export const getMe = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const data = await AuthRequests.me();
    dispatch({
      type: ACTION_ME,
      payload: data,
    });
  } catch (err) {
    // message.error(I18n.t(`routes.login.errors.${err.message}`));
  } finally {
    dispatch(removeLoading());
  }
};

export const verifyInitialFlow = () => async (dispatch, getState) => {
  try {
    await dispatch(getMe());
    const { me } = getState().auth;

    if (me.profileType === ProfileType.ADMIN) {
      navigate(I18n.t('routes.panel.users.items.administrator.url'));
    } else if (me.profileType === ProfileType.ESTABLISHMENT_ADMIN) {
      navigate(I18n.t('routes.panel.users.items.deliveryman.url'));
    } else {
      navigate(I18n.t('routes.panel.condominium.items.visits.url'));
    }
  } catch (err) {
    throw new Error(I18n.t('screen.login.errors.undefined'));
  }
};

export const authenticate = (userData, callback) => async (dispatch, getState) => {
  dispatch(addLoading());
  try {
    userData = {
      ...userData,
      grantType: 'password',
    };

    const auth = await AuthRequests.auth(userData);
    AuthService.create(auth);
    dispatch({
      type: ACTION_AUTH_LOGIN,
      auth,
    });

    await dispatch(getMe());
    const { me } = getState().auth;

    if (me.profileType === ProfileType.ADMIN) {
      navigate(I18n.t('routes.panel.users.items.administrator.url'));
    } else if (me.profileType === ProfileType.ESTABLISHMENT_ADMIN) {
      navigate(I18n.t('routes.panel.users.items.deliveryman.url'));
    } else {
      navigate(I18n.t('routes.panel.condominium.items.visits.url'));
    }

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t(`routes.login.errors.${err.message}`));
  } finally {
    dispatch(removeLoading());
  }
};

export const sendRecoveryEmail = (data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    data = {
      ...data,
      sendTo: 'email',
    };
    await AuthRequests.sendRecoveryEmail(data);

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t(`routes.forgotPassword.errors.${err.message}`));
  } finally {
    dispatch(removeLoading());
  }
};

export const validateCode = (data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await AuthRequests.validateCode(data);

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t(`routes.forgotPasswordCode.errors.${err.message}`));
  } finally {
    dispatch(removeLoading());
  }
};

export const passwordRecovery = (data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await AuthRequests.passwordRecovery(data);

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t(`routes.forgotPasswordCode.errors.${err.message}`));
  } finally {
    dispatch(removeLoading());
  }
};

export const logout = () => async (dispatch) => {
  dispatch(addLoading());
  dispatch(cleanAuth());
  dispatch(removeLoading());
};
