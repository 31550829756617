import { Translate } from 'react-redux-i18n';
import React from 'react';

const notImported = {
  EMAIL_AND_CELLPHONE_AND_NUMBER_AND_BLOCK_ALREADY_REGISTERED: 'email_and_cellphone_and_number_and_block_already_registered',
  EMAIL_AND_NUMBER_AND_BLOCK_ALREADY_REGISTERED: 'email_and_number_and_block_already_registered',
  CELLPHONE_AND_NUMBER_AND_BLOCK_ALREADY_REGISTERED: 'cellphone_and_number_and_block_already_registered',
};

export const notImportedArray = [
  {
    id: notImported.EMAIL_AND_CELLPHONE_AND_NUMBER_AND_BLOCK_ALREADY_REGISTERED,
    name: <Translate value="enum.notImportedType.email_cellphone" />,
  },
  {
    id: notImported.EMAIL_AND_NUMBER_AND_BLOCK_ALREADY_REGISTERED,
    name: <Translate value="enum.notImportedType.email" />,
  },
  {
    id: notImported.CELLPHONE_AND_NUMBER_AND_BLOCK_ALREADY_REGISTERED,
    name: <Translate value="enum.notImportedType.cellphone" />,
  },
];

export const GetNotImportedTitle = (value) => (notImportedArray.find((o) => o.id === value).name);

export default notImported;
