import { Translate } from 'react-redux-i18n';
import React from 'react';

const DaysType = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7,
};

export const DaysTypeArray = [
  { id: DaysType.MONDAY, name: <Translate value="enum.daysType.monday" /> },
  { id: DaysType.TUESDAY, name: <Translate value="enum.daysType.tuesday" /> },
  { id: DaysType.WEDNESDAY, name: <Translate value="enum.daysType.wednesday" /> },
  { id: DaysType.THURSDAY, name: <Translate value="enum.daysType.thursday" /> },
  { id: DaysType.FRIDAY, name: <Translate value="enum.daysType.friday" /> },
  { id: DaysType.SATURDAY, name: <Translate value="enum.daysType.saturday" /> },
  { id: DaysType.SUNDAY, name: <Translate value="enum.daysType.sunday" /> },
];

export const GetDaysTypeTitle = (value) => (DaysTypeArray.find((o) => o.id === value).name);

export default DaysType;
