import getInstance from './config';

export default class CondominiumUserApi {
  static async getPaginated(params) {
    const instance = await getInstance();
    const { data } = await instance.get('/v1/condominium-user', { params });
    return data;
  }

  static async update(id, payload) {
    const instance = await getInstance();
    const { data } = await instance.put(`/v1/condominium-user/${id}`, payload);
    return data;
  }
}
